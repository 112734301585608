import { SignIn } from "@react-gcc-eds/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUserActions } from "../../actions/user-actions";
import { useStore } from "../../store";
import {
  deleteUnauthorizedUrl,
  getPersistedUnauthorizedUrl
} from "../../utils/local-storage/authorization";

const LoggedIn = () => {
  const [state] = useStore();
  const history = useHistory();
  const { getUser } = useUserActions();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (state.user) {
      const tmo = setTimeout(() => {
        const redirectUrl = getPersistedUnauthorizedUrl() || "/";
        deleteUnauthorizedUrl();
        history.replace(redirectUrl);
      }, 1000);

      return () => clearTimeout(tmo);
    }
  }, [state.user]);

  const fakeUser = { firstName: "", lastName: "" };

  return (
    <SignIn productName="Ericsson Customer Order Dashboard" signedInUser={state.user || fakeUser} />
  );
};

export default LoggedIn;
