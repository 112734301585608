import React, { useEffect, useMemo, useState } from "react";
import ReactJoyride, { CallBackProps, STATUS } from "react-joyride";
import WalkThroughDialog from "./components/walk-through-dialog";
import { useSettings } from "@react-gcc-eds/core";
import { IStep, useStore } from "../../store";

export const useWalkThrough = ({ steps, maxWidth }: { steps: IStep[]; maxWidth?: number }) => {
  const [state, setState] = useStore();
  const [running, setRunning] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [maxCount, setMaxCount] = useState(0);

  const resetCount = () => {
    setStepCount(0);
    setMaxCount(0);
  };

  const startWalkThrough = (continousWalkthrough?: boolean) => {
    resetCount();
    setRunning(true);
    if (continousWalkthrough) {
      setState(old => ({
        ...old,
        walkthrough: {
          ...old.walkthrough,
          continueBeingActive: true
        }
      }));
    }
  };

  useEffect(() => {
    if (running) {
      setMaxCount(old => Math.max(old, stepCount));
    }
  }, [stepCount]);

  const currStep = useMemo(() => {
    if (running) {
      return steps[stepCount];
    }
  }, [stepCount]);

  useEffect(() => {
    if (currStep) {
      setState(old => ({
        ...old,
        walkthrough: {
          ...old.walkthrough,
          blockClickOutside: currStep.blockClickOutside
        }
      }));
    }
  }, [currStep]);

  const callBackFunction = async (data: CallBackProps) => {
    const { type, action, index, status } = data;
    const stepCallback = steps[index];
    if (stepCallback && type === "step:after") {
      if (action === "next") {
        setStepCount(old => (old < data.size ? data.index + 1 : data.size));
      }
      if (action === "prev") {
        if (stepCallback.backSeveralSteps) {
          const backsteps = stepCallback.backSeveralSteps;
          setStepCount(old => (old > backsteps ? data.index - backsteps : 0));
        } else {
          setStepCount(old => (old > 1 ? data.index - 1 : 0));
        }
      }
      if (action === "close" || action === "skip") {
        if (currStep && currStep.setupBeforeClose) {
          await currStep.setupBeforeClose();
        }
        setState(old => ({
          ...old,
          walkthrough: { ...old.walkthrough, continueBeingActive: false }
        }));
        resetCount();
        setRunning(false);
      }
    }
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRunning(false);
      resetCount();
    }
  };

  const WalkThrough = () => {
    const { theme } = useSettings();
    return running ? (
      <div className="walk-trough">
        <ReactJoyride
          run={running}
          steps={steps}
          stepIndex={stepCount}
          disableOverlayClose
          disableScrolling={currStep && currStep.disableScrolling}
          callback={callBackFunction}
          tooltipComponent={props =>
            WalkThroughDialog({
              ...props,
              totalSteps: steps.length - 1,
              stepProps: steps[props.index],
              maxStep: maxCount,
              maxWidth: maxWidth ? maxWidth - 50 : undefined
            })
          }
          continuous
          styles={{
            options: {
              arrowColor: theme === "dark" ? "#242424" : "#fafafa"
            }
          }}
        />
      </div>
    ) : null;
  };

  return { WalkThrough, startWalkThrough, stepCount };
};
