import React, { useEffect, useMemo, useRef, useState } from "react";
import { parse } from "date-fns";
import { Pill, Tile } from "@react-gcc-eds/core";

import { ISearchResult, isFetching, IWeekMetric, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { IDateRange } from "../../../../contracts";
import { WeekBarChart } from "../bars-chart";
import { useSearchActions } from "../../../../actions/search-actions";
import SearchBarFilter from "../../../filters/search-bar-filter";
import { DashboardGraph, DashboardTileAlignment, IDashboardItemSelected } from "..";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardPlannedDeliveryDateSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { dateAsYMDString } from "../../../../utils";
import { useLocalization } from "../../../../utils/localization";

/*

All commented code here is due to the fact that PO graph is not relevant at the moment

*/
const PlannedDeliveryDate = ({
  customerId,
  className,
  alignment,
  onItemSelected
}: {
  customerId: string;
  className: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const [state] = useStore();
  const { localeString } = useLocalization();
  const [dateRange, setDateRange] = useState<IDateRange>();
  const { steps } = useDashboardPlannedDeliveryDateSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { getItemPlannedDeliveriesMetric } = useMetricsActions();
  const { setMetricsPlannedDeliveryItemsSearch } = useMetricsFiltersActions();
  const { searchPlannedItems } = useSearchActions();
  const { isWalkthroughEnabled } = useSettingsActions();
  const { itemPlannedDeliveryDateMetric } = state.metrics;
  const { plannedDeliverySearchResult } = state.metricsSearches;
  const { itemPlannedDeliveryDateMetric: loading } = state.fetchingData.metrics;

  useEffect(() => {
    getItemPlannedDeliveriesMetric(
      state.metricsFilters.plannedDeliveryItemsSearch
        ? state.metricsFilters.plannedDeliveryItemsSearch.purchaseOrderItemIds
        : []
    );
  }, [customerId, state.metricsFilters.plannedDeliveryItemsSearch]);

  useEffect(() => {
    const metric = itemPlannedDeliveryDateMetric;
    if (metric && metric.length) {
      const from = new Date(metric[0].startDate) as Date;
      const to = new Date(metric[metric.length - 1].endDate) as Date;
      setDateRange({
        from,
        to
      } as IDateRange);
    }
  }, [itemPlannedDeliveryDateMetric]);

  const onWeekSelected = (metrics: IWeekMetric[], graph: DashboardGraph) => {
    if (!onItemSelected) {
      return undefined;
    }

    return (selection: { label: string }) => {
      const { label, ...rest } = selection;
      const week = label.replace(/^[W0]+/, "");
      const weekData = metrics.find(w => w.week.toString() === week);
      const { plannedDeliveryItemsSearch } = state.metricsFilters;

      if (weekData) {
        onItemSelected({
          filters: {
            ...rest,
            calculatedConfirmRange: {
              from: parse(weekData.startDate, "yyyy-MM-dd", new Date(1901, 1, 1)),
              to: parse(weekData.endDate, "yyyy-MM-dd", new Date(1901, 1, 1))
            },
            property: plannedDeliveryItemsSearch
              ? {
                  name: plannedDeliveryItemsSearch.originalProperty,
                  value: plannedDeliveryItemsSearch.matchingValue
                }
              : undefined
          },
          graph
        });
      }
    };
  };

  const tileHeader = (
    <WalkthroughHeader
      walkthroughActive={isWalkthroughEnabled()}
      header={localeString("Dashboard_PlannedDeliveryDate") || "Planned delivery date"}
      tooltipInfo={`Graph is showing what has been planned for delivery in the included weeks.\nFor weeks prior to current all completed deliveries has been included`}
      alignment={alignment}
      onClick={startWalkThrough}
      disabled={isFetching(state)}
    />
  );

  const onSearchResultClick = (result: ISearchResult) => {
    setMetricsPlannedDeliveryItemsSearch(result);
  };

  const onBarChartSearch = (searchQuery: string) => {
    if (dateRange && searchQuery.length > 0) {
      const from = dateAsYMDString(dateRange.from);
      const to = dateAsYMDString(dateRange.to);
      searchPlannedItems(searchQuery, from, to);
    }
  };

  const filters = !loading ? (
    <SearchBarFilter
      onSearchQueryChange={onBarChartSearch}
      searchResult={plannedDeliverySearchResult}
      resultAlignment="results-right"
      datesRange={dateRange}
      onClickSearchResult={onSearchResultClick}
      big
      placeholderText="Search material..."
      // placeholderText={`Search ${localeString("View_po_it")}, Material Description or Number...`}
    />
  ) : undefined;

  const getSelectionPills = () => {
    const { plannedDeliveryItemsSearch } = state.metricsFilters;
    const pills = [<Pill key="period-pill">Weekly</Pill>];
    if (plannedDeliveryItemsSearch) {
      pills.push(
        <Pill
          key="selected-item-pill"
          onRemove={() => setMetricsPlannedDeliveryItemsSearch(undefined)}
        >
          {plannedDeliveryItemsSearch.matchingValue}
        </Pill>
      );
    }
    return pills;
  };

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={getSelectionPills()}
        actions={filters}
      >
        <WeekBarChart
          loading={loading === true}
          metrics={itemPlannedDeliveryDateMetric}
          unit=""
          onSelection={onWeekSelected(itemPlannedDeliveryDateMetric, DashboardGraph.Items)}
        />
      </Tile>
      <WalkThrough />
    </>
  );
};

export default PlannedDeliveryDate;
