import {
  Dropdown,
  Radiobutton,
  IDropdownItem,
  IRadiobutton,
  Row,
  Column,
  Switch,
  Loader,
  Icon,
  Tooltip
} from "@react-gcc-eds/core";
import { useFeedback } from "components/mail-forms/contact-us/feedback-actions";
import ItemsFilters from "components/reports/delivery-schedule/items-filters";
import { useEffect, useState } from "react";
import { useUserHelpers } from "../../../../utils/user-helpers";
import {
  INotificationItem,
  IUser,
  IUserCustomerNotificationItem,
  useStore
} from "../../../../store";
import { useLocalization } from "../../../../utils/localization";

const NotificationsTab = ({
  onDayChanged,
  onFrequencyChanged,
  onNotificationScopeChanged,
  notifications,
  adminNotifications,
  onNotificationEnabled
}: {
  onDayChanged(i: number): void;
  onFrequencyChanged(i: number): void;
  onNotificationScopeChanged(value: number, item?: IUserCustomerNotificationItem): void;
  notifications: {
    current: IUserCustomerNotificationItem[];
    original: IUserCustomerNotificationItem[];
    equals: boolean;
  };
  adminNotifications: INotificationItem[];
  onNotificationEnabled: (key: number, value: boolean) => void;
}) => {
  const [state] = useStore();
  const [notificationsList, setNotificationsList] = useState<{
    current: IUserCustomerNotificationItem[];
    original: IUserCustomerNotificationItem[];
    equals: boolean;
  }>({ current: [], original: [], equals: true });
  const [frequencyValue, setFrequencyValue] = useState(0);
  const [notificationScope, setNotificationScope] = useState(0);
  const { localeString } = useLocalization();
  const [selectedDay, setSelectedDay] = useState(1);
  const [items, setItems] = useState<IUserCustomerNotificationItem[]>([]);
  const { userRoleIsSuperUser, userRoleIsAdministrator, userRoleIsTechSupport } = useUserHelpers();

  const dropDownItems = () => {
    const items = [
      {
        title: "Monday",
        value: 1
      },
      {
        title: "Tuesday",
        value: 2
      },
      {
        title: "Wednesday",
        value: 3
      },
      {
        title: "Thursday",
        value: 4
      },
      {
        title: "Friday",
        value: 5
      },
      {
        title: "Saturday",
        value: 6
      },
      {
        title: "Sunday",
        value: 0
      }
    ];

    return items;
  };

  const notificationLabels = [
    {
      title: "Shipment creation",
      index: 1
    },
    {
      title: "Shipment started",
      index: 2
    },
    {
      title: "Shipment delivered",
      index: 3
    },
    {
      title: "Purchase order delivered",
      index: 4
    }
  ];

  const notificationDescriptions = [
    {
      notificationKey: "ShipmentCreation",
      title: localeString("Notification_ShipmentCreation") || "Shipment creation",
      description: "Turn on notifications for shipment creation"
    },
    {
      notificationKey: "ShipmentStarted",
      title: localeString("Notification_ShipmentStarted") || "Shipment started",
      description: "Turn on notifications for shipment started"
    },
    {
      notificationKey: "ShipmentDelivered",
      title: localeString("Notification_ShipmentDelivered") || "Shipment delivered",
      description: "Turn on notifications for shipment delivered"
    },
    {
      notificationKey: "PurchaseOrderDelivered",
      title: localeString("Notification_PurchaseOrderDelivered") || "Purchase order delivered",
      description: "Turn on notifications for purchase order delivered"
    }
  ];
  const isAdminRole = (user: IUser) => {
    if (
      userRoleIsSuperUser(user.role) ||
      userRoleIsAdministrator(user.role) ||
      userRoleIsTechSupport(user.role)
    )
      return true;
    return false;
  };

  const filterRoles = (items: IUserCustomerNotificationItem[]) => {
    const currentUser = state.user;

    const filteredAdminNotifications =
      currentUser &&
      state.notifications.filter(y => y.visible && y.roles.some(x => x === currentUser.role));

    const filteredUserCustomerNotifications =
      filteredAdminNotifications &&
      items.filter(x =>
        filteredAdminNotifications.some(y => y.notificationId === x.notificationId)
      );

    return currentUser && isAdminRole(currentUser)
      ? items.filter(x =>
          state.notifications.find(y => y.visible && y.notificationId === x.notificationId)
        )
      : filteredUserCustomerNotifications;
  };

  useEffect(() => {
    const filterItems = filterRoles(notifications.current);
    filterItems && setItems(filterItems);
  }, [notifications]);

  const notificationDescription = (notification: IUserCustomerNotificationItem) => {
    const key = state.notifications.find(
      x => x.notificationId === notification.notificationId
    )?.notificationKey;
    const description = notificationDescriptions.find(d => d.notificationKey === key);
    return description || { title: "", description: "" };
  };

  const areEqual = (a?: IUserCustomerNotificationItem[], b?: IUserCustomerNotificationItem[]) => {
    if (!a || !b) return false;
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const tooltipTexts = {
    bookmarkedOnly: "Select this options if you want notifications only on specific order items",
    POBookmarkedOnly: "You can bookmark the relevant orders from Purchase order list"
  };

  return (
    <div className="notifications-selection">
      <h4>Select your preferred frequency of notifications</h4>
      <Row>
        <Column>
          <div className="selections">
            <div className="left">
              <Radiobutton
                text="Daily"
                onChange={(newValue: boolean) => {
                  setFrequencyValue(0);
                  onFrequencyChanged(0);
                  // onViewChanged(frequencyValue, newValue);
                }}
                value={notifications.current[0].frequency === 0 ? true : false}
              />
              <Radiobutton
                text="Weekly"
                onChange={() => {
                  setFrequencyValue(1);
                  onFrequencyChanged(1);
                  // onViewChanged(frequencyValue, newValue);
                }}
                value={notifications.current[0].frequency === 1 ? true : false}
              />
            </div>
            <div className="right">
              <Dropdown
                items={dropDownItems().map(item => {
                  return { ...item, value: item.value.toString() };
                })}
                label={
                  notifications.current[0].recurringDay
                    ? dropDownItems().find(x => x.value === notifications.current[0].recurringDay)
                        ?.title
                    : "Monday"
                }
                onItemClick={(d: IDropdownItem) => {
                  setSelectedDay(Number(d.value));
                  onDayChanged(Number(d.value));
                }}
              />
            </div>
          </div>
        </Column>
      </Row>
      <div className="admin-dashboard-table-body notifications-body">
        <Row>
          <Column lg={6} md={6} sm={6} className="notifications-title-column">
            <></>
          </Column>
          <Column lg={2} md={2} sm={2} className="notifications-title-column">
            <span className="notifications-scope-title">All</span>
          </Column>
          <Column lg={4} md={4} sm={4} className="notifications-title-column">
            <span className="notifications-scope-title">Bookmarked only</span>
            <Tooltip text={tooltipTexts.bookmarkedOnly} type="pointer" position="right">
              <Icon name="info"></Icon>
            </Tooltip>
          </Column>
        </Row>
        {state.fetchingData.userCustomerNotifications ? (
          <div className="notifications-loader">
            <Loader size="medium" />
          </div>
        ) : (
          items &&
          items.map((notification, index) => (
            <>
              <Row className="admin-row">
                <Column lg={1} sm={1} md={1} className="admin-entry">
                  <div className="switch-row">
                    <Switch
                      offLabel={""}
                      onLabel={""}
                      value={notification.enabled}
                      onChange={(newValue: boolean) =>
                        onNotificationEnabled(notification.notificationId, newValue)
                      }
                    />
                  </div>
                </Column>
                <Column lg={5} sm={5} md={5} className="notifications-title admin-entry">
                  <span>{notificationDescription(notification).title}</span>
                </Column>
                <Column lg={2} md={2} sm={2} className="admin-entry scope-button">
                  <Radiobutton
                    text=""
                    onChange={() => {
                      onNotificationScopeChanged(0, notification);
                      setNotificationScope(notification.notificationScope);
                    }}
                    value={notification.notificationScope === 0 ? true : false}
                    disabled={notification.notificationId != 4}
                  />
                </Column>
                <Column lg={4} md={4} sm={4} className="admin-entry scope-button">
                  <Radiobutton
                    text=""
                    onChange={() => {
                      onNotificationScopeChanged(1, notification);
                      setNotificationScope(notification.notificationScope);
                    }}
                    value={notification.notificationScope === 1 ? true : false}
                    disabled={notification.notificationId != 4}
                  />
                  {notification.notificationId === 4 ? (
                    <Tooltip text={tooltipTexts.POBookmarkedOnly} type="pointer" position="right">
                      <Icon name="info"></Icon>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Column>
              </Row>
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default NotificationsTab;
