import { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { useNotifications } from "@react-gcc-eds/core";
import { useStore } from "../../../store";
import { useUserAccessRequestActions } from "../../../actions/user-access-request-actions";
import { useUserHelpers } from "../../../utils/user-helpers";

const PendingUserRequestsNotification = () => {
  const [notified, setNotified] = useState(false);
  const [state] = useStore();
  const { createInstantNotification } = useNotifications();
  const history = useHistory();

  const { getUnconnectedPendingRequests, getUnapprovedPendingRequests } =
    useUserAccessRequestActions();

  const { userRoleIsSuperUser, userRoleIsAdministrator } = useUserHelpers();

  const notifyPendingRequests = () => {
    if (!notified) {
      try {
        const howMany = state.userAccessRequests.length;
        createInstantNotification(
          "You have pending user requests",
          `${howMany} request${howMany > 1 ? "s" : ""} ${
            howMany > 1 ? "are" : "is"
          } waiting to be processed`,
          "default",
          "info",
          undefined,
          () => history.push("/administration/user-requests")
        );
        setNotified(true);
      } catch (e) {}
    }
  };

  useEffect(() => {
    if (state.user && userRoleIsSuperUser(state.user.role)) {
      getUnconnectedPendingRequests();
    } else if (state.user && userRoleIsAdministrator(state.user.role)) {
      getUnapprovedPendingRequests();
    }
  }, [state.user, state.userRoles]);

  useEffect(() => {
    if (state.userAccessRequests.length) {
      notifyPendingRequests();
    }
  }, [state.userAccessRequests]);

  return null;
};

export default PendingUserRequestsNotification;
