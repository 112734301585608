import React, { useState, useRef, useEffect, useCallback } from "react";
import * as d3 from "d3";
import { Data, Serie, TooltipItem, ITooltipItem } from "../common/interfaces";
import * as utils from "../common/helpers";

import LineGroups from "../common/components/LineGroups";
import Tooltip from "../common/components/Tooltip";
import Legend from "../common/components/Legend";
import Unit from "../common/components/Unit";
import MouseArea from "../common/components/MouseArea";
import Circle from "../common/components/Circle";
import TooltipLine from "../common/components/TooltipLine";
import LineThresholds from "../common/components/LineThresholds";
import AxisTresholds from "../common/components/AxisTresholds";
import ILineChart from "./ILineChart";
import AxisLeft from "../common/components/AxisLeft";
import AxisBottom from "../common/components/AxisBottom";

interface Point {
  x: number;
  y: number;
}

const LineChart = ({
  data,
  thresholds,
  onToggle,
  width = 1000,
  height = 400,
  startFromZero = true,
  x,
  y,
  fill,
  onClick,
  hideLegend = false,
  parentRef,
  invertColorScheme = false,
  hideStandardTooltipItems = false
}: ILineChart) => {
  const errorMsg = utils.checkData(data); // Check if data is correct
  if (errorMsg !== "") {
    throw new Error(errorMsg);
  }

  const [initialized, setInitialized] = useState(false);
  const widthOffset = 50;

  // chart-legend
  const heightOffset = 20;
  const heightPaddingBottom = 50;

  const legendRef = useRef<HTMLDivElement>(null);
  const defaultLegendHeight = hideLegend ? 0 : 55;
  const chartLegendHeight =
    legendRef !== null && legendRef.current !== null
      ? legendRef.current.clientHeight + heightOffset
      : defaultLegendHeight;

  const [Width, setWidth] = useState(width);
  const [Height, setHeight] = useState(height);

  const axisPadding = 8;

  const ref = useRef<HTMLDivElement>(null);

  const [allHidden, setAllHidden] = useState(false);
  const [totalValues, setTotalValues] = useState([] as number[]);
  const [chartData, setChartData] = useState({} as unknown as Data);
  const [circlePositions, setCirclePositions] = useState([{ x: 0, y: 0 }]);
  const [emptyChart, setEmptyChart] = useState(false);

  // Windowresize listener
  const [, updateState] = React.useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("toggleNavigation", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("toggleNavigation", handleResize);
    };
  }, []);

  useEffect(() => {
    const clientWidth = ref !== null && ref.current !== null ? ref.current.clientWidth : 0;
    const clientHeight = ref !== null && ref.current !== null ? ref.current.clientHeight : 0;

    setWidth(clientWidth);
    setHeight(clientHeight - chartLegendHeight);
  });

  useEffect(() => {
    const willHideAll = data.series.filter((s: Serie) => s.hidden).length === data.series.length;
    const newChartData = Object.assign({}, data);

    if (newChartData.series[0].values.length === 0 && newChartData.common.length === 0) {
      newChartData.series = [
        {
          name: "",
          values: [0, 100, 200, 300, 400, 500]
        }
      ];
      newChartData.common = [
        "2019-03-01",
        "2019-03-02",
        "2019-03-03",
        "2019-03-04",
        "2019-03-05",
        "2019-03-06"
      ];
      setEmptyChart(true);
    } else {
      setEmptyChart(false);
    }

    newChartData.series = newChartData.series.filter((s: Serie) => !s.hidden);

    const circlesToAdd = [] as Point[];

    const newTotalValues = [] as number[];

    if (willHideAll) {
      data.series.forEach((serie: Serie) => {
        serie.values.forEach((number: number) => {
          newTotalValues.push(number);
        });
      });
    } else {
      newChartData.series.forEach((serie: Serie, i: number) => {
        circlesToAdd[i] = { x: 0, y: 0 };
        serie.values.forEach((number: number) => {
          if (number !== undefined) {
            newTotalValues.push(number);
          }
        });
      });
    }
    setAllHidden(willHideAll);
    setTotalValues(newTotalValues);
    setChartData(newChartData);
    setCirclePositions(circlesToAdd);
    setInitialized(true);
  }, [data, data.series, allHidden]);

  const yMin = Math.min(...totalValues) > 0 && startFromZero ? 0 : Math.min(...totalValues);
  const yMax = Math.max(...totalValues);

  const isTimeX = (x !== undefined && x.isTime) || x === undefined;
  const isStringX = (x !== undefined && x.isString) || x === undefined;
  const isLogarithmic = (y !== undefined && y.isLogarithmic) || y === undefined;

  if (initialized) {
    if (isTimeX) {
      utils.sortCommonData(chartData.common);
    }
  }

  const ordinalXScale = isStringX
    ? d3
        .scalePoint()
        .domain(data.common.map((c: any) => c))
        .range([0, Width - widthOffset - 100])
    : undefined;
  const timeXScale = isTimeX
    ? d3
        .scaleTime()
        .domain(
          d3.extent(data.common.map((c: string) => Date.parse(c))) as unknown as (
            | number
            | Date
            | { valueOf(): number }
          )[]
        )
        .nice()
        .range([0, Width - widthOffset - 100])
    : undefined;

  const linearXScale =
    !isTimeX && !isStringX
      ? d3
          .scaleLinear()
          .domain(
            d3.extent(data.common.map((c: any) => parseFloat(c))) as unknown as (
              | number
              | { valueOf(): number }
            )[]
          )
          .nice()
          .range([0, Width - widthOffset - 100])
      : undefined;

  let xScale: any;
  if (isStringX) {
    xScale = ordinalXScale;
  } else if (isTimeX) {
    xScale = timeXScale;
  } else {
    xScale = linearXScale;
  }

  const yScale = isLogarithmic
    ? d3
        .scaleLog()
        .domain([Math.min(...totalValues), Math.max(...totalValues)])
        .range([Height - heightOffset - heightPaddingBottom, 0])
    : d3
        .scaleLinear()
        .domain([yMin, yMax])
        .nice()
        .range([Height - heightOffset - heightPaddingBottom, 0]);

  const colorScale = utils.getColorScale(data.series.length, undefined, invertColorScheme);
  const colors = {};
  data.series.forEach(d => {
    colors[d.name] = colorScale(d.name);
  });

  const [hoveredSerie, setHoveredSerie] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipData, setTooltipData] = useState({
    unitTitle: "",
    unitValue: "",
    items: [] as TooltipItem[],
    description: "" as string | JSX.Element
  });

  const [tooltipLinePosition, setTooltipLinePosition] = useState(0);

  const xCommon = data.common.map((c: string) => {
    let result: any;
    if (isTimeX) {
      result = xScale(Date.parse(c));
    } else if (isStringX) {
      result = xScale(c);
    } else {
      result = xScale(parseFloat(c));
    }
    return result;
  });

  const svgRef = useRef<SVGSVGElement>(null); // This is trash

  const heightStyling = fill
    ? { height: "100%", width: "100%" }
    : { height: `${height + chartLegendHeight}px`, width };

  const sizeFromParentRef =
    parentRef && parentRef.current
      ? {
          height: `${parentRef.current.clientHeight}px`,
          width: `${parentRef.current.clientWidth}px`
        }
      : { height: "100%", width: "100%", display: "flex" };

  const nTicks = Width / 120;

  return initialized ? (
    <div className="chart-container" style={heightStyling}>
      {!hideLegend && (
        <div ref={legendRef}>
          <Legend
            series={data.series}
            colorScale={colors}
            onMouseHover={(s: string) => {
              setHoveredSerie(s);
            }}
            onClick={(i: number) => {
              if (onToggle) {
                const newData = utils.jsonClone(data) as Data;
                newData.series[i].hidden = !newData.series[i].hidden;
                onToggle(newData, i);
                setHoveredSerie("");
              }
            }}
          />
        </div>
      )}
      <div ref={ref} style={!fill ? { height: "inherit" } : sizeFromParentRef}>
        <svg
          id="svg"
          className="chart"
          style={{ height: "100%", width: "100%", display: "flex" }}
          ref={svgRef}
        >
          <g transform="translate(45,38)" className="canvas line-chart">
            <AxisLeft
              yScale={yScale}
              tickSize={-(Width - 100)}
              tickPadding={4}
              ticks={Height / 80}
              line
              noLine
              width={Width - 100}
              height={Height - heightOffset - heightPaddingBottom}
              tickFormat={y !== undefined ? y.format : undefined}
              hideValues={emptyChart}
            />

            {!emptyChart && (
              <AxisTresholds
                bottom
                yScale={yScale}
                tickSize={Width - widthOffset - heightPaddingBottom}
                tickPadding={axisPadding}
                thresholds={thresholds}
                fontSize={12}
                tickFormat={y !== undefined ? y.format : undefined}
              />
            )}
            <AxisBottom
              xScale={xScale}
              tickSize={Height - heightOffset - heightPaddingBottom + 10}
              tickPadding={axisPadding}
              ticks={nTicks < chartData.common.length ? nTicks : chartData.common.length}
              line
              domain
              // removeLastTick
              tickFormat={x !== undefined ? x.format : undefined}
            />
            <LineGroups
              data={chartData}
              colorScale={colors}
              xScale={xScale}
              yScale={yScale}
              hasDate={isTimeX}
              hasString={isStringX}
              hasThresholds={thresholds !== undefined}
              hoveredSerie={hoveredSerie}
            />
            {thresholds &&
              Object.values(colors).map((colorData: string, i: number) => {
                const keyIndex = i;
                return (
                  <LineThresholds
                    key={keyIndex}
                    thresholds={thresholds}
                    startFromZero={startFromZero}
                    yMin={yMin}
                    yMax={yMax}
                    height={Height - heightOffset * 3 - 10}
                    defaultColor={colorData}
                  />
                );
              })}
            {x !== undefined && x.unit !== undefined && (
              <Unit
                x={Width - 90 - (x.unit.length * 8) / 1.5 - 20}
                y={Height - chartLegendHeight + heightPaddingBottom - 20}
                unit={x.unit}
              />
            )}
            <Unit x={5} y={-20} unit={y !== undefined ? y.unit : ""} textAnchor="middle" />
            <TooltipLine
              active={tooltipVisible}
              height={Height - heightOffset - heightPaddingBottom}
              x={tooltipLinePosition}
            />
            <g className="circles">
              {chartData.series.map((s: Serie, i: number) => {
                const key = i;
                return (
                  <Circle
                    key={key}
                    active={tooltipVisible}
                    size={3}
                    x={circlePositions[i].x}
                    y={circlePositions[i].y}
                    colorData={colors[s.name]}
                  />
                );
              })}
            </g>
            <MouseArea
              width={Width - widthOffset * 2 > 0 ? Width - widthOffset * 2 : 0}
              height={
                Height - heightOffset - heightPaddingBottom > 0
                  ? Height - heightOffset - heightPaddingBottom
                  : 0
              }
              onMouseMove={(event: React.MouseEvent, element: any) => {
                if (allHidden) return;

                const mouseX = event.pageX - element.current.getBoundingClientRect().x + 10;
                const coord = [mouseX, event.pageY];

                const iCommon = d3.bisect(xCommon, coord[0]) - 1;
                const xDistance = xCommon[iCommon] || 0;

                if (iCommon > -1) {
                  const xPos = xDistance;
                  const clonedCirclePositions = Object.assign({}, circlePositions);
                  chartData.series.forEach((s: Serie, i: number) => {
                    const yPos = yScale(s.values[iCommon]);

                    clonedCirclePositions[i] = { x: xPos, y: yPos };
                  });
                  setCirclePositions(clonedCirclePositions);

                  const tooltipItems = chartData.series.map(d => {
                    const tooltipFormatSpecifierY =
                      y !== undefined && y.tooltipFormat !== undefined
                        ? y.tooltipFormat
                        : "Default";
                    const axisFormatSpecifierY =
                      y !== undefined && y.format !== undefined ? y.format : "Default";

                    const formatSpecifierY =
                      tooltipFormatSpecifierY !== ""
                        ? tooltipFormatSpecifierY
                        : axisFormatSpecifierY;
                    return {
                      key: d.name,
                      value: utils.d3Format(formatSpecifierY, d.values[iCommon] as number)
                    };
                  });
                  setTooltipVisible(true);

                  const tooltipFormatSpecifierX =
                    x !== undefined && x.tooltipFormat !== undefined ? x.tooltipFormat : "Default";
                  const axisFormatSpecifierX =
                    x !== undefined && x.format !== undefined ? x.format : "Default";
                  const formatSpecifierX =
                    tooltipFormatSpecifierX !== "" ? tooltipFormatSpecifierX : axisFormatSpecifierX;

                  setTooltipData({
                    unitTitle:
                      !isTimeX && !isStringX
                        ? utils.d3Format(
                            formatSpecifierX,
                            parseFloat(data.common[iCommon]) as number
                          )
                        : data.common[iCommon],
                    unitValue: y !== undefined && y.unit !== undefined ? y.unit : "",
                    items: tooltipItems,
                    description:
                      data.descriptions !== undefined && iCommon < data.descriptions.length
                        ? data.descriptions[iCommon]
                        : ""
                  });

                  setTooltipPosition({
                    x: event.clientX,
                    y: coord[1]
                  });
                  setTooltipLinePosition(xDistance);
                }
              }}
              onMouseLeave={() => {
                setTooltipVisible(false);
              }}
              onClick={(event: React.MouseEvent, element: any) => {
                const mouseX = event.pageX - element.current.getBoundingClientRect().x + 10;
                const coord = [mouseX, event.pageY];

                const iCommon = d3.bisect(xCommon, coord[0]) - 1;
                const tooltipItems = chartData.series.map(d => {
                  return { key: d.name, value: d.values[iCommon] };
                }) as ITooltipItem[];
                const unitTitle = data.common[iCommon];
                if (onClick) {
                  onClick(unitTitle, tooltipItems);
                }
              }}
            />
          </g>
        </svg>
        <Tooltip
          colors={colors}
          unitTitle={tooltipData.unitTitle}
          unitValue={tooltipData.unitValue}
          description={tooltipData.description}
          items={tooltipData.items}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          visible={tooltipVisible}
          hideStandardItems={hideStandardTooltipItems}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default LineChart;
