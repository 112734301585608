import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MultiPanelTile,
  Tile,
  Pill,
  Tooltip,
  useSettings,
  ISelectItem,
  SingleSelect
} from "@react-gcc-eds/core";
import { IDeliveryRecurrence, isFetching, useStore, deliveryRecurrences } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { useLocalization } from "../../../../utils/localization";
import DatePicker from "../../../filters/date-picker";
import CustomTabs, { ITab } from "../../../shared/custom-tabs";
import { ShipmentDeliveredBarChart, StatusBarChart } from "../bars-chart";
import { useSearchActions } from "../../../../actions/search-actions";
import SearchBarFilter from "../../../filters/search-bar-filter";
import { activeFiltersIcon } from "../../../filters/filters-icon";
import { DashboardGraph, DashboardTileAlignment, IDashboardItemSelected } from "..";
import { IExternalOrdersRedirectionFilters } from "../../../../actions/orders-filters-actions";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { usePredefinedRanges } from "../../../filters/date-picker/predefined-ranges";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useWalkThrough } from "../../../walkthrough";
import { useDashboardOverallOrderBarChartSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { dateAsYMDString } from "../../../../utils";
import cx from "classnames";
import { NoMetrics } from "../utils";
import { useUserActions } from "../../../../actions/user-actions";

const ShipmentDelivered = ({
  ordersLabel,
  className,
  customerId,
  alignment,
  onItemSelected
}: {
  ordersLabel: string;
  className: string;
  customerId: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const { currentCustomer } = useSettingsActions();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ITab>();
  const [tabs, setTabs] = useState<ITab[]>([]);
  const [state] = useStore();
  const [recurrence, setRecurrence] = useState<IDeliveryRecurrence>(deliveryRecurrences[1]);
  const { shipmentDeliveredMetrics: shipmentDeliveredMetricLoading } = state.fetchingData.metrics;
  const { localeString } = useLocalization();
  const { steps } = useDashboardOverallOrderBarChartSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );

  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);

  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { isWalkthroughEnabled } = useSettingsActions();
  const { rangeAsString, largestRange, isLargestRange } = usePredefinedRanges(
    new Date(currentCustomer().dataAvailableFromDate)
  );
  const { getShipmentDeliveredMetrics } = useMetricsActions();
  const { setMetricsShipmentStatusBarsDeliveredRange } = useMetricsFiltersActions();

  const { theme } = useSettings();

  useEffect(() => {
    if (state.metricsFilters.shipmentDeliveredRange) {
      const {
        shipmentDeliveredRange: { from, to }
      } = state.metricsFilters;
      getShipmentDeliveredMetrics(recurrence.value, dateAsYMDString(from), dateAsYMDString(to));
    }
  }, [state.metricsFilters.shipmentDeliveredRange, customerId, recurrence]);

  useEffect(() => {
    const tabs = [];
    if (ordersLabel !== undefined) {
      tabs.push(shipmentValueTab);
    }
    if (tabs.length) {
      setTabs(tabs);
      if (!selectedTab) {
        setSelectedTab(tabs[0]);
      }
    }
  }, [shipmentDeliveredMetricLoading]);

  const shipmentValue = () => {
    const { shipmentDeliveredMetrics } = state.metrics;
    return currentCustomer().isUsingFinancialData ? (
      <ShipmentDeliveredBarChart
        metrics={shipmentDeliveredMetrics}
        loading={shipmentDeliveredMetricLoading === true}
        unit={shipmentDeliveredMetrics.currency}
        datesRange={state.metricsFilters.shipmentDeliveredRange}
        onSelection={(filters: IExternalOrdersRedirectionFilters) =>
          onItemSelected && onItemSelected({ graph: DashboardGraph.Orders, filters })
        }
      />
    ) : shipmentDeliveredMetricLoading ? (
      <NoMetrics loading />
    ) : (
      <NoMetrics />
    );
  };

  const resetFilter = () => {
    setMetricsShipmentStatusBarsDeliveredRange(largestRange.selector());
  };

  const handleRecurrenceChanged = (recurrence: IDeliveryRecurrence) => {
    setRecurrence(recurrence);
    // setFilterChanged(true);
  };

  const resetFilterPill = () => {
    return (
      <Pill className={cx("filter-pill", theme)} onToggle={resetFilter}>
        Reset filters
      </Pill>
    );
  };

  const filtersSummary = (
    <div className="dashboard-active-filter">
      <Pill>{rangeAsString(state.metricsFilters.shipmentDeliveredRange)}</Pill>
      {!isLargestRange(state.metricsFilters.shipmentDeliveredRange) ? resetFilterPill() : null}
    </div>
  );

  const shipmentValueTab = {
    view: shipmentValue(),
    title: "Shipment value",
    index: 0
  } as ITab;

  const dateFilter = (
    <DatePicker
      dual={state.userPreferences.useDualCalendar}
      rolling
      selectedRange={state.metricsFilters.shipmentDeliveredRange}
      oldestDate={new Date(currentCustomer().dataAvailableFromDate)}
      onRangeSelected={setMetricsShipmentStatusBarsDeliveredRange}
      key="date-picker"
      className="filter"
    />
  );

  const periodFilter = (
    <SingleSelect
      className="medium"
      items={deliveryRecurrences.map(r => ({ title: r.description, value: r.value.toString() }))}
      label={recurrence?.description}
      onChange={(v: ISelectItem) =>
        handleRecurrenceChanged(
          deliveryRecurrences.find(r => r.value.toString() === v.value) ?? deliveryRecurrences[0]
        )
      }
    />
  );

  const tileHeader = (
    <div className="dashboard-tile">
      <WalkthroughHeader
        walkthroughActive={isWalkthroughEnabled()}
        tooltipInfo="Graph is showing the status for the selected time period"
        alignment={alignment}
        header={localeString("Dashboard_ShipmentDelivered") || "Shipment delivered"}
        onClick={startWalkThrough}
        disabled={isFetching(state)}
      />
    </div>
  );

  return (
    <>
      <MultiPanelTile
        parentRef={tileRef}
        centerTitle={"Shipment delivered"}
        centerContent={<CustomTabs onTabChange={(tab: ITab) => setSelectedTab(tab)} tabs={tabs} />}
        centerLeftIcon={
          <Tooltip text={`${showFilter ? "Hide" : "Show"} filters`} type="pointer" position="right">
            {activeFiltersIcon(1, () => setShowFilter(!showFilter))}
          </Tooltip>
        }
        centerRightActions={periodFilter}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        leftContent={dateFilter}
        leftTitle={tileHeader}
        leftVisible={showFilter}
        leftSubtitle={filtersSummary}
      />
      <WalkThrough />
    </>
  );
};

export default ShipmentDelivered;
