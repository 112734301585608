import { TextArea, TextField, useNotifications } from "@react-gcc-eds/core";
import { FileAttachment } from "./FileAttachment";
import React, { useState } from "react";
import { useMailActions } from "../../../actions/mail-actions";

export const useSupport = (customerIdentifier: string) => {
  const { askSupport } = useMailActions();
  const { createInstantNotification } = useNotifications();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [attachment, setAttachment] = useState<File | undefined>(undefined);

  const notifySuccess = () => {
    createInstantNotification(
      "Support request sent",
      "Your support request has been sent, it will be addressed as soon as possible.",
      "default",
      "send"
    );
  };

  const submitIsDisabled = () => !body || !title || title.length >= 250;

  const getDisabledTooltip = () => {
    const tooltip = [];
    if (!body) {
      tooltip.push("No support request text provided.");
    }
    if (!title) {
      tooltip.push("No support title provided.");
    } else if (title.length >= 250) {
      tooltip.push("Support title too long.");
    }
    return tooltip.join("\n");
  };

  const titleValidation = (title: string) => {
    return title.length >= 250 ? "Title can not be longer than 250 characters" : undefined;
  };

  const submit = () => {
    askSupport(customerIdentifier, title, body, attachment, notifySuccess);
  };

  const components = () => [
    <p key="explanation-text">
      Please describe your problem and provide a screenshot if possible. We will get back to you as
      soon as possible.
    </p>,
    <div className="input-form-content" key="input-textarea-container">
      <TextField
        key="title-field"
        placeholder={"Please give a summary title..."}
        label={"Title"}
        value={title}
        className="input-form-title"
        onChange={setTitle}
        validation={titleValidation(title)}
      />
      <label key="label">Support issue</label>
      <TextArea
        key="text-area"
        className="input-form-textarea"
        value={body}
        placeholder="Please describe your issue..."
        onChange={setBody}
      />
    </div>,
    <FileAttachment key="file-attachment" file={attachment} fileSetter={setAttachment} />
  ];

  return {
    components,
    submit,
    getDisabledTooltip,
    submitIsDisabled
  };
};
