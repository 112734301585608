import React from "react";

interface Props {
  unit?: string;
  x: number;
  y: number;
  textAnchor?: string;
}

const Unit = ({ unit, x, y, textAnchor = "end" }: Props) => {
  return (
    <g className="axis">
      <text
        className="unit"
        // textAnchor="start"
        textAnchor={textAnchor}
        x={x}
        y={y}
      >
        {unit !== undefined ? unit : ""}
      </text>
    </g>
  );
};

export default Unit;
