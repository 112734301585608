import {
  apiReadingOff,
  apiReadingOn,
  apiWritingOff,
  apiWritingOn,
  ICustomer,
  IDashboardItem,
  IMenuItem,
  INotificationAdminItem,
  IScheduledExportAdminItem,
  useStore
} from "../store";
import { useApi } from "./api-factory";

export enum CustomerParamCategory {
  Application = 10,
  DataImportCpoSo = 20,
  GeneralImport = 40,
  TracyImport = 50,
  InternalOrders = 60,
  DBSchenker = 70,
  General = 80,
  DataImportItem = 90,
  EriSiteIntegration = 100,
  ManaIntegration = 110
}

export enum CustomerParamType {
  Boolean = 10,
  Text = 20,
  TextValues = 30,
  Number = 40,
  Date = 50,
  NumbersList = 60,
  CommaSeparatedValues = 70,
  MultiSelection = 80
}

export interface ICustomerParams {
  id: string;
  key: string;
  description: string;
  detailedDescription: string;
  range?: string;
  value?: string;
  category: CustomerParamCategory;
  type: CustomerParamType;
}
/*
    Type
    Boolean = 10,
    SingleTextValue = 20,
    MultipleTextValues = 30,
    Numeric = 40,
    Date = 50,
    NumbersList = 60,
    CommaSeparatedValues = 70,
    MultiSelection = 80

    Category
     Application = 10,
     DataImport = 20,
     GeneralImport = 40,
     Tracy = 50
*/

export interface IProjectViewCustomer {
  companyCodeFecId: number;
  finalExternalCustomer: string;
  finalExternalCustomerDesc: string;
}
export interface IProjectViewSettingOption {
  companyCode: string;
  customers: IProjectViewCustomer[];
  plantStorageLocations: [
    {
      plant: string;
      plantDescription: string;
      storageLocations: [
        {
          storageLocationId: number;
          storageLocationDescription: string;
          storageLocation: string;
        }
      ];
    }
  ];
}
export interface ICustomerProjectViewSettings {
  customerId: number;
  aspWhClientId: string;
  esrCustomer: string;
  companyCode: string | undefined;
  companyCodeFec: string | undefined;
  companyCodeFecId: number | undefined;
  companyCodeFecDesc: string | undefined;
  finalExternalCustomers: IProjectViewCustomer[];
  plantStorageInfos: IPlantStorageLocation[];
}
export interface IStoreCustomerProjectViewSettings {
  aspWhClientId?: string;
  esrCustomer?: string;
  companyCodeFecId?: number;
  finalExternalCustomerIds?: number[] | undefined;
  plantStorageInfos: { plantStorageLocationId?: number; companyCodeFecIds: number[] }[];
}
export interface IPlantStorageLocation {
  plant?: string;
  plantDescription?: string;
  storageLocationId?: number;
  storageLocation?: string;
  storageLocationDescription?: string;
  companyCustomers?: IProjectViewCustomer[];
}

export interface IFinancialSettings {
  isUsingFinancialData: boolean;
}

export const useCustomerActions = () => {
  const [_, setState] = useStore();

  const { api } = useApi({ includeCustomer: false });
  const sortByCustomerName = (customers: ICustomer[]) => {
    return customers.sort((a: ICustomer, b: ICustomer) => {
      const x = a.displayName.toLowerCase();
      const y = b.displayName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  const getCustomers = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.customers));
      const availableCustomers = await api.get("Customer");
      setState(old => ({
        ...apiReadingOff(old, f => f.customers),
        customers: sortByCustomerName(availableCustomers)
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customers));
    }
  };
  const getConnectedCustomers = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.customers));
      const availableCustomers = await api.get("Customer/connected");
      setState(old => ({
        ...apiReadingOff(old, f => f.customers),
        customers: sortByCustomerName(availableCustomers)
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customers));
    }
  };

  const getCustomer = async (customerId: string): Promise<ICustomer | undefined> => {
    try {
      setState(old => apiReadingOn(old, f => f.customer));
      const customer = await api.get(`Customer/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customer)
      }));
      return customer;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customer));
      return undefined;
    }
  };

  const getCustomerParams = async (customerId: string): Promise<ICustomerParams[]> => {
    try {
      setState(old => apiReadingOn(old, f => f.customerParams));
      const params = await api.get(`v1/Settings/parameter/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customerParams)
      }));
      return params.map((p: ICustomerParams) => ({
        ...p,
        value: p.value === null ? undefined : p.value
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customerParams));
      return [];
    }
  };

  const updateCustomerParams = async (
    customerId: string,
    params: ICustomerParams[],
    callback?: Function
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, params));
      await api.put(`v1/Settings/parameter/${customerId}`, params, callback);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getCustomerMenus = async (customerId: string): Promise<IMenuItem[]> => {
    try {
      setState(old => apiReadingOn(old, f => f.customerMenus));
      const menus = await api.get(`v1/Settings/menus/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customerMenus)
      }));

      return menus;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customerMenus));
      return [];
    }
  };

  const updateCustomerMenus = async (customerId: string, menus: IMenuItem[]) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, menus));
      await api.put(`v1/Settings/menus/${customerId}`, menus);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getCustomerDashboards = async (customerId: string): Promise<IDashboardItem[]> => {
    try {
      setState(old => apiReadingOn(old, f => f.customerDashboards));
      const menus = await api.get(`v1/Settings/dashboards/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customerDashboards)
      }));
      return menus;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customerDashboards));
      return [];
    }
  };

  const updateCustomerDashboards = async (customerId: string, dashboards: IDashboardItem[]) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, dashboards));
      await api.put(`v1/Settings/dashboards/${customerId}`, dashboards);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getCustomerNotifications = async (
    customerId: string
  ): Promise<INotificationAdminItem[]> => {
    try {
      setState(old => apiReadingOn(old, f => f.customerNotifications));
      const customerNotifications = await api.get(`v1/Settings/notificationadmins/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customerNotifications),
        customerNotifications
      }));
      return customerNotifications;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customerNotifications));
      return [];
    }
  };

  const updateCustomerNotifications = async (
    customerId: string,
    notifications: INotificationAdminItem[]
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, notifications));
      await api.put(`v1/Settings/notificationadmins/${customerId}`, notifications);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getCustomerScheduledExports = async (
    customerId: string
  ): Promise<IScheduledExportAdminItem[]> => {
    try {
      setState(old => apiReadingOn(old, f => f.customerScheduledExports));
      const customerScheduledExports = await api.get(
        `v1/Settings/scheduledexportadmins/${customerId}`
      );
      setState(old => ({
        ...apiReadingOff(old, f => f.customerScheduledExports),
        customerScheduledExports
      }));
      return customerScheduledExports;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customerScheduledExports));
      return [];
    }
  };

  const updateCustomerScheduledExports = async (
    customerId: string,
    scheduledExports: IScheduledExportAdminItem[]
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, scheduledExports));
      await api.put(`v1/Settings/scheduledexportadmins/${customerId}`, scheduledExports);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getCustomerFinancial = async (
    customerId: string
  ): Promise<IFinancialSettings | undefined> => {
    try {
      setState(old => apiReadingOn(old, f => f.customerFinancial));
      const settings = await api.get(`v1/Finance/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customerFinancial)
      }));
      return settings;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customerFinancial));
      return undefined;
    }
  };

  const updateCustomerFinancial = async (customerId: string, settings: IFinancialSettings) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, settings));
      if (settings.isUsingFinancialData) {
        await api.post(`v1/Finance/${customerId}`);
      } else {
        await api.remove(`v1/Finance/${customerId}`);
      }
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getCustomerProjectViewOptions = async (): Promise<IProjectViewSettingOption[]> => {
    try {
      setState(old => apiReadingOn(old));
      const options = await api.get(`/CoreLookup/project-settings`);
      setState(old => apiReadingOff(old));
      return options;
    } catch (e) {
      setState(old => apiReadingOff(old));
      return [];
    }
  };

  const getCustomerProjectViewSettings = async (
    customerId: string
  ): Promise<ICustomerProjectViewSettings | undefined> => {
    try {
      setState(old => apiReadingOn(old, f => f.customerProjectViewSettings));
      const params = await api.get(`/Customer/project-settings/${customerId}`);
      setState(old => apiReadingOff(old, f => f.customerProjectViewSettings));
      return { ...params, finalExternalCustomers: params.finalExternalCustomers || [] };
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customerProjectViewSettings));
      return undefined;
    }
  };

  const updateCustomerProjectViewSettings = async (
    customerId: string,
    settings: IStoreCustomerProjectViewSettings
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, settings));
      await api.put(`/Customer/project-settings/${customerId}`, settings);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const createCustomer = async (customer: ICustomer, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, customer));
      await api.post("Customer", customer, callback);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const updateCustomer = async (customer: ICustomer, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, customer));
      await api.put(`Customer/${customer.id}`, customer, callback);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const deleteCustomer = async (customerId: string, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, customerId));
      await api.remove(`Customer/${customerId}`, undefined, callback);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  return {
    getCustomers,
    getConnectedCustomers,
    getCustomer,
    getCustomerParams,
    updateCustomerParams,
    getCustomerMenus,
    updateCustomerMenus,
    getCustomerDashboards,
    updateCustomerDashboards,
    getCustomerNotifications,
    updateCustomerNotifications,
    getCustomerScheduledExports,
    updateCustomerScheduledExports,
    getCustomerFinancial,
    updateCustomerFinancial,
    getCustomerProjectViewOptions,
    getCustomerProjectViewSettings,
    updateCustomerProjectViewSettings,
    createCustomer,
    updateCustomer,
    deleteCustomer
  };
};
