import React, { RefObject, useEffect, useState } from "react";
import { Pill } from "@react-gcc-eds/core";
import {
  closeLeftPane,
  condtionalElementCheck,
  elementVisible,
  getDefinedElement,
  getElement,
  getTextOfElement,
  getVisulizationBarValue,
  openLeftPane
} from "../helpers";
import { useLocalization } from "../../../utils/localization";
import { IStep } from "../../../store";
import { Placement } from "react-joyride";

const useDashboardOverallOrderBarChartSteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const { localeString } = useLocalization();

  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Overall order status - bar chart"
  };

  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);

  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");
  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);

  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          content:
            "This is a guided walkthrough for overall order status donut chart dashboard tile.",
          placement: "center"
        },
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".mid-panel .title"),
          content: "Here you can see what is being displayed in the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container"),
          content: "Try hovering the visualization for more info",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".action.custom-filter"),
          content: "Clicking this icon opens the filter pane. Try this now",
          placement: "right",
          autoNext: true,
          spotlightClicks: true,
          blockNextWhile: () =>
            condtionalElementCheck(tileRefElement, ".left-panel", el => {
              return !el || el.classList.contains("hidden");
            })
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left-panel"),
          content: `This is the filter pane. Here you can change filters for the dashboard tile.`,
          placement: "right",
          setupBeforePrevious: () => closeLeftPane(tileRefElement),
          setupBeforeClose: () => closeLeftPane(tileRefElement)
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left-panel .filter:first-of-type"),
          content: `This filter controls the period for the bar chart. Try clicking it to open up the calendar`,
          placement: "right",
          autoNext: true,
          blockClickOutside: true,
          spotlightClicks: true,
          blockNextWhile: () =>
            !elementVisible(tileRefElement, ".date-picker .calendar.opened.dual")
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".date-picker .calendar.opened.dual") ||
            getDefinedElement(tileRefElement, ".left-panel .filter:first-of-type"),
          content: `${
            !elementVisible(tileRefElement, ".chart-container svg .bar-group .bar")
              ? "No data for selected period."
              : ""
          } Try changing the period filter.`,
          spotlightClicks: true,
          blockClickOutside: true,
          backSeveralSteps: 2,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(tileRefElement, ".chart-container svg .bar-group .bar") ||
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `You can see that the dashboard updated`,
          setupBeforePrevious: () => openLeftPane(tileRefElement)
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".mid-panel .subtitle"),
          content: `You can see the active filters here`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container svg .bar-group .bar"),
          spotlightPadding: 40,
          offset: 40,
          content: `If were to click one of the bars, you would navigate to the ${localeString(
            "MenuKeys_PurchaseOrders"
          )} with the selected status as a preset filter`,
          placement: "right"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".custom-tabs-buttons"),
          content: `You can toggle between ${localeString("view_po")} and ${localeString(
            "view_po_it"
          )} here`,
          placement: "bottom-end"
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".custom-tabs-buttons .tabs-button:last-of-type") ||
            tileRefElement,
          content: `Try toggling from ${localeString("view_po")} and ${localeString("view_po_it")}`,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(
              tileRefElement,
              ".custom-tabs-buttons .tabs-button:last-of-type.selected"
            ),
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container"),
          content: `We can see that the bar chart switched to ${localeString("view_po_it")}`,
          spotlightClicks: true,
          setupBeforeNext: () => openLeftPane(tileRefElement)
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left-panel .filter.search-bar-filter"),
          content: `${localeString("view_po_it")} also has a search filter.`,
          placement: "right",
          setupBeforePrevious: () => closeLeftPane(tileRefElement),
          setupBeforeClose: () => closeLeftPane(tileRefElement)
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `Trying searching for a ${localeString(
            "view_po_it"
          )} and select one in the dropdown.`,
          spotlightClicks: true,
          setupBeforeNext: () => closeLeftPane(tileRefElement),
          setupBeforeClose: () => closeLeftPane(tileRefElement),
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(tileRefElement, ".mid-panel .subtitle .pill:nth-of-type(2)")
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `We can see the status for just that  ${localeString("view_po_it")}`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".subtitle .pill.removable"),
          spotlightClicks: true,
          content: `You can remove filters with a cross by clicking on them. Do this now`,
          autoNext: true,
          blockNextWhile: () =>
            elementVisible(tileRefElement, ".mid-panel .subtitle .pill:nth-of-type(2)")
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".mid-panel .title .technical-tooltip"), // ".dashboard-wrapper .row:first-of-type .tile:first-of-type .mid-panel .title div div", //getElement(tileRefElement, ".mid-panel .title div div"),
          content: "You can get a further description by hovering this info icon ",
          setupBeforePrevious: () => closeLeftPane(tileRefElement),
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `This concludes the walkthrough`,
          placement: "center",
          spotlightClicks: true
        }
      ];
  return { steps };
};

const useDashboardOverallOrderDonutChartSteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const { localeString } = useLocalization();
  const tileRefElement = ref.current;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement || "",
    title: "Overall order status - donut chart"
  };

  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);

  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");
  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);
  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          content: "This is a guided walkthrough for the Overall order status",
          placement: "center"
        },
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title"),
          content: "Here we see the title"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut"),
          content: `Here we see the donut chart.`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut svg .labels"),
          content: `This is the total quantity`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut svg"),
          content: `Try hovering the different colors in the wheel. In the middle you can see the quantity for the current status`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut .chart-legend"),
          content: `Here is the legend for the donut chart`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut .chart-legend"),
          content: `You can click on the items to toggle them on and off from the chart. Try this now`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () => !elementVisible(tileRefElement, ".chart-legend .pill.unselected")
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut"),
          content: `We can see that the donut chart is now excluding that status`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".custom-tabs-buttons"),
          content: `You can toggle between ${localeString("view_po")} and ${localeString(
            "view_po_it"
          )} here.`,
          placement: "bottom-end"
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".custom-tabs-buttons .tabs-button:last-of-type") ||
            tileRefElement,
          content: `Try clicking the this tab`,
          placement: "bottom-end",
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(
              tileRefElement,
              ".custom-tabs-buttons .tabs-button:last-of-type.selected"
            )
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `We can see that the donut chart updated from ${localeString(
            "view_po"
          )} to ${localeString("view_po_it")}`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".date-picker"),
          content: `This is the date filter.`,
          placement: "right"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `${
            !elementVisible(tileRefElement, ".eds-donut") ? "No data for selected period." : ""
          } Try changing the date filter for the tile.`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(tileRefElement, ".eds-donut") ||
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `You can see that the dashboard updated`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".subtitle .pill"),
          content: `You can see the active filters here`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title .technical-tooltip"),
          content: "You can get a further description by hovering this info icon ",
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `This concludes the walkthrough`,
          placement: "center",
          spotlightClicks: true
        }
      ];
  return { steps };
};

const useDashboardConfirmedDeliveryDateSteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const { localeString } = useLocalization();
  const tileRefElement = ref.current;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement || "",
    title: "Confirmed delivery dates"
  };
  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          target: tileRefElement,
          content: "This is a guided walkthrough for the Confirmed delivery date",
          placement: "center"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title"),
          content: "Here we see the title"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".enhanced-kpi-container"),
          content: `Here we see the KPIs for the current and upcoming weeks`
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".enhanced-kpi-container .column:nth-of-type(2)") ||
            tileRefElement,
          content: (() => {
            const kpiHTML = getElement(
              tileRefElement,
              ".enhanced-kpi-container .column:nth-of-type(2) .kpi"
            );
            if (kpiHTML) {
              const content = kpiHTML.innerText.split(/\n/g);
              const period = content.length && content[0];
              const value = content.length > 1 && content[1];
              const label = content.length > 2 && content[2];
              if (period && value && label) {
                return `We see that for ${period} there are ${value} ${label} for delivery`;
              }
            }
            return `Here we have a KPI`;
          })(),
          spotlightPadding: 15,
          offset: 15
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".enhanced-kpi-container .column:nth-of-type(2)") ||
            tileRefElement,
          content: (() => {
            const kpiHTML = getElement(
              tileRefElement,
              ".enhanced-kpi-container .column:nth-of-type(2) .kpi"
            );
            if (kpiHTML) {
              const content = kpiHTML.innerText.split(/\n/g);
              const period = content.length && content[0];
              if (period) {
                return `Clicking the KPI will take you to ${localeString(
                  "MenuKeys_PurchaseOrders"
                )} with the Confirmed delivery filter set to ${period}`;
              }
            }
            return `Clicking a KPI will take you to ${localeString(
              "MenuKeys_PurchaseOrders"
            )} with the preset filter of the period`;
          })(),
          spotlightPadding: 15,
          offset: 15
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".custom-tabs-buttons"),
          content: `You can toggle between ${localeString("view_po")} and ${localeString(
            "view_po_it"
          )} here.`,
          placement: "bottom-end"
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".custom-tabs-buttons .tabs-button:last-of-type") ||
            tileRefElement,
          content: `Try clicking the this tab`,
          placement: "bottom-end",
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(
              tileRefElement,
              ".custom-tabs-buttons .tabs-button:last-of-type.selected"
            )
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `We can see that the KPIs updated from ${localeString(
            "view_po"
          )} to ${localeString("view_po_it")}`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title .technical-tooltip"),
          content: "You can get a further description by hovering this info icon ",
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `This concludes the walkthrough`,
          placement: "center"
        }
      ];
  return { steps };
};
const useDashboardDeliveryPrecisionSteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const { localeString } = useLocalization();

  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Delivery precision"
  };
  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);

  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");
  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);

  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          target: tileRefElement,
          disableScrolling: false,
          content: "This is a guided walkthrough for the delivery precision.",
          placement: "center"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title"),
          content: "Here you can see what is being displayed in the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".delivery-precision"),
          content: `This is showing the delivery precision gauge`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".subtitle .pill"),
          content: (() => {
            const pillHTML = getElement(tileRefElement, ".subtitle .pill");
            return `It's currently showing for ${
              !pillHTML ? "this period" : `the period ${pillHTML.innerHTML}`
            }`;
          })(),
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".delivery-precision"),
          content: (() => {
            const valueHTML = getElement(
              tileRefElement,
              ".eds-gauge svg g g:last-of-type text.total"
            );
            const pillHTML = getElement(tileRefElement, ".subtitle .pill");
            return `As we can see the delivery precision for ${
              pillHTML ? `the period ${pillHTML.innerHTML}` : "this period"
            } is ${
              valueHTML ? `${valueHTML.innerHTML}%` : "the percentage shown inside the gauge"
            }`;
          })(),
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".custom-tabs-buttons"),
          content: `You can toggle between ${localeString("view_po")} and ${localeString(
            "view_po_it"
          )} here`,
          placement: "bottom-end"
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".custom-tabs-buttons .tabs-button:last-of-type") ||
            tileRefElement,
          content: `Try toggling from ${localeString("view_po")} and ${localeString("view_po_it")}`,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(
              tileRefElement,
              ".custom-tabs-buttons .tabs-button:last-of-type.selected"
            ),
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `The data is now showing delivery precision for ${localeString("view_po_it")}`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".date-picker"),
          content: (() => {
            const dateHTML = getElement(
              tileRefElement,
              ".date-picker .date-picker-header-value .tooltip span"
            );
            return `This is the date filter${
              dateHTML ? `. It's currently selected as ${dateHTML.innerText}` : ""
            }`;
          })(),
          placement: "right"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `Try changing the date filter for the tile.`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: (() => {
            const el = getElement(
              tileRefElement,
              ".date-picker .date-picker-header-value .tooltip span"
            );
            return `The delivery precision is now showing data for ${
              el ? `the period ${el.innerText}` : "the new period"
            }`;
          })()
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".precision-data-text"),
          content: "Here we can see the quantity data"
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".precision-data-text .text-container:nth-of-type(1)") ||
            tileRefElement,
          content: (() => {
            const el = getElement(
              tileRefElement,
              ".precision-data-text .text-container:nth-of-type(1)"
            );
            return `We see that the total quantity ${el ? `is ${el.innerText}` : "here"}`;
          })()
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".precision-data-text .text-container:nth-of-type(2)") ||
            tileRefElement,
          content: (() => {
            const el = getElement(
              tileRefElement,
              ".precision-data-text .text-container:nth-of-type(2)"
            );
            return `We see that the amount of orders ${
              el && el.innerText.length ? `${el.innerText}` : "on time here"
            }.`;
          })()
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".precision-data-text .text-container:nth-of-type(3)") ||
            tileRefElement,
          content: (() => {
            const el = getElement(
              tileRefElement,
              ".precision-data-text .text-container:nth-of-type(3)"
            );
            return `We see that the amount of orders ${
              el && el.innerText.length ? `${el.innerText}` : "not on time here"
            }.`;
          })()
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".precision-data-text"),
          content: `If you click On Time or Not on Time, it will lead you to ${localeString(
            "MenuKeys_PurchaseOrders"
          )} with a preset filter`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title .technical-tooltip"),
          content: "You can get a further description by hovering this info icon ",
          setupBeforePrevious: () => closeLeftPane(tileRefElement),
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `This concludes the walkthrough`,
          placement: "center",
          spotlightClicks: true
        }
      ];
  return { steps };
};
const useDashboardMaterialInventorySteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const { localeString } = useLocalization();

  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Inventory status in National WH"
  };
  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          target: tileRefElement,
          content: "This is a guided walkthrough of the Inventory status in National WH.",
          placement: "center"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .title"),
          content: "Here you can see what is being displayed in the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".search-bar-filter"),
          content: "You can search for materials here"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: "Search for a material and select it",
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            condtionalElementCheck(
              tileRefElement,
              ".column .row:nth-of-type(1) .material-info .item b",
              el => {
                return el === null || el.innerText === "-";
              }
            )
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".column:nth-of-type(1) .row .material-info .item") ||
            tileRefElement,
          content: "Here we can see the material id"
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".column:nth-of-type(2) .row .material-info .item") ||
            tileRefElement,
          content: "Here we can see the material name"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".column:nth-of-type(1) .row .kpi"),
          content: "Here we can see In transit to National WH / Ordered but not received"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".column:nth-of-type(2) .row .kpi"),
          content: `Here we see the quantity in the national warehouse. Clicking this will lead you to ${localeString(
            "MenuKeys_NWHInventory"
          )} with the material preselected`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .title .technical-tooltip"),
          content: "You can get a further description by hovering this info icon ",
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `This concludes the walkthrough`,
          placement: "center"
        }
      ];
  return { steps };
};
const useDashboardMeetRequestedDateSteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const { localeString } = useLocalization();

  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Does not meet requested date"
  };
  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is a guided walkthrough for the Does not meet requested date",
          placement: "center"
        },
        {
          ...defaultStep,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title"),
          content: "Here we see the title"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".enhanced-kpi-container"),
          content: `Here we see the KPIs for the current and upcoming weeks`
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".enhanced-kpi-container .column:nth-of-type(2)") ||
            tileRefElement,
          content: (() => {
            const kpiHTML = getElement(
              tileRefElement,
              ".enhanced-kpi-container .column:nth-of-type(2) .kpi"
            );
            if (kpiHTML) {
              const content = kpiHTML.innerText.split(/\n/g);
              const period = content.length && content[0];
              const value = content.length > 1 && content[1];
              const label = content.length > 2 && content[2];
              if (period && value && label) {
                return `We see that for ${period} there are ${value} ${label} that does not meet requested date`;
              }
            }
            return `Here we see a KPI`;
          })(),
          spotlightPadding: 15,
          offset: 15
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".enhanced-kpi-container .column:nth-of-type(2)") ||
            tileRefElement,
          content: `Clicking a KPI will take you to ${localeString(
            "MenuKeys_PurchaseOrders"
          )} with the preset filter of the period and Does not meet requested date set to true`,
          spotlightPadding: 15,
          offset: 15
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".custom-tabs-buttons"),
          content: `You can toggle between ${localeString("view_po")} and ${localeString(
            "view_po_it"
          )} here.`,
          placement: "bottom-end"
        },
        {
          ...defaultStep,
          target:
            getElement(tileRefElement, ".custom-tabs-buttons .tabs-button:last-of-type") ||
            tileRefElement,
          content: `Try clicking the this tab`,
          placement: "bottom-end",
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(
              tileRefElement,
              ".custom-tabs-buttons .tabs-button:last-of-type.selected"
            ),
          spotlightClicks: true
        },
        {
          ...defaultStep,
          content: `We can see that the KPIs updated from ${localeString(
            "view_po"
          )} to ${localeString("view_po_it")}`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title .technical-tooltip"),
          content: "You can get a further description by hovering this info icon ",
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          content: `This concludes the walkthrough`,
          placement: "center"
        }
      ];
  return { steps };
};
const useDashboardOrderAverageLeadTimeSteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Average lead time"
  };
  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);

  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");
  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);

  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          content: "This is a guided walkthrough of the Average lead time dashboard.",
          placement: "center"
        },
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .title"),
          content: "Here you can see what is being displayed in the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .subtitle .pill"),
          content: (() => {
            const periodHTML = getElement(tileRefElement, ".left .subtitle .pill");
            return periodHTML
              ? `Here we can see that it's currently displaying data for ${periodHTML.innerHTML}`
              : "Here we can see the current period selected";
          })()
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".column:nth-of-type(1) .kpi"),
          spotlightPadding: 15,
          offset: 15,
          content: "Here you can see the average lead time for the last period"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".column:nth-of-type(2) .kpi"),
          spotlightPadding: 15,
          offset: 15,
          content: "Here you can see the average lead time for the current period"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".right"),
          content: (() => {
            const periodHTML = getElement(tileRefElement, ".right > div");
            return `This period selection filter${
              periodHTML && periodHTML.title ? `. It's currently set as ${periodHTML.title}` : ""
            }`;
          })()
        },
        {
          ...defaultStep,
          content: `Try changing the period filter`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          content: (() => {
            const periodHTML = getElement(tileRefElement, ".right > div");
            return `We can see that the data updated${
              periodHTML && periodHTML.title ? ` to show the period ${periodHTML.title}` : ""
            }`;
          })()
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .title .technical-tooltip"),
          content: "You can get a further description by hovering this info icon ",
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          content: `This concludes the walkthrough`,
          placement: "center"
        }
      ];
  return { steps };
};
const useDashboardOrderLeadTimeSteps = (ref: RefObject<HTMLDivElement>, placement?: Placement) => {
  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Lead time - graph chart"
  };
  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);

  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");
  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);
  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is a guided walkthrough of the Lead time graph dashboard.",
          placement: "center"
        },
        {
          ...defaultStep,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .title"),
          content: "Here you can see what is being displayed in the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container"),
          content: "Try hovering the visualization for more info",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container .chart-legend"),
          content: "This is the legend.",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container"),
          content:
            "Try hovering one of the labels in the legend. This will show only that chart line.",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container .chart-legend"),
          content: "Try clicking on one of the labels.",
          autoNext: true,
          blockNextWhile: () => !elementVisible(tileRefElement, ".chart-legend .pill.unselected"),
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container"),
          content: "This hides it from the line chart."
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".right"),
          content: (() => {
            const periodHTML = getElement(tileRefElement, ".right > div");
            return `This period selection filter${
              periodHTML && periodHTML.title ? `. It's currently set as ${periodHTML.title}` : ""
            }`;
          })()
        },
        {
          ...defaultStep,
          content: `Try changing the period filter`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          content: (() => {
            const periodHTML = getElement(tileRefElement, ".right > div");
            return `We can see that the bar chart updated${
              periodHTML && periodHTML.title ? ` to show the period ${periodHTML.title}` : ""
            }`;
          })()
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".subtitle"),
          content: `You can also see what period is selected here`
        },
        {
          ...defaultStep,
          content: `This concludes the walkthrough`,
          placement: "center"
        }
      ];
  return { steps };
};
const useDashboardOverallInternalOrderStatusBarsSteps = (
  ref: RefObject<HTMLDivElement>,
  placement: Placement
) => {
  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Overall internal order status - bar chart"
  };
  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);

  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");
  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);

  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          disableScrolling: false,
          content:
            "This is a guided walkthrough of the Overall internal order status dashboard tile.",
          placement: "center"
        },
        {
          ...defaultStep,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .title"),
          content: "Here you can see what is being displayed in the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container"),
          content: "Try hovering the visualization",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".subtitle .pill"),
          content: `We can see here that data in the chart is ${getTextOfElement(
            tileRefElement,
            ".subtitle .pill",
            "set to this"
          )}`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".date-picker"),
          content: `You can change the displayed period with the date picker. Let's do that now.`
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `Change the period with the datepicker.`,
          spotlightClicks: true,
          blockNextWhile: () =>
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          content: `We can see that the text updated to show the period ${getTextOfElement(
            tileRefElement,
            ".subtitle .pill",
            ""
          )}`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          content: `This concludes the walkthrough`,
          placement: "center",
          spotlightClicks: true
        }
      ];
  return { steps };
};
const useDashboardOverallInternalOrderStatusDonutSteps = (ref: RefObject<HTMLDivElement>) => {
  const tileRefElement = ref.current;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    isFixed: true,
    placement: "top",
    spotlightPadding: 0,
    target: tileRefElement || "",
    title: "Overall internal order status - donut chart"
  };

  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);

  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");
  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);
  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          content: "This is a guided walkthrough for the Overall internal order status",
          placement: "center"
        },
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title"),
          content: "Here we see the title"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut"),
          content: `Here we see the donut chart. The total quantity is shown in the middle of it.`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut"),
          content: `Try hovering the different colors in the wheel. In the middle you can see the quantity for the current status`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut .chart-legend"),
          content: `Here is the legend for the donut chart`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut .chart-legend"),
          content: `You can click on the items to toggle them on and off from the chart`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () => !elementVisible(tileRefElement, ".chart-legend .pill.unselected")
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut"),
          content: `We can see that the donut chart is now excluding that status`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".date-picker"),
          content: `This is the date filter.`,
          placement: "right"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `${
            !elementVisible(tileRefElement, ".eds-donut") ? "No data for selected period." : ""
          } Try changing the date filter for the tile.`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(tileRefElement, ".eds-donut") ||
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `You can see that the dashboard updated`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".subtitle .pill"),
          content: `You can see the active filters here`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title .technical-tooltip"),
          content: "You can get a further description by hovering this info icon ",
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          target: tileRefElement,
          content: `This concludes the walkthrough`,
          placement: "center",
          spotlightClicks: true
        }
      ];
  return { steps };
};
const useDashboardPlannedDeliveryDateSteps = (
  ref: RefObject<HTMLDivElement>,
  placement?: Placement
) => {
  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Planned delivery date"
  };

  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          content: "This is a guided walkthrough of the Planned delivery date dashboard tile.",
          placement: "center"
        },
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .title"),
          content: "Here you can see what is being displayed in the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container"),
          content: "Try hovering the visualization",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .subtitle .pill"),
          content: "We can see here that data in the chart is 'Weekly'",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container svg .bar-group .bar"),
          spotlightPadding: 40,
          offset: 40,
          content: (() => {
            const info = getVisulizationBarValue(tileRefElement);
            return `Here we can see that for ${
              info.label ? `${info.label}` : "the first week"
            } the number of planned deliveries ${
              info.value ? `is ${info.value}` : "can be seen at the top of the bar"
            }`;
          })(),
          placement: "right"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".search-bar-filter"),
          content:
            "This is the search filter. Here you can limit the data in the bar chart by searching for and selecting a material."
        },
        {
          ...defaultStep,
          content: "Search for and select a material before continuing",
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            condtionalElementCheck(
              tileRefElement,
              ".subtitle .pill:nth-of-type(2)",
              (el: Element | null) => el === null
            )
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".chart-container svg .bar-group .bar"),
          content: (() => {
            const materialExists = getElement(
              tileRefElement,
              ".chart-container svg .bar-group .bar"
            );
            const materialChosen = (
              getElement(tileRefElement, ".subtitle .pill:nth-of-type(2)") || {
                innerHTML: undefined
              }
            ).innerHTML;

            if (materialExists) {
              const info = getVisulizationBarValue(tileRefElement);
              return `Here we can see that for ${
                info.label ? `${info.label}` : "the first week"
              } the number of planned deliveries for ${materialChosen} ${
                info.value ? `is ${info.value}` : "can be seen at the top of the bar"
              }`;
            } else {
              return `There is not data for ${materialChosen}, please try another`;
            }
          })(),
          spotlightPadding: 40,
          offset: 40,
          placement: "right",
          autoNext: true,
          blockNextWhile: () =>
            condtionalElementCheck(
              tileRefElement,
              ".chart-container svg .bar-group .bar",
              (el: Element | null) => el === null
            )
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .subtitle .pill:nth-of-type(2)"),
          content: (() => {
            const el = getElement(tileRefElement, ".left .subtitle .pill:nth-of-type(2)");
            if (el) {
              return (
                <span>
                  You can remove filters with a cross in them, like this one
                  <Pill style={{ marginLeft: "8px" }} onRemove={undefined}>
                    Filter
                  </Pill>
                  by clicking on them.
                </span>
              );
            }
            return `You can remove a filter with a by clicking on it`;
          })()
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".left .subtitle .pill:last-of-type"),
          content: "Try removing the material filter you have added",
          autoNext: true,
          blockNextWhile: () =>
            elementVisible(tileRefElement, ".left .subtitle .pill:nth-of-type(2)"),
          spotlightClicks: true
        },
        {
          ...defaultStep,
          content: "The bar chart is now showing data for all materials again",
          spotlightClicks: true
        },
        {
          ...defaultStep,
          content: `This concludes the walkthrough`,
          placement: "center",
          spotlightClicks: true
        }
      ];
  return { steps };
};
const useDashboardShipmentCountSteps = (ref: RefObject<HTMLDivElement>, placement?: Placement) => {
  const tileRefElement = ref.current as HTMLDivElement;
  const defaultStep: IStep = {
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    placement: placement || "top",
    spotlightPadding: 0,
    target: tileRefElement,
    title: "Shipment count - donut chart"
  };

  const [originalDate, setOriginalDate] = useState<string | undefined>(undefined);
  const dateText = tileRefElement && getTextOfElement(tileRefElement, ".subtitle .pill", "");

  useEffect(() => {
    if (dateText && (!originalDate || originalDate.includes("No range"))) {
      setOriginalDate(dateText);
    }
  }, [dateText]);

  const steps: IStep[] = !tileRefElement
    ? []
    : [
        {
          ...defaultStep,
          content: "This is a guided walkthrough for the Shipment count",
          placement: "center"
        },
        {
          ...defaultStep,
          disableScrolling: false,
          content: "This is the dashboard tile"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title"),
          content: "Here we see the title"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".title .technical-tooltip"), // ".dashboard-wrapper .row:first-of-type .tile:first-of-type .mid-panel .title div div", //getElement(tileRefElement, ".mid-panel .title div div"),
          content: "You can get a further description by hovering this info icon ",
          spotlightClicks: true,
          placement: "left"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut"),
          content: `Here we see the donut chart.`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut svg .labels"),
          content: (() => {
            const valueHTML = getElement(tileRefElement, ".eds-donut svg .labels .value");
            return `This is the total quantity${
              valueHTML
                ? `. We can see that the total number of shipments is ${valueHTML.innerHTML}`
                : ""
            }`;
          })()
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut svg"),
          content: `Try hovering the different colors in the wheel. In the middle you can see the quantity for the current status`,
          spotlightClicks: true
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut .chart-legend"),
          content: `Here is the legend for the donut chart`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut .chart-legend"),
          content: `You can click on the items to toggle them on and off from the chart`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () => !elementVisible(tileRefElement, ".chart-legend .pill.unselected")
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".eds-donut"),
          content: `We now see that the donut is excluding that period`
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".date-picker"),
          content: (() => {
            const dateHTML = getElement(
              tileRefElement,
              ".date-picker .date-picker-header-value .tooltip span"
            );
            return `This is the date filter${
              dateHTML ? `. It's currently selected as ${dateHTML.innerText}` : ""
            }`;
          })(),
          placement: "right"
        },
        {
          ...defaultStep,
          target: getDefinedElement(tileRefElement, ".date-picker .calendar.opened.dual"),
          content: `${
            !elementVisible(tileRefElement, ".eds-donut") ? "No data for selected period." : ""
          } Try changing the date filter for the tile.`,
          spotlightClicks: true,
          autoNext: true,
          blockNextWhile: () =>
            !elementVisible(tileRefElement, ".eds-donut") ||
            getTextOfElement(tileRefElement, ".subtitle .pill", "_") === originalDate
        },
        {
          ...defaultStep,
          content: `The donut chart is now showing data for ${getTextOfElement(
            tileRefElement,
            ".date-picker .date-picker-header-value .tooltip span",
            "the new period",
            "the period "
          )}`
        },
        {
          ...defaultStep,
          content: `This concludes the walkthrough`,
          placement: "center",
          spotlightClicks: true
        }
      ];
  return { steps };
};

export {
  useDashboardConfirmedDeliveryDateSteps,
  useDashboardMaterialInventorySteps,
  useDashboardDeliveryPrecisionSteps,
  useDashboardMeetRequestedDateSteps,
  useDashboardOrderAverageLeadTimeSteps,
  useDashboardOrderLeadTimeSteps,
  useDashboardOverallOrderDonutChartSteps,
  useDashboardPlannedDeliveryDateSteps,
  useDashboardShipmentCountSteps,
  useDashboardOverallInternalOrderStatusDonutSteps,
  useDashboardOverallInternalOrderStatusBarsSteps,
  useDashboardOverallOrderBarChartSteps
};
