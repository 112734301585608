import React, { useEffect, useState } from "react";
import { FullScreenLoader } from "./full-screen-loader";

export const useLoadingBar = (loaders: (boolean | number | undefined)[], reShow?: boolean) => {
  const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

  useEffect(() => {
    if (!firstLoadCompleted && someHasStarted(loaders)) {
      const completed = loaders.every(l => l !== undefined && !isActive(l));
      if (completed) {
        setFirstLoadCompleted(completed);
      }
    }
  }, [loaders]);

  const someHasStarted = (loaders: (boolean | number | undefined)[]) => {
    return loaders.some(l => l !== undefined);
  };

  const isActive = (loader: boolean | number | undefined) => {
    if (typeof loader === "boolean") {
      return loader;
    } else if (typeof loader === "number") {
      return loader > 0;
    } else {
      return false;
    }
  };

  const renderLoadingBar = () => <FullScreenLoader />;

  const showLoadingBar = (reShow || !firstLoadCompleted) && loaders.some(l => isActive(l));

  return { showLoadingBar, renderLoadingBar };
};
