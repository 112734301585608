import React from "react";
import AxisGrid from "./AxisGrid";
import AxisLeft from "./AxisLeft";
import AxisBottom from "./AxisBottom";

interface Props {
  xScale: any;
  yScale: any;
  y: any;
  x: any;
  tickPadding: number;
  width: number;
  height: number;
  axisPadding: number;
  hideValues?: boolean;
}

const AxisGroup = ({
  xScale,
  yScale,
  y,
  x,
  tickPadding,
  width,
  height,
  axisPadding,
  hideValues
}: Props) => {
  return (
    <>
      <AxisLeft
        yScale={yScale}
        width={width}
        height={height - tickPadding}
        tickSize={-width}
        tickPadding={axisPadding}
        ticks={height / 80}
        tickFormat={y !== undefined ? y.tickFormat : undefined}
        domain
        formatType="default"
        hideValues={hideValues}
        grid
      />
      <AxisBottom
        xScale={xScale}
        tickSize={height}
        tickPadding={axisPadding - 8}
        ticks={width / 120}
        tickFormat={x !== undefined ? x.tickFormat : undefined}
        domain
        hideValues={hideValues}
        formatType="default"
      />
      <AxisGrid
        left
        yScale={yScale}
        width={width}
        height={height - tickPadding}
        tickSize={-width}
        tickPadding={axisPadding}
        ticks={height / 80}
        tickFormat={y !== undefined ? y.tickFormat : undefined}
        domain
        formatType="default"
        hideValues={hideValues}
      />
      <AxisGrid
        bottom
        xScale={xScale}
        tickSize={height - tickPadding}
        tickPadding={4}
        ticks={width / 120}
        tickFormat={x !== undefined ? x.tickFormat : undefined}
        domain
        formatType="default"
      />
    </>
  );
};

export default AxisGroup;
