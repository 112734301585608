import React, { useEffect, useMemo, useRef } from "react";
import { Pill, Tile, useSettings } from "@react-gcc-eds/core";

import { isFetching, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import DatePicker from "../../../filters/date-picker";
import { StatusBarChart } from "../bars-chart";

import { DashboardGraph, DashboardTileAlignment, IDashboardItemSelected } from "..";
import { IExternalOrdersRedirectionFilters } from "../../../../actions/orders-filters-actions";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { useWalkThrough } from "../../../walkthrough";
import { useDashboardOverallInternalOrderStatusBarsSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { usePredefinedRanges } from "../../../filters/date-picker/predefined-ranges";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { dateAsYMDString } from "../../../../utils";
import { activeFiltersIcon } from "../../../filters/filters-icon";
import { useLocalization } from "../../../../utils/localization";
import cx from "classnames";

const OverallInternalOrderStatusBars = ({
  className,
  customerId,
  alignment,
  onItemSelected
}: {
  className: string;
  customerId: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const [state] = useStore();
  const tileRef = useRef<HTMLDivElement>(null);

  const { internalOrderCreationDateBarsMetric: loading } = state.fetchingData.metrics;
  const { steps } = useDashboardOverallInternalOrderStatusBarsSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { currentCustomer } = useSettingsActions();
  const { rangeAsString, largestRange, isLargestRange } = usePredefinedRanges(
    new Date(currentCustomer().dataAvailableFromDate)
  );
  const { getInternalOrderCreationDateBarsMetric } = useMetricsActions();
  const { setMetricsOverallInternalOrderStatusBarsCreationRange } = useMetricsFiltersActions();
  const { isWalkthroughEnabled } = useSettingsActions();
  const { theme } = useSettings();
  const { localeString } = useLocalization();

  useEffect(() => {
    if (state.metricsFilters.overallInternalOrderStatusBarsCreationRange) {
      const { from, to } = state.metricsFilters.overallInternalOrderStatusBarsCreationRange;

      getInternalOrderCreationDateBarsMetric(dateAsYMDString(from), dateAsYMDString(to));
    }
  }, [state.metricsFilters.overallInternalOrderStatusBarsCreationRange, customerId]);

  const purchaseOrders = () => {
    const { internalOrderCreationDateBarsMetric } = state.metrics;
    return (
      <StatusBarChart
        metrics={internalOrderCreationDateBarsMetric}
        loading={loading === true}
        unit={""}
        datesRange={state.metricsFilters.overallInternalOrderStatusBarsCreationRange}
        internal
        onSelection={(filters: IExternalOrdersRedirectionFilters) =>
          onItemSelected && onItemSelected({ graph: DashboardGraph.InternalOrders, filters })
        }
      />
    );
  };
  const tileHeader = (
    <div className="dashboard-tile">
      {!isLargestRange(state.metricsFilters.overallInternalOrderStatusBarsCreationRange)
        ? activeFiltersIcon(1)
        : null}
      <WalkthroughHeader
        walkthroughActive={isWalkthroughEnabled()}
        header={
          localeString("Dashboard_InternalOverallOrderStatusBarChart") ||
          "Internal Overall order status"
        }
        tooltipInfo="Graph is showing the status for the selected time period"
        alignment={alignment}
        onClick={startWalkThrough}
        disabled={isFetching(state)}
      />
    </div>
  );

  const resetFilter = () => {
    setMetricsOverallInternalOrderStatusBarsCreationRange(largestRange.selector());
  };

  const resetFilterPill = () => {
    return (
      <Pill className={cx("filter-pill", theme)} onToggle={resetFilter}>
        Reset filters
      </Pill>
    );
  };

  const filtersSummary = (
    <div className="dashboard-active-filter">
      <Pill>{rangeAsString(state.metricsFilters.overallInternalOrderStatusBarsCreationRange)}</Pill>
      {!isLargestRange(state.metricsFilters.overallInternalOrderStatusBarsCreationRange)
        ? resetFilterPill()
        : null}
    </div>
  );

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={`hide-labels ${className}`}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={filtersSummary}
        actions={[
          <DatePicker
            dual={state.userPreferences.useDualCalendar}
            rolling
            selectedRange={state.metricsFilters.overallInternalOrderStatusBarsCreationRange}
            oldestDate={new Date(currentCustomer().dataAvailableFromDate)}
            onRangeSelected={setMetricsOverallInternalOrderStatusBarsCreationRange}
            right={alignment !== TileAlignment.LEFT}
            key="date-picker"
          />
        ]}
      >
        {purchaseOrders()}
      </Tile>
      <WalkThrough />
    </>
  );
};

export default OverallInternalOrderStatusBars;
