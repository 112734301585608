import React from "react";
import { ApplicationLayer, Tile, Row, Column, Pill } from "@react-gcc-eds/core";
import useAppMenu from "../../shared/menu";
import { ReleaseNotes } from "../../../releasenotes";
import { useStore } from "../../../store";

interface IReleaseNoteSection {
  version: string;
  released: string;
  notes: IReleaseNote[];
}
interface IReleaseNote {
  type: string;
  comment: string;
  internal: string;
  backlogUrls: string[];
  customers: string[];
}
const ReleaseNotesView = () => {
  const [{ settings, user }] = useStore();
  const Customer = ({ name }: { name: string }) => {
    return <Pill className="small-pill">{name}</Pill>;
  };

  const BacklogUrl = ({ url }: { url: string }) => {
    const trimmed = url.slice(url.lastIndexOf("/") + 1, url.length);
    return (
      <Pill className="small-pill">
        <a href={url} rel="noopener noreferrer" target="_blank">
          {trimmed}
        </a>
      </Pill>
    );
  };
  const ReleaseNoteItem = ({ note }: { note: IReleaseNote }) => {
    const userHasCustomerConnected = user
      ? user.customers.filter(value =>
          note.customers.map(f => f.toUpperCase()).includes(value.displayName.toUpperCase())
        ).length
      : 0;
    if (
      (note.customers.length > 0 &&
        !userHasCustomerConnected &&
        settings.ReleaseNotes.CustomersFilter !== "off") ||
      (note.internal === "1" && settings.ReleaseNotes.ShowInternal !== "on")
    ) {
      return null;
    }

    return (
      <Row className={`release-note-text${note.internal === "1" ? " internal" : ""}`}>
        <Column lg={5}>
          <li>{note.comment}</li>
        </Column>
        <Column lg={1} />
        <Column lg={3}>
          {settings.ReleaseNotes.Customers === "on" &&
            note.customers.map((c, i) => {
              return <Customer key={`${c}_${i}`} name={c} />;
            })}
        </Column>
        <Column lg={3}>
          {settings.ReleaseNotes.BacklogLinks === "on" &&
            note.backlogUrls.map((u, i) => {
              return <BacklogUrl key={`${u}_${i}`} url={u} />;
            })}
        </Column>
      </Row>
    );
  };
  const renderSection = (title: string, notes: IReleaseNote[]) => {
    if (notes.length <= 0) return null;
    return (
      <>
        <h4>{title}</h4>
        {notes.map((n: IReleaseNote) => {
          return <ReleaseNoteItem key={n.comment} note={n} />;
        })}
        <div style={{ marginBottom: "10px" }} />
      </>
    );
  };
  const ReleaseSection = ({ release }: { release: IReleaseNoteSection }) => {
    return (
      <Tile title={`v${release.version}`}>
        {renderSection(
          "New features",
          release.notes.filter(n => n.type === "FEATURE")
        )}
        {renderSection(
          "Changes",
          release.notes.filter(n => n.type === "CHANGES")
        )}
        {renderSection(
          "Fixed bugs",
          release.notes.filter(n => n.type === "BUGFIX")
        )}
      </Tile>
    );
  };

  return (
    <ApplicationLayer title="Release notes" menu={useAppMenu()}>
      {settings &&
        settings.ReleaseNotes &&
        ReleaseNotes.map((r: IReleaseNoteSection) => {
          return (
            <Row key={`${r.version}_${r.released}`}>
              <ReleaseSection release={r} />
            </Row>
          );
        })}
    </ApplicationLayer>
  );
};

export default ReleaseNotesView;
