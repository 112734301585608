import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Dropdown,
  ISelectItem,
  Loader,
  MultiSelect,
  Switch
} from "@react-gcc-eds/core";
import { useCustomerActions } from "../../../actions/customer-actions";
import { useSettingsActions } from "../../../actions/settings-actions";
import { ConfirmDialog } from "../../../components/shared/confirm-dialog";
import {
  IScheduledExport,
  IScheduledExportItem,
  IUser,
  IUserCustomerScheduledExportItem,
  useStore
} from "../../../store";
import { MenuKey, useCustomerHelpers } from "../../../utils/customer-helpers";
import { useLocalization } from "../../../utils/localization";
import { useUserHelpers } from "../../../utils/user-helpers";
import { CustomTooltip } from "../../reports/helpers/custom-tooltip";

enum Operation {
  Cancel,
  Save,
  Delete
}
const areEqual = (a?: IScheduledExportItem[], b?: IScheduledExportItem[]) => {
  if (!a || !b) return false;
  return JSON.stringify(a) === JSON.stringify(b);
};

const ScheduledExportsDialog = ({
  onClose,
  adminCustomerExportItems,
  userCustomerScheduledExportItemsList,
  onScheduledExportViewChanged,
  onScheduledExportsEnabled,
  onWeeklyChecked,
  onWeekdaySelected,
  viewChanged,
  onSave
}: {
  onClose: Function;
  adminCustomerExportItems: IScheduledExportItem[];
  userCustomerScheduledExportItemsList: {
    current: IScheduledExport;
    original: IScheduledExport;
    equals: boolean;
  };
  onScheduledExportsEnabled: (key: number, value: boolean) => void;
  onScheduledExportViewChanged: (key: number, values: number[]) => void;
  onWeeklyChecked: (value: boolean, weekdayValue: number) => void;
  onWeekdaySelected: (value: number) => void;
  viewChanged: boolean;
  onSave: () => void;
}) => {
  const { localeString } = useLocalization();
  const { userRoleIsSuperUser, userRoleIsAdministrator, userRoleIsTechSupport } = useUserHelpers();
  const { isMenuEnabled, areMenuRolesValid } = useCustomerHelpers();
  const { currentCustomer } = useSettingsActions();
  const [hasTemplateForDataExport, setHasTemplateForDataExport] = useState<boolean>(false);
  const [state] = useStore();
  const [confirm, setConfirm] = useState({ active: false, operation: Operation.Cancel });
  const viewUntouched = viewChanged;
  const [filteredItems, setFilteredItems] = useState<IScheduledExport>({
    recurringDay: 0,
    scheduledExportUserCustomerSettings: []
  });
  const { getCustomerParams } = useCustomerActions();
  const [isWeeklyChecked, setIsWeeklyChecked] = useState(false);
  const [weekday, setWeekday] = useState("Monday");
  const [weekdayNumber, setWeekdayValue] = useState(0);

  const getParameters = async () => {
    if (state.customer) {
      const params = await getCustomerParams(state.customer.id);
      if (params) {
        const template = params.find(x => x.key === "UseTemplateForDataExport")?.value;
        template === "true"
          ? setHasTemplateForDataExport(true)
          : setHasTemplateForDataExport(false);
      }
    }
  };
  const dropDownItems = [
    { title: "Monday", value: 1 },
    { title: "Tuesday", value: 2 },
    { title: "Wednesday", value: 3 },
    { title: "Thursday", value: 4 },
    { title: "Friday", value: 5 },
    { title: "Saturday", value: 6 },
    { title: "Sunday", value: 0 }
  ];
  useEffect(() => {
    getParameters();
  }, []);

  //Setting default value of recurring day
  useEffect(() => {
    const recurringDay = userCustomerScheduledExportItemsList.current.recurringDay;
    const weekDayLabel = dropDownItems.find(x => x.value === recurringDay)?.title;
    weekDayLabel && setWeekday(weekDayLabel);
    setWeekdayValue(recurringDay);
    setIsWeeklyChecked(recurringDay === 0 ? false : true);
  }, []);

  const buttons = () => {
    return [
      <>
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          primary
          key="submit"
          onClick={() => setConfirm({ active: true, operation: Operation.Save })}
          disabled={viewUntouched}
        >
          Save
        </Button>
      </>
    ];
  };

  const isAdminRole = (user: IUser) => {
    if (
      userRoleIsSuperUser(user.role) ||
      userRoleIsAdministrator(user.role) ||
      userRoleIsTechSupport(user.role)
    )
      return true;
    return false;
  };

  //Setting the switches according to admin settings
  const filterScheduledExports = (items: IScheduledExport) => {
    const currentUser = state.user;
    const filteredScheduledExports =
      currentUser &&
      state.adminCustomerScheduleExports.filter(
        y =>
          ((y.roles && y.roles.some(x => x === currentUser.role)) || isAdminRole(currentUser)) &&
          isMenuEnabled(getScheduledExportKey(y.id) as MenuKey) &&
          areMenuRolesValid(getScheduledExportKey(y.id) as MenuKey)
      );
    const filteredUserCustomerScheduledExport: IScheduledExport = {
      recurringDay: items.recurringDay,
      scheduledExportUserCustomerSettings:
        filteredScheduledExports &&
        items.scheduledExportUserCustomerSettings &&
        items.scheduledExportUserCustomerSettings.filter(x =>
          filteredScheduledExports.find(y => y.id === x.scheduledExportId)
        )
    };
    return filteredUserCustomerScheduledExport && filteredUserCustomerScheduledExport;
  };

  //Setting filtered items
  useEffect(() => {
    const items = filterScheduledExports(userCustomerScheduledExportItemsList.current);
    items && setFilteredItems(items);
  }, [userCustomerScheduledExportItemsList]);

  const handleSave = async () => {
    onSave();
  };

  useEffect(() => {
    if (confirm.operation === 1) onClose();
  }, [state.fetchingData.userCustomerScheduledExports]);

  const handleCancel = async () => {
    onClose();
  };

  const confirmParams = [
    {
      title: "Discard updates?",
      body: "This will discard any updated settings and revert back to previous saved settings.",
      buttonContent: "Discard updates",
      buttonType: "warning",
      handle: handleCancel
    },
    {
      title: "Save changes?",
      body: "This will save and update the current settings. Any previous saved settings will be overwritten.",
      buttonContent: "Save",
      buttonType: "primary",
      handle: handleSave
    }
  ];

  const scheduledExportDescriptions = [
    {
      scheduledExportKey: "PurchaseOrders",
      title: "Purchase Order List",
      description: "Turn on scheduled export for Purchase Order List"
    },
    {
      scheduledExportKey: "ShipmentList",
      title: "Shipment List",
      description: "Turn on scheduled export for Shipment List"
    },
    {
      scheduledExportKey: "InternalPurchaseOrders",
      title: "Internal Purchase Orders",
      description: "Turn on scheduled export for Internal Purchase Orders"
    },
    {
      scheduledExportKey: "InternalShipmentList",
      title: "Shipment List Internal",
      description: "Turn on scheduled export for Shipment List Internal"
    }
  ];

  const ScheduledExportViewNames = [
    {
      key: "includeItems",
      scheduledExportKey: "PurchaseOrders",
      title: "Include Purchase Order Items"
    },
    {
      key: "includeMaterials",
      scheduledExportKey: "PurchaseOrders",
      title: "Include Purchase Order child Items"
    },
    {
      key: "includeMaterialItems",
      scheduledExportKey: "ShipmentList",
      title: "Include Shipping Items"
    },
    {
      key: "includeMaterialChildItems",
      scheduledExportKey: "ShipmentList",
      title: "Include Shipping Items children"
    },
    {
      key: "includeItems",
      scheduledExportKey: "InternalPurchaseOrders",
      title: "Include Internal Purchase Order Items"
    },
    {
      key: "includeShipments",
      scheduledExportKey: "InternalPurchaseOrders",
      title: "Include Internal Shipping Details data "
    },
    {
      key: "includeMaterialItems",
      scheduledExportKey: "InternalShipmentList",
      title: "Include Internal Shipping Items"
    },
    {
      key: "includeMaterialChildItems",
      scheduledExportKey: "InternalShipmentList",
      title: "Include Internal Shipping Items children"
    }
  ];

  const ScheduledExportViewsName = (key: string, scheduledExportKey: string) => {
    const view = ScheduledExportViewNames.find(
      d => d.key === key && d.scheduledExportKey === scheduledExportKey
    );
    return (view && view.title) || "";
  };

  const scheduledExportDescription = (scheduledExport: IUserCustomerScheduledExportItem) => {
    const key = getScheduledExportKey(scheduledExport.scheduledExportId);
    const description = scheduledExportDescriptions.find(d => d.scheduledExportKey === key);
    return description || { title: "", description: "" };
  };

  const getScheduledExportKey = (scheduledExportId: number) => {
    return (
      state.adminCustomerScheduleExports.find(x => x.id === scheduledExportId)
        ?.scheduledExportKey ?? ""
    );
  };

  const getScheduledExportViewKey = (scheduledExportId: number, scheduledExportViewId: number) => {
    return (
      state.adminCustomerScheduleExports
        .find(x => x.id === scheduledExportId)
        ?.views.find(x => x.id == scheduledExportViewId)?.key ?? ""
    );
  };

  const getSelectedViews = (selectedViews: number[], scheduledExportId: number) => {
    return selectedViews.reduce((all: ISelectItem[], r: number) => {
      return [
        ...all,
        {
          value: r.toString(),
          title: ScheduledExportViewsName(
            getScheduledExportViewKey(scheduledExportId, r),
            getScheduledExportKey(scheduledExportId)
          )
        }
      ];
    }, []);
  };

  const onSelectedItemChanged = (
    scheduledExport: IUserCustomerScheduledExportItem,
    items: ISelectItem[]
  ) => {
    const views = state.adminCustomerScheduleExports
      .find(x => x.id === scheduledExport.scheduledExportId)
      ?.views.filter(x => items.some(y => y.value === x.id.toString()));

    views!.forEach(x => {
      if (x.dependencyId) {
        if (!items.some(z => z.value === x.dependencyId?.toString())) {
          items = items.filter(item => !(item.value === x.id.toString()));
        }
      }
    });
    onScheduledExportViewChanged(
      scheduledExport.scheduledExportId,
      items.map(i => Number(i.value))
    );
  };
  const handleWeeklyChecked = (value: boolean, weekdayValue: number) => {
    setIsWeeklyChecked(value);
    if (!isWeeklyChecked) {
      setWeekdayValue(0);
      handleWeekdaySelected(weekdayValue);
    } else {
      setWeekdayValue(weekdayValue);
    }
    onWeeklyChecked(value, weekdayNumber);
  };

  const handleWeekdaySelected = (value: number) => {
    const weekdayLabel = dropDownItems.find(x => x.value === value)?.title;
    weekdayLabel && setWeekday(weekdayLabel);
    onWeekdaySelected(value);
  };

  const getWeekdayLabel = () => {
    const recurringDay = userCustomerScheduledExportItemsList.current.recurringDay;
    const weekDayLabel = dropDownItems.find(x => x.value === recurringDay)?.title;

    if (weekDayLabel) {
      return weekDayLabel;
    }
    return weekday;
  };
  const findAdminScheduleExportVisible = (id: number) => {
    const item: IScheduledExportItem | undefined =
      adminCustomerExportItems && adminCustomerExportItems.find(x => x.id === id);
    if (item) {
      return item.visible;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (currentCustomer().isHoldingCompany && hasTemplateForDataExport) {
      const exportItem = adminCustomerExportItems.find(x => x.id === 1);
      const views = exportItem?.views.map(x => x.id);
      if (views) {
        onScheduledExportViewChanged(1, views);
      }
    }
  }, []);

  return (
    <Dialog title="Schedule excel export" buttons={buttons()} className={"notifications-dialog"}>
      <div className="weekly-section">
        {state.customerScheduledExports[0].frequency === 1 ? (
          <div>
            <span>Select you preferred weekday</span>
            <div className="weekly-options-section">
              <Checkbox
                text="Weekly"
                checked={isWeeklyChecked}
                onChange={value => handleWeeklyChecked(value, weekdayNumber)}
                disabled={false}
              />
              <Dropdown
                items={dropDownItems}
                onItemClick={value => handleWeekdaySelected(value.value)}
                label={getWeekdayLabel()}
                disabled={!isWeeklyChecked}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <span className="sub-title">
        Select the information you want to be part of the daily excel export. You will receive an
        email to your Inbox with a link to download the file after each daily update of information.
      </span>

      <div className="admin-dashboard-table">
        <div className="admin-dashboard-table-body">
          {state.fetchingData.adminCustomerScheduleExports ? (
            <div className="notifications-loader">
              <Loader size="medium" />
            </div>
          ) : (
            filteredItems.scheduledExportUserCustomerSettings &&
            filteredItems.scheduledExportUserCustomerSettings.map((scheduledExport, index) => (
              <div
                key={`notification-row-${scheduledExport.scheduledExportId}`}
                className="admin-row"
              >
                <div
                  className={`admin-entry ${
                    !findAdminScheduleExportVisible(scheduledExport.scheduledExportId)
                      ? "inactive"
                      : "active"
                  }`}
                >
                  <div className="switch-row">
                    <Switch
                      offLabel={""}
                      onLabel={""}
                      value={scheduledExport.enabled}
                      onChange={(newValue: boolean) =>
                        onScheduledExportsEnabled(scheduledExport.scheduledExportId, newValue)
                      }
                    />
                  </div>
                  <div className="title-row">
                    <CustomTooltip
                      breakStyle="pixelWidth"
                      position="right"
                      hoverColor
                      maxWidthPixels={500}
                      tooltip={[
                        {
                          header: `${scheduledExportDescription(scheduledExport).title}`,
                          body: scheduledExportDescription(scheduledExport).description
                        }
                      ]}
                    >
                      <span className="admin-entry-title">
                        {scheduledExportDescription(scheduledExport).title}
                      </span>
                    </CustomTooltip>
                  </div>
                  <div className="scheduled-export-roles role-select-row">
                    {currentCustomer().isHoldingCompany &&
                    scheduledExport.scheduledExportId === 1 &&
                    hasTemplateForDataExport &&
                    currentCustomer().identifier === "MTNHOLDING" ? (
                      <></>
                    ) : (
                      <MultiSelect
                        items={adminCustomerExportItems
                          .find(x => x.id === scheduledExport.scheduledExportId)!
                          .views.map(
                            view =>
                              ({
                                value: view.id.toString(),
                                title: ScheduledExportViewsName(
                                  view.key,
                                  getScheduledExportKey(scheduledExport.scheduledExportId)
                                ),
                                disabled: adminCustomerExportItems.find(x =>
                                  x.views.find(
                                    y =>
                                      y.id === view.dependencyId &&
                                      !scheduledExport.selectedViewIds.find(
                                        x => x === view.dependencyId
                                      )
                                  )
                                )
                              }) as ISelectItem
                          )}
                        placeholder={`Views (${scheduledExport.selectedViewIds.length}/${
                          adminCustomerExportItems.find(
                            x => x.id === scheduledExport.scheduledExportId
                          )!.views.length
                        })`}
                        type="multi-pills"
                        onChange={(items: ISelectItem[]) =>
                          onSelectedItemChanged(scheduledExport, items)
                        }
                        selectedItems={getSelectedViews(
                          scheduledExport.selectedViewIds.map(item => item),
                          scheduledExport.scheduledExportId
                        )}
                        disabled={!scheduledExport.enabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <ConfirmDialog
        confirmCondition={confirm.active}
        messageTitle={confirmParams[confirm.operation].title}
        messageBody={confirmParams[confirm.operation].body}
        onProceedButton={
          <Button
            key="proceed"
            primary={confirmParams[confirm.operation].buttonType === "primary"}
            warning={confirmParams[confirm.operation].buttonType === "warning"}
            onClick={() => {
              setConfirm({ active: true, operation: Operation.Save });
              confirmParams[confirm.operation].handle();
            }}
          >
            {confirmParams[confirm.operation].buttonContent}
          </Button>
        }
        onCancel={() => {
          setConfirm({ active: false, operation: Operation.Cancel });
        }}
      />
    </Dialog>
  );
};

export default ScheduledExportsDialog;
