import React, { Component } from "react";
import { select, selectAll } from "d3-selection";
import { AxisScale, AxisDomain, format, axisLeft } from "d3";
import * as utils from "../helpers";

interface AxisProps {
  yScale: AxisScale<AxisDomain>;
  tickSize: number;
  tickPadding: number;
  ticks: number;
  domain?: boolean;
  formatType?: "default" | "round" | "date";
  line?: boolean;
  width?: number;
  height?: number;
  removeLastTick?: boolean;
  tickFormat?: string;
  hideValues?: boolean;
  grid?: boolean;
  noLine?: boolean;
  xMax?: number;
}

export default class AxisLeft extends Component<AxisProps> {
  private axisElement: SVGGElement | null;

  public componentDidMount() {
    this.renderAxis();
  }

  public componentDidUpdate() {
    this.renderAxis();
  }

  private renderAxis() {
    const {
      yScale,
      tickSize,
      tickPadding,
      ticks,
      domain,
      formatType,
      tickFormat,
      hideValues,
      grid,
      xMax
    } = this.props;
    const hasDomain = domain !== undefined ? domain : false;

    if (yScale !== undefined) {
      const axisConfig = axisLeft(yScale).tickSize(tickSize).tickPadding(tickPadding).ticks(ticks);

      if (formatType !== undefined && formatType !== "default") {
        axisConfig.tickFormat((d: number) => format(".2s")(d as number).replace(".0", ""));
      }

      if (tickFormat !== undefined) {
        axisConfig.tickFormat((d: number) => utils.d3Format(tickFormat, d));
      }

      select(this.axisElement).call(axisConfig);

      select(this.axisElement).selectAll(".domain").remove();
      if (hideValues) {
        select(this.axisElement).selectAll("text").remove();
      }

      // select(this.axisElement)
      //     .selectAll(".tick")
      //     .selectAll("line")
      //     .classed("solid", hasDomain)
      //     .attr("x1", -10)
      //     .attr("x2", 0)
      //     .classed("zero", (d: number) => d === 0);

      if (!grid) {
        select(this.axisElement).selectAll("line").attr("x1", 5);
        if (xMax) {
          select(this.axisElement).selectAll("line").attr("x2", xMax);
        }

        selectAll(".tick")
          .selectAll("line")
          .classed("zero", (d: number) => d === 0);

        selectAll(".tick").selectAll("text").attr("x1", -10).attr("x2", 0);
      } else {
        select(this.axisElement)
          .selectAll(".tick")
          .selectAll("line")
          .classed("solid", hasDomain)
          .attr("x1", -10)
          .attr("x2", 0)
          .classed("zero", (d: number) => d === 0);
      }
    }
  }

  public render() {
    const { line, height, width, noLine } = this.props;

    return (
      <g
        transform={line ? "translate(0,0)" : ""}
        className="axis left"
        fontFamily="sans-serif"
        fontSize={10}
        textAnchor="middle"
        ref={(el: any) => {
          this.axisElement = el;
        }}
      >
        {height !== undefined && width !== undefined && !noLine && (
          <>
            <path
              className="customdomain"
              stroke="currentColor"
              transform="translate(0,0)"
              d={`M-10,${height}.5H0`}
            ></path>
            <path
              className="customdomain"
              stroke="currentColor"
              d={`M0,${height + 8}.5H0.5V0.5H-8`}
            ></path>
          </>
        )}
        {height !== undefined && width !== undefined && noLine && (
          <>
            <path
              className="customdomain"
              stroke="currentColor"
              transform="translate(0,0)"
              d={`M${width},${height}.5H0`}
            ></path>
          </>
        )}
      </g>
    );
  }
}
