import React from "react";

const LoginInfoBlock = ({
  paragraphText,
  subtleClass,
  hasToolTip,
  leftLink,
  rightLink,
  leftText,
  rightText
}: {
  paragraphText: string;
  subtleClass: string;
  hasToolTip?: boolean;
  leftLink: string;
  rightLink: string;
  leftText: string;
  rightText: string;
}) => {
  return (
    <div className="login-info-container">
      <p>{paragraphText}</p>
      <div className="info-block">
        <div className="info-links">
          {hasToolTip ? (
            <a className={subtleClass} href={leftLink} target="blank">
              <span className="tooltip">
                {leftText}
                <span className="message bottom">One time setup</span>
              </span>
            </a>
          ) : (
            <a className={subtleClass} href={leftLink} target="blank">
              {leftText}
            </a>
          )}
        </div>
        <div className="info-links">
          <a className={subtleClass} href={rightLink} target="blank">
            {rightText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginInfoBlock;
