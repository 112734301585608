import React, { useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Column, Kpi, KpiItem, KpiProgressBar, Row, Tile } from "@react-gcc-eds/core";

import { useSearchActions } from "../../../../actions/search-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { useMetricsActions } from "../../../../actions/metrics-actions";

import { IMaterialInventorySearchResult, isFetching, useStore } from "../../../../store";
import { DashboardTileAlignment, DashboardTileAlignment as TileAlignment } from "../index";
import SearchBarFilter from "../../../filters/search-bar-filter";
import { NoMetrics } from "../utils";
import { useLocalization } from "../../../../utils/localization";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardMaterialInventorySteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { useSettingsActions } from "../../../../actions/settings-actions";

const MaterialInventory = ({
  className,
  alignment
}: {
  className: string;
  alignment: TileAlignment;
}) => {
  const [state] = useStore();
  const tileRef = useRef<HTMLDivElement>(null);
  const { localeString } = useLocalization();
  const { isWalkthroughEnabled } = useSettingsActions();
  const history = useHistory();
  const { steps } = useDashboardMaterialInventorySteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const {
    materialInventoryMetric: {
      materialName,
      materialId,
      ordered,
      goodsReceivedPosted,
      inventoryInNwh
    }
  } = state.metrics;
  const { materialInventoryMetric: loadingMetric } = state.fetchingData.metrics;
  const { materialInventorySearchResult } = state.metricsSearches;

  const { searchMaterial } = useSearchActions();
  const { setMetricsMaterialInventorySearch } = useMetricsFiltersActions();
  const { getMaterialInventoryMetric } = useMetricsActions();

  useEffect(() => {
    if (state.metricsFilters.materialInventorySearch) {
      getMaterialInventoryMetric(state.metricsFilters.materialInventorySearch.materialId);
    }
  }, [state.metricsFilters.materialInventorySearch]);

  const tileHeader = (
    <WalkthroughHeader
      walkthroughActive={isWalkthroughEnabled()}
      header={localeString("Dashboard_MaterialStatus") || "Material Status"}
      tooltipInfo="Search by material and get overview of quantities ordered, in transit and in stock in E/// warehouse"
      alignment={alignment}
      onClick={startWalkThrough}
      disabled={isFetching(state)}
    />
  );

  const onMaterialSearch = (searchQuery: string) => {
    if (searchQuery) {
      searchMaterial(searchQuery);
    }
  };

  const onSearchResultClick = (result: IMaterialInventorySearchResult) => {
    setMetricsMaterialInventorySearch(result);
  };

  const onNavigateToInventory = () => {
    if (inventoryInNwh > 0 && materialId) {
      history.push(`inventory-nwh/${materialId}`);
    }
  };

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
      >
        <div>
          {!loadingMetric && (
            <SearchBarFilter
              onSearchQueryChange={onMaterialSearch}
              searchResult={materialInventorySearchResult}
              resultAlignment="results-right"
              datesRange={{ from: new Date(), to: new Date() }}
              onClickSearchResult={onSearchResultClick}
              big
              placeholderText="Search material..."
            />
          )}
        </div>
        {loadingMetric ? (
          <NoMetrics loading />
        ) : (
          <Row className="inventory-status-graph">
            <Column lg={6}>
              <Row>
                <Column lg={12} className="material-info">
                  <KpiItem color="gray">{localeString("materialId")}</KpiItem>
                  <KpiItem inline lg>
                    <b>{materialId || "-"}</b>
                  </KpiItem>
                </Column>
              </Row>
              <Row>
                <Column lg={12}>
                  <Kpi>
                    <KpiItem color="gray">Goods Received Posted / Ordered</KpiItem>
                    <KpiItem>
                      <KpiItem span xl>
                        {goodsReceivedPosted || "-"}/{ordered || "-"}
                      </KpiItem>
                    </KpiItem>
                    <KpiProgressBar
                      value={
                        goodsReceivedPosted && ordered
                          ? Math.floor((goodsReceivedPosted / ordered) * 100)
                          : 0
                      }
                    />
                  </Kpi>
                </Column>
              </Row>
            </Column>
            <Column lg={6}>
              <Row>
                <Column lg={12} className="material-info">
                  <KpiItem color="gray">{localeString("materialName")}</KpiItem>
                  <KpiItem inline lg>
                    <b>{materialName || "-"}</b>
                  </KpiItem>
                </Column>
                <Column lg={12}>
                  <Kpi>
                    <KpiItem color="gray">Inventory in National WH</KpiItem>
                    <div
                      className={`info-navigation ${inventoryInNwh > 0 ? "clickable" : ""}`}
                      onClick={onNavigateToInventory}
                    >
                      <KpiItem inline xl>
                        {inventoryInNwh || "-"}
                      </KpiItem>
                      {inventoryInNwh > 0 && <span className="icon color-blue icon-arrow-right" />}
                    </div>
                  </Kpi>
                </Column>
              </Row>
            </Column>
          </Row>
        )}
      </Tile>
      <WalkThrough />
    </>
  );
};

export default MaterialInventory;
