import React, { useEffect, useMemo, useRef, useState } from "react";
import { MultiPanelTile, Tile, Pill, Tooltip, useSettings } from "@react-gcc-eds/core";

import { ISearchResult, isFetching, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { useLocalization } from "../../../../utils/localization";
import DatePicker from "../../../filters/date-picker";
import CustomTabs, { ITab } from "../../../shared/custom-tabs";
import { StatusBarChart } from "../bars-chart";
import { useSearchActions } from "../../../../actions/search-actions";
import SearchBarFilter from "../../../filters/search-bar-filter";
import { activeFiltersIcon } from "../../../filters/filters-icon";
import { DashboardGraph, DashboardTileAlignment, IDashboardItemSelected } from "..";
import { IExternalOrdersRedirectionFilters } from "../../../../actions/orders-filters-actions";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { usePredefinedRanges } from "../../../filters/date-picker/predefined-ranges";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useWalkThrough } from "../../../walkthrough";
import { useDashboardOverallOrderBarChartSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { dateAsYMDString } from "../../../../utils";
import cx from "classnames";

const OverallOrderStatusBars = ({
  ordersLabel,
  itemsLabel,
  className,
  customerId,
  alignment,
  onItemSelected
}: {
  ordersLabel: string;
  itemsLabel: string;
  className: string;
  customerId: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const tileRef = useRef<HTMLDivElement>(null);
  const { currentCustomer } = useSettingsActions();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ITab>();
  const [tabs, setTabs] = useState<ITab[]>([]);
  const [state] = useStore();
  const {
    orderCreationDateBarsMetric: orderCreationDateBarsMetricLoading,
    itemCreationDateBarsMetric: itemCreationDateBarsMetricLoading
  } = state.fetchingData.metrics;
  const { overallOrderStatusSearchResult } = state.metricsSearches;
  const { localeString } = useLocalization();
  const { steps } = useDashboardOverallOrderBarChartSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );

  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);

  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { isWalkthroughEnabled } = useSettingsActions();
  const { rangeAsString, largestRange, isLargestRange } = usePredefinedRanges(
    new Date(currentCustomer().dataAvailableFromDate)
  );
  const { getOrderCreationDateBarsMetric, getItemCreationDateBarsMetric } = useMetricsActions();
  const {
    setMetricsOverallOrderStatusBarsCreationRange,
    setMetricsOverallOrderStatusBarsItemsSearch
  } = useMetricsFiltersActions();

  const { searchOverallOrderItems } = useSearchActions();
  const { theme } = useSettings();

  useEffect(() => {
    if (state.metricsFilters.overallOrderStatusBarsCreationRange) {
      const { from, to } = state.metricsFilters.overallOrderStatusBarsCreationRange;

      getOrderCreationDateBarsMetric(dateAsYMDString(from), dateAsYMDString(to));
    }
  }, [state.metricsFilters.overallOrderStatusBarsCreationRange, customerId]);

  useEffect(() => {
    if (state.metricsFilters.overallOrderStatusBarsCreationRange) {
      const {
        overallOrderStatusBarsCreationRange: { from, to },
        overallOrderStatusBarsItemsSearch
      } = state.metricsFilters;
      getItemCreationDateBarsMetric(
        dateAsYMDString(from),
        dateAsYMDString(to),
        overallOrderStatusBarsItemsSearch
          ? overallOrderStatusBarsItemsSearch.purchaseOrderItemIds
          : []
      );
    }
  }, [
    state.metricsFilters.overallOrderStatusBarsCreationRange,
    state.metricsFilters.overallOrderStatusBarsItemsSearch,
    customerId
  ]);

  useEffect(() => {
    const tabs = [];
    if (ordersLabel !== undefined) {
      tabs.push(purchaseOrderTab);
    }
    if (itemsLabel !== undefined) {
      tabs.push(purchaseOrderItemsTab);
    }
    if (tabs.length) {
      setTabs(tabs);
      if (!selectedTab) {
        setSelectedTab(tabs[0]);
      }
    }
  }, [orderCreationDateBarsMetricLoading, itemCreationDateBarsMetricLoading]);

  const purchaseOrders = () => {
    const { orderCreationDateBarsMetric } = state.metrics;
    return (
      <StatusBarChart
        metrics={orderCreationDateBarsMetric}
        loading={orderCreationDateBarsMetricLoading === true}
        unit={""}
        datesRange={state.metricsFilters.overallOrderStatusBarsCreationRange}
        onSelection={(filters: IExternalOrdersRedirectionFilters) =>
          onItemSelected && onItemSelected({ graph: DashboardGraph.Orders, filters })
        }
      />
    );
  };

  const purchaseOrdersItems = () => {
    const { itemCreationDateBarsMetric } = state.metrics;
    const { overallOrderStatusBarsItemsSearch } = state.metricsFilters;
    return (
      <StatusBarChart
        metrics={itemCreationDateBarsMetric}
        loading={itemCreationDateBarsMetricLoading === true}
        unit={""}
        datesRange={state.metricsFilters.overallOrderStatusBarsCreationRange}
        onSelection={(barFilters: IExternalOrdersRedirectionFilters) =>
          onItemSelected &&
          onItemSelected({
            graph: DashboardGraph.Items,
            filters: {
              ...barFilters,
              property: overallOrderStatusBarsItemsSearch
                ? {
                    name: overallOrderStatusBarsItemsSearch.originalProperty,
                    value: overallOrderStatusBarsItemsSearch.matchingValue
                  }
                : undefined
            }
          })
        }
      />
    );
  };

  const resetFilter = () => {
    setMetricsOverallOrderStatusBarsCreationRange(largestRange.selector());
  };

  const resetFilterPill = () => {
    return (
      <Pill className={cx("filter-pill", theme)} onToggle={resetFilter}>
        Reset filters
      </Pill>
    );
  };

  const filtersSummary = (
    <div className="dashboard-active-filter">
      <Pill>{rangeAsString(state.metricsFilters.overallOrderStatusBarsCreationRange)}</Pill>
      {!isLargestRange(state.metricsFilters.overallOrderStatusBarsCreationRange)
        ? resetFilterPill()
        : null}
    </div>
  );

  const purchaseOrderTab = {
    view: purchaseOrders(),
    title: ordersLabel,
    index: 0
  } as ITab;

  const purchaseOrderItemsTab = {
    view: purchaseOrdersItems(),
    title: itemsLabel,
    index: 1
  } as ITab;

  const dateFilter = (
    <DatePicker
      dual={state.userPreferences.useDualCalendar}
      rolling
      selectedRange={state.metricsFilters.overallOrderStatusBarsCreationRange}
      oldestDate={new Date(currentCustomer().dataAvailableFromDate)}
      onRangeSelected={setMetricsOverallOrderStatusBarsCreationRange}
      key="date-picker"
      className="filter"
    />
  );

  const tileHeader = (
    <div className="dashboard-tile">
      {!isLargestRange(state.metricsFilters.overallOrderStatusBarsCreationRange)
        ? activeFiltersIcon(1)
        : null}
      <WalkthroughHeader
        walkthroughActive={isWalkthroughEnabled()}
        tooltipInfo="Graph is showing the status for the selected time period"
        alignment={alignment}
        header={localeString("Dashboard_OverallOrderStatusBarChart") || "Overall order status"}
        onClick={startWalkThrough}
        disabled={isFetching(state)}
      />
    </div>
  );

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={filtersSummary}
        actions={[dateFilter]}
      >
        <CustomTabs onTabChange={(tab: ITab) => setSelectedTab(tab)} tabs={tabs} />
      </Tile>
      <WalkThrough />
    </>
  );
};

export default OverallOrderStatusBars;
