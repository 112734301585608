import React from "react";
import { motion } from "framer-motion";
import cx from "classnames";

interface Props {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  colorData: string;
  opacity: number;
}

const Trendline = ({ x1, y1, x2, y2, colorData, opacity }: Props) => {
  return (
    <motion.line
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className={cx(`trendline ${colorData}`)}
      strokeWidth="1"
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      opacity={opacity}
    ></motion.line>
  );
};

export default Trendline;
