import React from "react";
import * as d3 from "d3";
import { Data, Colors, Serie } from "../interfaces";
import * as utils from "../helpers";
import Line from "./Line";

interface Props {
  data: Data;
  colorScale: Colors;
  xScale: any;
  yScale: any;
  hasDate: boolean;
  hasString: boolean;
  hasThresholds: boolean;
  hoveredSerie: string;
}

const LineGroups = ({
  data,
  colorScale,
  xScale,
  yScale,
  hasDate,
  hasString,
  hasThresholds,
  hoveredSerie
}: Props) => {
  return (
    <>
      {data.series.map((serie: Serie, keyIndex: number) => {
        const lineData = utils.createLineData(data, serie.name);

        const line = d3
          .line()
          .defined((_d, i) => {
            return lineData[i].yLabel !== "";
          })
          .x((_d, i) => {
            let result: any;
            if (hasDate) {
              result = xScale(Date.parse(lineData[i].xLabel));
            } else if (hasString) {
              result = xScale(lineData[i].xLabel);
            } else {
              result = xScale(parseFloat(lineData[i].xLabel));
            }
            return result;
          })
          .y((_d, i) => {
            return yScale(parseFloat(lineData[i].yLabel));
          });

        const newline = line(lineData as unknown as [number, number][]);
        const lineOpacity = hoveredSerie === serie.name ? 1 : 0.2;
        const key = keyIndex;

        return (
          <g key={key} className="line-group">
            <Line
              path={newline !== null && !newline.includes("NaN") ? newline : ""}
              colorData={colorScale[serie.name]}
              hasThresholds={hasThresholds}
              opacity={hoveredSerie !== "" ? lineOpacity : 1}
            />
          </g>
        );
      })}
    </>
  );
};
export default LineGroups;
