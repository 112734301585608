import React from "react";
import cx from "classnames";
import { Serie, PlotSerie, Colors } from "../interfaces";

interface Props {
  series: Serie[] | PlotSerie[];
  colorScale: Colors;
  onMouseHover: Function;
  onClick: Function;
}

const Legend = ({ series, colorScale, onMouseHover, onClick }: Props) => {
  const seriesFix = series as Serie[];
  return (
    <div className="chart-legend" style={{ marginLeft: "16px" }}>
      {seriesFix.map((serie: Serie | PlotSerie, i: number) => {
        let colorData = colorScale[serie.name];
        colorData = colorData !== undefined ? colorData : "red";

        const customIcon = (
          <i role="presentation" className={cx("icon", `${colorData}`, "icon-alarm-level6")} />
        );
        const key = i;
        return (
          <span
            key={key}
            role="presentation"
            className={cx("pill toggle", { unselected: serie.hidden })}
            onClick={() => {
              onClick(i);
            }}
            onMouseMove={() => {
              if (!serie.hidden) {
                onMouseHover(series[i].name, i);
              }
            }}
            onMouseLeave={() => {
              if (!serie.hidden) {
                onMouseHover("");
              }
            }}
          >
            {customIcon}
            {serie.name}
          </span>
        );
      })}
    </div>
  );
};
export default Legend;
