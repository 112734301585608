import React from "react";

const FullScreenPanel = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="fs-container">
      <div className="fs-content">{children}</div>
    </div>
  );
};

export default FullScreenPanel;
