import React, { Component } from "react";
import cx from "classnames";
import { select } from "d3-selection";
import { AxisScale, AxisDomain, axisBottom, format, axisLeft } from "d3";
import * as utils from "../helpers";

interface AxisProps {
  xScale?: any;
  yScale?: AxisScale<AxisDomain>;
  tickSize: number;
  tickPadding: number;
  ticks: number;
  bottom?: boolean;
  left?: boolean;
  domain?: boolean;
  formatType?: "default" | "round" | "date";
  line?: boolean;
  width?: number;
  height?: number;
  removeLastTick?: boolean;
  tickFormat?: string;
  hideValues?: boolean;
  grid?: boolean;
}

export default class AxisGrid extends Component<AxisProps> {
  private axisElement: SVGGElement | null;

  public componentDidMount() {
    this.renderAxis();
  }

  public componentDidUpdate() {
    this.renderAxis();
  }

  private renderAxis() {
    const { xScale, yScale, tickSize, tickPadding, ticks, bottom, left, formatType, tickFormat } =
      this.props;

    if ((bottom && xScale !== undefined) || (!bottom && !left && xScale !== undefined)) {
      const gridConfig = axisBottom(xScale)
        .tickSize(tickSize)
        .tickPadding(tickPadding)
        .ticks(ticks);

      if (formatType !== undefined && formatType === "default") {
        gridConfig.tickFormat((d: number) => format(".2s")(d as number).replace(".0", ""));
      }

      if (tickFormat !== undefined) {
        gridConfig.tickFormat((d: number) => utils.d3Format(tickFormat, d));
      }

      select(this.axisElement).call(gridConfig);
    } else if (yScale !== undefined) {
      const gridConfig = axisLeft(yScale).tickSize(tickSize).tickPadding(tickPadding).ticks(ticks);

      if (formatType !== undefined && formatType !== "default") {
        gridConfig.tickFormat((d: number) => format(".2s")(d as number).replace(".0", ""));
      }

      if (tickFormat !== undefined) {
        gridConfig.tickFormat((d: number) => utils.d3Format(tickFormat, d));
      }

      select(this.axisElement).call(gridConfig);
    }
  }

  public render() {
    const { left, line, height, width, bottom = false } = this.props;
    return (
      <g
        transform={line && bottom ? "translate(0,0)" : ""}
        className={cx("grid", { left, bottom: !left })}
        ref={(el: any) => {
          this.axisElement = el;
        }}
      >
        {height !== undefined && width !== undefined && (
          <path
            className="customdomain"
            stroke="currentColor"
            transform="translate(0,0)"
            d={`M${width},${height}.5H0`}
          ></path>
        )}
      </g>
    );
  }
}
