import { useStore } from "../store";
import { useSettingsActions } from "../actions/settings-actions";

const statusTooltips = [
  {
    code: 0,
    tooltip: "The order has been received by Ericsson, but not yet processed."
  },
  {
    code: 10,
    tooltip: "The order has been validated and is currently being processed by Ericsson."
  },
  {
    code: 20,
    tooltip:
      "The goods have been confirmed for delivery by the warehouse, \nwith a corresponding preliminary delivery date. \nFor the status to be displayed on purchase order level, \nall items in the order have been confirmed by the warehouse."
  },
  {
    code: 30,
    tooltip:
      "The goods have been picked and packed, and are ready for shipment. \nFor the status to be displayed on purchase order level, \nall hardware items in the order have been picked and packed."
  },
  {
    code: 40,
    tooltip:
      "The goods have been shipped and are on its way to the agreed address. \nFor the status to be displayed on purchase order level, \nall hardware items in the order are in transit."
  },
  {
    code: 50,
    tooltip:
      "The goods have been delivered to the agreed address. \nFor the status to be displayed on purchase order level, \nall hardware items in the order have been delivered."
  },
  {
    code: 60,
    tooltip:
      "The order has been invoiced. \nFor the status to be displayed on purchase order level, \nthe entire order has been invoiced."
  },
  {
    title: "Partially delivered",
    tooltip: "A portion of the materials have been completely delivered to the final destination.",
    code: 70
  },
  { code: 99, tooltip: "No status" }
];

const internalStatusDeliveryTooltips = [
  {
    title: "Deleted",
    tooltip: "All the items in the Internal purchase order \nhave been cancelled",
    code: 0
  },
  {
    title: "Released",
    tooltip:
      "The internal purchase order has been released by the local \ncompany and is awaiting to be confirmed by the vendor.",
    code: 10
  },
  {
    title: "Confirmed",
    tooltip:
      "The goods have been confirmed for delivery by the vendor with a \npreliminary delivery date (order acknowledgement). For the status \nto be displayed on Internal purchase order level, all items in the internal \npurchase order have been confirmed by the vendor.",
    code: 15
  },
  {
    title: "InTransit",
    tooltip:
      "The goods have been shipped and are on its way to the destination. \nFor the status to be displayed on Internal purchase order level, at least \none of the items in the internal purchase order has been shipped.",
    code: 18
  },
  {
    title: "Partially delivered",
    tooltip:
      "Some of the goods have been delivered at the destination. \nFor the status to be displayed on Internal purchase order level, at least \none of the items in the internal purchase order has been delivered.",
    code: 20
  },
  {
    title: "Delivered",
    tooltip:
      "All the goods have been delivered to the destination. \nFor the status to be displayed on Internal purchase order level, all the \nitems in the internal purchase order have been delivered.",
    code: 25
  },
  {
    title: "GoodsReceivedPosted",
    tooltip:
      "All the goods have a delivery complete mark and goods receipt posted \nupon reception at the destination. For the status to be displayed \non Internal purchase order level, all the items in the internal purchase \norder have goods received posted.",
    code: 30
  }
];

const shipmentStatusTooltips = [
  {
    code: 0,
    tooltip: "The shipment has been created by Ericsson, but not yet processed."
  },
  {
    code: 10,
    tooltip:
      "The goods have been confirmed for delivery by the warehouse, \nwith a corresponding preliminary delivery date."
  },
  {
    code: 20,
    tooltip: "The goods have been shipped and are on its way to the agreed address."
  },
  {
    code: 30,
    tooltip: "The goods have been delivered to the agreed address."
  },
  { code: 99, tooltip: "No status" }
];

export const useLocalization = () => {
  const [state] = useStore();

  const { currentCustomer } = useSettingsActions();

  const localeString = (viewName: string) => {
    const localizations = state.userPreferences.customerId ? currentCustomer().localizations : [];
    const locale = localizations.find(l => l.key.toLowerCase() === viewName.toLowerCase());
    if (!locale) {
      return undefined;
    }
    return locale.value || locale.defaultValue;
  };

  const statusTooltip = (code: number) =>
    (statusTooltips.find(t => t.code === code) || { tooltip: "" }).tooltip || undefined;

  const internalStatusDeliveryTooltip = (code: number) =>
    (internalStatusDeliveryTooltips.find(t => t.code === code) || { tooltip: "" }).tooltip ||
    undefined;

  const shipmentStatusTooltip = (code: number) =>
    (shipmentStatusTooltips.find(t => t.code === code) || { tooltip: "" }).tooltip || undefined;

  return {
    statusTooltip,
    localeString,
    internalStatusDeliveryTooltip,
    shipmentStatusTooltip
  };
};
