import React from "react";
import { motion } from "framer-motion";

interface Props {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  onMouseEnter: Function;
  onMouseLeave: Function;
  onMouseMove: Function;
}

const TrendlineHover = ({ x1, y1, x2, y2, onMouseEnter, onMouseLeave, onMouseMove }: Props) => {
  return (
    <motion.line
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className="trendline-hover"
      strokeWidth="8"
      stroke="blue"
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      opacity="0"
      onMouseEnter={(e: any) => (onMouseEnter !== undefined ? onMouseEnter(e) : null)}
      onMouseLeave={(e: any) => (onMouseLeave !== undefined ? onMouseLeave(e) : null)}
      onMouseMove={(e: any) => (onMouseMove !== undefined ? onMouseMove(e) : null)}
    ></motion.line>
  );
};

export default TrendlineHover;
