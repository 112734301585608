import React from "react";
import Dot from "./Dot";
import DotHover from "./DotHover";

interface Props {
  x: number;
  y: number;
  r: number;
  colorData: string;
  opacity: number;
  onMouseHover: Function;
  onMouseLeave: Function;
  onClick: Function;
}

const DotGroup = ({ x, y, r, colorData, opacity, onMouseHover, onClick }: Props) => {
  return (
    <g className="dot-group">
      <Dot x={x} y={y} r={r} opacity={opacity} colorData={colorData} />
      <DotHover
        x={x}
        y={y}
        r={r}
        onMouseEnter={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(true, e);
        }}
        onMouseLeave={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(false, e);
        }}
        onMouseMove={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(true, e);
        }}
        onClick={() => onClick()}
      />
    </g>
  );
};

export default DotGroup;
