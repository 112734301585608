import { useRef, useEffect } from "react";
import { useStore } from "../../../store";

export const useClickOutside = (handler: EventListener) => {
  const [state] = useStore();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseClick = (e: MouseEvent) => {
    if (
      state.walkthrough.blockClickOutside ||
      (containerRef.current && containerRef.current.contains(e.target as Node))
    ) {
      // inside click
      return;
    }

    // outside click
    handler(e);
  };

  useEffect(() => {
    document.addEventListener("click", handleMouseClick, true);
    return () => {
      document.removeEventListener("click", handleMouseClick, true);
    };
  }, [state.walkthrough.blockClickOutside]);

  return containerRef;
};
