import axios, { AxiosInstance } from "axios";
export class Api {
  private api: AxiosInstance;
  private errorHandler: (message: string) => void;
  private unauthorizedHandler: (url: string) => void;
  private getToken: () => Promise<string>;

  public constructor(
    baseUrl: string,
    customer: string,
    module: string,
    onError: (message: string) => void,
    onUnauthorized: (url: string) => void,
    getToken: () => Promise<string>
  ) {
    // const cypress = (window as unknown as { Cypress: any }).Cypress;

    const headers: object = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Tenant": customer,
      ModuleId: module
    };

    // if (cypress) {
    //   const userApiKey = cypress.config("currentUserApiKey");
    //   if (userApiKey) {
    //     console.log("Using key ", userApiKey);
    //     headers = {
    //       ...headers,
    //       "Ocp-Apim-Subscription-Key": cypress.config("currentUserApiKey"),
    //       "X-Tenant": "DEMO"
    //     };
    //   }
    // }

    this.api = axios.create({
      baseURL: baseUrl,
      headers: headers,
      transformRequest: [
        data => {
          return JSON.stringify(data, (k, v) => {
            return v === undefined ? null : v;
          });
        }
      ]
    });

    this.errorHandler = onError;
    this.unauthorizedHandler = onUnauthorized;
    this.getToken = getToken;
  }

  public setCustomer = (customer: string) => {
    this.api.defaults.headers["X-Tenant"] = customer;
  };

  public get = async (url: string, params?: object, callBack?: Function) => {
    try {
      let response: any;
      await this.addTokenToRequestHeaders().then(
        async () => (response = await this.api.get(url, { params: { ...(params || {}) } }))
      );

      if (callBack) {
        callBack();
      }
      return response.data;
    } catch (error) {
      this.processError(url, error);
      throw error;
    }
  };

  public post = async (url: string, data?: any, callBack?: Function) => {
    try {
      let result: any;
      await this.addTokenToRequestHeaders().then(
        async () => (result = await this.api.post(url, data || {}))
      );

      if (callBack) {
        callBack();
      }
      return result.data;
    } catch (error) {
      this.processError(url, error);
      throw error;
    }
  };

  public postForBlob = async (url: string, data?: any) => {
    try {
      let response: any;
      await this.addTokenToRequestHeaders().then(
        async () => (response = await this.api.post(url, data || {}, { responseType: "blob" }))
      );

      const contentDisposition = response.headers["content-disposition"];
      let fileName = undefined;

      if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      return [response.data, fileName];
    } catch (error) {
      this.processError(url, error);
      throw error;
    }
  };
  public getForBlob = async (url: string) => {
    try {
      let response: any;
      await this.addTokenToRequestHeaders().then(
        async () => (response = await this.api.get(url, { responseType: "blob" }))
      );

      return response.data;
    } catch (error) {
      this.processError(url, error);
      throw error;
    }
  };

  public postForm = async (url: string, form: any, callBack?: Function) => {
    try {
      //Special case to get the boundary on the call
      const cloned = axios.create({
        baseURL: this.api.defaults.baseURL,
        headers: {
          Accept: "application/json",
          "X-Tenant": this.api.defaults.headers["X-Tenant"],
          Authorization: this.api.defaults.headers["Authorization"]
        }
      });
      const formData = new FormData();

      const keys = Object.keys(form);
      keys.forEach(key => {
        formData.append(key, form[key]);
      });

      let response: any;
      await this.addTokenToRequestHeaders(cloned).then(
        async () => (response = await cloned.post(url, formData))
      );

      if (callBack) {
        callBack(response.data);
      }
    } catch (error) {
      this.processError(url, error);
      throw error;
    }
  };

  public put = async (url: string, data: any, callBack?: Function) => {
    try {
      await this.addTokenToRequestHeaders().then(async () => await this.api.put(url, data));

      if (callBack) {
        callBack();
      }
    } catch (error) {
      this.processError(url, error);
      throw error;
    }
  };

  public remove = async (url: string, data?: any, callBack?: Function) => {
    try {
      await this.addTokenToRequestHeaders().then(async () => await this.api.delete(url, { data }));

      if (callBack) {
        callBack();
      }
    } catch (error) {
      this.processError(url, error);
      throw error;
    }
  };

  private processError = (url: string, error: any) => {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log("err-data", error.response.data);
      console.log("err-status", error.response.status);
      console.log("err-headers", error.response.headers);

      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 407
      ) {
        this.unauthorizedHandler(url);
      } else {
        let msg = "An unexpected error occurred";
        if (error.response && error.response.data) {
          const { message, title } = error.response.data;
          if (message) {
            msg = `${JSON.stringify(message)} (${error.response.status})`;
          }
          if (title) {
            msg = `${JSON.stringify(title)} (${error.response.status})`;
          }
        }
        this.errorHandler(msg);
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log("Request error", error.request);

      this.errorHandler("API call failed");
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log("Error", error);

      this.errorHandler("An unexpected error occurred");
    }
  };

  private addTokenToRequestHeaders = async (instance?: AxiosInstance) => {
    const axiosInstance = instance ?? this.api;
    await this.getToken().then(token =>
      axiosInstance.interceptors.request.use(request => {
        request.headers.setAuthorization(`Bearer ${token || ""}`);
        return request;
      })
    );
  };
}
