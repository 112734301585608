import { useCallback, useEffect, useRef, useState } from "react";

const calculateAccuracy = (stepSize: number) => {
  if (Math.floor(stepSize.valueOf()) === stepSize.valueOf() || isNaN(stepSize)) {
    return 0;
  }
  return stepSize.toString().split(".")[1].length || 0;
};

enum OPERATOR {
  INCREASE = 1,
  DECEASE = -1
}

export const useQueuedAnimation = (target: number) => {
  const [targetValue, setTargetValue] = useState<number>(target);
  const [stepSize, setStepSize] = useState(1);
  const [animationQueue, setAnimationQueue] = useState<number[]>([]);
  const { value, isAnimating } = useNumberAnimation({
    targetValue: targetValue,
    stepSize
  });

  useEffect(() => {
    setAnimationQueue(old => [...old, target]);
  }, [target]);

  useEffect(() => {
    if (!isAnimating() && animationQueue.length) {
      const newTarget = animationQueue[0];
      const oldTarget = targetValue;
      setTargetValue(newTarget);
      setStepSize(Math.max(Math.abs(oldTarget - newTarget) / 500, 0.05));
      setAnimationQueue(old => old.filter((_, i) => i !== 0));
    }
  }, [isAnimating, animationQueue]);

  return { value };
};

export const useNumberAnimation = ({
  targetValue,
  stepSize
}: {
  targetValue: number;
  stepSize: number;
}) => {
  const requestRef = useRef<number>(targetValue);
  const previousTimeRef = useRef<number>(targetValue);

  const [value, setValue] = useState<number>(targetValue);
  const [operator, setOperator] = useState<OPERATOR>(OPERATOR.INCREASE);

  const isAnimating = useCallback(() => {
    return value !== targetValue || requestRef.current !== previousTimeRef.current;
  }, [value, targetValue, requestRef, previousTimeRef]);

  const decimals = calculateAccuracy(stepSize);

  const animate = (time: number, operator: number) => {
    if (previousTimeRef.current) {
      const deltaTime = time - previousTimeRef.current;
      setValue(prevValue => {
        const calcValue = parseFloat(
          (prevValue + deltaTime * stepSize * operator).toFixed(decimals)
        );
        return (operator === OPERATOR.INCREASE && calcValue < targetValue) ||
          (operator === OPERATOR.DECEASE && calcValue > targetValue)
          ? calcValue
          : targetValue;
      });
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(lastFrame => animate(lastFrame, operator));
  };

  /** Stop animation when it has been reached */
  useEffect(() => {
    if (
      (operator === OPERATOR.DECEASE && value <= targetValue) ||
      (operator === OPERATOR.INCREASE && value >= targetValue)
    ) {
      setValue(targetValue);
      cancelAnimationFrame(requestRef.current);
      previousTimeRef.current = 0;
      requestRef.current = 0;
    }
  }, [value]);

  /** Starts a new animation if there isn't one ongoing **/
  useEffect(() => {
    if (value !== targetValue && requestRef.current === 0 && previousTimeRef.current === 0) {
      const op = targetValue > value ? 1 : -1;
      setOperator(op);
      requestRef.current = requestAnimationFrame(lastFrame => animate(lastFrame, op));
    }
  }, [targetValue]);

  return { value, isAnimating };
};
