import React from "react";
import cx from "classnames";

interface Props {
  x1: number | undefined;
  x2?: number | undefined;
  y1: number;
  y2: number;
  colorData: string | unknown;
  thresholdColor: string;
  lineOpacity: number;
  strokeWidth?: number;
}

const Bar = ({ x1, x2, y1, y2, colorData, thresholdColor, lineOpacity, strokeWidth }: Props) => {
  return (
    <line
      className={cx(`bar ${colorData} ${thresholdColor}`)}
      strokeWidth={strokeWidth || 6}
      x1={x1}
      x2={x2 !== undefined && !isNaN(x2) ? x2 : x1}
      y1={y1}
      y2={y2}
      opacity={lineOpacity}
    />
  );
};

export default Bar;
