import React, { useEffect, useMemo, useRef, useState } from "react";
import { Pill, Tile, useSettings } from "@react-gcc-eds/core";
import { Donut } from "@react-gcc-eds/charts";
import { Serie } from "@react-gcc-eds/charts/dist/components/donut/interfaces";
import { DashboardGraph, IDashboardItemSelected } from "..";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { isFetching, useStore } from "../../../../store";
import DatePicker from "../../../filters/date-picker";
import { usePredefinedRanges } from "../../../filters/date-picker/predefined-ranges";
import { usePoStatus } from "../../helpers/po-status";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { NoMetrics, noStatusMetric } from "../utils";
import { useWalkThrough } from "../../../walkthrough";
import { useDashboardOverallInternalOrderStatusDonutSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { dateAsYMDString } from "../../../../utils";
import { activeFiltersIcon } from "../../../filters/filters-icon";
import cx from "classnames";
import { useLocalization } from "../../../../utils/localization";

const OverallInternalOrderStatusDonut = ({
  customerId,
  className,
  alignment,
  onItemSelected
}: {
  customerId: string;
  className: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const { currentCustomer } = useSettingsActions();
  const [hiddenOrdersStatuses, setHiddenOrdersStatuses] = useState([]);
  const tileRef = useRef<HTMLDivElement>(null);
  const [state] = useStore();
  const { steps } = useDashboardOverallInternalOrderStatusDonutSteps(tileRef);
  const { localeString } = useLocalization();
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { getInternalOrderCreationDateDonutMetric } = useMetricsActions();
  const { setMetricsOverallInternalOrderStatusDonutCreationRange } = useMetricsFiltersActions();
  const { isWalkthroughEnabled } = useSettingsActions();
  const { internalLabelToDeliveryStatus } = usePoStatus();
  const { rangeAsString, largestRange, isLargestRange } = usePredefinedRanges(
    new Date(currentCustomer().dataAvailableFromDate)
  );
  const { theme } = useSettings();

  useEffect(() => {
    if (state.metricsFilters.overallInternalOrderStatusDonutCreationRange) {
      const { from, to } = state.metricsFilters.overallInternalOrderStatusDonutCreationRange;
      getInternalOrderCreationDateDonutMetric(dateAsYMDString(from), dateAsYMDString(to));
    }
  }, [state.metricsFilters.overallInternalOrderStatusDonutCreationRange, customerId]);

  const purchaseOrders = () => {
    const { internalOrderCreationDateDonutMetric } = state.metrics;
    const { internalOrderCreationDateDonutMetric: loading } = state.fetchingData.metrics;

    if (loading) {
      return <NoMetrics loading />;
    }
    if (noStatusMetric(internalOrderCreationDateDonutMetric)) {
      return <NoMetrics />;
    }

    const d: Serie[] = [];
    internalOrderCreationDateDonutMetric.forEach(m => {
      d.push({
        colorID: m.status,
        name: m.label,
        values: [m.totalCount],
        hidden: hiddenOrdersStatuses.find(i => i === m.status) !== undefined
      });
    });

    return (
      <Donut
        onClick={(label: string) => {
          onItemSelected &&
            onItemSelected({
              filters: {
                orderCreationRange:
                  state.metricsFilters.overallInternalOrderStatusDonutCreationRange,
                status: internalLabelToDeliveryStatus(label)
              },
              graph: DashboardGraph.InternalOrders
            });
        }}
        data={{ series: d }}
        unit="orders"
        showValue
        showAbsoluteValue
        onToggle={(data: any) =>
          setHiddenOrdersStatuses(
            data.series.filter((d: Serie) => d.hidden).map((d: Serie) => d.colorID)
          )
        }
      />
    );
  };

  const resetFilter = () => {
    setMetricsOverallInternalOrderStatusDonutCreationRange(largestRange.selector());
  };

  const resetFilterPill = () => {
    return (
      <Pill className={cx("filter-pill", theme)} onToggle={resetFilter}>
        Reset filters
      </Pill>
    );
  };

  const filtersSummary = (
    <div className="dashboard-active-filter">
      <Pill>
        {rangeAsString(state.metricsFilters.overallInternalOrderStatusDonutCreationRange)}
      </Pill>
      {!isLargestRange(state.metricsFilters.overallInternalOrderStatusDonutCreationRange)
        ? resetFilterPill()
        : null}
    </div>
  );

  const dateFilter = (
    <DatePicker
      key="datepicker"
      dual={state.userPreferences.useDualCalendar}
      rolling
      right={alignment !== TileAlignment.LEFT}
      selectedRange={state.metricsFilters.overallInternalOrderStatusDonutCreationRange}
      oldestDate={new Date(currentCustomer().dataAvailableFromDate)}
      onRangeSelected={setMetricsOverallInternalOrderStatusDonutCreationRange}
    />
  );

  const tileHeader = (
    <div className="dashboard-tile">
      {!isLargestRange(state.metricsFilters.overallInternalOrderStatusDonutCreationRange)
        ? activeFiltersIcon(1)
        : null}
      <WalkthroughHeader
        walkthroughActive={isWalkthroughEnabled()}
        header={
          localeString("Dashboard_InternalOverallOrderStatusPieChart") ||
          "Overall Internal Order Satus"
        }
        tooltipInfo="Graph is showing the status for the selected time period"
        alignment={alignment}
        onClick={startWalkThrough}
        disabled={isFetching(state)}
      />
    </div>
  );

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={filtersSummary}
        actions={[dateFilter]}
      >
        {purchaseOrders()}
      </Tile>
      <WalkThrough />
    </>
  );
};

export default OverallInternalOrderStatusDonut;
