import { IUserAccessRequest } from "../types";
import { apiReadingOff, apiReadingOn, apiWritingOff, apiWritingOn, useStore } from "../store";
import { useApi } from "./api-factory";
import { createGuid } from "../utils";

export const useUserAccessRequestActions = () => {
  const [_, setState] = useStore();

  const { api } = useApi({ includeCustomer: false });

  const newRegistration = async (
    email: string,
    firstName: string,
    lastName: string,
    company: string,
    city: string,
    country: string,
    phoneNumber: string,
    ericssonContact: string,
    signum: string,
    accessToCustomer: string,
    callback?: Function
  ) => {
    const data = {
      loginEmail: email,
      firstName,
      lastName,
      companyName: company,
      city,
      country,
      phoneNumber,
      contactPerson: ericssonContact,
      signum,
      customerAccessRequest: accessToCustomer
    };
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, data));
      await api.post("Authorization/request-access", data, callback);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const newCustomerRequest = async (accessToCustomer: string, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, accessToCustomer));
      await api.post(
        `UserRequest/existing-user-access-request?customerAccessRequest=${accessToCustomer}`,
        undefined,
        callback
      );
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getUnconnectedPendingRequests = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.userAccessRequests));
      const requests = await api.get("UserRequest/pending-user-request");
      setState(old => ({
        ...apiReadingOff(old, f => f.userAccessRequests),
        userAccessRequests: requests.map((r: IUserAccessRequest) => ({
          ...r,
          uniqueKey: createGuid()
        }))
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userAccessRequests));
    }
  };

  const getUnapprovedPendingRequests = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.userAccessRequests));
      const requests = await api.get("UserRequest/connected-user-request");
      setState(old => ({
        ...apiReadingOff(old, f => f.userAccessRequests),
        userAccessRequests: requests.map((r: IUserAccessRequest) => ({
          ...r,
          uniqueKey: createGuid()
        }))
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userAccessRequests));
    }
  };

  const customerHasApprovers = async (requestId: string, customerId: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isCheckingApprovers: true } : r
        )
      }));
      const hasApprovers = await api.get(
        `UserRequest/customer-has-approvers?customerId=${customerId}`
      );
      setState(old => ({
        ...apiReadingOff(old),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isCheckingApprovers: false } : r
        )
      }));
      return hasApprovers;
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isCheckingApprovers: false } : r
        )
      }));
      return false;
    }
  };

  const sendRequestForApproval = async (
    requestId: string,
    customerId: string,
    callback?: Function
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => ({
        ...setApiWritingOn(old, requestId),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: true } : r
        )
      }));
      await api.put(
        `UserRequest/connect-user-request?id=${requestId}&customerId=${customerId}`,
        undefined,
        callback
      );
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.filter(r => r.id !== requestId)
      }));
    } catch (e) {
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: false } : r
        )
      }));
    }
  };

  const approveRequest = async (
    requestId: string,
    contactPerson: string,
    role?: number,
    callback?: Function
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => ({
        ...setApiWritingOn(old, requestId),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: true } : r
        )
      }));
      await api.put(
        `UserRequest/approve-user-request?id=${requestId}`,
        { role, contactPerson },
        callback
      );
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.filter(r => r.id !== requestId)
      }));
    } catch (e) {
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: false } : r
        )
      }));
    }
  };

  const rejectRequest = async (requestId: string, reason: string, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => ({
        ...setApiWritingOn(old, requestId),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: true } : r
        )
      }));
      await api.put(`UserRequest/reject-user-request?id=${requestId}`, { reason }, callback);
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.filter(r => r.id !== requestId)
      }));
    } catch (e) {
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: false } : r
        )
      }));
    }
  };

  const deleteRequest = async (requestId: string, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => ({
        ...setApiWritingOn(old, requestId),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: true } : r
        )
      }));
      await api.remove(`UserRequest/delete-user-request?id=${requestId}`, undefined, callback);
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.filter(r => r.id !== requestId)
      }));
    } catch (e) {
      setState(old => ({
        ...apiWritingOff(id, old),
        userAccessRequests: old.userAccessRequests.map(r =>
          r.id === requestId ? { ...r, isWriting: false } : r
        )
      }));
    }
  };

  return {
    newRegistration,
    newCustomerRequest,
    getUnconnectedPendingRequests,
    getUnapprovedPendingRequests,
    customerHasApprovers,
    sendRequestForApproval,
    approveRequest,
    rejectRequest,
    deleteRequest
  };
};
