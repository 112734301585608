/**
 * @return a promise that resolves once an element has been found and passes an acceptance criteria
 * Rejects after 1000ms
 */
const waitForElement = (
  querySelector: string | HTMLDivElement | null,
  acceptCriteria: (e: Element | null) => boolean,
  parentRoot?: HTMLElement
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let iterationCount = 0;
    const interval = setInterval(() => {
      const e =
        typeof querySelector === "string"
          ? (parentRoot || document).querySelector(querySelector)
          : querySelector;
      if (iterationCount > 20) {
        clearInterval(interval);
        reject(false);
      } else if (acceptCriteria(e)) {
        clearInterval(interval);
        resolve(true);
      }
      iterationCount++;
    }, 50);
  });
};

const getInnerTextOfFilterFromList = (
  parentRoot: HTMLDivElement,
  leftPanelSelector: string,
  filterName: string | undefined,
  fallbackText?: string
) => {
  const filter =
    filterName &&
    findElement(
      parentRoot,
      leftPanelSelector + " .filter-item",
      (el: Element) => el.innerHTML.includes(filterName),
      ".date-picker-header-value .tooltip span:not(.message)"
    );
  return filter ? filter.innerText : fallbackText;
};

const getFilterFromFilterList = (
  parentRoot: HTMLDivElement,
  leftPanelSelector: string,
  filterName?: string
) => {
  return (
    filterName &&
    findElement(parentRoot, leftPanelSelector + " .filter-item", (el: Element) =>
      el.innerHTML.includes(filterName)
    )
  );
};

/**
 * @return A Promise
 * Adds a class to an element and resolves when the element contains the class
 * Rejects if no success after 1000ms
 */
const appendClassToDiv = async (
  querySelector: string,
  classToAppend: string,
  parentRoot?: HTMLElement
) => {
  const element = (parentRoot || document).querySelector(querySelector);
  if (element) {
    element.classList.add(classToAppend);
    try {
      await waitForElement(
        querySelector,
        e => e !== null && e.classList.contains(classToAppend),
        parentRoot
      );
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * @return A Promise
 * Removes a class from an element and resolves when the element no longer contains the class
 * Rejects if no success after 1000ms
 */
const removeClassFromDiv = async (
  querySelector: string,
  classToRemove: string,
  parentRoot?: HTMLElement
) => {
  const element = (parentRoot || document).querySelector(querySelector);
  if (element) {
    element.classList.remove(classToRemove);
    try {
      await waitForElement(
        querySelector,
        e => e !== null && !e.classList.contains(classToRemove),
        parentRoot
      );
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * @return Timeout promise that resolves after waitTimeMs milliseconds
 * */
const waitForAnimation = (waitTimeMs: number): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, waitTimeMs);
  });
};

/**
 * Opens the left-panel of a Tile component
 * @param parentRef reference to Tile component
 * @param parentSelector Search query for selecting an parent element
 * */
const openLeftPane = async (parentRef?: HTMLElement, parentSelector?: string) => {
  await Promise.all([
    removeClassFromDiv((parentSelector || "") + ".left-panel", "hidden", parentRef)
  ]);
  await waitForAnimation(200);
};

/**
 * Closes the left-panel of a Tile component
 * @param parentRef reference to Tile component
 * @param parentSelector Search query for selecting an parent element
 * */
const closeLeftPane = async (parentRef?: HTMLElement, parentSelector?: string) => {
  await Promise.all([
    appendClassToDiv((parentSelector || "") + ".left-panel", "hidden", parentRef)
  ]);
  await waitForAnimation(200);
};

/**
 * @returns the first matching child query of a parent element.
 * If none found, it returns undefined
 * */
const getElement = (parent: HTMLDivElement | Document, childQuery: string) => {
  if (parent && childQuery.length) {
    const child = parent.querySelector(childQuery);
    return child ? (child as HTMLElement) : undefined;
  }
};
/**
 * Searches the {@link document} for an element. Returns found element or fallback element
 * @returns A {@link HTMLDivElement}
 * @param childQuery Search query for selecting an element
 * @param fallbackElement Fallback element if no child found
 */
const getDefinedDocumentElement = (childQuery: string, fallbackElement: HTMLDivElement) => {
  if (childQuery.length) {
    const child = document.querySelector(childQuery);
    if (child) {
      return child as HTMLDivElement;
    }
  }
  return fallbackElement;
};

/**
 * Querys a parent element and returns child or itself, if no child found
 * @return Child of parent node, or parent node itself if child not found
 * @param parent node to query on
 * @param childQuery query to select a child from the root on
 * */
const getDefinedElement = (parent: HTMLDivElement, childQuery: string) => {
  if (childQuery.length) {
    const child = parent.querySelector(childQuery);
    if (child) {
      return child as HTMLDivElement;
    }
  }
  return parent;
};

/**
 * Checks if an element exists inside a parent
 * @returns True if element found, otherwise false
 * @param parent node to query on or {@link document}
 * @param childQuery query to select a child from the root on
 * */
const elementVisible = (parent: HTMLDivElement | Document, childQuery: string) => {
  const child = parent.querySelector(childQuery);
  return child !== null;
};

/**
 * Selects a child from a parent node and checks a boolean condition on it
 * @returns true if conditions are met, otherwise false
 * @param parent Root element to query on
 * @param childQuery The query to find a child element
 * @param condition Function to test a selected child element on
 */
const condtionalElementCheck = (
  parent: HTMLDivElement,
  childQuery: string,
  condition: (el: HTMLElement | null) => boolean
): boolean => {
  const child = parent.querySelector(childQuery) as HTMLElement;
  return condition(child);
};

/**
 * @return Value and label of a {@link VerticalBarChart BarChart}
 * @param ref Reference to the {@link VerticalBarChart barChart} visualisation
 */
const getVisulizationBarValue = (
  ref: HTMLDivElement
): { label: string | undefined; value: string | undefined } => {
  const barGroup = ref.querySelector(".chart-container svg .bar-group");
  if (barGroup) {
    const valueHTML = barGroup.querySelector(".label.value");
    const labelHTML = barGroup.querySelector(".label.text");
    return {
      label: labelHTML ? labelHTML.innerHTML : undefined,
      value: valueHTML ? valueHTML.innerHTML : undefined
    };
  }
  return { label: undefined, value: undefined };
};

/**
 * @return An array of child elements that fulfills a criteria |
 * @return An array of children of children elements that fulfills a criteria |
 * @return Empty array if no children (or children of children) were found
 * @param parent The root element to query on
 * @param childrenSelector The query to select multiple children of the parent element
 * @param equalCompare Function to select a specific children elements. Selects the first matching child element
 * @param subSelector Optional query selector of selected child element
 * */
const findElements = (
  parent: HTMLDivElement | Document,
  childrenSelector: string,
  equalCompare: (e: Element) => boolean,
  subSelector?: string
) => {
  const children = parent.querySelectorAll(childrenSelector);
  const items = Array.from(children).filter(e => equalCompare(e));
  return items.map((e: Element) => {
    return (subSelector ? e.querySelector(subSelector) : e) as HTMLDivElement;
  });
};

/**
 * @return An child element that fulfills a criteria |
 * @return A child of a child element that fulfills a criteria |
 * @return Undefined if no children (or child of child) were found
 * @param parent The root element to query on
 * @param childrenSelector The query to select multiple children of the parent element
 * @param equalCompare Function to select a specific child element. Selects the first matching child element
 * @param subSelector Optional query selector of selected child element
 * */
const findElement = (
  parent: HTMLDivElement | Document,
  childrenSelector: string,
  equalCompare: (e: Element, index: number, thisArray: any[]) => boolean,
  subSelector?: string
) => {
  const children = parent.querySelectorAll(childrenSelector);
  const item = Array.from(children).find((element, index, thisArray) =>
    equalCompare(element, index, thisArray)
  );
  if (subSelector && item) {
    return item.querySelector(subSelector) as HTMLDivElement;
  }
  if (item) {
    return item as HTMLDivElement;
  }
};

/**
 * Finds the index of a child element
 * @param parent The root element to query on
 * @param childrenSelector The query to select multiple children of the parent element
 * @param equalCompare Function to select a specific child element. Selects the first matching child element
 * @return Index of child element or -1 if no found
 */
const findElementIndex = (
  parent: HTMLDivElement | Document,
  childrenSelector: string,
  equalCompare: (e: Element) => boolean
) => {
  const children = parent.querySelectorAll(childrenSelector);
  return Array.from(children).findIndex(e => equalCompare(e));
};

/**
 * @return Text content of selected element, otherwise the fallback text
 * @param parent Root node to query on
 * @param selector Query selector
 * @param fallbackText Text returned if no child found
 * @param prefixText Text before text content
 */
const getTextOfElement = (
  parent: HTMLDivElement,
  selector: string,
  fallbackText?: string,
  prefixText?: string
) => {
  const el = getElement(parent, selector);
  return el ? `${prefixText || ""}${el.textContent}` : fallbackText;
};

/**
 * Opens the right-hand settings menu of ECOD
 * Adds/removes the appropriate classes, then waits 250ms for the animations to finish
 * @return Promise<void>
 */
const openSettingsMenu = async () => {
  await Promise.all([
    removeClassFromDiv("main .syspanel", "hidden"),
    removeClassFromDiv("main .syspanel .settings", "hidden"),
    removeClassFromDiv("main .app", "slide-right"),
    appendClassToDiv("main .app", "slide-left")
  ]);
  await waitForAnimation(250);
};

/**
 * Closes the right-hand settings menu of ECOD
 * Adds/removes the appropriate classes, then waits 250ms for the animations to finish
 * @return Promise<void>
 */
const closeSettingsMenu = async () => {
  appendClassToDiv("main .syspanel", "hidden");
  removeClassFromDiv("main .syspanel .settings", "hidden");
  await Promise.all([
    removeClassFromDiv("main .app", "slide-left"),
    appendClassToDiv("main .app", "slide-right")
  ]);
  await waitForAnimation(250);
};

export {
  appendClassToDiv,
  getDefinedDocumentElement,
  closeLeftPane,
  getTextOfElement,
  condtionalElementCheck,
  getDefinedElement,
  elementVisible,
  getElement,
  findElementIndex,
  getVisulizationBarValue,
  findElement,
  findElements,
  openLeftPane,
  removeClassFromDiv,
  waitForAnimation,
  waitForElement,
  openSettingsMenu,
  closeSettingsMenu,
  getFilterFromFilterList,
  getInnerTextOfFilterFromList
};
