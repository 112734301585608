import React from "react";
import { Button, Icon } from "@react-gcc-eds/core";
import { CustomTooltip } from "../../reports/helpers/custom-tooltip";
import {
  DashboardTileAlignment as TileAlignment,
  DashboardTileAlignment
} from "../../reports/dashboard";

export const WalkthroughHeader = ({
  header,
  walkthroughActive,
  tooltipInfo,
  walkthroughInfo,
  className,
  alignment,
  onClick,
  disabled
}: {
  header: string;
  walkthroughActive?: boolean;
  tooltipInfo?: string;
  walkthroughInfo?: string;
  className?: string;
  alignment?: DashboardTileAlignment;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const WalkthroughTooltip = ({
    tooltipInfo,
    walkthroughInfo
  }: {
    tooltipInfo?: string;
    walkthroughInfo?: string;
  }) => {
    return (
      <div className="start-walkthrough-tooltip">
        {tooltipInfo && (
          <div className="tooltip-header-container">
            <span className="tooltip-header">Info</span>
            {tooltipInfo.split(/\n/g).map((tip, i) => (
              <span key={`key-tooltip-info-${i}`}>{tip}</span>
            ))}
          </div>
        )}
        {walkthroughActive && (
          <div className="content">
            <span className="tooltip-header">Walkthrough</span>
            <span>
              {walkthroughInfo ||
                "For a more in depth tutorial of how to use this component, start the guided walkthrough"}
            </span>
            <div className="button-row">
              <Button disabled={disabled} primary onClick={onClick}>
                Start walkthrough
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`header-with-walkthrough-icon ${className || ""}`}>
      <span>{header}</span>
      {walkthroughActive ? (
        <CustomTooltip
          minWidthPixels={400}
          maxWidthPixels={400}
          breakStyle={"pixelWidth"}
          tooltip={WalkthroughTooltip({ tooltipInfo, walkthroughInfo })}
          position={alignment === TileAlignment.RIGHT ? "bottom-end" : "bottom"}
        >
          <Icon name="help" />
        </CustomTooltip>
      ) : (
        <CustomTooltip
          tooltip={tooltipInfo}
          position={alignment === TileAlignment.RIGHT ? "bottom-end" : "bottom"}
        >
          <Icon name="info" />
        </CustomTooltip>
      )}
    </div>
  );
};
