import React from "react";

interface Props {
  active: boolean;
  height: number;
  x: number;
}

const TooltipLine = ({ active, height, x }: Props) => {
  return (
    <path
      className="tooltip-line"
      opacity={active ? 1 : 0}
      d={`M0,${0}L0,${height}`}
      transform={`translate(${x + 2},0)`}
    ></path>
  );
};

export default TooltipLine;
