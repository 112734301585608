import React from "react";
import { ProgressBar } from "@react-gcc-eds/core";
import FullScreenPanel from "./full-screen-panel";

export const FullScreenLoader = () => {
  return (
    <FullScreenPanel>
      <div className="fullscreen-loader">
        <ProgressBar
          className="progress-bar"
          thin
          format="none"
          max={1}
          value={0}
          indeterminate={true}
        />
      </div>
    </FullScreenPanel>
  );
};
