import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  RedirectRequest
} from "@azure/msal-browser";

let { appRegistrationClientId } = (window as any)["runConfig"];
if (process.env.NODE_ENV === "development")
  appRegistrationClientId = "d8957989-51fc-45f4-835e-c05f78964893";

export const msalConfig: Configuration = {
  auth: {
    clientId: appRegistrationClientId,
    authority: "https://login.microsoftonline.com/92e84ceb-fbfd-47ab-be52-080c6b87953f",
    redirectUri: `${window.location.origin}/logged`,
    postLogoutRedirectUri: `${window.location.origin}/login`
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) return; // "Personally identifiable information" should normally not be logged.

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      piiLoggingEnabled: false
    }
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [`https://ericsson.onmicrosoft.com/${appRegistrationClientId}/user_impersonation`]
};
