import { localStorageKey } from ".";

const LOCAL_STORAGE_UNAUTH_URL_KEY = "unauth-url";

export const getPersistedUnauthorizedUrl = (): string => {
  const value = window.localStorage.getItem(localStorageKey(LOCAL_STORAGE_UNAUTH_URL_KEY));

  if (value) {
    return JSON.parse(value);
  }

  return "";
};

export const persistUnauthorizedUrl = (url: string) => {
  if (url === "/login" || url === "/logged" || url === "/no-access") {
    return;
  }
  window.localStorage.setItem(localStorageKey(LOCAL_STORAGE_UNAUTH_URL_KEY), JSON.stringify(url));
};

export const deleteUnauthorizedUrl = () => {
  window.localStorage.removeItem(localStorageKey(LOCAL_STORAGE_UNAUTH_URL_KEY));
};
