/**
 * Calculate linear regression coefficients using the method of least squares.
 * Linear regression equation: y = ax + b
 */

function regressionLinear(data: any[]) {
  const xValues = data.map(d => d.x);
  const yValues = data.map(d => d.y);

  if (!xValues.length || !yValues.length) {
    return [];
  }

  const reduceSumFunc = (prev: any, cur: any) => prev + cur;

  const xBar = (xValues.reduce(reduceSumFunc) * 1.0) / xValues.length;
  const yBar = (yValues.reduce(reduceSumFunc) * 1.0) / yValues.length;

  const ssXX = xValues.map(d => (d - xBar) ** 2).reduce(reduceSumFunc);
  const ssYY = yValues.map(d => (d - yBar) ** 2).reduce(reduceSumFunc);
  const ssXY = xValues.map((d, i) => (d - xBar) * (yValues[i] - yBar)).reduce(reduceSumFunc);

  const slope = ssXY / ssXX;
  const intercept = yBar - xBar * slope;
  const rSquare = ssXY ** 2 / (ssXX * ssYY);

  return [slope, intercept, rSquare];
}

export default regressionLinear;
