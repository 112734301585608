import React from "react";

interface Props {
  path: string;
  colorData: string | unknown;
  hasThresholds: boolean;
  opacity: number;
}

const Line = ({ path, colorData, hasThresholds, opacity }: Props) => {
  const stroke = hasThresholds
    ? { stroke: `url(#gradient-threshold-line-chart-${colorData})` }
    : {};
  return (
    <path
      transform="translate(3,0)"
      className={`trendline line ${colorData}`}
      d={path as string | undefined}
      style={stroke}
      opacity={opacity}
    ></path>
  );
};

export default Line;
