import { ISearchResult, IMaterialInventorySearchResult } from "../../../store";
import { Icon, TextField } from "@react-gcc-eds/core";
import React, { useEffect, useState } from "react";
import { useClickOutside } from "../date-picker/hooks";
import { IDateRange } from "../../../contracts";

let timer: any;

const SearchBarFilter = ({
  onClickSearchResult,
  searchResult,
  onSearchQueryChange,
  datesRange,
  label,
  big,
  className,
  resultAlignment,
  placeholderText
}: {
  onClickSearchResult(searchResult: ISearchResult | IMaterialInventorySearchResult): void;
  searchResult: (ISearchResult | IMaterialInventorySearchResult)[];
  onSearchQueryChange(query: string): void;
  datesRange?: IDateRange;
  label?: string;
  big?: boolean;
  className?: string;
  resultAlignment?: "results-left" | "results-right";
  placeholderText?: string;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchOngoing, setSearchOngoing] = useState(false);
  const [input, setInput] = useState("");

  const searchContainerRef = useClickOutside(() => {
    setSearchQuery("");
    setInput("");
  });

  useEffect(() => {
    onSearchQueryChange(searchQuery);
  }, [searchQuery]);

  const handleSearchSelection = (result: ISearchResult | IMaterialInventorySearchResult) => {
    setInput("");
    onClickSearchResult(result);
  };

  const SearchResultList = ({
    searchResults,
    alignment
  }: {
    searchResults: (ISearchResult | IMaterialInventorySearchResult)[];
    alignment: "results-right" | "results-left";
  }) => {
    if (searchOngoing || input !== searchQuery || input.length === 0) {
      return null;
    } else if (!datesRange || searchResults.length === 0) {
      return (
        <div className={`menu visible search-bar-filter-results ${alignment || ""}`}>
          <div className="item no-results">No results found</div>
        </div>
      );
    } else {
      return (
        <div className={`menu visible search-bar-filter-results ${alignment || ""}`}>
          {searchResults.length > 0 &&
            searchResults.map(
              (result: ISearchResult | IMaterialInventorySearchResult, index: number) => {
                return (
                  <div
                    className="item"
                    key={`${result.matchingValue}_${index}`}
                    onClick={() => handleSearchSelection(result)}
                  >
                    <div>{result.matchingProperty}</div>
                    <div className="fat">{result.matchingValue}</div>
                  </div>
                );
              }
            )}
        </div>
      );
    }
  };

  useEffect(() => {
    if (input.length > 0) {
      setSearchOngoing(false);
    }
  }, [searchResult]);

  const handleSearchInput = (input: string) => {
    setInput(input);
    clearTimeout(timer);
    if (input.length > 0) {
      timer = setTimeout(() => {
        clearTimeout(timer);
        setSearchOngoing(true);
        setSearchQuery(input);
      }, 500);
    }
  };

  return (
    <div className={`search-bar-filter ${className ? className : ""}`} ref={searchContainerRef}>
      <div className="search-input-container">
        <TextField
          key="search-input"
          label={label}
          className={`search-input ${big ? "big" : ""}`}
          suffixInside
          suffix={
            datesRange && searchOngoing && input.length > 0 ? (
              <div className="loading small" />
            ) : (
              <Icon name={`search`} />
            )
          }
          value={input}
          onChange={(input: string) => handleSearchInput(input)}
          placeholder={placeholderText || "Search..."}
        />
      </div>
      <SearchResultList
        searchResults={searchResult}
        alignment={resultAlignment ? resultAlignment : "results-left"}
      />
    </div>
  );
};

export default SearchBarFilter;
