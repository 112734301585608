import React, { useState } from "react";
import { BubbleChartData as Data, Colors } from "../interfaces";
import TrendlineGroup from "./TrendlineGroup";
import regressionLinear from "./regression";

interface Props {
  data: Data;
  xMin: number;
  xMax: number;
  yScale: any;
  xScale: any;
  colorScale: Colors;
  onHover: Function;
  onHoverLeave: Function;
  hoveredSerie: string;
  clipPath: string;
}

const TrendlineGroups = ({
  data,
  xMin,
  xMax,
  yScale,
  xScale,
  colorScale,
  onHover,
  onHoverLeave,
  hoveredSerie,
  clipPath
}: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [hoveredTrendline, setHoveredTrendline] = useState("");
  const defaultOpacity = hoveredIndex === -1 && hoveredSerie === "" ? 1 : 0.2;

  const trendData: {
    name: string;
    a: number;
    b: number;
    rSquared: number;
    coords: number[];
  }[] = [];
  data.series.forEach(series => {
    const coeff = regressionLinear(series.values);
    if (coeff.length) {
      trendData.push({
        name: series.name,
        a: coeff[0],
        b: coeff[1],
        rSquared: coeff[2],
        coords: [xMin, coeff[0] * xMin + coeff[1], xMax, coeff[0] * xMax + coeff[1]]
      });
    }
  });

  return (
    <g className="trendlines" clipPath={`url(#${clipPath})`}>
      {trendData.map((serie, index: number) => {
        const key = index;
        let colorData = colorScale[serie.name];
        colorData = colorData !== undefined ? colorData : "red";
        return (
          <TrendlineGroup
            key={key}
            x1={xScale(serie.coords[0])}
            y1={yScale(serie.coords[1])}
            x2={xScale(serie.coords[2])}
            y2={yScale(serie.coords[3])}
            colorData={colorData}
            opacity={
              hoveredTrendline === serie.name || hoveredSerie === serie.name ? 1 : defaultOpacity
            }
            onMouseHover={(isHovered: boolean, e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
              if (isHovered) {
                setHoveredIndex(index);
                setHoveredTrendline(serie.name);
              } else {
                setHoveredIndex(-1);
                setHoveredTrendline("");
              }

              const items = [
                {
                  key:
                    data.series[hoveredIndex] !== undefined ? data.series[hoveredIndex].name : "",
                  subItems: {
                    x: {
                      key:
                        data.series[hoveredIndex] !== undefined
                          ? `y = ${trendData[hoveredIndex].a.toFixed(3)} x + ${trendData[
                              hoveredIndex
                            ].b.toFixed(3)}`
                          : ""
                    },
                    y: {
                      key: "R-squared",
                      value:
                        data.series[hoveredIndex] !== undefined
                          ? trendData[hoveredIndex].rSquared.toFixed(3)
                          : 0
                    }
                  }
                }
              ];

              const label = "Linear trend";
              onHover(e, label, items, index, isHovered, hoveredTrendline);
            }}
            onMouseLeave={(event: React.MouseEvent) => onHoverLeave(event)}
          />
        );
      })}
    </g>
  );
};

export default TrendlineGroups;
