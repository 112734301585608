import { useSettings } from "@react-gcc-eds/core";
import { useEffect, useRef } from "react";
import { useSettingsActions } from "../../actions/settings-actions";
import { useUserPreferencesActions } from "../../actions/user-preferences-actions";
import { useStore } from "../../store";

export const PersistTheme = ({
  onThemeChanged
}: {
  onThemeChanged: (theme: "light" | "dark" | undefined) => void;
}) => {
  const [state] = useStore();
  const { theme } = useSettings();
  const { persistUserPreferences } = useUserPreferencesActions();

  useEffect(() => {
    const newTheme = theme === "light" ? "light" : "dark";
    if (!state.userPreferences.theme || theme !== state.userPreferences.theme) {
      persistUserPreferences({ ...state.userPreferences, theme: newTheme });
    }
    onThemeChanged(newTheme);
  }, [theme]);

  return null;
};

export const useUserPreferences = () => {
  const mounted = useRef<{ filters: boolean; grids: boolean } | undefined>(undefined);
  const [state] = useStore();
  const { currentCustomer } = useSettingsActions();
  const { persistUserPreferences, persistUserFilters, persistGridsColumnsPreferences } =
    useUserPreferencesActions();

  const cacheFilters = async () => {
    // caching filters
    const {
      ordersFilters,
      metricsFilters,
      shipmentFilters,
      internalShipmentFilters,
      inventoryFilters,
      itemsDeliveriesFilters,
      internalItemsDeliveriesFilters,
      internalOrdersFilters,
      overViewsCollapsedStatuses,
      gridColumnsWeekFormatPreferences,
      poItemsFilters
    } = state;

    await persistUserFilters(currentCustomer().id, currentCustomer().identifier, {
      orders: ordersFilters,
      metrics: metricsFilters,
      shipment: shipmentFilters,
      internalShipment: internalShipmentFilters,
      inventory: inventoryFilters,
      itemsDeliveries: itemsDeliveriesFilters,
      internalItemsDeliveries: internalItemsDeliveriesFilters,
      internalOrders: internalOrdersFilters,
      overViewsCollapsed: overViewsCollapsedStatuses,
      gridColumnsWeekFormatPreferences: gridColumnsWeekFormatPreferences,
      poItemsFilters: poItemsFilters
    });
  };

  useEffect(() => {
    if (mounted.current?.filters) {
      cacheFilters();
    } else {
      mounted.current = { ...(mounted.current || { grids: false }), filters: true };
    }
  }, [
    state.ordersFilters,
    state.metricsFilters,
    state.shipmentFilters,
    state.internalShipmentFilters,
    state.inventoryFilters,
    state.itemsDeliveriesFilters,
    state.internalItemsDeliveriesFilters,
    state.internalOrdersFilters,
    state.overViewsCollapsedStatuses,
    state.gridColumnsWeekFormatPreferences,
    state.poItemsFilters
  ]);

  useEffect(() => {
    if (mounted.current?.grids) {
      persistGridsColumnsPreferences(
        currentCustomer().id,
        currentCustomer().identifier,
        state.gridsColumnsPreferences
      );
    } else {
      mounted.current = { ...(mounted.current || { filters: false }), grids: true };
    }
  }, [state.updateGridsColumnsPreferences]);

  const persistCustomer = (customerId: string) => {
    persistUserPreferences({ ...state.userPreferences, customerId: customerId });
  };

  const persistPreference = (prop: string, status: boolean) => {
    persistUserPreferences({ ...state.userPreferences, [prop]: status });
  };

  return {
    persistCustomer,
    persistPreference
  };
};
