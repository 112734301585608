import { useState } from "react";
import { Button, Row, Column, Dialog, TextField, Radiobutton, Icon } from "@react-gcc-eds/core";
import { useStore, isApiWriting } from "../../../store";
import { useUserAccessRequestActions } from "../../../actions/user-access-request-actions";
import { isEmail } from "../../../utils";
import newCustomerRequestImage from "../../../style/new-customer-request.png";

enum Step {
  IdentifyUser,
  ExternalUserInfo,
  InternalUserInfo,
  CustomerInfo,
  Recap,
  HaveAccount
}

enum AccountType {
  External,
  Internal,
  Ecod
}
interface IUserInfo {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: string;
  country: string;
  company: string;
  email: string;
  signum: string;
  ericssonContact: string;
  customer: string;
}

const NewUserAccountForm = ({ onClose }: { onClose: () => void }) => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.IdentifyUser);

  const [accountType, setAccountType] = useState(AccountType.External);
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    city: "",
    country: "",
    company: "",
    email: "",
    signum: "",
    ericssonContact: "",
    customer: ""
  });

  const [state] = useStore();

  const setUser = (property: string) => (value: string) =>
    setUserInfo(user => ({ ...user, [property]: value }));

  const { newRegistration } = useUserAccessRequestActions();

  const register = () => {
    const {
      firstName,
      lastName,
      email,
      city,
      country,
      company,
      phoneNumber,
      ericssonContact,
      signum,
      customer
    } = userInfo;

    newRegistration(
      email,
      firstName,
      lastName,
      company,
      city,
      country,
      phoneNumber,
      ericssonContact,
      signum,
      customer,
      () => setCurrentStep(Step.Recap)
    );
  };

  const validateEmail = (email: string) => {
    if (!email || !email.length) {
      return "E-mail is required";
    } else {
      if (!isEmail(email)) {
        return "E-mail is not valid";
      } else {
        return undefined;
      }
    }
  };

  const userType = () => {
    return (
      <div>
        <Radiobutton
          className="user-type"
          // group="user-type"
          text="I am an Ericsson customer / ASP"
          value={accountType === AccountType.External}
          onChange={() => setAccountType(AccountType.External)}
        />
        <Radiobutton
          className="user-type"
          // group="user-type"
          text="I am an Ericsson employee"
          value={accountType === AccountType.Internal}
          onChange={() => setAccountType(AccountType.Internal)}
        />
        <Radiobutton
          className="user-type"
          // group="user-type"
          text="I already have an ECOD account"
          value={accountType === AccountType.Ecod}
          onChange={() => setAccountType(AccountType.Ecod)}
        />
      </div>
    );
  };

  const externalUserInfo = () => {
    return (
      <>
        <Row>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.firstName}
              label="First name"
              fullWidth
              onChange={setUser("firstName")}
            />
          </Column>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.lastName}
              label="Last name"
              fullWidth
              onChange={setUser("lastName")}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.company}
              label="Your company name"
              fullWidth
              onChange={setUser("company")}
            />
          </Column>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField value={userInfo.city} label="City" fullWidth onChange={setUser("city")} />
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.country}
              label="Country"
              fullWidth
              onChange={setUser("country")}
            />
          </Column>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.phoneNumber}
              label="Phone number"
              fullWidth
              onChange={setUser("phoneNumber")}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.email}
              label="Email"
              fullWidth
              className="validation-field"
              validation={validateEmail(userInfo.email)}
              onChange={setUser("email")}
            />
          </Column>
        </Row>
      </>
    );
  };

  const internalUserInfo = () => {
    return (
      <>
        <Row>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.firstName}
              label="First name"
              fullWidth
              onChange={setUser("firstName")}
            />
          </Column>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.lastName}
              label="Last name"
              fullWidth
              onChange={setUser("lastName")}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.signum}
              label="Ericsson signum"
              fullWidth
              onChange={setUser("signum")}
            />
          </Column>
          <Column sm={12} md={12} lg={6} className="input-form-field">
            <TextField
              value={userInfo.email}
              label="Email"
              fullWidth
              className="validation-field"
              validation={validateEmail(userInfo.email)}
              onChange={setUser("email")}
            />
          </Column>
        </Row>
      </>
    );
  };

  const customerInfo = () => {
    return (
      <Row>
        <Column sm={12} md={12} lg={6} className="input-form-field">
          <TextField
            value={userInfo.ericssonContact}
            label="Your Ericsson contact person"
            fullWidth
            onChange={setUser("ericssonContact")}
          />
        </Column>
        <Column sm={12} md={12} lg={6} className="input-form-field">
          <TextField
            value={userInfo.customer}
            label="Operator name and country"
            fullWidth
            onChange={setUser("customer")}
          />
          <label className="subtitle">
            (Please, specify <b>one</b> operator and <b>one</b> country only in this request)
          </label>
        </Column>
      </Row>
    );
  };

  const recap = () => {
    return (
      <div className="recap">
        <Icon name="check" />
        <div>Thank you for your interest in Ericsson Customer Order Dashboard.</div>
        <div>Your request will be processed soon and we will send you an email once completed.</div>
        <div>
          In the mean time you can read the Multi-factor authentication (MFA){" "}
          <a
            rel="noreferrer"
            href={`/${state.settings.Features.MfaGuide_Filename}`}
            target="_blank"
          >
            guide
          </a>
          .
        </div>
      </div>
    );
  };

  const haveAccount = () => {
    return (
      <div className="internal-request">
        <div>
          <div>If you already have access to ECOD and you'd like to </div>
          <div>request access to an additional customer, please sign</div>
          <div>in and place the request from the button that you will</div>
          <div>find in your UserName menu.</div>
        </div>
        <img src={newCustomerRequestImage} alt="New customer request" />
      </div>
    );
  };

  const isEricssonEmail = (email: string) => {
    return email.toLowerCase().endsWith("@ericsson.com");
  };

  const isExternalUserValid = () => {
    return (
      userInfo.firstName &&
      userInfo.lastName &&
      userInfo.company &&
      userInfo.city &&
      userInfo.country &&
      userInfo.email &&
      isEmail(userInfo.email) &&
      !isEricssonEmail(userInfo.email) &&
      userInfo.phoneNumber
    );
  };

  const isInternalUserValid = () => {
    return (
      userInfo.firstName &&
      userInfo.lastName &&
      userInfo.signum &&
      userInfo.email &&
      isEmail(userInfo.email) &&
      isEricssonEmail(userInfo.email)
    );
  };

  const isCustomerValid = () => {
    return userInfo.ericssonContact && userInfo.customer;
  };

  const isStepValid = () => {
    switch (currentStep) {
      case Step.ExternalUserInfo:
        return isExternalUserValid();
      case Step.InternalUserInfo:
        return isInternalUserValid();
      default:
        return true;
    }
  };

  const stepForward = () => {
    const calculateStep = (step: Step): Step => {
      if (step === Step.IdentifyUser) {
        switch (accountType) {
          case AccountType.External:
            return step + 1;
          case AccountType.Internal:
            return step + 2;
          case AccountType.Ecod:
            return Step.HaveAccount;
          default:
            return step + 1;
        }
      } else if (step === Step.ExternalUserInfo) {
        return step + 2;
      }
      return step + 1;
    };

    setCurrentStep(step => calculateStep(step));
  };

  const stepBackward = () => {
    const calculateStep = (step: Step): Step => {
      if (step === Step.CustomerInfo && accountType !== AccountType.Internal) {
        return step - 2;
      } else if (step === Step.InternalUserInfo) {
        return step - 2;
      }
      return step - 1;
    };

    setCurrentStep(step => calculateStep(step));
  };

  const buttons = () => {
    if (isApiWriting(state)) {
      return [];
    }

    const btns = [
      <Button key="cancel" onClick={onClose}>
        {currentStep === Step.Recap || currentStep === Step.HaveAccount ? "Close" : "Cancel"}
      </Button>
    ];

    if (
      currentStep !== Step.IdentifyUser &&
      currentStep !== Step.Recap &&
      currentStep !== Step.HaveAccount
    ) {
      btns.push(
        <Button key="previous" iconName="arrow-left" onClick={stepBackward}>
          Previous
        </Button>
      );
    }

    if (currentStep < Step.CustomerInfo) {
      btns.push(
        <Button
          key="next"
          primary
          iconName="arrow-right"
          onClick={stepForward}
          disabled={!isStepValid()}
        >
          Next
        </Button>
      );
    }

    if (currentStep === Step.CustomerInfo) {
      btns.push(
        <Button
          key="submit"
          primary
          iconName="arrow-right"
          disabled={!isCustomerValid() || isApiWriting(state)}
          onClick={register}
        >
          Submit
        </Button>
      );
    }
    return btns;
  };

  const step = () => {
    switch (currentStep) {
      case Step.IdentifyUser:
        return "Identify your account type";
      case Step.ExternalUserInfo:
        return "Step 1/1 - External user without Ericsson account";
      case Step.InternalUserInfo:
        return "Step 1/2 - Internal user";
      case Step.CustomerInfo:
        return "Step 2/2 - Customer access details";
      default:
        return "";
    }
  };

  const stepContent = () => {
    switch (currentStep) {
      case Step.IdentifyUser:
        return userType();
      case Step.ExternalUserInfo:
        return externalUserInfo();
      case Step.InternalUserInfo:
        return internalUserInfo();
      case Step.CustomerInfo:
        return customerInfo();
      case Step.Recap:
        return recap();
      case Step.HaveAccount:
        return haveAccount();
      default:
        return null;
    }
  };

  return (
    <Dialog className="user-registration-form" title="ECOD access request" buttons={buttons()}>
      {(currentStep === Step.IdentifyUser || currentStep === Step.Recap) && (
        <div className="subtitle">
          {currentStep !== Step.Recap
            ? "Thank you for your interest in ECOD. Please provide the following information to request access."
            : "Your request has been submitted successfully."}
        </div>
      )}
      <div className="step-summary">{step()}</div>
      <div key="content" className="step-content">
        {stepContent()}
      </div>
    </Dialog>
  );
};

export default NewUserAccountForm;
