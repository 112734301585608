import React, { useEffect } from "react";
import { VerticalBarChart } from "@react-gcc-eds/charts";
import {
  IShipmentDataPoint,
  IShipmentDeliveredMetric,
  IStatusMetric,
  IWeekMetric
} from "../../../store";
import {
  NoMetrics,
  noStatusMetric,
  noWeekMetric,
  minMetricCreationDate,
  maxMetricCreationDate,
  noShipmentDeliveredMetric
} from "./utils";
import { leadingZero } from "../../../utils";
import { usePoStatus } from "../helpers/po-status";
import { useLocalization } from "../../../utils/localization";
import { IDateRange } from "../../../contracts";

const BarsChart = (props: any) => {
  return <VerticalBarChart format=".2s" hideLegend {...props} fill />;
};

export const WeekBarChart = ({
  metrics,
  loading,
  unit,
  onSelection
}: {
  metrics: IWeekMetric[];
  loading: boolean;
  unit: string;
  onSelection?(data: object): void;
}) => {
  if (loading) {
    return <NoMetrics loading />;
  }
  if (noWeekMetric(metrics)) {
    return <NoMetrics />;
  }

  const barData = {
    common: metrics.map(m => `W${leadingZero(m.week)}`),
    series: [{ name: "", values: metrics.map(m => m.amount) }]
  };

  return (
    <BarsChart
      data={barData}
      unit={unit}
      onClick={(label: string) => {
        onSelection &&
          onSelection({
            orderCreationRange: {
              from: minMetricCreationDate(metrics),
              to: maxMetricCreationDate(metrics)
            },
            label: label
          });
      }}
    />
  );
};

export const StatusBarChart = ({
  metrics,
  loading,
  unit,
  datesRange,
  internal,
  onSelection
}: {
  metrics: IStatusMetric[];
  loading: boolean;
  unit: string;
  datesRange?: IDateRange;
  internal?: boolean;
  onSelection?(data: object): void;
}) => {
  const { statusTooltip, internalStatusDeliveryTooltip } = useLocalization();
  const { labelToStatus, internalLabelToDeliveryStatus } = usePoStatus();

  if (loading) {
    return <NoMetrics loading />;
  }
  if (noStatusMetric(metrics)) {
    return <NoMetrics />;
  }
  const poData = {
    common: metrics.map(m => m.label),
    descriptions: metrics.map(m =>
      internal ? internalStatusDeliveryTooltip(m.status) : statusTooltip(m.status)
    ),
    series: [{ name: "", values: metrics.map(m => m.totalCount) }]
  };
  return (
    <BarsChart
      data={poData}
      unit={unit}
      onClick={(label: string) => {
        onSelection &&
          onSelection({
            orderCreationRange: datesRange,
            status: internal ? internalLabelToDeliveryStatus(label) : labelToStatus(label)
          });
      }}
    />
  );
};

export const ShipmentDeliveredBarChart = ({
  metrics,
  loading,
  unit,
  datesRange,
  internal,
  onSelection
}: {
  metrics: IShipmentDeliveredMetric;
  loading: boolean;
  unit: string;
  datesRange?: IDateRange;
  internal?: boolean;
  onSelection?(data: object): void;
}) => {
  if (loading) {
    return <NoMetrics loading />;
  }
  if (noShipmentDeliveredMetric(metrics)) {
    return <NoMetrics />;
  }
  const shipmentCommon: string[] = [];
  const shipmentSeries: { name: string; values: number[] }[] = [];
  metrics.dataPoints.map(x => shipmentCommon.push(x.label));
  shipmentSeries.push({ name: "", values: metrics.dataPoints.map(x => x.value) });
  const shipmentData = {
    common: shipmentCommon,
    descriptions: undefined,
    series: shipmentSeries
  };
  return <BarsChart data={shipmentData} unit={unit} onClick={(label: string) => console.log("")} />;
};
