import {
  apiReadingOff,
  apiReadingOn,
  apiWritingOff,
  apiWritingOn,
  IScheduledExport,
  IScheduledExportItem,
  IUserCustomerScheduledExportItem,
  useStore
} from "../store";
import { useApi } from "./api-factory";

export const useScheduledExportsActions = () => {
  const [_, setState] = useStore();
  const { api } = useApi();
  const [state] = useStore();

  const getAdminCustomerScheduledExportsList = async (customerId: string) => {
    try {
      setState(old => apiReadingOn(old, f => f.adminCustomerScheduleExports));
      const scheduledExports = await api.get(`v1/settings/scheduledexports/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.adminCustomerScheduleExports),
        adminCustomerScheduleExports: scheduledExports
      }));
      return scheduledExports;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.adminCustomerScheduleExports));
    }
  };

  const updateAdminCustomerScheduledExportsList = async (
    customerId: string,
    scheduledExports: IScheduledExport
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => apiReadingOn(old, f => f.userCustomerScheduledExports));
      setState(old => setApiWritingOn(old, scheduledExports));
      await api.put("v1/Settings/scheduledexports/user-settings/", scheduledExports);
      setState(old => apiWritingOff(id, old));
      setState(old => ({
        ...apiReadingOff(old, f => f.scheduledExports),
        scheduledExports: scheduledExports
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      setState(old => apiReadingOff(old, f => f.userCustomerScheduledExports));
    }
  };

  const getUserCustomerScheduledExportsList = async (customerId: string) => {
    try {
      const id = customerId;
      setState(old => apiReadingOn(old, f => f.userCustomerScheduledExports));
      const scheduledExports = await api.get("v1/settings/scheduledexports/user-settings/");
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerScheduledExports),
        userCustomerScheduledExports: scheduledExports
      }));

      return scheduledExports;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userCustomerScheduledExports));
    }
  };

  const updateUserCustomerScheduledExportsList = async (
    customerId: string,
    scheduledExports: IScheduledExport
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => apiReadingOn(old, f => f.userCustomerScheduledExports));
      setState(old => setApiWritingOn(old, scheduledExports));
      await api.put("v1/Settings/scheduledexports/user-settings/", scheduledExports);
      setState(old => apiWritingOff(id, old));
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerScheduledExports),
        userCustomerScheduledExports: scheduledExports
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      setState(old => apiReadingOff(old, f => f.userCustomerScheduledExports));
    }
  };

  const updateUserCustomerScheduledExportsEnabled = async (customerId: string, value: boolean) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => apiReadingOn(old, f => f.userCustomerScheduledExportsEnabled));
      setState(old => setApiWritingOn(old, customerId));
      await api.put("v1/Settings/scheduledexports/user-customer/", value);
      setState(old => apiWritingOff(id, old));
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerScheduledExportsEnabled),
        userCustomerScheduledExportsEnabled: value
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      setState(old => apiReadingOff(old, f => f.userCustomerScheduledExportsEnabled));
    }
  };

  const getUserCustomerScheduledExportsEnabled = async (customerId: string) => {
    try {
      const id = customerId;
      setState(old => apiReadingOn(old, f => f.userCustomerScheduledExportsEnabled));
      const scheduledExportsEnabled = await api.get("v1/settings/scheduledexports/user-customer/");
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerScheduledExportsEnabled),
        userCustomerScheduledExportsEnabled: scheduledExportsEnabled
      }));
      return scheduledExportsEnabled;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userCustomerScheduledExportsEnabled));
    }
  };
  return {
    getAdminCustomerScheduledExportsList,
    updateAdminCustomerScheduledExportsList,
    getUserCustomerScheduledExportsList,
    updateUserCustomerScheduledExportsList,
    updateUserCustomerScheduledExportsEnabled,
    getUserCustomerScheduledExportsEnabled
  };
};
