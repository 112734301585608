import React, { ReactNode, RefObject, useMemo, useCallback } from "react";
import {
  format,
  startOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfToday,
  endOfMonth,
  startOfYear,
  endOfYear,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  isBefore,
  isEqual
} from "date-fns";
import { Icon } from "@react-gcc-eds/core";

import { IDateRange } from "../../../contracts";
import { CustomTooltip } from "../../reports/helpers/custom-tooltip";

export enum PREDEFINED_RANGES {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  THIS_WEEK = "This week",
  LAST_WEEK = "Last week",
  THIS_MONTH = "This month",
  LAST_MONTH = "Last month",
  THIS_YEAR = "This year",
  LAST_12_MONTHS = "Last 12 months",
  LAST_YEAR = "Last year",
  ALL = "All"
}

const today = (): IDateRange => {
  const now = new Date();
  const d = startOfDay(now);
  return { from: d, to: d, reference: now };
};

const yesterday = (): IDateRange => {
  const now = new Date();
  const d = addDays(startOfDay(now), -1);
  return { from: d, to: d, reference: now };
};

const thisWeek = (): IDateRange => {
  const now = new Date();
  const d = startOfDay(now);
  return {
    from: startOfWeek(d, { weekStartsOn: 1 }),
    to: endOfWeek(d, { weekStartsOn: 1 }),
    reference: now
  };
};

const lastWeek = (): IDateRange => {
  const now = new Date();
  const d = addWeeks(startOfDay(now), -1);
  return {
    from: startOfWeek(d, { weekStartsOn: 1 }),
    to: endOfWeek(d, { weekStartsOn: 1 }),
    reference: now
  };
};

const thisMonth = (): IDateRange => {
  const now = new Date();
  const d = startOfDay(now);
  return { from: startOfMonth(d), to: endOfMonth(d), reference: now };
};

const lastMonth = (): IDateRange => {
  const now = new Date();
  const d = addMonths(startOfDay(now), -1);
  return { from: startOfMonth(d), to: endOfMonth(d), reference: now };
};

const thisYear = (): IDateRange => {
  const now = new Date();
  const d = startOfDay(now);
  return { from: startOfYear(d), to: endOfYear(d), reference: now };
};

const lastYear = (): IDateRange => {
  const now = new Date();
  const d = addYears(startOfDay(now), -1);
  return { from: startOfYear(d), to: endOfYear(d), reference: now };
};
const lastTwelveMonths = (): IDateRange => {
  const d = addYears(endOfToday(), -1);
  return { from: d, to: endOfToday(), reference: new Date() };
};

const allTime = (oldestDate: Date): IDateRange => {
  return { from: startOfDay(oldestDate), to: endOfToday(), reference: new Date() };
};

export interface IPredefinedRange {
  id: number;
  isAll?: boolean;
  element?: ReactNode;
  title: PREDEFINED_RANGES;
  selector(): IDateRange;
  ref?: RefObject<HTMLDivElement>;
}

const {
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  LAST_12_MONTHS,
  LAST_YEAR,
  ALL
} = PREDEFINED_RANGES;

const defaultRanges: IPredefinedRange[] = [
  { id: 0, title: TODAY, selector: today },
  { id: 1, title: YESTERDAY, selector: yesterday },
  { id: 2, title: THIS_WEEK, selector: thisWeek },
  { id: 3, title: LAST_WEEK, selector: lastWeek },
  { id: 4, title: THIS_MONTH, selector: thisMonth },
  { id: 5, title: LAST_MONTH, selector: lastMonth },
  { id: 6, title: THIS_YEAR, selector: thisYear },
  { id: 7, title: LAST_YEAR, selector: lastYear },
  { id: 8, title: LAST_12_MONTHS, selector: lastTwelveMonths }
];

const validRanges = (oldestDate?: Date) => {
  const ranges = defaultRanges.filter(
    r =>
      !oldestDate ||
      isBefore(startOfDay(oldestDate), startOfDay(r.selector().from)) ||
      isEqual(startOfDay(oldestDate), startOfDay(r.selector().from))
  );

  if (!ranges.length) {
    return [{ id: 0, title: LAST_12_MONTHS, selector: lastTwelveMonths }];
  }

  return ranges;
};

export const defaultRange = (oldestDate: Date) => {
  const ranges = validRanges(oldestDate);
  return ranges[ranges.length - 1].selector();
};

export const usePredefinedRanges = (oldestDate?: Date) => {
  const formattedDate = (d: Date) => {
    return format(d, "dd LLL yyyy");
  };

  const dateRangesAreEqual = (range1?: IDateRange, range2?: IDateRange) => {
    return (
      range1 &&
      range2 &&
      isEqual(startOfDay(range1.from), startOfDay(range2.from)) &&
      isEqual(startOfDay(range1.to), startOfDay(range2.to))
    );
  };

  const predefinedRanges = useMemo(() => {
    let ranges = validRanges(oldestDate);

    if (oldestDate) {
      const allTimeRange = allTime(oldestDate);

      if (!ranges.some(r => dateRangesAreEqual(allTimeRange, r.selector()))) {
        ranges = [
          ...ranges,
          {
            id: 9,
            isAll: true,
            title: ALL,
            element: (
              <CustomTooltip
                tooltip={[
                  {
                    header: "All available data",
                    body: `This includes all available data, starting from ${formattedDate(
                      oldestDate
                    )}`
                  }
                ]}
              >
                All
                <Icon name="info" />
              </CustomTooltip>
            ),
            selector: () => allTimeRange
          }
        ];
      }
    }

    return ranges.map((r, index) => ({ ...r, id: index }));
  }, [oldestDate]);

  const largestRange = useMemo((): IPredefinedRange => {
    const ranges = predefinedRanges.filter(r => !r.isAll);
    if (!ranges.length) {
      return { id: 0, title: LAST_12_MONTHS, selector: lastTwelveMonths };
    }
    return ranges[ranges.length - 1];
  }, [predefinedRanges]);

  const isLargestRange = useCallback(
    (range?: IDateRange) => dateRangesAreEqual(range, largestRange.selector()) === true,
    [largestRange]
  );

  const matchesPredefinedRange = useCallback(
    (range?: IDateRange): IPredefinedRange | undefined => {
      return (
        range &&
        range.reference &&
        predefinedRanges.find(r => dateRangesAreEqual(r.selector(), range))
      );
    },
    [predefinedRanges]
  );

  const nextPredefinedRange = useCallback(
    (range?: IDateRange): IPredefinedRange => {
      const predefinedRange = matchesPredefinedRange(range);

      if (!predefinedRange) {
        return predefinedRanges[0];
      }

      return predefinedRanges[(predefinedRange.id + 1) % predefinedRanges.length];
    },
    [predefinedRanges]
  );

  const previousPredefinedRange = useCallback(
    (range?: IDateRange): IPredefinedRange => {
      const predefinedRange = matchesPredefinedRange(range);

      if (!predefinedRange) {
        return predefinedRanges[predefinedRanges.length - 1];
      }

      let previousRangeId = predefinedRange.id - 1;
      if (previousRangeId < 0) {
        previousRangeId = predefinedRanges.length - Math.abs(previousRangeId);
      }
      return predefinedRanges[previousRangeId];
    },
    [predefinedRanges]
  );

  const rangeAsString = useCallback(
    (range?: IDateRange) => {
      if (!range) {
        return "No range selected";
      }

      const predefinedRange = matchesPredefinedRange(range);

      if (predefinedRange) {
        return predefinedRange.title;
      }

      return `${formattedDate(range.from)} - ${formattedDate(range.to)}`;
    },
    [predefinedRanges]
  );

  return {
    predefinedRanges,
    largestRange,
    isLargestRange,
    matchesPredefinedRange,
    previousPredefinedRange,
    nextPredefinedRange,
    rangeAsString
  };
};
