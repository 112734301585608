import React from "react";

interface Props {
  x: number;
  y: number;
  xScale: any;
  yScale: any;
  width: number;
  height: number;
  visible: boolean;
  dotHover: boolean;
}

const Crosshair = ({ x, y, xScale, yScale, width, height, visible, dotHover }: Props) => {
  const mouseX = xScale.invert(x);
  const mouseY = yScale.invert(y);
  return (
    <g className="crosshair">
      <line
        className="crosshair-line-x"
        x1={x}
        x2={x}
        y1={0}
        y2={height}
        opacity={visible ? 1 : 0}
      />
      <line
        className="crosshair-line-y"
        x1={0}
        x2={width}
        y1={y}
        y2={y}
        opacity={visible ? 1 : 0}
      />
      <text className="crosshair-text" x={x + 3} y={y - 4} opacity={visible && !dotHover ? 1 : 0}>
        {isNaN(mouseX) || isNaN(mouseY) ? "" : `${mouseX.toFixed(2)}, ${mouseY.toFixed(2)}`}
      </text>
    </g>
  );
};

export default Crosshair;
