import { useContext } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { __RouterContext } from "react-router";
import { useStore } from "../store";

export const useInsights = () => {
  const { history } = useContext(__RouterContext);
  const [state] = useStore();

  const configureInsights = (instrumentationKey: string) => {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history }
        }
      }
    });

    appInsights.loadAppInsights();
  };

  const setupInsights = () => {
    if (state.settings) {
      if (state.settings.ApplicationInsightsInstrumentationKeyWeb) {
        configureInsights(state.settings.ApplicationInsightsInstrumentationKeyWeb);
      }
    }
  };

  return { setupInsights };
};
