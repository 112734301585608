import React, { useRef } from "react";

interface Props {
  width: number;
  height: number;
  onMouseMove: Function;
  onMouseLeave: Function;
  onClick: Function;
}

const MouseArea = ({ width, height, onMouseMove, onMouseLeave, onClick }: Props) => {
  const svgRef = useRef<SVGRectElement>(null);
  return (
    <rect
      ref={svgRef}
      onMouseMove={(event: React.MouseEvent) => {
        onMouseMove(event, svgRef);
      }}
      onMouseLeave={(event: React.MouseEvent) => onMouseLeave(event)}
      className="mouse-area"
      width={width}
      height={height > 0 ? height : 0}
      fill="blue"
      opacity="0"
      pointerEvents="all"
      onClick={(event: React.MouseEvent) => {
        onClick(event, svgRef);
      }}
    ></rect>
  );
};

export default MouseArea;
