import React, { useState } from "react";
import * as d3 from "d3";
import cx from "classnames";
import IGauge from "./IGauge";

const Gauge = ({ value, dataSettings }: IGauge) => {
  const decimals = dataSettings.decimals !== undefined ? dataSettings.decimals : false;

  const min = dataSettings.min !== undefined ? dataSettings.min : 0;
  const max = dataSettings.max !== undefined ? dataSettings.max : 0;
  const units = dataSettings.units !== undefined ? dataSettings.units : "%";
  const getScale = (size: string) => {
    switch (size) {
      case "small":
        return 0.5;
      case "medium":
        return 0.75;
      case "large":
        return 1;
      default:
        return 0.75;
    }
  };
  const scale = dataSettings.size !== undefined ? getScale(dataSettings.size) : 0.75;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipVisibleIndex, setTooltipVisibleIndex] = useState(-1);

  interface TooltipData {
    left: number;
    top: number;
  }
  const getInitialTooltipsPositions = () => {
    const arrtooltipPositions = [] as TooltipData[];
    if (dataSettings.limits !== undefined) {
      for (let i = 0; i < dataSettings.limits.length; i += 1) {
        arrtooltipPositions.push({
          left: 0,
          top: 0
        });
      }
    }
    return arrtooltipPositions;
  };
  const [tooltipPositions, setTooltipPositions] = useState(getInitialTooltipsPositions);

  const val2angle = (v: number) => {
    const createScale = d3.scaleLinear().domain([min, max]).range([-135, 135]);
    return createScale(v);
  };
  const getArcColor = () => {
    let currentColor = "";
    if (dataSettings.limits !== undefined) {
      for (let i = 0; i < dataSettings.limits.length; i += 1) {
        if (value >= dataSettings.limits[i].from) {
          currentColor = dataSettings.limits[i].color;
        }
      }
    }
    return !(currentColor === "unknown" || currentColor === "transparent") ? currentColor : "";
  };
  interface ArcOptions {
    arc: {
      radius: { inner: number; outer: number };
      angle: {
        start: number;
        end: number;
      };
      scale: number;
    };
    props: {
      class: string;
      opacity?: number;
      color?: string;
    };
  }
  const drawArc = (
    _options: ArcOptions,
    onMouseEnter?: { (): void; (): void } | undefined,
    onMouseLeave?: { (): void; (): void } | undefined,
    onMouseMove?:
      | {
          (evt: React.MouseEvent<Element, MouseEvent>): void;
          (arg0: React.MouseEvent<SVGPathElement, MouseEvent>): void;
        }
      | undefined
  ) => {
    const createArc = (arcOptions: ArcOptions) => {
      const s = arcOptions.arc.scale || 0.75;
      const arc = d3.arc();
      const createdArc = arc({
        innerRadius: arcOptions.arc.radius.inner * s,
        outerRadius: arcOptions.arc.radius.outer * s,
        startAngle: arcOptions.arc.angle.start * (Math.PI / 180),
        endAngle: arcOptions.arc.angle.end * (Math.PI / 180)
      });
      return createdArc != null ? createdArc : undefined;
    };
    return (
      <path
        key={Math.random() * 100}
        className={_options.props.class}
        d={createArc(_options)}
        opacity={_options.props.opacity}
        onMouseEnter={() => (onMouseEnter !== undefined ? onMouseEnter() : null)}
        onMouseLeave={() => (onMouseLeave !== undefined ? onMouseLeave() : null)}
        onMouseMove={evt => (onMouseMove !== undefined ? onMouseMove(evt) : null)}
      />
    );
  };
  interface LabelData {
    message: string | number;
    class: string;
    x: number;
    y: number;
    fontSize: number;
    scale: number;
  }
  const drawLabel = (_options: LabelData, showGray = false) => {
    return (
      <g
        transform={`translate(${_options.x * _options.scale || 0}, ${
          _options.y * _options.scale || 0
        })`}
      >
        <text
          className={cx("total", { label: showGray })}
          style={{ fontSize: `${_options.fontSize * _options.scale}px` }}
        >
          {_options.message}
        </text>
      </g>
    );
  };
  const drawChart = () => {
    const gScale = scale;
    const frequency = 275 / 37;
    const length = 5;
    const negativeArcs = [];
    for (let i = -140; i < 135; i += frequency) {
      negativeArcs.push(
        drawArc({
          arc: {
            radius: { inner: 94, outer: 103 },
            angle: { start: i, end: i + length },
            scale: gScale
          },
          props: {
            class: "negativeArc"
          }
        })
      );
    }
    const limits = [];
    // eslint-disable-next-line
    if (dataSettings.limits !== undefined) {
      // eslint-disable-next-line
      for (let i = 0; i < dataSettings.limits.length; i += 1) {
        const onMouseEnter = (index: number) => {
          setTooltipVisible(true);
          setTooltipVisibleIndex(index);
        };
        const onMouseLeave = () => {
          setTooltipVisible(false);
        };
        const onMouseMove = (evt: React.MouseEvent, index: number) => {
          const newTooltopPositions = tooltipPositions.slice();
          const tooltipPosition = newTooltopPositions[index];
          tooltipPosition.left = evt.clientX;
          tooltipPosition.top = evt.clientY - 40;
          setTooltipPositions(newTooltopPositions);
        };

        const limitArc = drawArc({
          arc: {
            radius: { inner: 82, outer: 87 },
            angle: {
              // eslint-disable-next-line

              start: val2angle(dataSettings.limits[i].from) + 1,
              // eslint-disable-next-line

              end: val2angle(dataSettings.limits[i].to) - 1
            },
            scale: gScale
          },
          props: {
            // eslint-disable-next-line
            class: dataSettings.limits[i].color,
            opacity: tooltipVisible && tooltipVisibleIndex === i ? 1 : 0.3
          }
        });
        const limitHoverArc = drawArc(
          {
            arc: {
              radius: { inner: 72, outer: 117 },
              angle: {
                // eslint-disable-next-line

                start: val2angle(dataSettings.limits[i].from) + 1,
                // eslint-disable-next-line

                end: val2angle(dataSettings.limits[i].to) - 1
              },
              scale: gScale
            },
            props: {
              class: "transparent"
            }
          },
          () => onMouseEnter(i),
          () => onMouseLeave(),
          (evt: React.MouseEvent) => onMouseMove(evt, i)
        );
        limits.push(limitArc);
        limits.push(limitHoverArc);
      }
    }
    const foregroundArcOptions = {
      arc: {
        radius: { inner: 95, outer: 102 },
        angle: { start: -135, end: 135 },
        scale: gScale
      },
      props: {
        class: "foregroundArc"
      }
    };

    const valueArcColor = getArcColor();
    const valueArcOptions = {
      arc: {
        radius: { inner: 95, outer: 102 },
        angle: { start: -135, end: val2angle(value) },
        scale: gScale
      },
      props: {
        class: valueArcColor === "" ? "valueArc" : valueArcColor
      }
    };
    const labelUnits = {
      message: units,
      class: "units",
      x: 0,
      y: 40,
      fontSize: 28,
      scale: gScale
    };
    const labelLeft = {
      message: min,
      class: "label left",
      x: -65,
      y: 110,
      fontSize: 24,
      scale: gScale
    };
    const labelRight = {
      message: max,
      class: "label right",
      x: 65,
      y: 110,
      fontSize: 24,
      scale: gScale
    };
    const labelTotal = {
      // eslint-disable-next-line
      message: decimals ? value.toFixed(decimals) : value,
      class: "total",
      x: 0,
      y: 0,
      fontSize: 60,
      scale: gScale
    };
    return (
      <svg width={250 * scale} height={250 * gScale}>
        <g transform={`translate(${125 * gScale}, ${125 * gScale})`}>
          {drawArc(foregroundArcOptions)}
          {drawArc(valueArcOptions)}
          {negativeArcs}
          {limits}
          {drawLabel(labelUnits)}
          {drawLabel(labelLeft, true)}
          {drawLabel(labelRight, true)}
          {drawLabel(labelTotal)}
        </g>
      </svg>
    );
  };

  const tooltips = [];
  if (dataSettings.limits !== undefined) {
    for (let i = 0; i < dataSettings.limits.length; i += 1) {
      tooltips.push(
        <div
          key={Math.random() * 100}
          className={cx("tooltip", {
            hidden: !(tooltipVisible && tooltipVisibleIndex === i)
          })}
          style={{
            left: tooltipPositions[i].left,
            top: tooltipPositions[i].top,
            pointerEvents: "none"
          }}
        >
          {dataSettings.limits[i].label}
        </div>
      );
    }
  }

  return (
    <div className="eds-gauge" style={{ height: `${250 * scale}px`, width: `${250 * scale}px` }}>
      {drawChart()}
      {tooltips}
    </div>
  );
};
export default Gauge;
