import * as d3 from "d3";
import React, { useState, useRef, useEffect, useCallback } from "react";

import { Data, Serie, TooltipItem } from "../common/interfaces";
import * as utils from "../common/helpers";
import AxisTresholds from "../common/components/AxisTresholds";
import VerticalBarGroups from "../common/components/VerticalBarGroups";
import Legend from "../common/components/Legend";
import Tooltip from "../common/components/Tooltip";
import Unit from "../common/components/Unit";
import IVerticalBarChart from "./IVerticalBarChart";
import AxisLeft from "../common/components/AxisLeft";

const VerticalBarChart = ({
  data,
  thresholds,
  unit,
  width = 754,
  height = 351,
  onToggle,
  grouped,
  stacked,
  largeBars,
  single = (!grouped && !stacked) || data.series.length === 1,
  fill = false,
  onClick,
  hideLegend = false,
  format,
  tooltipFormat,
  parentRef,
  invertColorScheme = false,
  hideStandardTooltipItems = false
}: IVerticalBarChart) => {
  const errorMsg = utils.checkData(data); // Check if data is correct
  if (errorMsg !== "") {
    throw new Error(errorMsg);
  }

  if (data.series.length > 1 && single) {
    throw new Error("Data with multiple series needs grouped or stacked as property.");
  }

  const [initialized, setInitialized] = useState(false);
  const axisPadding = 8;
  const fontSize = 14;

  const ref = useRef<HTMLDivElement>(null);
  const legendRef = useRef<HTMLDivElement>(null);

  // chart-legend
  const heightOffset = 60;
  const defaultLegendHeight = hideLegend ? 0 : 55;
  const chartLegendHeight =
    legendRef !== null && legendRef.current !== null
      ? legendRef.current.clientHeight
      : defaultLegendHeight;

  const [Height, setHeight] = useState(height);
  const [Width, setWidth] = useState(width);
  const [emptyChart, setEmptyChart] = useState(false);

  useEffect(() => {
    const clientWidth = ref !== null && ref.current !== null ? ref.current.clientWidth : 0;
    const clientHeight = ref !== null && ref.current !== null ? ref.current.clientHeight : 0;

    setWidth(clientWidth);
    setHeight(clientHeight - chartLegendHeight);
  });

  // Windowresize listener
  const [, updateState] = React.useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("toggleNavigation", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("toggleNavigation", handleResize);
    };
  }, []);

  const colorScale = utils.getColorScale(data.series.length, undefined, invertColorScheme);

  const [hoveredSerie, setHoveredSerie] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipData, setTooltipData] = useState({
    unitTitle: "",
    unitValue: "",
    items: [] as TooltipItem[],
    description: "" as string | JSX.Element
  });

  const [allHidden, setAllHidden] = useState(false);
  const [totalValues, setTotalValues] = useState([] as number[]);
  const [chartData, setChartData] = useState({} as unknown as Data);

  useEffect(() => {
    const newChartData = Object.assign({}, data);

    if (newChartData.series[0].values.length === 0 && newChartData.common.length === 0) {
      newChartData.common = [""];
      newChartData.series = [
        {
          name: "",
          values: [0, 1, 2, 3, 4]
        }
      ];
      setEmptyChart(true);
    } else {
      setEmptyChart(false);
    }
    const willHideAll = data.series.filter((s: Serie) => s.hidden).length === data.series.length;

    newChartData.series = newChartData.series.filter((s: Serie) => !s.hidden);

    const newTotalValues = [] as number[];

    if (willHideAll) {
      data.series.forEach((serie: Serie) => {
        serie.values.forEach((number: number) => {
          newTotalValues.push(number);
        });
      });
    } else {
      newChartData.series.forEach((serie: Serie) => {
        serie.values.forEach((number: number) => {
          newTotalValues.push(number);
        });
      });
    }
    setAllHidden(willHideAll);
    setTotalValues(newTotalValues);
    setChartData(newChartData);
    setInitialized(true);
  }, [data, data.series, allHidden]);

  const stackedChartData = {};
  if (initialized) {
    chartData.common.forEach((label, i) => {
      const prevValues = [] as number[];
      const dataForLabel = chartData.series.map((s, k) => {
        const getPrevious = () => {
          if (k === 0) {
            return 0;
          }
          prevValues.push(chartData.series[k - 1].values[i]);

          return prevValues.reduce((a, b) => a + b);
        };
        return {
          key: s.name,
          value: s.values[i],
          previous: getPrevious()
        };
      });

      stackedChartData[label] = dataForLabel;
    });
  }

  const yMin = Math.min(...totalValues) > 0 ? 0 : Math.min(...totalValues);
  const maxValFromTotalValues = Math.max(...totalValues) <= 0 ? 0 : Math.max(...totalValues);
  const yMax = !stacked ? maxValFromTotalValues : utils.getMaxValueFromChartData(data, allHidden);

  const xScale0 = d3
    .scaleBand()
    .domain(initialized ? chartData.common : [""])
    .rangeRound([0, Width - 50])
    .paddingInner(0.7)
    .paddingOuter(0.3);

  const xScale1 = d3
    .scaleBand()
    .domain(initialized ? chartData.series.map(d => d.name) : [""])
    .rangeRound([-xScale0.bandwidth(), xScale0.bandwidth()])
    .padding(1);

  const yScale = d3
    .scaleLinear()
    .domain([yMin, yMax])
    .nice()
    .range([Height - heightOffset, 0]);

  const colors = {};
  data.series.forEach(d => {
    colors[d.name] = colorScale(d.name);
  });

  const heightStyling = fill
    ? { height: "100%", width: "100%" }
    : { height: `${height + chartLegendHeight}px`, width };

  const sizeFromParentRef =
    parentRef && parentRef.current
      ? {
          height: `${parentRef.current.clientHeight}px`,
          width: `${parentRef.current.clientWidth}px`
        }
      : { height: "100%", width: "100%", display: "flex" };
  return initialized ? (
    <div className="chart-container" style={heightStyling}>
      {!hideLegend && (
        <div ref={legendRef}>
          <Legend
            series={data.series}
            colorScale={colors}
            onMouseHover={(s: string) => {
              setHoveredSerie(s);
            }}
            onClick={(i: number) => {
              if (onToggle) {
                const newData = utils.jsonClone(data) as Data;
                newData.series[i].hidden = !newData.series[i].hidden;
                onToggle(newData, i);
                setHoveredSerie("");
              }
            }}
          />
        </div>
      )}
      <div ref={ref} style={!fill ? { height: "100%", display: "flex" } : sizeFromParentRef}>
        <svg className="chart" style={{ height: "100%", width: "100%", display: "flex" }}>
          {unit && (
            <g transform="translate(15,15)" className="canvas bar-chart">
              <Unit x={0} y={0} unit={unit} textAnchor="start" />
            </g>
          )}
          <g transform={`translate(45,${unit ? "35" : "20"})`} className="canvas bar-chart">
            <AxisLeft
              yScale={yScale}
              tickSize={-(Width - heightOffset)}
              tickPadding={axisPadding}
              ticks={Height / 80}
              // formatType="default"
              tickFormat={format !== undefined ? format : ""}
              hideValues={emptyChart}
            />
            {!emptyChart && (
              <AxisTresholds
                left
                yScale={yScale}
                tickSize={Width - (fill ? heightOffset : heightOffset * 1.5)}
                tickPadding={axisPadding}
                thresholds={thresholds}
                tickFormat={format !== undefined ? format : ""}
                fontSize={fontSize}
                innerTicks={fill}
              />
            )}
            {!emptyChart && (
              <VerticalBarGroups
                data={chartData}
                thresholds={thresholds}
                x0={xScale0}
                x1={xScale1}
                y={yScale}
                single={single}
                grouped={grouped}
                stacked={stacked}
                largeBars={largeBars}
                colorScale={colors}
                height={!fill ? Height : Height - heightOffset}
                hoveredSerie={hoveredSerie}
                tooltipFormat={tooltipFormat}
                onHover={(
                  e: React.MouseEvent<SVGLineElement, MouseEvent>,
                  label: string,
                  tooltipItems: TooltipItem[],
                  index: number
                ) => {
                  if (e !== undefined) {
                    setTooltipVisible(true);
                    setTooltipData({
                      unitTitle: label,
                      unitValue: unit !== undefined ? unit : "",
                      items: tooltipItems,
                      description:
                        data.descriptions !== undefined && index < data.descriptions.length
                          ? data.descriptions[index]
                          : ""
                    });
                    setTooltipPosition({ x: e.clientX, y: e.clientY });
                  } else {
                    setTooltipVisible(false);
                  }
                }}
                onClickProvided={onClick !== undefined}
                onClick={(l: string, v: number[]) => onClick && onClick(l, v)}
              />
            )}
          </g>
        </svg>
        <Tooltip
          colors={colors}
          unitTitle={tooltipData.unitTitle}
          unitValue={tooltipData.unitValue}
          description={tooltipData.description}
          items={tooltipData.items}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          visible={(!single || data.descriptions !== undefined) && tooltipVisible}
          hideStandardItems={hideStandardTooltipItems}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default VerticalBarChart;
