import {
  apiReadingOff,
  apiReadingOn,
  apiWritingOff,
  apiWritingOn,
  INotificationItem,
  IUserCustomerNotificationItem,
  useStore
} from "../store";
import { useApi } from "./api-factory";

export const useNotificationActions = () => {
  const [_, setState] = useStore();
  const { api } = useApi();
  const [state] = useStore();
  const getAdminCustomerNotificationsList = async (customerId: string) => {
    try {
      setState(old => apiReadingOn(old, f => f.notifications));
      const notifications = await api.get(`v1/settings/notifications/${customerId}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.notifications),
        notifications: notifications
      }));
      return notifications;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.notifications));
    }
  };

  const updateAdminCustomerNotificationsList = async (
    customerId: string,
    notifications: INotificationItem[]
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => apiReadingOn(old, f => f.notifications));
      setState(old => setApiWritingOn(old, notifications));
      await api.put(`v1/Settings/notifications/${customerId}`, notifications);
      setState(old => apiWritingOff(id, old));
      setState(old => ({
        ...apiReadingOff(old, f => f.notifications),
        notifications: notifications
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      setState(old => apiReadingOff(old, f => f.notifications));
    }
  };

  const getUserCustomerNotificationsList = async (customerId: string) => {
    try {
      const id = customerId;
      setState(old => apiReadingOn(old, f => f.userCustomerNotifications));
      const notifications = await api.get("v1/settings/notifications/user-settings/");
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerNotifications),
        userCustomernotifications: notifications
      }));
      return notifications;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userCustomerNotifications));
    }
  };

  const updateUserCustomerNotificationsList = async (
    customerId: string,
    notifications: IUserCustomerNotificationItem[]
  ) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => apiReadingOn(old, f => f.userCustomerNotifications));
      setState(old => setApiWritingOn(old, notifications));
      await api.put("v1/Settings/notifications/user-settings/", notifications);
      setState(old => apiWritingOff(id, old));
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerNotifications),
        userCustomerNotifications: notifications
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      setState(old => apiReadingOff(old, f => f.userCustommerNotifications));
    }
  };

  const updateUserCustomerNotificationsEnabled = async (customerId: string, value: boolean) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => apiReadingOn(old, f => f.userCustomerNotificationsEnabled));
      setState(old => setApiWritingOn(old, customerId));
      await api.put("v1/Settings/notifications/user-customer/", value);
      setState(old => apiWritingOff(id, old));
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerNotificationsEnabled),
        userCustomerNotificationsEnabled: value
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      setState(old => apiReadingOff(old, f => f.userCustomerNotificationsEnabled));
    }
  };

  const getUserCustomerNotificationsEnabled = async (customerId: string) => {
    try {
      const id = customerId;
      setState(old => apiReadingOn(old, f => f.userCustomerNotificationsEnabled));
      const notificationsEnabled = await api.get("v1/settings/notifications/user-customer/");
      setState(old => ({
        ...apiReadingOff(old, f => f.userCustomerNotificationsEnabled),
        userCustomerNotificationsEnabled: notificationsEnabled
      }));
      return notificationsEnabled;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userCustomerNotificationsEnabled));
    }
  };
  return {
    getAdminCustomerNotificationsList,
    updateAdminCustomerNotificationsList,
    updateUserCustomerNotificationsList,
    getUserCustomerNotificationsList,
    updateUserCustomerNotificationsEnabled,
    getUserCustomerNotificationsEnabled
  };
};
