import React from "react";
import { PieData } from "./interfaces";

interface Props {
  data: PieData;
  arc: any;
  name: string;
  onHover: Function;
  onClick: Function;
}

const HoverArc = ({ data, name, arc, onHover, onClick }: Props) => {
  return (
    <path
      className="transparent"
      d={arc(data)}
      onMouseEnter={() => {
        onHover(name);
      }}
      onMouseLeave={() => {
        onHover("");
      }}
      onMouseMove={() => {
        onHover(name);
      }}
      onClick={() => {
        onClick(data.data.name, data.value);
      }}
    />
  );
};

export default HoverArc;
