import { useEffect, useState } from "react";
import { Dropdown, IDropdownItem, Icon, SettingsItem, Switch, Tooltip } from "@react-gcc-eds/core";
import { useCustomerActions } from "../../actions/customer-actions";
import { useSettingsActions } from "../../actions/settings-actions";
import { INotificationAdminItem, IScheduledExportAdminItem, useStore } from "../../store";
import { useUserHelpers } from "../../utils/user-helpers";

const Settings = ({
  onNewCustomerPressed,
  onVirtualRoleChanged,
  onDateFormatChanged,
  onUserPrefChanged,
  onShowNotificationsDialog,
  onUserNotificationsEnabled,
  notificationsEnabled,
  scheduledExportsEnabled,
  onShowScheduledExportsDialog,
  onUserScheduledExportsEnabled
}: {
  onNewCustomerPressed(): void;
  onVirtualRoleChanged(role: number | null): void;
  onDateFormatChanged(format: string): void;
  onUserPrefChanged(prop: string, status: boolean): void;
  onShowNotificationsDialog: () => void;
  onUserNotificationsEnabled: (customerId: string, value: boolean) => void;
  notificationsEnabled: boolean;
  scheduledExportsEnabled: boolean;
  onShowScheduledExportsDialog: () => void;
  onUserScheduledExportsEnabled: (customerId: string, value: boolean) => void;
}) => {
  const [state] = useStore();
  const [notificationActive, setNotificationActive] = useState<boolean>(false);
  const [notificationRole, setNotificationRole] = useState<boolean>(false);
  const [scheduledExportActive, setScheduledExportActive] = useState<boolean>(false);
  const [scheduledExportRole, setScheduledExportRole] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<INotificationAdminItem[]>([]);
  const [scheduledExports, setScheduledExports] = useState<IScheduledExportAdminItem[]>([]);
  const [hasLength, setHasLength] = useState<boolean>(false);
  const {
    userRoleIsSuperUser,
    userRoleIsAdministrator,
    userRoleIsTechSupport,
    userRoleIsInternal,
    userVirtualRoleName
  } = useUserHelpers();
  const { getCustomerNotifications } = useCustomerActions();
  const { getCustomerScheduledExports } = useCustomerActions();
  const { currentCustomer } = useSettingsActions();
  const isInternal = state.user && userRoleIsInternal(state.user.role);
  const isAdministrator =
    state.user &&
    (userRoleIsSuperUser(state.user.role) ||
      userRoleIsAdministrator(state.user.role) ||
      userRoleIsTechSupport(state.user.role));

  const virtualRole = state.user?.virtualRole || null;
  const dateFormat = state.user?.dateFormat;

  const getNotifications = async (customerId?: string) => {
    if (customerId) {
      const notifications = await getCustomerNotifications(customerId);
      setNotifications(notifications);
    }
  };

  useEffect(() => {
    isAdministrator && getNotifications(currentCustomer().id);
  }, [state.notifications]);

  useEffect(() => {
    const currentUser = state.user;
    const customer = currentCustomer();
    const notificationItem = state.customerNotifications[0];
    if (notificationItem != undefined) {
      setNotificationActive(notificationItem.visible);
      notificationItem.roles.length === 0
        ? setNotificationRole(false)
        : currentUser &&
          setNotificationRole(notificationItem.roles.some(x => x === currentUser.role));
    }
  }, [state.customerNotifications]);

  const notificationLinkActive = () => {
    return state.notifications.find(x => x.visible);
  };

  const getScheduledExports = async (customerId?: string) => {
    if (customerId) {
      const scheduledExports = await getCustomerScheduledExports(customerId);
      setScheduledExports(scheduledExports);
    }
  };

  useEffect(() => {
    isAdministrator && getScheduledExports(currentCustomer().id);
  }, [state.scheduledExports]);

  useEffect(() => {
    const currentUser = state.user;
    const customer = currentCustomer();
    const scheduledExportItem = state.customerScheduledExports.find(
      x => x.customerId == customer.id
    );
    if (scheduledExportItem != undefined) {
      setScheduledExportActive(scheduledExportItem.visible);
      scheduledExportItem.roles.length === 0
        ? setScheduledExportRole(false)
        : currentUser &&
          setScheduledExportRole(scheduledExportItem.roles.some(x => x === currentUser.role));
    }
  }, [state.customerScheduledExports]);

  const scheduledExportLinkActive = () => {
    return state.userCustomerScheduledExportsEnabled;
  };

  useEffect(() => {
    const hasLength = state.notifications.find(x => x.visible);
    if (hasLength != undefined) {
      setHasLength(true);
    } else {
      setHasLength(false);
    }
  }, [state.notifications]);
  const handleNotificationsEnabled = (prop: string, customerId: string, value: boolean) => {
    // onUserPrefChanged(prop, value);
    customerId && onUserNotificationsEnabled(customerId, value);
  };

  const handleScheduledExportsEnabled = (prop: string, customerId: string, value: boolean) => {
    // onUserPrefChanged(prop, value);
    customerId && onUserScheduledExportsEnabled(customerId, value);
  };
  return (
    <>
      {isInternal && (
        <SettingsItem text="Show technical tooltips" className="user-pref-item">
          <Switch
            onLabel="On"
            offLabel="Off"
            value={state.userPreferences.technicalTooltips}
            onChange={(v: boolean) => onUserPrefChanged("technicalTooltips", v)}
          />
        </SettingsItem>
      )}
      {state.userDateFormats.some(f => f) && (
        <SettingsItem text="Date format" className="user-pref-item date-format">
          <Dropdown
            label={
              dateFormat
                ? (state.userDateFormats.find(f => f.format === dateFormat) || { description: "" })
                    .description
                : "Select one"
            }
            items={[...state.userDateFormats.map(r => ({ title: r.description, value: r.format }))]}
            onItemClick={(r: IDropdownItem) => {
              onDateFormatChanged(r.value);
            }}
          />
        </SettingsItem>
      )}
      {isAdministrator && (
        <SettingsItem text="Virtual role" className="user-pref-item virtual-role">
          <Dropdown
            label={virtualRole !== null ? userVirtualRoleName(virtualRole) : "None"}
            items={[
              { title: "None", value: "" },
              ...state.userVirtualRoles.map(r => ({ title: r.roleName, value: r.value.toString() }))
            ]}
            onItemClick={(r: IDropdownItem) => {
              onVirtualRoleChanged(Number(r.value));
            }}
          />
        </SettingsItem>
      )}
      {notificationActive && (isAdministrator || notificationRole) && (
        <>
          <div className="title">Notifications</div>
          <SettingsItem text="" className="user-pref-item notifications">
            <div className="left">
              <span
                className="dotted-link"
                onClick={
                  notificationLinkActive() && notificationsEnabled
                    ? onShowNotificationsDialog
                    : () => false
                }
              >
                Notifications
              </span>
            </div>
            <div className="right">
              <Switch
                onLabel="On"
                offLabel="Off"
                value={state.userCustomerNotificationsEnabled}
                onChange={(v: boolean) =>
                  state.customer &&
                  handleNotificationsEnabled("notifications", state.customer?.id, v)
                }
              />
            </div>
          </SettingsItem>
        </>
      )}
      {scheduledExportActive && (isAdministrator || scheduledExportRole) && (
        <>
          <div className="title">Scheduled Exports</div>
          <SettingsItem text="" className="user-pref-item scheduledExports">
            <div className="left">
              <span
                className="dotted-link"
                onClick={
                  scheduledExportLinkActive() && scheduledExportsEnabled
                    ? onShowScheduledExportsDialog
                    : () => false
                }
              >
                Scheduled Exports
              </span>
            </div>
            <div className="right">
              <Switch
                onLabel="On"
                offLabel="Off"
                value={state.userCustomerScheduledExportsEnabled}
                onChange={(v: boolean) =>
                  state.customer &&
                  handleScheduledExportsEnabled("scheduledExports", state.customer?.id, v)
                }
              />
            </div>
          </SettingsItem>
        </>
      )}
      <div className="item user-pref-item add-access-btn">
        <Tooltip text="New customer access request" type="pointer" position="left">
          <button type="button" className="btn big" onClick={onNewCustomerPressed}>
            <Icon name="plus" />
            Add access to other customers
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default Settings;
