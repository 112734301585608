import { defaultInternalOrdersFilters, IPillFilter, useStore } from "../store";
import { IDateRange } from "../contracts";

export interface IInternalOrdersRedirectionFilters {
  orderCreationRange?: IDateRange;
  status?: number;
}

export const useInternalOrdersFiltersActions = () => {
  const [_, setState] = useStore();

  const setCustomSelectionFilter = ({
    filterAccessor,
    newValues
  }: {
    filterAccessor: string;
    newValues: IPillFilter[];
  }) => {
    setState(old => ({
      ...old,
      internalOrdersFilters: {
        ...old.internalOrdersFilters,
        [filterAccessor]: newValues
      }
    }));
  };

  const setInternalLocationStatusFilter = (newValue: number) => {
    setState(old => ({
      ...old,
      internalOrdersFilters: {
        ...old.internalOrdersFilters,
        locationStatuses: (old.internalOrdersFilters.locationStatuses || []).some(
          status => status === newValue
        )
          ? (old.internalOrdersFilters.locationStatuses || []).filter(s => s !== newValue)
          : [...(old.internalOrdersFilters.locationStatuses || []), newValue]
      }
    }));
  };

  const setInternalDeliveryStatusFilter = (newValue: number) => {
    setState(old => ({
      ...old,
      internalOrdersFilters: {
        ...old.internalOrdersFilters,
        deliveryStatuses: (old.internalOrdersFilters.deliveryStatuses || []).some(
          status => status === newValue
        )
          ? (old.internalOrdersFilters.deliveryStatuses || []).filter(s => s !== newValue)
          : [...(old.internalOrdersFilters.deliveryStatuses || []), newValue]
      }
    }));
  };

  const setInternalFilter = (accessor: string, newValue: IDateRange | number[] | IPillFilter[]) => {
    setState(old => ({
      ...old,
      internalOrdersFilters: {
        ...old.internalOrdersFilters,
        [accessor]: newValue
      }
    }));
  };

  const clearInternalFilter = (accessor: string) => {
    setState(old => ({
      ...old,
      internalOrdersFilters: {
        ...old.internalOrdersFilters,
        [accessor]: defaultInternalOrdersFilters[accessor]
      }
    }));
  };

  const setInternalOrdersRedirectionFilters = (filters: IInternalOrdersRedirectionFilters) => {
    const { orderCreationRange, status } = filters;
    setState(old => ({
      ...old,
      internalOrdersFilters: {
        ...old.internalOrdersFilters,
        orderCreationRange,
        deliveryStatuses: status === undefined ? undefined : [status]
      }
    }));
  };

  return {
    setCustomSelectionFilter,
    setInternalLocationStatusFilter,
    setInternalDeliveryStatusFilter,
    setInternalFilter,
    setInternalOrdersRedirectionFilters,
    clearInternalFilter
  };
};
