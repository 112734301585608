import React, { useState } from "react";
import { HistogramData as Data, Serie, Colors, Thresholds } from "../interfaces";
import * as utils from "../helpers";
import HistogramGroup from "./HistogramGroup";

interface Props {
  data: Data;
  bins: any;
  hiddenBins: number[];
  xScale: any;
  yScale: any;
  colorScale: Colors;
  height: number;
  onHover: Function;
  onClick: Function;
  tooltipFormat?: string;
  thresholds?: Thresholds[];
  hoveredSerie: string;
}

interface DataForBin {
  name: string;
  histogram: any;
}

const HistogramGroups = ({
  data,
  bins,
  hiddenBins,
  xScale,
  yScale,
  colorScale,
  height,
  onHover,
  onClick,
  tooltipFormat,
  thresholds,
  hoveredSerie
}: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const defaultOpacity = hoveredIndex === -1 && hoveredSerie === "" ? 1 : 0.2;

  const dataForBin =
    bins.length > 0
      ? bins[0].map((_: Serie, i: number) => {
          const histogramSeries = [] as DataForBin[];
          bins.forEach((bin: any, k: number) => {
            if (!hiddenBins.includes(k)) {
              histogramSeries.push({
                name: data.series[k].name,
                histogram: bin[i]
              });
            }
          });

          return histogramSeries;
        })
      : [];

  return dataForBin.map((bin: any, i: number) => {
    return bin.map((binData: any, j: number) => {
      const xScaleVal =
        xScale(binData.histogram.x1) -
        xScale(binData.histogram.x0) -
        (bins.length - hiddenBins.length) * 5;
      const barWidth = xScaleVal + 1;
      const barHeight = yScale(0) - yScale(binData.histogram.length);
      const x = j * 6;
      const y = yScale(binData.histogram.length);
      const hoverWidth = xScale(binData.histogram.x1) - xScale(binData.histogram.x0);
      const key = i + j;

      return (
        <HistogramGroup
          label={binData.name}
          value={binData.histogram.length}
          key={key}
          xPos={xScale(binData.histogram.x0)}
          x={x}
          y={y}
          height={barHeight}
          width={barWidth}
          hoveredSerie={hoveredSerie}
          opacity={hoveredIndex === i || hoveredSerie === binData.name ? 1 : defaultOpacity}
          hoverWidth={hoverWidth}
          hoverHeight={height}
          thresholds={thresholds}
          colorScale={colorScale}
          onMouseHover={(isHovered: boolean, e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
            if (isHovered) {
              setHoveredIndex(i);
            } else {
              setHoveredIndex(-1);
            }

            const items = data.series
              .filter((d: Serie) => !d.hidden)
              .map((d: Serie, k: number) => {
                return {
                  key: d.name,
                  value: utils.d3Format(
                    tooltipFormat !== undefined ? tooltipFormat : "Default",
                    bin[k].histogram.length
                  )
                };
              });
            const label = `[${binData.histogram.x0},${binData.histogram.x1}]`;
            onHover(e, label, items, i, isHovered);
          }}
          onClick={() => {
            const label = `[${binData.histogram.x0},${binData.histogram.x1}]`;
            const value = binData.histogram.length;
            onClick(label, value);
          }}
        />
      );
    });
  });
};
export default HistogramGroups;
