import React, { RefObject, useEffect, useState } from "react";

export interface ITab {
  title: string;
  view?: JSX.Element;
  onSelected?: Function;
  index: number;
  ref?: RefObject<HTMLDivElement>;
}

const CustomTabs = ({
  tabs,
  defaultTabIndex,
  onTabChange
}: {
  tabs: ITab[];
  defaultTabIndex?: number;
  onTabChange?(tab: ITab): void;
}) => {
  const [currTab, setCurrTab] = useState<ITab>();

  useEffect(() => {
    if (tabs.length > 0) {
      if (currTab) {
        setCurrTab(tabs.find(t => t.index === currTab.index));
      } else {
        const tab = defaultTabIndex ? tabs.find(t => t.index === defaultTabIndex) : undefined;
        setCurrTab(tab || tabs[0]);
      }
    }
  }, [tabs]);

  useEffect(() => {
    if (onTabChange && currTab) {
      onTabChange(currTab);
    }
  }, [currTab]);

  return (
    <div className="custom-tabs-container">
      <div className="custom-tabs-buttons-container">
        <div className="custom-tabs-buttons">
          {tabs.map((tab: ITab, i: number) => {
            return (
              <div
                ref={tab.ref}
                key={`tabs-${i}`}
                className={`tabs-button ${
                  currTab && currTab.title === tab.title ? "selected" : ""
                }`}
                onClick={() => setCurrTab(tab)}
              >
                {tab.title}
              </div>
            );
          })}
        </div>
      </div>
      {currTab && currTab.view}
    </div>
  );
};

export default CustomTabs;
