import React from "react";

import { Icon, Tooltip, useSettings } from "@react-gcc-eds/core";
import { IDateRange } from "../../../contracts";
import cx from "classnames";
import { usePredefinedRanges } from "./predefined-ranges";

const Header = ({
  tooltip,
  onClick,
  onRangeSelected,
  selectedRange,
  oldestDate
}: {
  tooltip: string;
  onClick(): void;
  onRangeSelected(r: IDateRange): void;
  selectedRange?: IDateRange;
  oldestDate?: Date;
}) => {
  const { previousPredefinedRange, rangeAsString, nextPredefinedRange } =
    usePredefinedRanges(oldestDate);
  const { theme } = useSettings();
  return (
    <div className={cx("date-picker-header", theme)}>
      <div className={cx("date-picker-header-control", theme)}>
        <Tooltip
          text={previousPredefinedRange(selectedRange).title}
          type="pointer"
          position="right"
        >
          <Icon
            name="chevron-left"
            onClick={() => onRangeSelected(previousPredefinedRange(selectedRange).selector())}
          />
        </Tooltip>
      </div>
      <div className={cx("date-picker-header-value", theme)} onClick={onClick}>
        <Tooltip text={tooltip} type="pointer" position="bottom-end">
          <span>{rangeAsString(selectedRange)}</span>
        </Tooltip>
      </div>
      <div className={cx("date-picker-header-control", theme)}>
        <Tooltip text={nextPredefinedRange(selectedRange).title} type="pointer" position="left">
          <Icon
            name="chevron-right"
            onClick={() => onRangeSelected(nextPredefinedRange(selectedRange).selector())}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
