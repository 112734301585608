import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tile } from "@react-gcc-eds/core";

import { defaultOrdersFilters, isFetching, IWeekMetric, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { parse } from "date-fns";
import { maxMetricCreationDate, minMetricCreationDate } from "../utils";
import CustomTabs, { ITab } from "../../../shared/custom-tabs";
import { DashboardGraph, DashboardTileAlignment, IDashboardItemSelected } from "..";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { KpiContainer } from "../../helpers/dashboard-kpis/kpi-container";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardMeetRequestedDateSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useLocalization } from "../../../../utils/localization";

const MeetRequestedDate = ({
  ordersLabel,
  itemsLabel,
  customerId,
  className,
  alignment,
  onItemSelected
}: {
  ordersLabel: string;
  itemsLabel: string;
  customerId: string;
  className: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const [state] = useStore();
  const tileRef = useRef<HTMLDivElement>(null);
  const { localeString } = useLocalization();
  const { isWalkthroughEnabled } = useSettingsActions();
  const { orderMeetRequestedDateMetric, itemMeetRequestedDateMetric } = state.fetchingData.metrics;
  const { steps } = useDashboardMeetRequestedDateSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const getTabs = useCallback(() => {
    const tabs = [];
    if (ordersLabel !== undefined) {
      tabs.push(orderTab);
    }
    if (itemsLabel !== undefined) {
      tabs.push(itemsTab);
    }
    return tabs;
  }, [orderMeetRequestedDateMetric, itemMeetRequestedDateMetric]);

  const { getOrderMeetRequestedDateMetric, getItemMeetRequestedDateMetric } = useMetricsActions();

  useEffect(() => {
    getOrderMeetRequestedDateMetric();
    getItemMeetRequestedDateMetric();
  }, [customerId]);

  const handleItemSelected = (
    metric: IWeekMetric,
    allMetrics: IWeekMetric[],
    graph: DashboardGraph
  ) => {
    if (!onItemSelected) {
      return undefined;
    }

    return () => {
      onItemSelected({
        filters: {
          orderCreationRange: {
            from: minMetricCreationDate(allMetrics),
            to: maxMetricCreationDate(allMetrics)
          },
          requestedDeliveryRange: {
            from: parse(metric.startDate, "yyyy-MM-dd", new Date(1901, 1, 1)),
            to: parse(metric.endDate, "yyyy-MM-dd", new Date(1901, 1, 1))
          },
          meetRequested: defaultOrdersFilters.meetRequested.map(f =>
            f.accessor === "no" ? { ...f, selected: true } : { ...f, selected: false }
          )
        },
        graph
      });
    };
  };

  const tileHeader = (
    <WalkthroughHeader
      walkthroughActive={isWalkthroughEnabled()}
      header={localeString("Dashboard_DoesNotMeetRequestedDate") || "Does not meet requested date"}
      tooltipInfo="If Confirmed date date is more than 2 days after Requested delivery date"
      alignment={alignment}
      onClick={startWalkThrough}
      disabled={isFetching(state)}
    />
  );

  const orderTab = {
    view: KpiContainer({
      metricLoading: orderMeetRequestedDateMetric === true,
      metrics: state.metrics.orderMeetRequestedDateMetric,
      onKPIClick: handleItemSelected,
      amountColorCondition: (amount: number) => amount > 0,
      kpiBottomText: "Orders"
    }),
    title: ordersLabel,
    index: 0
  } as ITab;

  const itemsTab = {
    view: KpiContainer({
      metricLoading: itemMeetRequestedDateMetric === true,
      metrics: state.metrics.itemMeetRequestedDateMetric,
      onKPIClick: handleItemSelected,
      amountColorCondition: (amount: number) => amount > 0,
      kpiBottomText: "Items"
    }),
    title: itemsLabel,
    index: 1
  } as ITab;

  const [_, setSelectedTab] = useState<ITab>(orderTab);

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
      >
        <CustomTabs onTabChange={(tab: ITab) => setSelectedTab(tab)} tabs={getTabs()} />
      </Tile>
      <WalkThrough />
    </>
  );
};

export default MeetRequestedDate;
