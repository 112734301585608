// Created at 2020-06-25
import React from "react";
import { ApplicationLayer } from "@react-gcc-eds/core";
import FullScreenPanel from "../shared/full-screen-panel";

const NoConnectedCustomer = () => {
  return (
    <ApplicationLayer title="">
      <FullScreenPanel>
        You are not connected to any customer, please contact a system administrator.
      </FullScreenPanel>
    </ApplicationLayer>
  );
};

export default NoConnectedCustomer;
