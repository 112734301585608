import React from "react";
import cx from "classnames";
import { Colors, Thresholds } from "../interfaces";
import * as utils from "../helpers";
import HistogramBar from "./HistogramBar";
import HistogramBarHover from "./HistogramBarHover";

interface Props {
  label: string;
  value: number;
  xPos: number;
  x: number;
  y: number;
  width: number;
  height: number;
  opacity: number;
  hoverWidth: number;
  hoverHeight: number;
  onMouseHover: Function;
  onClick: Function;
  colorScale: Colors;
  thresholds?: Thresholds[];
  hoveredSerie: string;
}

const HistogramGroup = ({
  label,
  value,
  xPos,
  x,
  y,
  width,
  height,
  opacity,
  hoverWidth,
  hoverHeight,
  onMouseHover,
  onClick,
  colorScale,
  thresholds
}: Props) => {
  const thresholdColor = thresholds ? utils.getThresholdsClass(thresholds, value) : "";
  const colorData = colorScale[label];

  return (
    <g className={cx("bar-group", { hidden: height === 0 })} transform={`translate(${xPos} ,0)`}>
      <HistogramBar
        thresholdColor={thresholdColor}
        colorData={colorData}
        x={x}
        y={y}
        width={width}
        height={height}
        opacity={opacity}
      />
      <HistogramBarHover
        hoverWidth={hoverWidth}
        hoverHeight={hoverHeight}
        onMouseEnter={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(true, e);
        }}
        onMouseLeave={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(false, e);
        }}
        onMouseMove={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(true, e);
        }}
        onClick={() => onClick()}
      />
    </g>
  );
};
export default HistogramGroup;
