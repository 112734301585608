import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tile } from "@react-gcc-eds/core";

import { isFetching, IWeekMetric, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { parse } from "date-fns";
import { maxMetricCreationDate, minMetricCreationDate } from "../utils";
import CustomTabs, { ITab } from "../../../shared/custom-tabs";
import { DashboardGraph, DashboardTileAlignment, IDashboardItemSelected } from "..";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { KpiContainer } from "../../helpers/dashboard-kpis/kpi-container";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardConfirmedDeliveryDateSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useLocalization } from "../../../../utils/localization";

const ConfirmedDeliveryDate = ({
  ordersLabel,
  itemsLabel,
  className,
  customerId,
  alignment,
  onItemSelected
}: {
  ordersLabel: string;
  itemsLabel: string;
  className: string;
  customerId: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const [state] = useStore();
  const tileRef = useRef<HTMLDivElement>(null);
  const { orderConfirmedDeliveriesMetric, itemConfirmedDeliveriesMetric } =
    state.fetchingData.metrics;
  const { steps } = useDashboardConfirmedDeliveryDateSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({ steps, maxWidth });
  const { isWalkthroughEnabled } = useSettingsActions();
  const { localeString } = useLocalization();

  const getTabs = useCallback(() => {
    const tabs = [];
    if (ordersLabel !== undefined) {
      tabs.push(orderTab);
    }
    if (itemsLabel !== undefined) {
      tabs.push(itemsTab);
    }
    return tabs;
  }, [orderConfirmedDeliveriesMetric, itemConfirmedDeliveriesMetric]);

  const { getOrderConfirmedDeliveriesMetric, getItemConfirmedDeliveriesMetric } =
    useMetricsActions();

  useEffect(() => {
    getOrderConfirmedDeliveriesMetric();
    getItemConfirmedDeliveriesMetric();
  }, [customerId]);

  const handleItemSelected = (
    metric: IWeekMetric,
    allMetrics: IWeekMetric[],
    graph: DashboardGraph
  ) => {
    if (!onItemSelected) {
      return undefined;
    }

    return () => {
      onItemSelected({
        filters: {
          orderCreationRange: {
            from: minMetricCreationDate(allMetrics),
            to: maxMetricCreationDate(allMetrics)
          },
          confirmedDeliveryRange: {
            from: parse(metric.startDate, "yyyy-MM-dd", new Date(1901, 1, 1)),
            to: parse(metric.endDate, "yyyy-MM-dd", new Date(1901, 1, 1))
          }
        },
        graph
      });
    };
  };

  const orderTab = {
    view: KpiContainer({
      metricLoading: orderConfirmedDeliveriesMetric === true,
      metrics: state.metrics.orderConfirmedDeliveriesMetric,
      onKPIClick: handleItemSelected,
      kpiBottomText: "Confirmed"
    }),
    title: ordersLabel,
    index: 0
  } as ITab;

  const itemsTab = {
    view: KpiContainer({
      metricLoading: itemConfirmedDeliveriesMetric === true,
      metrics: state.metrics.itemConfirmedDeliveriesMetric,
      onKPIClick: handleItemSelected,
      kpiBottomText: "Confirmed"
    }),
    title: itemsLabel,
    index: 1
  } as ITab;

  const [_, setSelectedTab] = useState<ITab>(orderTab);

  const tileHeader = (
    <WalkthroughHeader
      walkthroughActive={isWalkthroughEnabled()}
      tooltipInfo="What has been confirmed for delivery"
      header={localeString("Dashboard_ConfirmedDeliveryDate") || "Confirmed Delivery Date"}
      alignment={alignment}
      disabled={isFetching(state)}
      onClick={startWalkThrough}
    />
  );
  // const tileHeader = (
  //   <div className="my-first-step">
  //     <span>
  //       Confirmed delivery date
  //       <Tooltip
  //         text={"What has been confirmed for delivery"}
  //         type="pointer"
  //         position={alignment === TileAlignment.RIGHT ? "left" : "right"}
  //       >
  //         <i style={{ marginLeft: "8px" }} className="icon icon-info" />
  //       </Tooltip>
  //     </span>
  //   </div>
  // );

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
      >
        <CustomTabs onTabChange={(tab: ITab) => setSelectedTab(tab)} tabs={getTabs()} />
      </Tile>
      <WalkThrough />
    </>
  );
};

export default ConfirmedDeliveryDate;
