import React from "react";

interface Props {
  value: number;
  unit?: string;
  label: string;
  height: number;
}

const Label = ({ value, unit, label, height }: Props) => {
  const countDecimals = (value: number) => {
    if (Math.floor(value) === value) return 0;

    const str = value.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
      return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
      return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
  };

  return (
    <>
      <text className="value" transform={`translate(0, ${unit ? 0 : 5})`}>
        {countDecimals(value) >= 2 ? value.toFixed(2) : value}
      </text>
      {unit && (
        <text className="unit" transform="translate(0, 28)">
          {unit}
        </text>
      )}
      <text className="label" transform={`translate(0, ${height - 28})`}>
        {label}
      </text>
    </>
  );
};

export default Label;
