import React, { useEffect, useMemo, useRef } from "react";
import { Pill, Kpi, KpiItem, Icon, Row, Column, Tile, SingleSelect } from "@react-gcc-eds/core";

import { isFetching, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { NoMetrics } from "../utils";
import { weekRange, monthRange, quarterRange, IPeriodRange } from "../../../../contracts";
import { DashboardTileAlignment, DashboardTileAlignment as TileAlignment } from "../index";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardOrderAverageLeadTimeSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useLocalization } from "../../../../utils/localization";

const OrderAverageLeadTime = ({
  className,
  customerId,
  alignment
}: {
  className: string;
  customerId: string;
  alignment: TileAlignment;
}) => {
  const [state] = useStore();
  const tileRef = useRef<HTMLDivElement>(null);
  const { getOrderAverageLeadTimeMetric } = useMetricsActions();
  const { isWalkthroughEnabled } = useSettingsActions();
  const { setMetricsOrderAverageLeadTimeCreationRange } = useMetricsFiltersActions();
  const { localeString } = useLocalization();
  const { steps } = useDashboardOrderAverageLeadTimeSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({ steps, maxWidth });
  useEffect(() => {
    getOrderAverageLeadTimeMetric(state.metricsFilters.orderAverageLeadTimeCreationRange);
  }, [state.metricsFilters.orderAverageLeadTimeCreationRange, customerId]);

  const leadTimeMetric = () => {
    const { orderAverageLeadTimeMetric } = state.metrics;
    const { orderAverageLeadTimeMetric: loading } = state.fetchingData.metrics;

    if (loading) {
      return <NoMetrics loading />;
    }
    return (
      <Row className="enhanced-kpi-container">
        {orderAverageLeadTimeMetric.map((m, i) => {
          const cssColor = m.relativeCount < 0 ? "red" : "green";
          const arrow = m.relativeCount < 0 ? "arrow-up" : "arrow-down";
          return (
            <Column lg={6} sm={6} md={6} key={`kpi_${i}`} className="kpi-outer-container">
              <Kpi>
                <KpiItem color="gray">{m.totalLabel}</KpiItem>
                <KpiItem xl>{m.totalCount}</KpiItem>
                <KpiItem>
                  <KpiItem inline color={cssColor} lg>
                    <Icon name={arrow} />
                  </KpiItem>
                  <KpiItem span lg color={cssColor} className="kpi-large-text">
                    {Math.abs(m.relativeCount)}
                  </KpiItem>
                  <span className="hide-on-mobile">
                    <KpiItem inline separator />
                    <KpiItem inline>
                      <KpiItem color={cssColor}>{m.relativeLabel}</KpiItem>
                      <KpiItem>since previous period</KpiItem>
                    </KpiItem>
                  </span>
                </KpiItem>
              </Kpi>
            </Column>
          );
        })}
      </Row>
    );
  };

  const filtersSummary = (
    <Pill>{state.metricsFilters.orderAverageLeadTimeCreationRange.title}</Pill>
  );

  const periodFilter = () => {
    const { orderAverageLeadTimeCreationRange } = state.metricsFilters;

    return (
      <SingleSelect
        key="period-filter-select"
        label={orderAverageLeadTimeCreationRange.title || ""}
        items={[weekRange, monthRange, quarterRange]}
        onChange={item => setMetricsOrderAverageLeadTimeCreationRange(item as IPeriodRange)}
      />
    );
  };

  const tileHeader = (
    <WalkthroughHeader
      walkthroughActive={isWalkthroughEnabled()}
      header={localeString("Dashboard_AverageLeadTime") || "Average Lead Time"}
      tooltipInfo="Graph is showing average lead time from when order was received by Ericsson until it has been delivered to agreed destination"
      alignment={alignment}
      disabled={isFetching(state)}
      onClick={startWalkThrough}
    />
  );

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={filtersSummary}
        actions={[periodFilter()]}
      >
        {leadTimeMetric()}
      </Tile>
      <WalkThrough />
    </>
  );
};

export default OrderAverageLeadTime;
