import { Icon } from "@react-gcc-eds/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDownloadActions } from "../../actions/download-actions";

const useQuery = () => new URLSearchParams(useLocation().search);

const ExportDownload = () => {
  const [blob] = useState<string | null>(useQuery().get("blob"));
  const { downloadExport } = useDownloadActions();

  useEffect(() => {
    if (blob) {
      downloadExport(blob);
    }
  }, []);

  return (
    <main className="download-export-container">
      <div className="signin download-logo">
        <div className="watermark">
          <i className="icon icon-econ" />
          <div className="product">Ericsson Customer Order Dashboard</div>
        </div>
      </div>
      <div className="download-progress">
        <Icon name="download-save"></Icon>
        <div className="download-text">Thank you for downloading</div>
        <a href="/">Go to ECOD login page</a>
      </div>
      <div className="download-placeholder"></div>
    </main>
  );
};

export default ExportDownload;
