import * as d3 from "d3";
import React, { useState } from "react";
import { Data, Colors } from "../interfaces";
import StackedLineBarGroup from "./StackedLineBarGroup";

interface Props {
  data: Data;
  x0: d3.ScaleLinear<number, number>;
  colorScale: Colors;
  hoveredSerie: string;
  onHover: Function;
  onClick: Function;
  onClickProvided: boolean;
  totalValue: number;
  empty?: boolean;
}

const StackedLineBarGroups = ({
  data,
  x0,
  colorScale,
  hoveredSerie,
  onHover,
  totalValue,
  onClick,
  onClickProvided,
  empty = false
}: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const defaultOpacity = hoveredIndex === -1 ? 1 : 0.2;

  const stackedChartData = {};
  const prevValues = [] as number[];

  data.common.forEach((label, i) => {
    const dataForLabel = data.series.map((s, k) => {
      const getPrevious = () => {
        if (k === 0) {
          return 0;
        }
        prevValues.push((data.series[k - 1].values[i] / totalValue) * 100);
        return prevValues.reduce((a, b) => {
          return a + b;
        });
      };
      return {
        key: s.name,
        value: !empty ? s.values[i] : 0,
        percent: (s.values[i] / totalValue) * 100,
        previous: getPrevious()
      };
    });
    stackedChartData[label] = dataForLabel;
  });

  return (
    <g className="bar-group" transform="translate(1,15)" opacity={1}>
      {stackedChartData[""].map((d: any, i: number) => {
        const x1 = -(x0(0) - x0(d.previous));

        const x2 = -(x0(0) - x0(d.percent) + x0(0) - x0(d.previous) + 2);

        const colorData = colorScale[d.key];
        const lineOpacity = hoveredSerie === d.key ? 1 : 0.2;

        const width = x2 - x1;
        const labelSpace = (d.value.toString().length * 12) / 1.5;
        const hasLabelSpace = width > labelSpace;

        const willShowValue = hoveredSerie === d.key || hoveredSerie === "";
        const value =
          d.value > 99999 && hoveredSerie === "" && !hasLabelSpace
            ? `${d3.format(",")(d.value).replace(/,/g, " ").toString().substring(0, 4)}...`
            : d.value;
        const keyIndex = i;
        return (
          <StackedLineBarGroup
            key={keyIndex}
            x1={x1}
            x2={x2}
            colorData={colorData}
            value={willShowValue ? value : ""}
            label={d.key}
            opacity={hoveredSerie !== "" ? lineOpacity : defaultOpacity}
            onMouseHover={(hovered: boolean, e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
              if (hovered) {
                setHoveredIndex(i);
              } else {
                setHoveredIndex(-1);
              }
              onHover(hovered ? d.key : "", value, e);
            }}
            onClickProvided={onClickProvided}
            onClick={(l: string, v: number[]) => {
              onClick(l, v);
            }}
          />
        );
      })}
    </g>
  );
};
export default StackedLineBarGroups;
