import { Icon, Menu, MenuItem, MenuNode, Tooltip, useSettings } from "@react-gcc-eds/core";
import { ReactNode, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useSettingsActions } from "../../actions/settings-actions";
import { useStore } from "../../store";
import { IMenuEntry, MenuEntries, MenuKey, useCustomerHelpers } from "../../utils/customer-helpers";
import { useLocalization } from "../../utils/localization";
import { useUserHelpers } from "../../utils/user-helpers";
import ContactUs, { EViews } from "../mail-forms/contact-us";

const Linkage = ({ href, text, icon }: { href: string; text: string; icon?: ReactNode }) => {
  return (
    <a className="item link-item" href={href} rel="noopener noreferrer" target="_blank">
      <span className="text">{text}</span>
      {icon}
    </a>
  );
};

const Link = ({ href, text }: { href: string; text: string }) => {
  const history = useHistory();
  return (
    <a className="item" onClick={() => history.push("/administration")}>
      {text}
    </a>
  );
};

const SupportMenuItem = ({ onClick, text }: { onClick: any; text: string }) => {
  return (
    <div className="item link-item" onClick={onClick}>
      <span className="text">{text}</span>
    </div>
  );
};

const useAppMenu = () => {
  const [state] = useStore();
  const { theme } = useSettings();
  const { isMenuEnabled, areMenuRolesValid } = useCustomerHelpers();
  const { localeString } = useLocalization();
  const { userRoleIsAdministrator } = useUserHelpers();
  const { currentCustomer } = useSettingsActions();
  const [showContact, setShowContact] = useState(false);

  if (!state.user) {
    return null;
  }
  const toggleContact = () => setShowContact(show => !show);

  /**
   * Creation of non-standard menu items (modals, external links, links to downloadable files).
   */

  const administrationEntry = (
    <MenuItem
      tag={Link}
      href={"/administration"}
      text="Administration"
      key={"menu-item-administration"}
    />
  );

  const administrationGuideEntry = (
    <MenuNode title="Administration" key={"menu-item-administration"}>
      <MenuItem
        tag={Link}
        href={"/administration"}
        text="Administration menu"
        key={"menu-item-administration"}
      />
      <MenuItem
        tag={Linkage}
        href={`/${state.settings.Features.AdministrationGuide_Filename}`}
        text="Administration guide"
        key={"menu-item-administration-guide"}
        icon={
          <Tooltip text={"Download PPTX"} type="pointer" position="top-end">
            <Icon name="download-save" />
          </Tooltip>
        }
      />
    </MenuNode>
  );

  const reportIssueEntry = (
    <MenuItem
      tag={SupportMenuItem}
      onClick={toggleContact}
      text="Report an issue"
      key={"menu-item-report-issue"}
    />
  );

  const userGuideEntry = (
    <MenuItem
      tag={Linkage}
      href={`/${state.settings.Features.UserGuide_Filename}`}
      text="User guide"
      key={"menu-item-user-guide"}
      icon={
        <Tooltip text={"Download PDF"} type="pointer" position="top-end">
          <img
            src={theme === "dark" ? "/pdf_light.svg" : "/pdf_dark.svg"}
            alt="pdf"
            height={16}
            width={16}
            key={"download-pdf-img"}
          />
        </Tooltip>
      }
    />
  );

  const externalLinksEntry = (
    <MenuNode title="External links" key={"menu-item-external-links"}>
      <Tooltip type="pointer" position="top" text="Replacement and Return platform">
        <MenuItem tag={Linkage} href={state.settings.Features.eRR_Link} text="eRR" key="err-link" />
      </Tooltip>
    </MenuNode>
  );

  const releaseTutorialsEntry = (
    <MenuItem
      tag={Linkage}
      href={state.settings.Features.ReleaseTutorials_Link}
      text="Release tutorials"
      key="release-tutorials-link"
      icon={<Icon name="video-play" />}
    />
  );

  const createMenuItem = (c: IMenuEntry): JSX.Element => {
    return c.accessor === MenuKey.UserGuide ? (
      userGuideEntry
    ) : c.accessor === MenuKey.ReportAnIssue ? (
      reportIssueEntry
    ) : c.accessor === MenuKey.ExternalLinks ? (
      externalLinksEntry
    ) : c.accessor === MenuKey.ReleaseTutorials ? (
      releaseTutorialsEntry
    ) : c.accessor === MenuKey.Administration ? (
      state.user && userRoleIsAdministrator(state.user.role) ? (
        administrationGuideEntry
      ) : (
        administrationEntry
      )
    ) : (
      <MenuItem
        key={c.accessor}
        tag={c.tag || NavLink}
        to={c.to}
        isActive={c.isActive}
        exact={c.exact}
      >
        {localeString(c.localizationKey) || c.name}
      </MenuItem>
    );
  };

  /**
   * Creation of entire menu. Iterate through MenuEntries. Check if a menu entry has its' own
   * children. Check if menu entry is enabled and user's role is valid for showing the
   * menu entry.
   */
  const menuArray = MenuEntries.reduce((outerArray: JSX.Element[], curr: IMenuEntry, index) => {
    if (curr.entries && curr.entries.length) {
      const array = curr.entries.reduce((innerArray: JSX.Element[], innerEntry) => {
        return !isMenuEnabled(curr.accessor) || !areMenuRolesValid(innerEntry.accessor)
          ? innerArray
          : [...innerArray, createMenuItem(innerEntry)];
      }, []);
      return [
        ...outerArray,
        isMenuEnabled(curr.accessor) && areMenuRolesValid(curr.accessor) ? (
          <MenuNode
            key={`menu_node_${index}`}
            title={localeString(curr.localizationKey) || curr.name}
          >
            {array}
          </MenuNode>
        ) : (
          <></>
        )
      ];
    } else {
      return !isMenuEnabled(curr.accessor) || !areMenuRolesValid(curr.accessor)
        ? outerArray
        : [...outerArray, createMenuItem(curr)];
    }
  }, []);

  return (
    <>
      <Menu>{menuArray}</Menu>
      {showContact && (
        <ContactUs
          customerIdentifier={currentCustomer().identifier}
          onClose={toggleContact}
          typeOfForm={EViews.SUPPORT}
        />
      )}
    </>
  );
};

export default useAppMenu;
