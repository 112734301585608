import React from "react";
import Trendline from "./Trendline";
import TrendlineHover from "./TrendlineHover";

interface Props {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  colorData: string;
  opacity: number;
  onMouseHover: Function;
  onMouseLeave: Function;
}

const TrendlineGroup = ({ x1, y1, x2, y2, colorData, opacity, onMouseHover }: Props) => {
  return (
    <g className="trendline-group">
      <Trendline x1={x1} y1={y1} x2={x2} y2={y2} opacity={opacity} colorData={colorData} />
      <TrendlineHover
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        onMouseEnter={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(true, e);
        }}
        onMouseLeave={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(false, e);
        }}
        onMouseMove={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(true, e);
        }}
      />
    </g>
  );
};

export default TrendlineGroup;
