import { Tooltip } from "@react-gcc-eds/core";
import React from "react";
import { IDateRange } from "../../../contracts";
import { IPredefinedRange, usePredefinedRanges } from "./predefined-ranges";

const PredefinedRangeSelector = ({
  onRangeSelected,
  selectedRange,
  oldestDate,
  rolling
}: {
  onRangeSelected(r: IDateRange): void;
  selectedRange?: IDateRange;
  oldestDate?: Date;
  rolling?: boolean;
}) => {
  const { matchesPredefinedRange, predefinedRanges } = usePredefinedRanges(oldestDate);
  const matchesPredefined = (r: IPredefinedRange) => {
    const pr = matchesPredefinedRange(selectedRange);
    return pr && pr.id === r.id;
  };
  return (
    <div className="range-selector">
      {rolling && (
        <div className="range-selector-header">
          <span className="text">Rolling filters</span>
          <Tooltip
            text="Filter will be updated when you access the application on a date different from the one you applied the filter"
            type="pointer"
          >
            <i style={{ marginLeft: "8px" }} className="icon icon-info" />
          </Tooltip>
        </div>
      )}
      {predefinedRanges.map(s => (
        <div
          className={`range-selector-item ${matchesPredefined(s) ? "active" : ""}`}
          key={s.id}
          onClick={() => onRangeSelected(s.selector())}
        >
          {s.element ? s.element : s.title}
        </div>
      ))}
    </div>
  );
};

export default PredefinedRangeSelector;
