import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { ApplicationLayer, Row } from "@react-gcc-eds/core";
import useAppMenu from "../../shared/menu";

import { IViewProperty, useStore } from "../../../store";
import { useCustomizationActions } from "../../../actions/customization-actions";
import {
  IExternalOrdersRedirectionFilters,
  useOrdersFiltersActions
} from "../../../actions/orders-filters-actions";
import {
  IInternalOrdersRedirectionFilters,
  useInternalOrdersFiltersActions
} from "../../../actions/internal-orders-filters-actions";
import { useSettingsActions } from "../../../actions/settings-actions";
import { useLocalization } from "../../../utils/localization";

import OverallOrderStatusBars from "./overall-order-status-bars";
import OverallOrderStatusDonut from "./overall-order-status-donut";
import MeetRequestedDate from "./meet-requested-date";
import ConfirmedDeliveryDate from "./confirmed-delivery-date";
import PlannedDeliveryDate from "./planned-delivery-date";
import OrderAverageLeadTime from "./order-average-lead-time";
import ShipmentCount from "./shipment-count";
import OrderLeadTime from "./order-lead-time";
import DeliveryTimePrecision from "./delivery-precision";
import { lowerCaseInitial } from "../../../utils";
import OverallInternalOrderStatusBars from "./overall-internal-order-status-bars";
import OverallInternalOrderStatusDonut from "./overall-internal-order-status-donut";
import MaterialInventory from "./material-inventory";
import { EViewId } from "../../shared/view-ids";
import { useLoadingBar } from "../../shared/use-loading-bar";
import ShipmentDelivered from "./shipment-delivered";

export enum DashboardGraph {
  Orders,
  Items,
  InternalOrders
}

export interface IDashboardItemSelected {
  graph: DashboardGraph;
  filters: IExternalOrdersRedirectionFilters | IInternalOrdersRedirectionFilters;
}

export enum DashboardTileAlignment {
  LEFT,
  RIGHT,
  FULL
}

interface IDashboard {
  id: string;
  component: any;
  redirectToOrders: boolean;
  redirectToItems: boolean;
}

const isPropHidden = (customization: IViewProperty[], prop: string) =>
  (customization.find(p => p.propertyName === prop) || { hide: true }).hide;

const dashboardsConfig = (
  ordersCustomization: IViewProperty[],
  itemsCustomization: IViewProperty[],
  internalOrdersCustomization: IViewProperty[]
): IDashboard[] => [
  {
    id: "OrderStatusBars",
    component: OverallOrderStatusBars,
    redirectToOrders: !isPropHidden(ordersCustomization, "StatusLabel"),
    redirectToItems: !isPropHidden(itemsCustomization, "StatusLabel")
  },
  {
    id: "OrderStatusDonuts",
    component: OverallOrderStatusDonut,
    redirectToOrders: !isPropHidden(ordersCustomization, "StatusLabel"),
    redirectToItems: !isPropHidden(itemsCustomization, "StatusLabel")
  },
  {
    id: "MeetRequestedDate",
    component: MeetRequestedDate,
    redirectToOrders:
      !isPropHidden(ordersCustomization, "MeetsRequestedDate") &&
      !isPropHidden(ordersCustomization, "RequestedDeliveryAt"),
    redirectToItems:
      !isPropHidden(itemsCustomization, "MeetsRequestedDate") &&
      !isPropHidden(ordersCustomization, "RequestedDeliveryAt")
  },
  {
    id: "ConfirmedDeliveryDate",
    component: ConfirmedDeliveryDate,
    redirectToOrders: !isPropHidden(ordersCustomization, "ConfirmedDeliveryAt"),
    redirectToItems: !isPropHidden(itemsCustomization, "ConfirmedDeliveryAt")
  },
  {
    id: "PlannedDeliveryDate",
    component: PlannedDeliveryDate,
    redirectToOrders: !isPropHidden(ordersCustomization, "CalculatedConfirmDate"),
    redirectToItems: !isPropHidden(itemsCustomization, "CalculatedConfirmDate")
  },
  {
    id: "OrderAverageLeadTime",
    component: OrderAverageLeadTime,
    redirectToOrders: false,
    redirectToItems: false
  },
  {
    id: "ShipmentCount",
    component: ShipmentCount,
    redirectToOrders: false,
    redirectToItems: false
  },
  {
    id: "OrderLeadTime",
    component: OrderLeadTime,
    redirectToOrders: false,
    redirectToItems: false
  },
  {
    id: "DeliveryTimePrecision",
    component: DeliveryTimePrecision,
    redirectToOrders: true,
    redirectToItems: false
  },
  {
    id: "InternalOrderStatusBars",
    component: OverallInternalOrderStatusBars,
    redirectToOrders: !isPropHidden(internalOrdersCustomization, "DeliveryStatusLabel"),
    redirectToItems: false
  },
  {
    id: "InternalOrderStatusDonuts",
    component: OverallInternalOrderStatusDonut,
    redirectToOrders: !isPropHidden(internalOrdersCustomization, "DeliveryStatusLabel"),
    redirectToItems: false
  },
  {
    id: "InventoryStatus",
    component: MaterialInventory,
    redirectToOrders: false,
    redirectToItems: false
  },
  {
    id: "ShipmentDelivered",
    component: ShipmentDelivered,
    redirectToOrders: false,
    redirectToItems: false
  }
];

const DashboardContainer = () => {
  const [ordersViewProperties, setOrdersViewProperties] = useState<IViewProperty[]>([]);
  const [itemsViewProperties, setItemsViewProperties] = useState<IViewProperty[]>([]);
  const [internalOrdersViewProperties, setInternalOrdersViewProperties] = useState<IViewProperty[]>(
    []
  );
  const [dashboards, setDashboards] = useState(
    dashboardsConfig(ordersViewProperties, itemsViewProperties, internalOrdersViewProperties)
  );
  const [state] = useStore();
  const { internalOrdersCustomization, itemsCustomization, ordersCustomization } =
    state.fetchingData.viewProperties;

  const loaders = [
    ordersCustomization === undefined ? true : ordersCustomization,
    itemsCustomization === undefined ? true : itemsCustomization,
    internalOrdersCustomization === undefined ? true : internalOrdersCustomization
  ];
  const { renderLoadingBar: loadingBar, showLoadingBar: loadingCustomizations } = useLoadingBar(
    loaders,
    true
  );

  const { getViewProperties } = useCustomizationActions();
  const { setOrdersRedirectionFilters } = useOrdersFiltersActions();
  const { setInternalOrdersRedirectionFilters } = useInternalOrdersFiltersActions();
  const { localeString } = useLocalization();

  const history = useHistory();

  const { currentCustomer } = useSettingsActions();

  const getCustomization = async () => {
    if (state.user) {
      const ordersCustomization = await getViewProperties(EViewId.PO, "ordersCustomization");
      const itemsCustomization = await getViewProperties(EViewId.PO_ITEMS, "itemsCustomization");
      const internalOrdersCustomization = await getViewProperties(
        EViewId.INTERNAL_PO,
        "internalOrdersCustomization"
      );
      setOrdersViewProperties(ordersCustomization);
      setItemsViewProperties(itemsCustomization);
      setInternalOrdersViewProperties(internalOrdersCustomization);
      setDashboards(
        dashboardsConfig(ordersCustomization, itemsCustomization, internalOrdersCustomization)
      );
    }
  };

  useEffect(() => {
    getCustomization();
  }, []);

  const handleItemSelected = (
    redirectToOrders: boolean,
    redirectToItems: boolean
  ): Function | undefined => {
    return redirectToItems || redirectToOrders
      ? (itemData: IDashboardItemSelected) => {
          if (itemData.filters) {
            if (itemData.graph === DashboardGraph.Orders && redirectToOrders) {
              setOrdersRedirectionFilters(itemData.filters);
              history.push("/orders");
            } else if (itemData.graph === DashboardGraph.Items && redirectToItems) {
              //TODO: how to change?
              //setItemsFilters(itemData.filters);
              //history.push("/order-items");
            } else if (itemData.graph === DashboardGraph.InternalOrders && redirectToOrders) {
              setInternalOrdersRedirectionFilters(itemData.filters);
              history.push("/internal-orders");
            }
          }
        }
      : undefined;
  };

  const dashboardsByRow = (
    dashboards: IDashboard[],
    customerId: string,
    ordersLabel?: string,
    itemsLabel?: string
  ) => {
    const result = [];
    let i;
    for (i = 0; i < dashboards.length; i += 2) {
      const leftDashboard = dashboards[i];
      const LeftDashboard = leftDashboard.component;
      let rightDashboard = undefined;
      let RightDashboard = undefined;
      if (i + 1 < dashboards.length) {
        rightDashboard = dashboards[i + 1];
        RightDashboard = rightDashboard.component;
      }
      result.push(
        <Row key={`db_row_${i}`} className={cx({ half: dashboards.length > 2 })}>
          <LeftDashboard
            alignment={RightDashboard ? DashboardTileAlignment.LEFT : DashboardTileAlignment.FULL}
            ordersLabel={ordersLabel}
            itemsLabel={itemsLabel}
            customerId={customerId}
            onItemSelected={handleItemSelected(
              leftDashboard.redirectToOrders,
              leftDashboard.redirectToItems
            )}
          />
          {RightDashboard && (
            <RightDashboard
              alignment={DashboardTileAlignment.RIGHT}
              ordersLabel={ordersLabel}
              itemsLabel={itemsLabel}
              customerId={customerId}
              onItemSelected={handleItemSelected(
                rightDashboard ? rightDashboard.redirectToOrders : false,
                rightDashboard ? rightDashboard.redirectToItems : false
              )}
            />
          )}
        </Row>
      );
    }

    return result;
  };

  const layoutDashboards = (dashboards: IDashboard[], customerId: string, views: string[]) => {
    const ordersLabel = views.some(v => lowerCaseInitial(v) === "purchaseOrders")
      ? localeString("view_po")
      : undefined;
    const itemsLabel = views.some(v => lowerCaseInitial(v) === "purchaseOrdersItems")
      ? localeString("view_po_it")
      : undefined;

    if (dashboards.length > 1) {
      return <>{dashboardsByRow(dashboards, customerId, ordersLabel, itemsLabel)}</>;
    }
    const { component: D, redirectToOrders, redirectToItems } = dashboards[0];
    return (
      <D
        className="column"
        lg={12}
        ordersLabel={ordersLabel}
        itemsLabel={itemsLabel}
        customerId={customerId}
        onItemSelected={handleItemSelected(redirectToOrders, redirectToItems)}
      />
    );
  };

  const { identifier, features } = currentCustomer();
  const customerId = identifier ? identifier.toLowerCase() : "default";

  const currentCustomerDashboards = () => {
    const features = currentCustomer().features;
    return [...features.dashboards].reduce((current: IDashboard[], dashboardId: string) => {
      const dashboard = dashboards.find(d => d.id === dashboardId);
      if (!dashboard) {
        return current;
      }
      return [...current, dashboard];
    }, []);
  };

  const customerDashboards = currentCustomerDashboards();

  const noDashboardsActivated = () => {
    return (
      <div className="dashboard-no-boards">
        <p>No dashboards activated</p>
      </div>
    );
  };

  return (
    <ApplicationLayer title={localeString("MenuKeys_Dashboard")} menu={useAppMenu()}>
      {loadingCustomizations ? (
        loadingBar()
      ) : (
        <div className={`dashboard-wrapper ${customerDashboards.length <= 1 ? "full-height" : ""}`}>
          {itemsViewProperties.length && customerDashboards.some((c: IDashboard) => c)
            ? layoutDashboards(customerDashboards, customerId, features.views)
            : noDashboardsActivated()}
        </div>
      )}
    </ApplicationLayer>
  );
};

export default DashboardContainer;
