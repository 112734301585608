import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Pill, Tile, useSettings } from "@react-gcc-eds/core";

import { isFetching, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { Donut } from "@react-gcc-eds/charts";
import { Serie } from "@react-gcc-eds/charts/dist/components/donut/interfaces";
import { noStatusMetric, NoMetrics } from "../utils";
import DatePicker from "../../../filters/date-picker";
import { DashboardTileAlignment, DashboardTileAlignment as TileAlignment } from "../index";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardShipmentCountSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { usePredefinedRanges } from "../../../filters/date-picker/predefined-ranges";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { dateAsYMDString } from "../../../../utils";
import { activeFiltersIcon } from "../../../../components/filters/filters-icon";
import cx from "classnames";
import { useLocalization } from "../../../../utils/localization";

const ShipmentCount = ({
  customerId,
  className,
  alignment
}: {
  customerId: string;
  className: string;
  alignment: TileAlignment;
}) => {
  const [hiddenStatuses, setHiddenStatuses] = useState([]);
  const tileRef = useRef<HTMLDivElement>(null);
  const [state] = useStore();
  const { theme } = useSettings();
  const { localeString } = useLocalization();
  const { steps } = useDashboardShipmentCountSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { isWalkthroughEnabled } = useSettingsActions();
  const { currentCustomer } = useSettingsActions();
  const { getShipmentCountMetric } = useMetricsActions();
  const { rangeAsString, largestRange, isLargestRange } = usePredefinedRanges(
    new Date(currentCustomer().dataAvailableFromDate)
  );
  const { setMetricsShipmentCountCreationRange } = useMetricsFiltersActions();

  useEffect(() => {
    if (state.metricsFilters.shipmentCountCreationRange) {
      const { from, to } = state.metricsFilters.shipmentCountCreationRange;
      getShipmentCountMetric(dateAsYMDString(from), dateAsYMDString(to));
    }
  }, [state.metricsFilters.shipmentCountCreationRange, customerId]);

  const shipmentMetric = () => {
    const { shipmentMetric } = state.metrics;
    const { shipmentMetric: loading } = state.fetchingData.metrics;

    if (loading) {
      return <NoMetrics loading />;
    }
    if (noStatusMetric(shipmentMetric)) {
      return <NoMetrics />;
    }

    const d: Serie[] = [];
    shipmentMetric.forEach(m => {
      d.push({
        colorID: m.status,
        name: m.label,
        values: [m.totalCount],
        hidden: hiddenStatuses.find(i => i === m.status) !== undefined
      });
    });

    return (
      <Donut
        data={{ series: d }}
        unit="shipments"
        showValue
        showAbsoluteValue
        onToggle={(data: any) =>
          setHiddenStatuses(data.series.filter((d: Serie) => d.hidden).map((d: Serie) => d.colorID))
        }
      />
    );
  };

  const dateFilter = (
    <DatePicker
      key="datepicker"
      dual={state.userPreferences.useDualCalendar}
      rolling
      right={alignment !== TileAlignment.LEFT}
      selectedRange={state.metricsFilters.shipmentCountCreationRange}
      onRangeSelected={setMetricsShipmentCountCreationRange}
    />
  );

  const tileHeader = (
    <div className="dashboard-tile">
      {!isLargestRange(state.metricsFilters.shipmentCountCreationRange)
        ? activeFiltersIcon(1)
        : null}
      <WalkthroughHeader
        walkthroughActive={isWalkthroughEnabled()}
        header={localeString("Dashboard_ShipmentCount") || "Shipment count"}
        tooltipInfo="Graph is showing intervals for Shipments time in transit for selected Time period"
        alignment={alignment}
        onClick={startWalkThrough}
        disabled={isFetching(state)}
      />
    </div>
  );

  const resetFilter = () => {
    setMetricsShipmentCountCreationRange(largestRange.selector());
  };

  const resetFilterPill = () => {
    return (
      <Pill className={cx("filter-pill", theme)} onToggle={resetFilter}>
        Reset filters
      </Pill>
    );
  };

  const filterSummary = (
    <div className="dashboard-active-filter">
      <span style={{ marginRight: "8px" }}>Time in transit</span>
      <Pill>{rangeAsString(state.metricsFilters.shipmentCountCreationRange)}</Pill>
      {!isLargestRange(state.metricsFilters.shipmentCountCreationRange) ? resetFilterPill() : null}
    </div>
  );

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={filterSummary}
        actions={[dateFilter]}
      >
        {shipmentMetric()}
      </Tile>
      <WalkThrough />
    </>
  );
};

export default ShipmentCount;
