import {
  AppBarItem,
  Dropdown,
  IDropdownItem,
  ISelectItem,
  SettingsItem,
  SystemLayer
} from "@react-gcc-eds/core";
import "@react-gcc-eds/core/lib/styling.css";
import { subMinutes } from "date-fns";
import {
  lazy,
  ReactElement,
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch, useHistory } from "react-router-dom";
import { ICustomerIdentity } from "types";
import { useCustomerActions } from "../actions/customer-actions";
import { useNotificationActions } from "../actions/notification-actions";
import { useScheduledExportsActions } from "../actions/scheduled-exports-actions";
import { useSettingsActions } from "../actions/settings-actions";
import { useUserActions } from "../actions/user-actions";
import {
  INotificationItem,
  IScheduledExport,
  IScheduledExportItem,
  IUser,
  IUserCustomerNotificationItem,
  UserInfo,
  useStore
} from "../store";
import { dateTimeFormatter, stringSorter } from "../utils";
import { MenuKey, useCustomerHelpers } from "../utils/customer-helpers";
import { useLocalization } from "../utils/localization";
import { useUserHelpers } from "../utils/user-helpers";
import PendingUserRequestsNotification from "./administration/user-access-requests/pending-requests-notification";
import { useMsalFlow } from "./auth/msal-flow";
import NoConnectedCustomer from "./auth/no-customer";
import NotFound from "./auth/not-found";
import { ApiErrorManager, ApplicationError } from "./errors";
import FaqView from "./help/faq";
import ReleaseNotesView from "./help/release-notes";
import ContactUs, { EViews } from "./mail-forms/contact-us";
import { usePopUpFeedback } from "./mail-forms/pop-up-feedback";
import { NewCustomerForm } from "./mail-forms/registration";
import DashboardContainer, { DashboardTileAlignment } from "./reports/dashboard";
import Settings from "./settings";
import AppViewLoader from "./shared/app-view-loader";
import ExportDownload from "./shared/export-download";
import NextDeployment from "./shared/next-deployment";
import NotificationsDialog from "./shared/notifications-dialog/notifications-dialog";
import ScheduledExportsDialog from "./shared/scheduled-exports-dialog/scheduled-exports-dialog";
import StartupMessage from "./shared/startup-message";
import { PersistTheme, useUserPreferences } from "./shared/user-preferences";
import VirtualRoleBanner from "./shared/virtual-role-banner";
import { useWalkThrough } from "./walkthrough";
import { WalkthroughHeader } from "./walkthrough/components/walkthrough-icon";
import useGeneralSteps from "./walkthrough/step-hooks/general-walkthrough";

const Administration = lazy(() => import("./administration"));
const ExternalOrders = lazy(() => import("./reports/purchase-lists/external-orders-list"));
const ExternalOrdersList = lazy(() => import("./reports/purchase-lists/purchase-order-items-list"));
const InternalOrders = lazy(() => import("./reports/purchase-lists/internal-orders-list"));
const DeliverySchedule = lazy(() => import("./reports/delivery-schedule"));
const InternalDeliverySchedule = lazy(() => import("./reports/internal-delivery-schedule"));
const Shipments = lazy(() => import("./reports/shipment-list/external-shipments"));
const Shipment = lazy(() => import("./reports/shipment-list/external-shipments/shipment"));
const InternalShipments = lazy(() => import("./reports/shipment-list/internal-shipments"));
const InternalShipment = lazy(
  () => import("./reports/shipment-list/internal-shipments/internal-shipment")
);
const InventoryNationalWarehouse = lazy(() => import("./reports/inventory/nwh"));
const SerialNumbers = lazy(() => import("./reports/serial-numbers"));
const InternalSerialNumbers = lazy(() => import("./reports/internal-serial-numbers"));

const LoadLazy = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<AppViewLoader />}>{children}</Suspense>
);

const areEqual = (
  a?: IUserCustomerNotificationItem[] | IScheduledExport | IScheduledExportItem[],
  b?: IUserCustomerNotificationItem[] | IScheduledExport | IScheduledExportItem[]
) => {
  if (!a || !b) return false;
  return JSON.stringify(a) === JSON.stringify(b);
};

const Application = ({ user }: { user: IUser }) => {
  const [features, toggleFeatures] = useState({ feedback: false, support: false, faq: false });
  const [showContact, setShowContact] = useState(false);
  const [showNewCustomer, setShowNewCustomer] = useState(false);
  // const [notificationsLength, setNotificationsLength] = useState<number>(0);
  const [showNotificationsDialog, setShowNotificationsDialog] = useState(false);
  const [showScheduledExportsDialog, setShowScheduledExportsDialog] = useState(false);
  const [notificationFrequency, setNotificationFrequency] = useState<number>(0);
  const [notificationScope, setNotificationScope] = useState({
    value: 0,
    item: {
      notificationId: 0,
      notificationScope: 0,
      frequency: 0,
      recurringDay: 0,
      enabled: true
    }
  });
  const [notificationDay, setNotificationDay] = useState<number>(0);
  const [viewUntouched, setViewUntouched] = useState(false);
  const [state] = useStore();
  const [currentTheme, setCurrentTheme] = useState<"light" | "dark" | undefined>(
    state.userPreferences.theme
  );

  const ref = useRef<HTMLDivElement>(null);
  const { persistCustomer, persistPreference } = useUserPreferences();
  const {
    resetUser,
    getUserRoles,
    updateUserVirtualRole,
    getUserDateFormats,
    updateUserDateFormat
  } = useUserActions();
  const {
    getUserCustomerNotificationsList,
    getAdminCustomerNotificationsList,
    updateUserCustomerNotificationsList,
    updateUserCustomerNotificationsEnabled,
    getUserCustomerNotificationsEnabled
  } = useNotificationActions();
  const {
    updateAdminCustomerScheduledExportsList,
    getAdminCustomerScheduledExportsList,
    getUserCustomerScheduledExportsList,
    updateUserCustomerScheduledExportsList,
    updateUserCustomerScheduledExportsEnabled,
    getUserCustomerScheduledExportsEnabled
  } = useScheduledExportsActions();
  const { getCustomerNotifications } = useCustomerActions();
  const { getCustomerScheduledExports } = useCustomerActions();
  const { steps } = useGeneralSteps(ref);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps
  });
  const {
    userRoleIsSuperUser,
    userRoleIsAdministrator,
    userRoleIsTechSupport,
    userRoleIsInternal
  } = useUserHelpers();
  const { currentCustomer, toggleUserPref } = useSettingsActions();
  const { PopUpFeedback } = usePopUpFeedback();
  const { isWalkthroughEnabled } = useSettingsActions();
  const { localeString } = useLocalization();
  const history = useHistory();
  const { isMenuEnabled } = useCustomerHelpers();
  const [userCustomerNotifications, setUserCustomerNotifications] = useState<{
    current: IUserCustomerNotificationItem[];
    original: IUserCustomerNotificationItem[];
    equals: boolean;
  }>({ current: [], original: [], equals: true });
  const [adminCustomerNotifications, setAdminCustomerNotifications] = useState<INotificationItem[]>(
    []
  );
  const [adminCustomerScheduledExports, setAdminCustomerScheduledExports] = useState<
    IScheduledExportItem[]
  >([]);
  const [userCustomerScheduledExports, setUserCustomerScheduledExports] = useState<{
    current: IScheduledExport;
    original: IScheduledExport;
    equals: boolean;
  }>({
    current: { recurringDay: 0, scheduledExportUserCustomerSettings: [] },
    original: { recurringDay: 0, scheduledExportUserCustomerSettings: [] },
    equals: true
  });
  const { useAutoLogout, signOut } = useMsalFlow();

  const onLogOut = () => {
    resetUser();
    signOut();
  };

  const isAdminRole = (user: IUser) => {
    if (
      userRoleIsSuperUser(user.role) ||
      userRoleIsAdministrator(user.role) ||
      userRoleIsTechSupport(user.role)
    )
      return true;
    return false;
  };

  const handleUserCustomerNotificationsEnabled = (key: number, value: boolean) => {
    const replaced = (
      notifications: IUserCustomerNotificationItem[],
      key: number,
      value: boolean
    ) => notifications.map(d => (d.notificationId === key ? { ...d, enabled: value } : d));

    setUserCustomerNotifications(notifications => ({
      ...notifications,
      current: replaced(notifications.current, key, value),
      equals: areEqual(replaced(notifications.current, key, value), notifications.original)
    }));
  };
  const handleUserNotificationsEnabled = (customerId: string, value: boolean) => {
    customerId && updateUserCustomerNotificationsEnabled(customerId, value);
  };
  const getUserCustomerNotifications = async (customerId?: string) => {
    if (customerId) {
      const notifications = await getUserCustomerNotificationsList(customerId);
      if (notifications)
        setUserCustomerNotifications({
          current: notifications,
          original: notifications,
          equals: true
        });
    }
  };
  // const handleAdminCustomerScheduledExportsEnabled = (key: number, value: boolean) => {
  //   const replaced = (scheduledExports: IScheduledExportItem[], key: number, value: boolean) =>
  //     scheduledExports.map(d => (d.id === key ? { ...d, enabled: value } : d));

  //   setAdminCustomerScheduledExports(scheduledExports => ({
  //     ...scheduledExports,
  //     current: replaced(scheduledExports.current, key, value),
  //     equals: areEqual(replaced(scheduledExports.current, key, value), scheduledExports.original)
  //   }));
  // };

  //Handles enabled switches
  const handleUserCustomerScheduledExportsEnabled = (key: number, value: boolean) => {
    const replaced = (scheduledExports: IScheduledExport, key: number, value: boolean) => {
      const newItems: IScheduledExport = {
        recurringDay: scheduledExports.recurringDay,
        scheduledExportUserCustomerSettings:
          scheduledExports.scheduledExportUserCustomerSettings &&
          scheduledExports.scheduledExportUserCustomerSettings.map(d =>
            d.scheduledExportId === key ? { ...d, enabled: value } : d
          )
      };
      return newItems;
    };
    setUserCustomerScheduledExports(scheduledExports => ({
      ...scheduledExports,
      current: replaced(scheduledExports.current, key, value),
      equals: areEqual(replaced(scheduledExports.current, key, value), scheduledExports.original)
    }));
  };

  //Handles view changes
  const handleUserCustomerScheduledExportsOptionsChanged = (key: number, value: number[]) => {
    const replaced = (scheduledExports: IScheduledExport, key: number, value: number[]) => {
      const newItems: IScheduledExport = {
        recurringDay: scheduledExports.recurringDay,
        scheduledExportUserCustomerSettings:
          scheduledExports.scheduledExportUserCustomerSettings &&
          scheduledExports.scheduledExportUserCustomerSettings.map(d =>
            d.scheduledExportId === key ? { ...d, selectedViewIds: value } : d
          )
      };
      return newItems;
    };

    setUserCustomerScheduledExports(scheduledExports => ({
      ...scheduledExports,
      current: replaced(scheduledExports.current, key, value),
      equals: areEqual(replaced(scheduledExports.current, key, value), scheduledExports.original)
    }));
  };

  //Handling admin export switch enabled
  const handleUserScheduledExportsEnabled = (customerId: string, value: boolean) => {
    customerId && updateUserCustomerScheduledExportsEnabled(customerId, value);
  };
  const getUserCustomerScheduledExports = async (customerId?: string) => {
    if (customerId) {
      const scheduledExports = await getUserCustomerScheduledExportsList(customerId);
      if (scheduledExports)
        setUserCustomerScheduledExports({
          current: scheduledExports,
          original: scheduledExports,
          equals: true
        });
    }
  };

  //Handle if user weekly export checked
  const handleWeeklyChecked = (value: boolean, weekdayValue: number) => {
    const replaced = (scheduledExports: IScheduledExport, value: boolean) => {
      if (scheduledExports) {
        value === true
          ? weekdayValue > 1
            ? (scheduledExports.recurringDay = weekdayValue)
            : (scheduledExports.recurringDay = 1)
          : (scheduledExports.recurringDay = 0);
      }
      return scheduledExports;
    };
    setUserCustomerScheduledExports(scheduledExports => ({
      ...scheduledExports,
      current: replaced(scheduledExports.current, value),
      equals: areEqual(replaced(scheduledExports.current, value), scheduledExports.original)
    }));
  };

  //Handle user weekday selected
  const handleWeekdaySelected = (value: number) => {
    const replaced = (scheduledExports: IScheduledExport, value: number) => {
      scheduledExports.recurringDay = value;
      return scheduledExports;
    };

    setUserCustomerScheduledExports(scheduledExports => ({
      ...scheduledExports,
      current: replaced(scheduledExports.current, value),
      equals: areEqual(replaced(scheduledExports.current, value), scheduledExports.original)
    }));
  };
  useEffect(() => {
    state.customer && getUserCustomerNotificationsEnabled(state.customer.id);
    state.customer && getUserCustomerScheduledExportsEnabled(state.customer.id);
    state.customer && getCustomerNotifications(state.customer.id);
    state.customer && getCustomerScheduledExports(state.customer.id);
    state.customer && getUserCustomerNotifications(state.customer.id);
    state.customer && getUserCustomerScheduledExports(state.customer.id);
  }, [state.customer, state.notifications, state.customerNotification]);

  useEffect(() => {
    setUserCustomerNotifications({
      current: state.userCustomerNotifications,
      original: state.userCustomerNotifications,
      equals: true
    });
  }, []);

  useEffect(() => {
    setUserCustomerScheduledExports({
      current: state.scheduledExports,
      original: state.scheduledExports,
      equals: true
    });
  }, []);

  const configureFeatures = async () => {
    const f = { ...features };
    const { settings } = state;
    if (settings && settings.Mails) {
      if (settings.Mails.Feedback !== "off") {
        f.feedback = true;
      }
      if (settings.Mails.Support !== "off") {
        f.support = true;
      }
    }

    if (settings && settings.Features) {
      if (settings.Features.FaQ !== "off") {
        f.faq = true;
      }
    }

    toggleFeatures(f);
  };

  const userHasRole = () => {
    const user = state.user;
    const visibleItems = state.notifications.filter(x => x.visible);
    const userRole = visibleItems.some(
      x => x.roles.length === 0 || x.roles.some(y => y === user?.role)
    );
    return userRole;
  };

  const userHasScheduledExportRole = () => {
    const user = state.user;
    const exportItems = state.adminCustomerScheduleExports;
    const userRole = exportItems.some(
      x => x.roles.length === 0 || x.roles.some(y => y === user?.role)
    );
    return userRole;
  };
  const handleNotificationScopeChanged = (
    i: number,
    item?: IUserCustomerNotificationItem | undefined
  ) => {
    const n = userCustomerNotifications.current.map(n =>
      item && n.notificationId === item.notificationId ? { ...n, notificationScope: i } : n
    );
    setUserCustomerNotifications({
      current: n,
      original: n,
      equals: true
    });
  };

  const handleOnDayChanged = (i: number) => {
    const n = userCustomerNotifications.current.map(n =>
      n.recurringDay != i ? { ...n, recurringDay: i } : n
    );
    setUserCustomerNotifications({
      current: n,
      original: n,
      equals: true
    });
  };
  const handleOnFrequencyChanged = (i: number) => {
    const n = userCustomerNotifications.current.map(n =>
      n.frequency != i ? { ...n, frequency: i } : n
    );
    setUserCustomerNotifications({
      current: n,
      original: n,
      equals: true
    });
  };

  const handleUserCustomerNotificationsSave = () => {
    state.customer &&
      updateUserCustomerNotificationsList(state.customer.id, userCustomerNotifications.current);
  };

  const handleUserCustomerScheduledExportsSave = () => {
    if (state.customerScheduledExports[0].frequency === 0) {
      handleWeeklyChecked(false, 0);
      state.customer &&
        updateUserCustomerScheduledExportsList(
          state.customer.id,
          userCustomerScheduledExports.current
        );
    } else {
      state.customer &&
        updateUserCustomerScheduledExportsList(
          state.customer.id,
          userCustomerScheduledExports.current
        );
    }
  };

  useEffect(() => {
    getUserRoles();
    getUserDateFormats();
    if (state.customer) {
      getAdminCustomerNotificationsList(state.customer.id);
      getAdminCustomerScheduledExportsList(state.customer.id);
    }
  }, []);

  useEffect(() => {
    setAdminCustomerScheduledExports(state.adminCustomerScheduleExports);
  }, [state.adminCustomerScheduleExports]);

  useEffect(() => {
    setAdminCustomerNotifications(state.notifications);
    setUserCustomerNotifications({
      current: userCustomerNotifications.current,
      original: userCustomerNotifications.current,
      equals: true
    });
  }, [state.notifications, state.userCustomerNotifications]);

  useEffect(() => {
    configureFeatures();
  }, [state.settings]);

  const toggleShowNewCustomerForm = () => setShowNewCustomer(old => !old);
  const toggleNotificationsDialog = () => setShowNotificationsDialog(show => !show);
  const toggleScheduledExportsDialog = () => setShowScheduledExportsDialog(show => !show);
  const handleCustomerChanged = (customerId: string) => {
    persistCustomer(customerId);
    resetUser();
    history.push("/logged");
  };

  const handleVirtualRoleChanged = async (role: number | null) => {
    if (state.user) {
      await updateUserVirtualRole(state.user.id, role);
      resetUser();
      history.push("/logged");
    }
  };

  const handleUserPrefChanged = (prop: string, status: boolean) => {
    toggleUserPref(prop, status);
    persistPreference(prop, status);
  };

  const handleDateFormatChanged = async (format: string) => {
    if (state.user) {
      await updateUserDateFormat(state.user.id, format);
      resetUser();
      history.push("/logged");
    }
  };

  const toggleContact = () => setShowContact(show => !show);

  const appBarItems = () => {
    //TODO: hidden since needs to be reworked
    //const items = [<GlobalSearchInput key="search" />];
    return [
      isWalkthroughEnabled() && (
        <WalkthroughHeader
          walkthroughActive={true}
          key="general-walkthrough-icon"
          className="item"
          header=""
          tooltipInfo=""
          walkthroughInfo="To get a good overview of how to use the application, start a walkthrough!"
          alignment={DashboardTileAlignment.RIGHT}
          disabled={Object.values(state.fetchingData).some(f => f === true)}
          onClick={startWalkThrough}
        />
        // <AppBarItem
        //   className={`${Object.values(state.fetchingData).some(f => f === true) ? "disabled" : ""}`}
        //   iconName="help"
        //   onClick={startWalkThrough}
        //   tooltip="Guided walk through"
        // />
      ),
      <AppBarItem
        key="version_icon"
        text={`v${state.settings.Version}`}
        onClick={() => history.push("/help/release-notes")}
        tooltip="View release notes"
      />,
      (features.feedback || features.support) && (
        <AppBarItem
          key="contact_us_icon"
          iconName="message-contact-us"
          onClick={toggleContact}
          tooltip="Contact us"
        />
      )
    ].reduce((all: JSX.Element[], curr) => (curr ? [...all, curr] : all), []);
  };

  const dataFreshness = () => {
    const customer = currentCustomer();
    const dataUpdateTime = new Date(customer.dataUpdatedAt);
    const dbSchenkerUpdateTime = new Date(customer.dbSchenkerDataUpdateAt);
    const timeOffset = dataUpdateTime.getTimezoneOffset();
    const dbSchenkerTimeOffset = dbSchenkerUpdateTime.getTimezoneOffset();
    const localTime = subMinutes(dataUpdateTime, timeOffset);
    const dbSchenkerLocalTime = subMinutes(dbSchenkerUpdateTime, dbSchenkerTimeOffset);

    let dataUpdatedAt =
      !customer.isHoldingCompany && customer.dataUpdatedAt
        ? `${localeString("DataRefreshed") || "Data refreshed"}: ${dateTimeFormatter(
            localTime.toISOString(),
            state.user?.dateFormat
          )}`
        : "";
    if (customer.features.applicationSettings.isUsingDbSchenkerFtpIntegration) {
      dataUpdatedAt += customer.dbSchenkerDataUpdateAt
        ? `\nDBSchenker refreshed: ${dateTimeFormatter(
            dbSchenkerLocalTime.toISOString(),
            state.user?.dateFormat
          )}`
        : "";
    }
    return dataUpdatedAt;
  };

  const connectedCustomers = () => {
    if (!state.user) {
      return [];
    }

    const customers = state.user.customers.reduce(
      (all: ICustomerIdentity[], current: ICustomerIdentity) => {
        const customer = state.customers.find(c => c.id === current.id);
        return [...all, customer || current];
      },
      []
    );

    return customers
      .sort(stringSorter<ICustomerIdentity>(c => c.displayName))
      .map(c => ({ title: c.displayName, value: c }));
  };

  const selectedCustomer = () => {
    return state.userPreferences.customerId ? currentCustomer().displayName : "Select customer";
  };

  const manyCustomers = () => state.user && state.user.customers.length > 1;

  const dropDownItem = () => {
    return (
      <SettingsItem text="" className="top-user-pref-item">
        <div className="user-pref-customer-selection">
          <Dropdown
            label={selectedCustomer()}
            items={connectedCustomers().map(c => {
              return {
                title: c.title,
                value: c.value.id.toString()
              } as ISelectItem;
            })}
            onItemClick={(c: IDropdownItem) => {
              handleCustomerChanged(c.value);
            }}
            disabled={!manyCustomers()}
          />
        </div>
      </SettingsItem>
    );
  };
  const userInfo = useMemo(
    () => ({
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      header: dropDownItem(),
      subHeader: dataFreshness()
    }),
    [user, currentCustomer()]
  );

  interface IDynamicRoute {
    key: string;
    menuKey: MenuKey;
    component: any; //ReactElement | ReactNode;
    path: string;
  }

  const dynamicRoutes: IDynamicRoute[] = [
    {
      key: "orders",
      menuKey: MenuKey.PurchaseOrders,
      component: ExternalOrders,
      path: "/orders"
    },
    {
      key: "order-items-list",
      menuKey: MenuKey.PurchaseOrderItemsList,
      component: ExternalOrdersList,
      path: "/purchase-order-items"
    },
    {
      key: "internal-orders",
      menuKey: MenuKey.InternalPurchaseOrderList,
      component: InternalOrders,
      path: "/internal-orders"
    },
    {
      key: "delivery-schedule",
      menuKey: MenuKey.DeliverySchedule,
      component: DeliverySchedule,
      path: "/delivery-schedule"
    },
    {
      key: "internal-delivery-schedule",
      menuKey: MenuKey.InternalDeliverySchedule,
      component: InternalDeliverySchedule,
      path: "/internal-delivery-schedule"
    },
    {
      key: "shipment-details",
      menuKey: MenuKey.ShipmentList,
      component: Shipment,
      path: "/shipment/:shipmentId/:orderId?"
    },
    {
      key: "internal-shipment-details",
      menuKey: MenuKey.InternalPoShipmentList,
      component: InternalShipment,
      path: "/internal-shipment/:shipmentId/:orderId?"
    },
    {
      key: "shipment-list",
      menuKey: MenuKey.ShipmentList,
      component: Shipments,
      path: "/shipment"
    },
    {
      key: "internal-shipment-list",
      menuKey: MenuKey.InternalPoShipmentList,
      component: InternalShipments,
      path: "/internal-shipment"
    },
    {
      key: "inventory-nwh-single-material",
      menuKey: MenuKey.InventoryNationalWarehouse,
      component: InventoryNationalWarehouse,
      path: "/inventory-nwh/:materialId+"
    },
    {
      key: "inventory-nwh",
      menuKey: MenuKey.InventoryNationalWarehouse,
      component: InventoryNationalWarehouse,
      path: "/inventory-nwh"
    },
    {
      key: "serial-numbers",
      menuKey: MenuKey.SerialNumberView,
      component: SerialNumbers,
      path: "/serials"
    },
    {
      key: "internal-serial-numbers",
      menuKey: MenuKey.SerialNumberInternalView,
      component: InternalSerialNumbers,
      path: "/internal-serials"
    }
  ];

  const routes = (user: IUser) => {
    if (!state.userRoles.length || !currentCustomer().features.views.length) {
      return [
        <Route key="no-customers" path="*">
          <AppViewLoader />
        </Route>
      ];
    }

    //TODO: Just a simple fix for now to render No Customers if user has no customers
    if (user.customers.length <= 0) {
      return [
        <Route key="no-customers" path="*">
          <NoConnectedCustomer />
        </Route>
      ];
    }
    const routes = [
      <Route key="relnotes" path="/help/release-notes">
        <ReleaseNotesView />
      </Route>,
      <Route key="faq" path="/help/faq">
        <FaqView />
      </Route>,
      <Route key="dashboard" path="/dashboard">
        <DashboardContainer />
      </Route>
    ];

    dynamicRoutes.forEach(r => {
      if (isMenuEnabled(r.menuKey)) {
        const Component = r.component as any;
        routes.push(
          <Route key={r.key} path={r.path}>
            <LoadLazy>
              <Component />
            </LoadLazy>
          </Route>
        );
      }
    });

    // In case they want it back ...
    //
    // if (isMenuEnabled(MenuKey.PurchaseOrdersItems)) {
    //   routes.push(
    //     <Route key="order-items" path="/order-items">
    //       <Reports />
    //     </Route>
    //   );
    // }

    if (
      userRoleIsSuperUser(user.role) ||
      userRoleIsAdministrator(user.role) ||
      userRoleIsTechSupport(user.role)
    ) {
      routes.push(
        <Route key="administration" path="/administration">
          <LoadLazy>
            <Administration />
          </LoadLazy>
        </Route>
      );
    }

    return [
      ...routes,
      <Route key="default" exact path="/">
        <DashboardContainer />
      </Route>,
      <Route key="notfound" path="*">
        <NotFound />
      </Route>
    ];
  };

  useAutoLogout(
    process.env.NODE_ENV !== "development" &&
      state.userRoles.some(r => r) &&
      !userRoleIsInternal(user.role)
  );

  return (
    <Switch>
      <Route key="export-download" path="/export/download">
        <ExportDownload />
      </Route>
      <Route key="App" path="*">
        <SystemLayer
          parentRef={ref}
          acronym="ECOD"
          applicationName={`Ericsson Customer Order Dashboard${
            currentCustomer().displayName ? ` - ${currentCustomer().displayName}` : ""
          }`}
          user={userInfo as UserInfo}
          theme={currentTheme}
          persistTheme={false}
          settingsContent={[
            <Settings
              key="stx"
              onNewCustomerPressed={toggleShowNewCustomerForm}
              onVirtualRoleChanged={handleVirtualRoleChanged}
              onUserPrefChanged={handleUserPrefChanged}
              onDateFormatChanged={handleDateFormatChanged}
              onShowNotificationsDialog={toggleNotificationsDialog}
              onUserNotificationsEnabled={handleUserNotificationsEnabled}
              notificationsEnabled={state.userCustomerNotificationsEnabled}
              onShowScheduledExportsDialog={toggleScheduledExportsDialog}
              onUserScheduledExportsEnabled={handleUserScheduledExportsEnabled}
              scheduledExportsEnabled={state.userCustomerScheduledExportsEnabled}
            />
          ]}
          onLogout={onLogOut}
          onBrandClick={() => history.push("/")}
          appBarItems={appBarItems()}
        >
          <ApiErrorManager />
          <PersistTheme onThemeChanged={setCurrentTheme} />
          <StartupMessage />
          <NextDeployment />
          {currentCustomer().showFeedbackForm && <PopUpFeedback />}
          {/*<PopUpFeedback />*/
          /*this is to force show ecodSpecialUserNotificationDialog*/}
          <PendingUserRequestsNotification />
          {showNewCustomer && <NewCustomerForm onClose={toggleShowNewCustomerForm} />}
          <VirtualRoleBanner
            role={state.user ? state.user.virtualRole : null}
            onRestoreRole={() => handleVirtualRoleChanged(null)}
          />
          {showContact && (
            <ContactUs
              customerIdentifier={currentCustomer().identifier}
              onClose={toggleContact}
              typeOfForm={EViews.FEEDBACK}
            />
          )}
          {showNotificationsDialog && state.user && (userHasRole() || isAdminRole(state.user)) && (
            <NotificationsDialog
              customerIdentifier={currentCustomer().identifier}
              onClose={toggleNotificationsDialog}
              typeOfForm={EViews.FEEDBACK}
              onFrequencyChanged={handleOnFrequencyChanged}
              onNotificationScopeChanged={handleNotificationScopeChanged}
              onDayChanged={handleOnDayChanged}
              notificationsList={userCustomerNotifications}
              adminCustomerNotificationsList={adminCustomerNotifications}
              onNotificationsEnabled={handleUserCustomerNotificationsEnabled}
              viewChanged={viewUntouched}
              onSave={handleUserCustomerNotificationsSave}
            />
          )}
          {showScheduledExportsDialog &&
            state.user &&
            (userHasScheduledExportRole() || isAdminRole(state.user)) && (
              <div className="scheduled-export-dialog">
                <ScheduledExportsDialog
                  onClose={toggleScheduledExportsDialog}
                  adminCustomerExportItems={adminCustomerScheduledExports}
                  userCustomerScheduledExportItemsList={userCustomerScheduledExports}
                  onScheduledExportsEnabled={handleUserCustomerScheduledExportsEnabled}
                  onScheduledExportViewChanged={handleUserCustomerScheduledExportsOptionsChanged}
                  onWeeklyChecked={handleWeeklyChecked}
                  onWeekdaySelected={handleWeekdaySelected}
                  viewChanged={viewUntouched}
                  onSave={handleUserCustomerScheduledExportsSave}
                />
              </div>
            )}
          <ErrorBoundary FallbackComponent={ApplicationError}>
            <Switch>{routes(user)}</Switch>
          </ErrorBoundary>
          <WalkThrough />
        </SystemLayer>
      </Route>
    </Switch>
  );
};

export default Application;
