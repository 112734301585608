import React from "react";

interface Props {
  rUnit: string;
  x: number;
  y: number;
  rMin: number;
  rMax: number;
  seriesLength: number;
}

const SizeLegend = ({ rUnit, x, y, rMin, rMax, seriesLength }: Props) => {
  return (
    <g className="size-legend">
      <text textAnchor="end" x={x} y={y}>
        <tspan className="icon icon-size" fontSize="16px">
          
        </tspan>
        <tspan dx="8" dy="-1">
          {rUnit !== undefined ? rUnit : ""}
        </tspan>
        <tspan dx="16">{isNaN(rMin) ? "" : `Min: ${seriesLength !== 0 ? rMin : "-"}`}</tspan>
        <tspan dx="16">{isNaN(rMax) ? "" : `Max: ${seriesLength !== 0 ? rMax : "-"}`}</tspan>
      </text>
    </g>
  );
};

export default SizeLegend;
