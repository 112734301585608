import { Dialog, Icon } from "@react-gcc-eds/core";
import React, { ReactElement } from "react";

const SurveyDialog = ({
  leftContent,
  rightContent,
  onCancel
}: {
  leftContent: ReactElement;
  rightContent: ReactElement;
  onCancel: () => void;
}): ReactElement => {
  return (
    <Dialog className="survey-dialog" buttons={[]}>
      <Icon className="close-dialog" name="cross" onClick={onCancel}></Icon>
      <div className="left-content">{leftContent}</div>
      <div className="right-content">{rightContent}</div>
    </Dialog>
  );
};
export default SurveyDialog;
