import { ISearchResult, useStore, IMaterialInventorySearchResult } from "../store";
import { IDateRange, IPeriodRange } from "../contracts";

export const useMetricsFiltersActions = () => {
  const [_, setState] = useStore();

  const setMetricsOverallOrderStatusBarsCreationRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        overallOrderStatusBarsCreationRange: range
      }
    }));
  };

  const setMetricsShipmentStatusBarsDeliveredRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        shipmentDeliveredRange: range
      }
    }));
  };

  const setMetricsOverallOrderStatusBarsItemsSearch = (search?: ISearchResult) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        overallOrderStatusBarsItemsSearch: search
      }
    }));
  };

  const setMetricsOverallOrderStatusDonutCreationRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        overallOrderStatusDonutCreationRange: range
      }
    }));
  };

  const setMetricsPlannedDeliveryItemsSearch = (search?: ISearchResult) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        plannedDeliveryItemsSearch: search
      }
    }));
  };

  const setMetricsShipmentCountCreationRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        shipmentCountCreationRange: range
      }
    }));
  };

  const setMetricsOrderLeadTimeCreationRange = (range: IPeriodRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        orderLeadTimeCreationRange: range
      }
    }));
  };

  const setMetricsOrderAverageLeadTimeCreationRange = (range: IPeriodRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        orderAverageLeadTimeCreationRange: range
      }
    }));
  };

  const setMetricsDeliveryPrecisionCreationRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        deliveryPrecisionCreationRange: range
      }
    }));
  };

  const setMetricsOverallInternalOrderStatusBarsCreationRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        overallInternalOrderStatusBarsCreationRange: range
      }
    }));
  };

  const setMetricsOverallInternalOrderStatusDonutCreationRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        overallInternalOrderStatusDonutCreationRange: range
      }
    }));
  };

  const setMetricsMaterialInventorySearch = (search?: IMaterialInventorySearchResult) => {
    setState(old => ({
      ...old,
      metricsFilters: {
        ...old.metricsFilters,
        materialInventorySearch: search
      }
    }));
  };

  return {
    setMetricsOverallOrderStatusBarsCreationRange,
    setMetricsShipmentStatusBarsDeliveredRange,
    setMetricsOverallOrderStatusBarsItemsSearch,
    setMetricsOverallOrderStatusDonutCreationRange,
    setMetricsPlannedDeliveryItemsSearch,
    setMetricsShipmentCountCreationRange,
    setMetricsOrderLeadTimeCreationRange,
    setMetricsOrderAverageLeadTimeCreationRange,
    setMetricsDeliveryPrecisionCreationRange,
    setMetricsOverallInternalOrderStatusDonutCreationRange,
    setMetricsOverallInternalOrderStatusBarsCreationRange,
    setMetricsMaterialInventorySearch
  };
};
