import React from "react";

interface Props {
  active: boolean;
  size?: number;
  x: number;
  y: number;
  colorData: string;
}

const Circle = ({ active, size, x, y, colorData }: Props) => {
  return (
    <circle
      r={size !== undefined ? size : 3}
      opacity={active ? 1 : 0}
      className={`tooltip-circle ${colorData}`}
      cx={x + 2}
      cy={y}
    ></circle>
  );
};

export default Circle;
