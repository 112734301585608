import { Pill, SingleSelect, Tile } from "@react-gcc-eds/core";
import { LineChart } from "@react-gcc-eds/charts";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { IPeriodRange, monthRange, quarterRange, weekRange } from "../../../../contracts";
import { isFetching, useStore } from "../../../../store";
import { DashboardTileAlignment, DashboardTileAlignment as TileAlignment } from "../index";
import { NoMetrics, noSeriesMetric } from "../utils";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardOrderLeadTimeSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useLocalization } from "../../../../utils/localization";

const OrderLeadTime = ({
  className,
  customerId,
  alignment
}: {
  className: string;
  customerId: string;
  alignment: TileAlignment;
}) => {
  const [hiddenNames, setHiddenNames] = useState([]);
  const tileRef = useRef<HTMLDivElement>(null);
  const [state] = useStore();
  const { localeString } = useLocalization();
  const { steps } = useDashboardOrderLeadTimeSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { isWalkthroughEnabled } = useSettingsActions();
  const { getOrderLeadTimeMetric } = useMetricsActions();
  const { setMetricsOrderLeadTimeCreationRange } = useMetricsFiltersActions();

  useEffect(() => {
    getOrderLeadTimeMetric(state.metricsFilters.orderLeadTimeCreationRange);
  }, [state.metricsFilters.orderLeadTimeCreationRange, customerId]);

  const leadTimeMetric = () => {
    const { orderLeadTimeMetric } = state.metrics;
    const { orderLeadTimeMetric: loading } = state.fetchingData.metrics;

    if (loading) {
      return <NoMetrics loading />;
    }
    if (noSeriesMetric(orderLeadTimeMetric)) {
      return <NoMetrics />;
    }

    const toSeries = {
      series: orderLeadTimeMetric.headers.map((d, i) => {
        return {
          name: d,
          values: orderLeadTimeMetric.metrics
            .map(m => {
              return m.totals[i];
            })
            .reverse(),
          hidden: hiddenNames.find(n => n === d) !== undefined
        };
      }),
      common: orderLeadTimeMetric.metrics
        .map(k => {
          return k.label;
        })
        .reverse()
    };
    return (
      <LineChart
        onToggle={(data: any) =>
          setHiddenNames(data.series.filter((d: any) => d.hidden).map((d: any) => d.name))
        }
        y={{ isTime: false, unit: "Days" }}
        x={{ isString: true }}
        data={toSeries}
        fill
      />
    );
  };

  const filtersSummary = <Pill>{state.metricsFilters.orderLeadTimeCreationRange.title}</Pill>;

  const tileHeader = (
    <WalkthroughHeader
      walkthroughActive={isWalkthroughEnabled()}
      header={localeString("Dashboard_LeadTime") || "Lead Time"}
      tooltipInfo="Graph is showing the average lead time between highlighted statuses for the selected time period"
      alignment={alignment}
      onClick={startWalkThrough}
      disabled={isFetching(state)}
    />
  );

  const periodFilter = () => {
    const { orderLeadTimeCreationRange } = state.metricsFilters;

    return (
      <SingleSelect
        key="period-filter-select"
        label={orderLeadTimeCreationRange.title || ""}
        items={[weekRange, monthRange, quarterRange]}
        onChange={item => setMetricsOrderLeadTimeCreationRange(item as IPeriodRange)}
      />
    );
  };

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={"order-lead-time-tile"}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        subtitle={filtersSummary}
        actions={[periodFilter()]}
        title={tileHeader}
      >
        {leadTimeMetric()}
        <WalkThrough />
      </Tile>
    </>
  );
};

export default OrderLeadTime;
