import React from "react";

interface Props {
  x: number;
  y: number;
  r: number;
  onMouseEnter: Function;
  onMouseLeave: Function;
  onMouseMove: Function;
  onClick: Function;
}

const DotHover = ({ x, y, r, onMouseEnter, onMouseLeave, onMouseMove, onClick }: Props) => {
  return (
    <circle
      className="dot-hover"
      cx={x}
      cy={y}
      r={r}
      fill="blue"
      opacity="0"
      onMouseEnter={e => (onMouseEnter !== undefined ? onMouseEnter(e) : null)}
      onMouseLeave={e => (onMouseLeave !== undefined ? onMouseLeave(e) : null)}
      onMouseMove={e => (onMouseMove !== undefined ? onMouseMove(e) : null)}
      onClick={() => {
        onClick();
      }}
    ></circle>
  );
};

export default DotHover;
