import { useStore } from "../store";

export const useApiActions = () => {
  const [state, setState] = useStore();

  const pushError = (message: string) => {
    setState(old => ({ ...old, api: { ...old.api, errors: [...old.api.errors, message] } }));
  };

  const popError = (): string => {
    if (!state.api.errors.length) {
      return "";
    }

    const message = state.api.errors[0];
    setState(old => ({
      ...old,
      api: { ...old.api, errors: old.api.errors.filter((_: string, idx: number) => idx > 0) }
    }));
    return message;
  };

  return { pushError, popError };
};
