// Created at 2020-06-25
import React, { ReactElement } from "react";
import { ApplicationLayer, Button } from "@react-gcc-eds/core";
import FullScreenPanel from "../shared/full-screen-panel";

const NotFound = (): ReactElement => {
  return (
    <ApplicationLayer title="Application error">
      <FullScreenPanel>
        <div style={{ marginBottom: 10 }}>Ops. The requested page doesn't exist.</div>
        <Button onClick={() => window.location.replace("/")}>Go to Home</Button>
      </FullScreenPanel>
    </ApplicationLayer>
  );
};

export default NotFound;
