import React, { ReactElement } from "react";
import {
  getISODay,
  getDaysInMonth,
  startOfDay,
  startOfMonth,
  isEqual,
  isWithinInterval,
  isBefore
} from "date-fns";

import { IDateRange } from "../../../contracts";

const CalendarBody = ({
  visibleDate,
  onDateSelected,
  onDateHovered,
  preselectedRange,
  selectedRange,
  oldestDate
}: {
  visibleDate: Date;
  onDateSelected: Function;
  onDateHovered: Function;
  preselectedRange?: IDateRange;
  selectedRange?: IDateRange;
  oldestDate?: Date;
}) => {
  const numOfDaysInMonth = getDaysInMonth(visibleDate);
  const firstDayIndex = getISODay(startOfMonth(visibleDate));
  const result = [];
  let day = 1;
  const today = startOfDay(new Date());
  const row = (from: number, to: number) => {
    const tds: ReactElement[] = [];
    for (let x = from; x <= to; x += 1) {
      if (x >= firstDayIndex && day <= numOfDaysInMonth) {
        const cellDate = startOfDay(
          new Date(visibleDate.getFullYear(), visibleDate.getMonth(), day)
        );
        const unselectable = oldestDate && isBefore(startOfDay(cellDate), startOfDay(oldestDate));
        tds.push(
          <td
            key={`drf_${x}`}
            role="gridcell"
            className={`${
              selectedRange &&
              isWithinInterval(cellDate, {
                start: startOfDay(selectedRange.from),
                end: startOfDay(selectedRange.to)
              })
                ? "selected"
                : ""
            } ${
              preselectedRange &&
              isWithinInterval(cellDate, {
                start: startOfDay(preselectedRange.from),
                end: startOfDay(preselectedRange.to)
              })
                ? "preselected"
                : ""
            } ${unselectable ? "no-data" : ""}`}
            onClick={() => !unselectable && onDateSelected(cellDate)}
            onMouseEnter={() => !unselectable && onDateHovered(cellDate)}
          >
            {isEqual(today, cellDate) ? <span className="today">{day}</span> : day}
          </td>
        );
        day += 1;
      } else {
        tds.push(
          <td key={`drf_${x}`} className="un-selectable">
            {" "}
          </td>
        );
      }
    }
    return <tr key={`dr_${from}`}>{tds}</tr>;
  };
  result.push(row(1, 7));
  result.push(row(8, 14));
  result.push(row(15, 21));
  result.push(row(22, 28));
  result.push(row(29, 35));
  result.push(row(36, 42));

  return <>{result}</>;
};

export default CalendarBody;
