import { useStore } from "../store";
import { useSettingsActions } from "../actions/settings-actions";
import { useUserHelpers } from "./user-helpers";

export enum MenuKey {
  Dashboard = "Dashboard",
  PurchaseOrders = "PurchaseOrders",
  DeliverySchedule = "PurchaseOrdersItems",
  PurchaseOrderItemsList = "PurchaseOrderItemsList",
  InternalPurchaseOrdersItems = "InternalPurchaseOrdersItems",
  InternalPurchaseOrderList = "InternalPurchaseOrders",
  InternalDeliverySchedule = "InternalDeliveryScheduleList",
  ShipmentList = "ShipmentList",
  InternalPoShipmentList = "InternalShipmentList",
  InventoryNationalWarehouse = "NWHInventory",
  SerialNumberView = "SerialNumberView",
  SerialNumberInternalView = "SerialNumberInternalView",
  Administration = "Administration",
  AdministrationGuide = "AdministrationGuide",
  Help = "Help",
  UserGuide = "UserGuide",
  FAQ = "FAQ",
  ReleaseNotes = "ReleaseNotes",
  ReleaseTutorials = "ReleaseTutorials",
  ReportAnIssue = "ReportAnIssue",
  ExternalLinks = "ExternalLinks"
}
export interface IMenuEntry {
  accessor: MenuKey;
  localizationKey: string;
  order: number;
  name: string;
  to?: string;
  tag?: any;
  exact?: boolean;
  isActive?: (match: any, location: any) => boolean;
  entries?: IMenuEntry[];
}

export const MenuEntries: IMenuEntry[] = [
  {
    accessor: MenuKey.Dashboard,
    localizationKey: "MenuKeys_Dashboard",
    name: "Dashboard",
    order: 0,
    isActive: (match: any, location: any) =>
      location.pathname === "/dashboard" || location.pathname === "/",
    to: "/dashboard"
  },
  {
    accessor: MenuKey.PurchaseOrders,
    localizationKey: "MenuKeys_PurchaseOrders",
    name: "Purchase order list",
    to: "/orders",
    order: 1
  },
  {
    accessor: MenuKey.PurchaseOrderItemsList,
    localizationKey: "MenuKeys_PurchaseOrderItemsList",
    name: "Purchase order items list",
    to: "/purchase-order-items",
    order: 2
  },
  {
    accessor: MenuKey.DeliverySchedule,
    localizationKey: "MenuKeys_PurchaseOrdersItems", //Gammal key, beroende av localisation i backend.
    name: "Delivery schedule",
    to: "/delivery-schedule",
    order: 2
  },
  {
    accessor: MenuKey.InternalDeliverySchedule,
    localizationKey: "MenuKeys_InternalDeliveryScheduleList",
    name: "Internal delivery schedule",
    to: "/internal-delivery-schedule",
    order: 3
  },
  {
    accessor: MenuKey.InternalPurchaseOrderList,
    localizationKey: "MenuKeys_InternalPurchaseOrders",
    name: "Internal purchase orders",
    to: "/internal-orders",
    order: 4
  },
  {
    accessor: MenuKey.ShipmentList,
    localizationKey: "MenuKeys_ShipmentList",
    name: "Shipment list",
    order: 5,
    to: "/shipment"
  },
  {
    accessor: MenuKey.InternalPoShipmentList,
    localizationKey: "MenuKeys_InternalShipmentList",
    name: "Shipment list internal",
    order: 5,
    to: "/internal-shipment"
  },
  {
    accessor: MenuKey.SerialNumberView,
    localizationKey: "MenuKeys_SerialNumberView",
    name: "Serial numbers",
    to: "/serials",
    order: 6
  },
  {
    accessor: MenuKey.SerialNumberInternalView,
    localizationKey: "MenuKeys_SerialNumbersInternalView",
    name: "Serial numbers internal",
    to: "/internal-serials",
    order: 7
  },
  {
    accessor: MenuKey.InventoryNationalWarehouse,
    localizationKey: "MenuKeys_NWHInventory",
    name: "Inventory E/// WH",
    to: "/inventory-nwh",
    order: 8
  },
  {
    accessor: MenuKey.Administration,
    to: "",
    localizationKey: "MenuKeys_Administration",
    name: "Administration",
    order: 9
  },
  {
    accessor: MenuKey.Help,
    localizationKey: "MenuKeys_Help",
    name: "Help",
    order: 10,
    entries: [
      {
        accessor: MenuKey.UserGuide,
        to: "/user-guide-201210.pdf",
        localizationKey: "",
        order: 1,
        name: "Administration guide"
      },
      {
        accessor: MenuKey.FAQ,
        localizationKey: "",
        to: "/help/faq",
        exact: true,
        name: "FAQ",
        order: 2
      },
      {
        accessor: MenuKey.ReleaseNotes,
        localizationKey: "",
        to: "/help/release-notes",
        name: "Release notes",
        exact: true,
        order: 3
      },
      {
        accessor: MenuKey.ReleaseTutorials,
        localizationKey: "",
        to: "",
        name: "Release tutorials",
        exact: true,
        order: 4
      },
      {
        accessor: MenuKey.ReportAnIssue,
        localizationKey: "",
        to: "",
        name: "Report an issue",
        exact: true,
        order: 5
      }
    ]
  },
  {
    accessor: MenuKey.ExternalLinks,
    localizationKey: "MenuKeys_ExternalLinks",
    name: "External links",
    order: 17
  }
];

export const useCustomerHelpers = () => {
  const [state] = useStore();
  const { currentCustomer } = useSettingsActions();
  const { userRoleIsCustomer } = useUserHelpers();

  const isMenuEnabled = (menu: MenuKey) => {
    return currentCustomer().features.views.includes(menu);
  };

  const areMenuRolesValid = (menu: MenuKey) => {
    if (!state.user) return false;

    switch (menu) {
      case MenuKey.ReleaseTutorials:
        return !userRoleIsCustomer(state.user.role) && state.user.role !== 3;
      default:
        return true;
    }
  };

  return { isMenuEnabled, areMenuRolesValid };
};
