import React from "react";
import Bar from "./Bar";
import BarHover from "./BarHover";

interface Props {
  x1: number;
  x2: number;
  colorData: string;
  label: string;
  value?: string;
  opacity: number;
  onMouseHover: Function;
  onClick: Function;
  onClickProvided: boolean;
}

const StackedLineBarGroup = ({
  x1,
  x2,
  colorData,
  label,
  value,
  opacity,
  onMouseHover,
  onClick,
  onClickProvided
}: Props) => {
  return (
    <>
      <Bar
        x1={x1}
        x2={x2}
        y1={7}
        y2={7}
        colorData={colorData}
        thresholdColor=""
        lineOpacity={opacity}
      />
      <BarHover
        x1={x1}
        x2={x2}
        strokeWidth={30}
        onMouseEnter={() => {
          onMouseHover(true);
        }}
        onMouseLeave={() => {
          onMouseHover(false);
        }}
        onMouseMove={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          onMouseHover(true, e);
        }}
        onClick={() => {
          onClick(label, value);
        }}
        onClickProvided={onClickProvided}
      />
    </>
  );
};

export default StackedLineBarGroup;
