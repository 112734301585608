import * as d3 from "d3";
import React, { useState, useEffect, useRef, useCallback } from "react";
import cx from "classnames";
import { Serie, HistogramData as Data, TooltipItem } from "../common/interfaces";
import * as utils from "../common/helpers";
import Legend from "../common/components/Legend";
import HistogramGroups from "../common/components/HistogramGroups";
import Unit from "../common/components/Unit";
import Tooltip from "../common/components/Tooltip";
import AxisTresholds from "../common/components/AxisTresholds";
import IHistogram from "./IHistogram";
import AxisBottom from "../common/components/AxisBottom";
import AxisLeft from "../common/components/AxisLeft";

const Histogram = ({
  data,
  fill = false,
  hideLegend = false,
  width = 1000,
  height = 400,
  onToggle,
  unit,
  thresholds,
  formatX,
  formatY,
  tooltipFormat,
  onClick,
  binSize = "medium",
  parentRef,
  invertColorScheme = false,
  hideStandardTooltipItems = false
}: IHistogram) => {
  if (data.series.length > 3) {
    // eslint-disable-next-line no-console
    console.warn(
      "Don't compare more than 3 series in the same chart. If you absolutely have to, considering spreading your data in different histograms."
    );
  }

  const errorMsg = utils.checkData(data as any, false); // Check if data is correct
  if (errorMsg !== "") {
    throw new Error(errorMsg);
  }

  const [initialized, setInitialized] = useState(false);
  const axisPadding = 8;
  const fontSize = 14;

  const ref = useRef<HTMLDivElement>(null);
  const legendRef = useRef<HTMLDivElement>(null);

  // chart-legend
  const heightOffset = 20;
  const defaultLegendHeight = hideLegend ? 0 : 55;
  const chartLegendHeight =
    legendRef !== null && legendRef.current !== null
      ? legendRef.current.clientHeight + heightOffset
      : defaultLegendHeight;

  const [Height, setHeight] = useState(height);
  const [Width, setWidth] = useState(width);
  const [emptyChart, setEmptyChart] = useState(false);

  useEffect(() => {
    const clientWidth = ref !== null && ref.current !== null ? ref.current.clientWidth : 0;
    const clientHeight = ref !== null && ref.current !== null ? ref.current.clientHeight : 0;

    setWidth(clientWidth);
    setHeight(clientHeight - chartLegendHeight);
  });

  const colorScale = utils.getColorScale(data.series.length, undefined, invertColorScheme);
  const [hoveredSerie, setHoveredSerie] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipData, setTooltipData] = useState({
    unitTitle: "",
    unitValue: "",
    items: [] as TooltipItem[],
    description: "" as string | JSX.Element
  });

  const [allHidden, setAllHidden] = useState(false);
  const [totalValues, setTotalValues] = useState([] as number[]);
  const [chartData, setChartData] = useState({} as unknown as Data);

  // Windowresize listener
  const [, updateState] = React.useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("toggleNavigation", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("toggleNavigation", handleResize);
    };
  }, []);

  useEffect(() => {
    const newChartData = Object.assign({}, data);

    if (newChartData.series[0].values.length === 0) {
      newChartData.series = [
        {
          name: "",
          values: [16.6, 37.2, 40.866667, 39.366667, 36.6]
        }
      ];
      setEmptyChart(true);
    } else {
      setEmptyChart(false);
    }

    const willHideAll = data.series.filter((s: Serie) => s.hidden).length === data.series.length;

    const newTotalValues = [] as number[];
    if (willHideAll) {
      data.series.forEach((serie: Serie) => {
        serie.values.forEach((number: number) => {
          newTotalValues.push(number);
        });
      });
    } else {
      newChartData.series.forEach((serie: Serie) => {
        if (!serie.hidden) {
          serie.values.forEach((number: number) => {
            newTotalValues.push(number);
          });
        }
      });
    }

    setAllHidden(willHideAll);
    setTotalValues(newTotalValues);
    setChartData(newChartData);
    setInitialized(true);
  }, [data, data.series, allHidden]);

  const colors = {};
  data.series.forEach((d: any) => {
    colors[d.name] = colorScale(d.name);
  });

  const xMin = Math.min(...totalValues);
  const xMax = Math.max(...totalValues);

  const size = {
    small: 15,
    medium: 25,
    large: 50
  };

  // Number of bars (resolution of histogram)
  const dividend = size[binSize];
  const count = Math.round(Width / dividend);

  const xScale = d3
    .scaleLinear()
    .domain([xMin, xMax])
    .nice()
    .range([0, Width - 80]);

  const histogram = d3
    .histogram()
    .domain([xScale.domain()[0], xScale.domain()[1]])
    .thresholds(xScale.ticks(count));

  if (!initialized) {
    return <div></div>;
  }
  const hiddenBins = [] as number[];
  const bins = [] as any[];
  chartData.series.forEach((s: Serie, i: number) => {
    bins.push(histogram(s.values));
    if (s.hidden) {
      hiddenBins.push(i);
    }
  });

  const maxBin = d3.max(
    // @ts-ignore
    bins.filter((b, i) => !hiddenBins.includes(i) || allHidden),
    d => d3.max(d, (e: any) => e.length)
  );

  const yScale = d3
    .scaleLinear()
    .domain([0, maxBin !== undefined ? parseInt(maxBin, 0) : 0])
    .nice()
    .range([Height - 100, 0]);

  const heightStyling = fill
    ? { height: "100%", width: "100%" }
    : { height: `${height + chartLegendHeight}px`, width };

  // const sizeFromParentRef = parentRef && parentRef.current ?  { height:  `${parentRef.current.clientHeight}px`, width: `${parentRef.current.clientWidth}px`} : {height:'inherit'}
  const className = cx("chart-container", binSize);

  const sizeFromParentRef =
    parentRef && parentRef.current
      ? {
          height: `${parentRef.current.clientHeight}px`,
          width: `${parentRef.current.clientWidth}px`
        }
      : { height: "100%", width: "100%", display: "flex" };

  return (
    <div className={className} style={heightStyling}>
      {!hideLegend && (
        <div ref={legendRef}>
          <Legend
            series={data.series}
            colorScale={colors}
            onMouseHover={(s: string) => {
              setHoveredSerie(s);
            }}
            onClick={(i: number) => {
              if (onToggle) {
                const newData = utils.jsonClone(data) as Data;
                newData.series[i].hidden = !newData.series[i].hidden;
                onToggle(newData, i);
                setHoveredSerie("");
              }
            }}
          />
        </div>
      )}
      <div ref={ref} style={!fill ? { height: "inherit" } : sizeFromParentRef}>
        <svg className="chart" style={{ height: "100%", width: "110%", display: "flex" }}>
          <g transform="translate(40,38)" className="canvas histogram">
            <AxisLeft
              yScale={yScale}
              tickSize={-(Width - 50)}
              tickPadding={axisPadding}
              ticks={Height / 60}
              line
              noLine
              width={Width - 50}
              height={Height - 100}
              hideValues={emptyChart}
              tickFormat={formatY !== undefined ? formatY : undefined}
            />
            {!emptyChart && (
              <AxisTresholds
                left
                yScale={yScale}
                tickSize={Width - 50}
                tickPadding={axisPadding}
                thresholds={thresholds}
                tickFormat={formatX !== undefined ? formatX : undefined}
                fontSize={fontSize}
                innerTicks={fill}
              />
            )}
            {!emptyChart && !allHidden && (
              <HistogramGroups
                data={chartData}
                hiddenBins={hiddenBins}
                bins={bins}
                xScale={xScale}
                yScale={yScale}
                colorScale={colors}
                thresholds={thresholds}
                height={Height - 100}
                tooltipFormat={tooltipFormat}
                hoveredSerie={hoveredSerie}
                onHover={(
                  e: React.MouseEvent<SVGLineElement, MouseEvent>,
                  label: string,
                  tooltipItems: TooltipItem[],
                  index: number,
                  isHovered: boolean
                ) => {
                  if (e !== undefined && isHovered) {
                    setTooltipVisible(true);
                    setTooltipData({
                      unitTitle: label,
                      unitValue: unit !== undefined ? unit : "",
                      items: tooltipItems,
                      description:
                        data.descriptions !== undefined && index < data.descriptions.length
                          ? data.descriptions[index]
                          : ""
                    });
                    setTooltipPosition({ x: e.clientX, y: e.clientY });
                  } else {
                    setTooltipVisible(false);
                  }
                }}
                onClick={(l: string, v: number) => onClick && onClick(l, v)}
              />
            )}
            <AxisBottom
              xScale={xScale}
              tickSize={Height - 90}
              tickPadding={axisPadding}
              ticks={Width / 120}
              tickFormat={formatX !== undefined ? formatX : undefined}
              line
              domain
              hideValues={emptyChart}
            />
            <Unit x={Width - 100} y={Height - 50} unit={unit} />
          </g>
        </svg>
        <Tooltip
          colors={colors}
          unitTitle={tooltipData.unitTitle}
          unitValue={tooltipData.unitValue}
          description={tooltipData.description}
          items={tooltipData.items}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          visible={tooltipVisible}
          hideStandardItems={hideStandardTooltipItems}
        />
      </div>
    </div>
  );
};

export default Histogram;
