import React, { ReactNode, SyntheticEvent, useRef, useState } from "react";
import { Loader } from "@react-gcc-eds/core";
import { InnerTooltip } from "./inner-tooltip";
import { ICustomTooltip } from "../../../../store";

const LoadingTooltip = () => (
  <div style={{ display: "flex", flexWrap: "nowrap" }}>
    <Loader size="small" />
    <span style={{ marginLeft: "4px", whiteSpace: "nowrap" }}>Loading tooltip</span>
  </div>
);

export const CustomTooltip = ({
  children,
  tooltip,
  props,
  position,
  lockable,
  maxWidthPixels,
  maxLengthChars,
  className,
  minWidthPixels,
  breakStyle,
  onMouseOver,
  onMouseLeave,
  hoverColor
}: {
  children: ReactNode;
  tooltip: string | ReactNode | ICustomTooltip[];
  position?: "top" | "top-end" | "bottom" | "bottom-end" | "left" | "right";
  props?: any;
  lockable?: boolean;
  maxWidthPixels?: number;
  maxLengthChars?: number;
  minWidthPixels?: number;
  className?: string;
  breakStyle?: "pixelWidth" | "wordLength" | "keep-all";
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  hoverColor?: boolean;
}) => {
  const [show, setShow] = useState(false);
  const [locked, setLocked] = useState(false);
  const showRef = useRef<number | null>(null);

  const mouseOver = () => {
    if (!showRef.current) {
      showRef.current = window.setTimeout(() => {
        !locked && setShow(true);
      }, 250);
    }
    onMouseOver && onMouseOver();
  };

  const mouseLeave = () => {
    if (showRef.current) {
      clearTimeout(showRef.current);
      showRef.current = null;
    }
    !locked && setShow(false);
    onMouseLeave && onMouseLeave();
  };

  const onLock = (e: SyntheticEvent) => {
    e.stopPropagation();
    setLocked(true);
  };

  const onUnlock = (e: SyntheticEvent) => {
    e.stopPropagation();
    setLocked(false);
  };
  const noTooltip =
    !tooltip || ((Array.isArray(tooltip) || typeof tooltip === "string") && tooltip.length === 0);

  return (
    <div onMouseOver={mouseOver} onMouseLeave={mouseLeave} className="technical-tooltip">
      {show && !noTooltip ? (
        <InnerTooltip
          {...props}
          key={`inner-tooltip`}
          position={position}
          breakStyle={breakStyle}
          maxWidthPixels={maxWidthPixels}
          maxLengthChars={maxLengthChars}
          minWidthPixels={minWidthPixels}
          hoverColor={hoverColor}
          className={className}
          tooltip={tooltip || LoadingTooltip()}
          lockable={
            tooltip && lockable
              ? {
                  locked,
                  onLock,
                  onUnlock
                }
              : undefined
          }
        >
          {children}
        </InnerTooltip>
      ) : (
        <div
          key={"simple-header"}
          className={`simple-header ${hoverColor ? "hover-color" : ""}`}
          {...props}
        >
          {children}
        </div>
      )}
    </div>
  );
};
