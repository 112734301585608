import React from "react";
import { Icon, Button } from "@react-gcc-eds/core";
import { useUserHelpers } from "../../utils/user-helpers";

const VirtualRoleBanner = ({
  role,
  onRestoreRole
}: {
  role: number | null;
  onRestoreRole: () => void;
}) => {
  const { userRoleName } = useUserHelpers();

  if (role === null) {
    return null;
  }

  return (
    <div className="virtual-role-banner">
      <Icon name="info" />
      <>{`You are currently using ECOD with the virtual role '${userRoleName(role)}'.`}</>
      <Button onClick={onRestoreRole}>Restore role</Button>
    </div>
  );
};

export default VirtualRoleBanner;
