import React, { useState } from "react";
import { BubbleChartData as Data, PlotSerie as Serie, Colors } from "../interfaces";
import DotGroup from "./DotGroup";

interface Props {
  data: Data;
  yScale: any;
  xScale: any;
  rScale: any;
  colorScale: Colors;
  hoveredSerie: string;
  onHover: Function;
  onHoverLeave: Function;
  xUnit?: string;
  yUnit?: string;
  rUnit?: string;
  onClick: Function;
}

const DotGroups = ({
  data,
  yScale,
  xScale,
  rScale,
  colorScale,
  onHover,
  onHoverLeave,
  hoveredSerie,
  xUnit,
  yUnit,
  rUnit,
  onClick
}: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [hoveredDot, setHoveredDot] = useState("");
  const defaultOpacity = hoveredDot === "" && hoveredSerie === "" ? 1 : 0.2;
  return (
    <g className="dots">
      {data.series.map((serie: Serie, index: number) => {
        let colorData = colorScale[serie.name];
        colorData = colorData !== undefined ? colorData : "red";
        return serie.values.map((serieData: any, j: number) => {
          const key = index + j;
          return (
            <DotGroup
              key={key}
              x={xScale(serieData.x)}
              y={yScale(serieData.y)}
              r={rScale(serieData.r)}
              colorData={colorData}
              opacity={
                hoveredDot === serieData.name || hoveredSerie === serie.name ? 1 : defaultOpacity
              }
              onMouseHover={(
                isHovered: boolean,
                e: React.MouseEvent<SVGLineElement, MouseEvent>
              ) => {
                if (isHovered) {
                  setHoveredIndex(index);
                  setHoveredDot(serieData.name);
                } else {
                  setHoveredIndex(-1);
                  setHoveredDot("");
                }

                const items = [
                  {
                    key:
                      data.series[hoveredIndex] !== undefined ? data.series[hoveredIndex].name : "",
                    subItems: {
                      x: {
                        key: xUnit,
                        value: serieData.x
                      },
                      y: {
                        key: yUnit,
                        value: serieData.y
                      },
                      r: {
                        key: rUnit,
                        value: serieData.r
                      }
                    }
                  }
                ];

                const label = `${serieData.name}`;
                onHover(e, label, items, index, isHovered, hoveredDot);
              }}
              onMouseLeave={(event: React.MouseEvent) => onHoverLeave(event)}
              onClick={() => {
                const label = serieData.name;
                const value = [serieData.x, serieData.y, serieData.r];
                onClick(label, value);
              }}
            />
          );
        });
      })}
    </g>
  );
};

export default DotGroups;
