import {
  AuthenticationResult,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  RedirectRequest
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUserActions } from "../../actions/user-actions";
import { useStore } from "../../store";
import { persistUnauthorizedUrl } from "../../utils/local-storage/authorization";
import { loginRequest } from "./msal-config";

/**
 * -------------------------------------------------------
 * MSAL authentication functions based on ADAL equivalents
 * in ./auth-flow.tsx.
 * -------------------------------------------------------
 */
export const useMsalFlow = () => {
  const { instance, accounts, inProgress } = useMsal();

  /**
   * Adds an event listener for successful login events and sets the user
   * account as active account. A callback id is kept to unsubscribe to the
   * event at useEffect cleanup.
   */
  const useAddMsalEventCallback = () => {
    useEffect(() => {
      const callbackId = instance.addEventCallback(event => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ) {
          instance.setActiveAccount((event.payload as AuthenticationResult).account);
        } else if (event.eventType === EventType.LOGIN_FAILURE) {
          console.error(event.error);
        }
      });

      return () => {
        if (callbackId) instance.removeEventCallback(callbackId);
      };
    }, []);
  };

  /**
   * Signs in user to application. Will redirect to Microsoft's authentication
   * page if account has no valid token.
   */
  const signIn = () => {
    if (inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
      });
    }
  };

  /**
   * By calling acquireTokenSilent(), the function first looks for a cached
   * token. If none exists or it is invalid, tries to acquire access token
   * silently for later use in HTTP request header. If this attempt fails,
   * tries to acquire token via "Redirect" user login method.
   *
   * As per the documentation for msal-react v.2.0.21, the silent acquisition
   * always fetches both an access token and id token.
   */
  const acquireToken = async (onTokenAcquired?: () => Promise<void>) => {
    let tokenString = "";
    let interactiveLoginRequired = false;
    const requestForAccount: RedirectRequest = {
      ...loginRequest
    };

    if (inProgress === InteractionStatus.None) {
      await instance
        .acquireTokenSilent(requestForAccount)
        .then(async authResult => {
          tokenString = authResult.idToken; //Replace accessToken with idToken to avoid status code 401 from API.
          onTokenAcquired && (await onTokenAcquired());
        })
        .catch(async e => {
          if (e instanceof InteractionRequiredAuthError) {
            interactiveLoginRequired = true;
          } else {
            if (e.errorCode && !isSkippableErrorCode(e.errorCode)) console.error(e);
          }
        });

      if (interactiveLoginRequired) {
        await instance.acquireTokenRedirect(requestForAccount);
        onTokenAcquired && (await onTokenAcquired());
      }
    }
    return tokenString;
  };

  const useAutoRedirectToLoginWhenNoUser = () => {
    const [state] = useStore();
    const history = useHistory();

    useEffect(() => {
      if (!state.user) {
        const timeOut = setTimeout(() => {
          persistUnauthorizedUrl(history.location.pathname + history.location.search);
          history.replace("/login");
        }, 1000);

        return () => clearTimeout(timeOut);
      }
    }, []);
  };

  /**
   * Redirects immediately to /logged without going via Microsoft's
   * authentication page if the current account already has a valid token.
   *
   * @param active
   */
  const useAutoRedirectToLoggedWhenTokenValid = (active: boolean) => {
    const history = useHistory();
    const { resetUser } = useUserActions();
    const isAuthenticated = useIsAuthenticated(accounts[0]);

    useEffect(() => {
      if (active && isAuthenticated) {
        if (accounts[0]) history.replace("/logged");
        else resetUser();
      }
    }, [active, isAuthenticated]);
  };

  const useAutoLogout = (active: boolean) => {
    const [state] = useStore();
    const [timeoutId, saveTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const { resetUser } = useUserActions();

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      saveTimeoutId(
        setTimeout(
          () => {
            resetUser();
            //User will be logged out
            instance.logoutRedirect();
          },
          1000 * 60 * 60
        )
      );
    };

    //If api is reading/writing timer will be reset
    useEffect(() => {
      if (active) resetTimer();
    }, [state.api.pendingReads, state.api.pendingWrites]);
  };

  const signOut = () => {
    instance.logoutRedirect();
  };

  const isSkippableErrorCode = (errorCode: string) => {
    const skippableErrorCodes = ["user_cancelled"];

    if (skippableErrorCodes.includes(errorCode)) return true;
    else return false;
  };

  return {
    useAddMsalEventCallback,
    signIn,
    acquireToken,
    useAutoRedirectToLoginWhenNoUser,
    useAutoRedirectToLoggedWhenTokenValid,
    useAutoLogout,
    signOut
  };
};
