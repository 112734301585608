import cx from "classnames";
import React, { useRef } from "react";
import { Colors, TooltipItem } from "../interfaces";

interface Props {
  colors: Colors;
  unitTitle: string;
  unitValue: string;
  description?: string | JSX.Element;
  items: TooltipItem[];
  x: number;
  y: number;
  visible: boolean;
  small?: boolean;
  hideStandardItems?: boolean;
}

const Tooltip = ({
  colors,
  items,
  unitTitle,
  unitValue,
  description = "",
  x,
  y,
  visible,
  small = false,
  hideStandardItems = false
}: Props) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const tooltipItems = items.map((d: TooltipItem, i: number) => {
    let colorData = colors[d.key];
    colorData = colorData !== undefined ? colorData : "red";

    const isNan = (v: string | undefined) => v === "NaN" || v === undefined;

    const key = i;
    return (
      <div key={key} className="item">
        <i role="presentation" className={cx("icon", `${colorData}`, "icon-alarm-level6")} />
        {d.key.trim() !== "" ? <span className="key">{d.key}</span> : ""}
        {d.key.trim() !== "" ? (
          <span className="value">&nbsp;{!isNan(d.value) ? d.value : "NA"}</span>
        ) : (
          <span className="key" style={{ fontWeight: "bold" }}>
            &nbsp;{!isNan(d.value) ? d.value : "NA"}
          </span>
        )}
      </div>
    );
  });

  const mouseDistance = 12;
  const offsetWidth = tooltipRef.current !== null ? tooltipRef.current.offsetWidth : 0;
  const offsetHeight = tooltipRef.current !== null ? tooltipRef.current.offsetHeight : 0;

  const xPos =
    x + offsetWidth + mouseDistance > window.innerWidth
      ? x - offsetWidth - mouseDistance
      : x + mouseDistance;
  const yPox =
    y + offsetHeight + mouseDistance > window.innerHeight
      ? y - offsetHeight - mouseDistance / 2
      : y + mouseDistance;

  const insideBounds = x + offsetWidth + mouseDistance < window.innerWidth;

  const smallTooltip = (
    <div
      ref={tooltipRef}
      className={cx("chart-tooltip-small", { visible })}
      style={{
        top: `${yPox}px`,
        left: `${insideBounds ? xPos : xPos + 70}px`,
        display: "flex"
      }}
    >
      <span className="tooltip dotted">
        <span className={cx("message visible", !insideBounds ? "top-end" : "top")}>
          <div>
            {" "}
            {unitTitle}: {unitValue}
          </div>
        </span>
      </span>
    </div>
  );

  return !small ? (
    <div
      ref={tooltipRef}
      className={cx("chart-tooltip", { visible })}
      style={{ top: `${yPox}px`, left: `${xPos}px`, display: "flex" }}
    >
      <div className="content">
        <div className="title">
          <span className="unit-title">{unitTitle}</span>
          <span className="unit-value" style={{ float: "right" }}>
            {unitValue}
          </span>
        </div>
        {hideStandardItems ? undefined : tooltipItems}
        {description !== "" && <div className="description">{description}</div>}
      </div>
    </div>
  ) : (
    smallTooltip
  );
};

export default Tooltip;
