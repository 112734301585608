import { useState } from "react";
import { Button, Dialog, Row, TextField, useNotifications } from "@react-gcc-eds/core";
import { useUserAccessRequestActions } from "../../../actions/user-access-request-actions";

const NewCustomerForm = ({ onClose }: { onClose: Function }) => {
  const [customer, setAccessToCustomers] = useState("");

  const { newCustomerRequest } = useUserAccessRequestActions();
  const { createNotification } = useNotifications();

  const register = () => {
    newCustomerRequest(customer, () =>
      createNotification(
        "New customer access request",
        `Your request for customer '${customer}' has been processed successfully`
      )
    );
    onClose();
  };

  const formValid = () => {
    return customer;
  };

  const buttons = () => {
    return [
      <Button key="cancel" onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key="submit" primary onClick={register} disabled={!formValid()}>
        Submit
      </Button>
    ];
  };

  return (
    <Dialog
      className="user-registration-form"
      title="New customer access request"
      buttons={buttons()}
    >
      <div className="input-form">
        <p>Please provide the following information</p>

        <Row>
          <TextField
            value={customer}
            label="Operator you want to have ECOD access to, include country if possible"
            fullWidth
            onChange={setAccessToCustomers}
          />
          <label>(please specify one account per request)</label>
        </Row>

        <p className="info">
          Your request will be processed soon and we will send you an email once completed. Thank
          you!
        </p>
      </div>
    </Dialog>
  );
};

export default NewCustomerForm;
