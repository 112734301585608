import React, { useRef } from "react";
import * as d3 from "d3";
import cx from "classnames";

interface Props {
  value: number | string;
  y: number;
  x?: number;
  textAnchor: "start" | "middle" | "end";
  hidden?: boolean;
}

const Value = ({ value, x, y, textAnchor, hidden = false }: Props) => {
  const textRef = useRef<SVGTextElement>(null);

  const parentNode = textRef.current?.parentElement;

  const labelElement = parentNode
    ? (d3.select(parentNode).select(".label.text").node() as SVGTextElement)
    : undefined;

  const textLength = labelElement ? labelElement.getComputedTextLength() + 16 : 0;

  return (
    <text
      ref={textRef}
      textAnchor={textAnchor}
      style={{ pointerEvents: "none" }}
      className={cx("label value", { hidden: hidden })}
      x={x !== undefined ? x : textLength}
      y={y}
    >
      {typeof value === "number" ? d3.format(",")(value).replace(/,/g, " ") : value}
    </text>
  );
};
export default Value;
