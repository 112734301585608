import { apiWritingOff, apiWritingOn, useStore } from "../store";
import { useApi } from "./api-factory";

interface IFormQuestion {
  question: string;
  answer: string;
}

export const useMailActions = () => {
  const [_, setState] = useStore();

  const { api } = useApi();

  const sendFeedback = async (
    customerId: string,
    topic: string,
    body: string,
    attachment?: File,
    callback?: Function
  ) => {
    const data = { Identifier: customerId, topic, body, attachment };
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, data));
      await api.postForm("v1/Feedback", data, callback);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const sendFeedbackForm = async (
    customerId: string,
    formQuestions?: IFormQuestion[],
    callback?: Function
  ) => {
    const data = formQuestions
      ? { identifier: customerId, isMobile: false, formQuestions: formQuestions }
      : { identifier: customerId, isMobile: false };
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, data));
      await api.post("v1/Feedback/form", data, callback);
      setState(old => ({
        ...apiWritingOff(id, old),
        customer: old.customer ? { ...old.customer, showFeedbackForm: false } : undefined
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const sendSimpleEmail = async (
    to: string[],
    subject: string,
    body: string,
    callbackSuccess?: Function,
    callbackFailure?: Function
  ) => {
    const data = { to, subject, body };
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, data));
      await api.post("v1/Mail", data, callbackSuccess);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      if (callbackFailure) {
        callbackFailure();
      }
      setState(old => apiWritingOff(id, old));
    }
  };

  const askSupport = async (
    customerId: string,
    topic: string,
    body: string,
    attachment?: File,
    callback?: Function
  ) => {
    const data = { Identifier: customerId, topic, body, attachment };
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, data));
      await api.postForm("v1/Support", data, callback);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  return {
    sendFeedback,
    askSupport,
    sendSimpleEmail,
    sendFeedbackForm
  };
};
