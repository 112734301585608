import React, { ReactNode, SyntheticEvent } from "react";
import { splitLongBodyOnLength, splitLongBodyOnWidth } from "../../dashboard/utils";
import { Icon } from "@react-gcc-eds/core";
import { ICustomTooltip } from "../../../../store";

export const InnerTooltip = ({
  children,
  tooltip,
  breakStyle = "pixelWidth",
  position = "bottom",
  minWidthPixels,
  maxWidthPixels,
  maxLengthChars,
  className,
  hoverColor,
  lockable
}: {
  children: ReactNode;
  tooltip: ReactNode | string | ICustomTooltip[];
  breakStyle?: "pixelWidth" | "wordLength" | "keep-all";
  position?: "top" | "top-end" | "bottom" | "bottom-end" | "left" | "right";
  minWidthPixels?: number;
  maxWidthPixels?: number;
  maxLengthChars?: number;
  className?: string;
  hoverColor?: boolean;
  lockable?: {
    locked: boolean;
    onToggleLock: (e: SyntheticEvent) => void;
    onLock: (e: SyntheticEvent) => void;
    onUnlock: (e: SyntheticEvent) => void;
  };
}) => {
  const splitter = (s: string) => {
    return breakStyle === "keep-all"
      ? [s]
      : "wordLength"
        ? splitLongBodyOnLength(s, maxLengthChars || 60)
        : splitLongBodyOnWidth(s, maxWidthPixels || 300, []);
  };
  const massageTooltip = (): ReactNode => {
    if (typeof tooltip === "string") {
      const s = tooltip as string;
      const parts = s.split(/\n/g);
      return (
        <div className="full-tooltip-inner-parts">
          {parts.map((part, i) =>
            part.length ? (
              <div key={`tooltip-part-${i}`} className="tooltip-row">
                {part}
              </div>
            ) : (
              <div className="bridge" />
            )
          )}
        </div>
      );
    } else if (Array.isArray(tooltip)) {
      const s = tooltip as ICustomTooltip[];
      return (
        <div className={`full-tooltip-inner-parts`}>
          {s.map((entry, index) => {
            return (
              <div key={`tooltip-section-${index}`} className="tooltip-section">
                {entry.header && <span className="tooltip-header">{entry.header}</span>}
                <div className={`tooltip-body`}>
                  {splitter(entry.body).map(b => (
                    <span key={`tooltip-body-${b}`} className={`tooltip-line ${breakStyle}`}>
                      {b}
                    </span>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return tooltip as ReactNode;
    }
  };

  return (
    <div style={{ display: "flex" }} className={className || ""}>
      <div className="custom-tooltip-container">
        <div className={`custom-tooltip-header ${hoverColor ? "hover-color" : ""}`}>{children}</div>
        <div style={{ minWidth: minWidthPixels }} className={`bridge bridge-${position}`} />
        <div
          onClick={e => e.stopPropagation()}
          style={{ minWidth: minWidthPixels }}
          className={`custom-tooltip-message tooltip-${position}`}
        >
          {massageTooltip()}
          {lockable && (
            <div className="message-header-row">
              <Icon
                className={"action"}
                name={lockable.locked ? "lock-locked" : "lock-unlocked"}
                onClick={lockable.locked ? lockable.onUnlock : lockable.onLock}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
