import React, { ReactElement } from "react";
import { Button, Dialog } from "@react-gcc-eds/core";

export const ConfirmDialog = ({
  confirmCondition,
  messageBody,
  messageTitle,
  onProceedButton,
  onCancel
}: {
  confirmCondition: boolean;
  messageBody: string | ReactElement;
  messageTitle: string;
  stopRefresh?: boolean;
  onProceedButton: ReactElement;
  onCancel: () => void;
}) => {
  return (
    <>
      {confirmCondition && (
        <Dialog
          title={messageTitle}
          buttons={[
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            onProceedButton
          ]}
        >
          <div>{messageBody}</div>
        </Dialog>
      )}
    </>
  );
};
