import React, { useState } from "react";
import { Thresholds, Data, Colors, StackedChartData } from "../interfaces";
import * as utils from "../helpers";
import Bar from "./Bar";
import Value from "./Value";
import Label from "./Label";
import BarHover from "./BarHover";
import d3 from "d3";

interface Props {
  data: Data;
  thresholds?: Thresholds[];
  label: string;
  single?: boolean;
  grouped?: boolean;
  stacked?: boolean;
  stackedChartData: StackedChartData;
  largeBars?: boolean;
  x0: d3.ScaleLinear<number, number>;
  y: d3.ScaleBand<string>;
  y1: d3.ScaleBand<string>;
  colorScale: Colors;
  value: number;
  heigth: number;
  width: number;
  onMouseHover: Function;
  opacity: number;
  hoveredSerie: string;
  onClick: Function;
  onClickProvided: boolean;
}

const HorizontalBarGroup = ({
  data,
  label,
  thresholds,
  single,
  grouped,
  stacked,
  stackedChartData,
  largeBars,
  x0,
  y,
  y1,
  colorScale,
  value,
  heigth,
  width,
  onMouseHover,
  opacity,
  hoveredSerie,
  onClick,
  onClickProvided
}: Props) => {
  const valueSeparator = 2;
  const barWidth = 6;
  const fontSize = 14;
  const axisPadding = 8;

  const [isHovered, setIsHovered] = useState(false);
  const xPos = x0(0) + 1;
  const yPos = y(label);

  const dataCommon1 =
    data.common.length >= 1 && y(data.common[1]) !== undefined ? y(data.common[1]) : 0;
  const dataCommon2 =
    data.common.length > 0 && y(data.common[0]) !== undefined ? y(data.common[0]) : 0;

  const seriesDistance =
    (dataCommon1 !== undefined ? dataCommon1 : 0) - (dataCommon2 !== undefined ? dataCommon2 : 0);

  const hasLabelSpace =
    data.series.length === 1 || stacked
      ? heigth / data.common.length > fontSize + axisPadding + barWidth * 2
      : heigth / data.common.length > axisPadding * 2 + fontSize + barWidth * data.common.length;

  return (
    <g className="bar-group" transform={`translate(${xPos},${yPos})`} opacity={opacity}>
      <Label
        textAnchor="start"
        label={label}
        x={2}
        y={-barWidth - 2}
        // hidden={single ? (!hasLabelSpace(data,fontSize,x0) && !isHovered) : !hasLabelSpace(data,fontSize,x0)}
        hidden={!hasLabelSpace && !isHovered}
      />
      {stackedChartData[label].map((d, i) => {
        const separator = stacked ? valueSeparator : 0;
        const thresholdColor = thresholds ? utils.getThresholdsClass(thresholds, value) : "";
        const x1 = single || stacked ? -(x0(0) - x0(d.previous)) : 0;
        const x2 =
          single || stacked
            ? -(x0(0) - x0(d.value) + x0(0) - x0(d.previous) + separator)
            : -(x0(0) - x0(d.value));

        const colorData = colorScale[d.key];

        const barPosition = grouped ? y1(d.key) : 0;

        const lineOpacity = hoveredSerie === d.key ? 1 : 0.2;
        const key = i;
        return (
          <Bar
            key={key}
            x1={x1}
            x2={x2}
            y1={barPosition !== undefined ? barPosition : 0}
            y2={barPosition !== undefined ? barPosition : 0}
            colorData={colorData}
            thresholdColor={thresholdColor}
            lineOpacity={hoveredSerie !== "" ? lineOpacity : 1}
            strokeWidth={stacked && largeBars ? 12 : undefined}
          />
        );
      })}

      {single && (
        <Value
          value={value}
          textAnchor="start"
          y={-barWidth - 2}
          hidden={!hasLabelSpace && !isHovered}
        />
      )}
      <BarHover
        width={width}
        strokeWidth={
          dataCommon1 !== undefined && dataCommon1 > 0
            ? Math.abs(seriesDistance)
            : Math.abs(seriesDistance) * 2
        }
        onMouseEnter={() => {
          setIsHovered(true);
          onMouseHover(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          onMouseHover(false);
        }}
        onMouseMove={(e: React.MouseEvent) => {
          setIsHovered(true);
          onMouseHover(true, e);
        }}
        onClick={() => {
          const values = stackedChartData[label].map(d => {
            return { key: d.key, value: d.value };
          });
          onClick(label, values);
        }}
        onClickProvided={onClickProvided}
      />
    </g>
  );
};

export default HorizontalBarGroup;
