import {
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfToday,
  startOfMonth,
  startOfQuarter,
  subMonths,
  subQuarters
} from "date-fns";

export interface IDateRange {
  from: Date;
  to: Date;
  reference?: Date;
}
export const lastTwelveMonthsDatesRange: IDateRange = {
  from: addYears(endOfToday(), -1),
  to: endOfToday(),
  reference: new Date()
};
export const previousMonthRange: IDateRange = {
  from: startOfMonth(subMonths(new Date(), 1)),
  to: endOfMonth(subMonths(new Date(), 1)),
  reference: new Date()
};
export const previousQuarterRange: IDateRange = {
  from: subQuarters(startOfQuarter(new Date()), 1),
  to: endOfQuarter(subQuarters(startOfQuarter(new Date()), 1)),
  reference: new Date()
};
export interface SearchResult {
  matchingProperty: string;
  matchingValue: string;
  purchaseOrderId: string;
  purchaseOrderItemId: string;
  searchScore: number;
  view: string;
}
export interface IPeriodRange {
  value: string;
  title: string;
}
export const weekRange: IPeriodRange = {
  title: "Weekly",
  value: "Weekly"
};
export const monthRange: IPeriodRange = {
  title: "Monthly",
  value: "Monthly"
};
export const quarterRange: IPeriodRange = {
  title: "Per quarter",
  value: "PerQuarter"
};
export const lastMonth: IPeriodRange = {
  title: "Last month",
  value: "lastMonth"
};
export const lastQuarter: IPeriodRange = {
  title: "Last quarter",
  value: "lastQuarter"
};
