/* TYPES
    BUGFIX
    FEATURE
    CHANGES
*/

export const ReleaseNotes = [
  {
    version: "13.7",
    released: "2024-08-30",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Order Quantity mismatch issues in Excel reports",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/199153"
        ],
        customers: []
      }
    ]
  },
  {
    version: "13.6",
    released: "2024-08-08",
    notes: [
      {
        type: "FEATURE",
        internal: "1",
        comment: "Migrate from ADAL authentication to Microsoft Authentication Libraries (MSAL)",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/186303"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Sonatype High findings to be fixed",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/185747"
        ],
        customers: []
      }
    ]
  },
  {
    version: "13.5",
    released: "2024-07-09",
    notes: [
      {
        type: "FEATURE",
        internal: "1",
        comment: "Deactivate notification feature until the Delta load introduction",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/186891"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Tracy import Part 2.1 (status): new attributes to be captured in Serial number view",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/176842"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "POD attribute refinement",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/189336"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Possibility to extend/collapse all the lines in UI",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/187375"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Adjust the layout for expanded items",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/188252"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "INC000032240810 - MILA ECOD discrepancies - REQ000012849085",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/188540"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Holding customer issue after creating new child customer",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/188160"
        ],
        customers: []
      }
    ]
  },
  {
    version: "13.4",
    released: "2024-05-31",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Include OpCo attribute into Shipment list",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/182619"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Delivery schedule refinement",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/183891"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Include Shipment details into scheduled Excel export for Internal POs",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/181832"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "SONATYPE Critical findings to be Fixed",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/165017"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Deleted status disappears from the menu bar when filtering",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/182085"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Serial number info doesn't visualize fully in Excel",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/185713"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Unallocated line contains unneeded attributes",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/178106"
        ],
        customers: []
      }
    ]
  },
  {
    version: "13.3",
    released: "2024-05-16",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Serial number view adjustment",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/175819"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Align date format in the column view and in the advanced filtering",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/149277"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Optimize performance in fetching salesorder data from sap hana",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/180059"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "AG Grid grouping filter",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/174372"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Administration -> localization: cursor is misfocused",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/176898"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "INC000031961755 - Missing line items from latest 7 weeks bucket compared with previous 7 weeks bucket - REQ000012707552",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/180027"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "INC000032053157 - Order quantity over than actual order - REQ000012758761",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/182504"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "HOTFIX - INC000032063264 - LC date not visible for MTN Holding - REQ000012770687",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/182898"
        ],
        customers: []
      }
    ]
  },
  {
    version: "13.2",
    released: "2024-04-12",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Adjustments to the financial values display.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/177516"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Adoption rate added to Adoption metrics.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/174700"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Hard-coded template for Internal views is replaced with additional Shipment data in Excel export.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/170567"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Code cleanup.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99800"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Remove shipment start week attribute from the PO item view.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/144766"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Excel export for Adoption metrics.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/174693"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Tracy data in Serial number view.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/169401"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "SSL certificate renewals.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/179506"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Corrected OR option in advanced grid filter",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/171852"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Corrected explanation for Deleted and Released order statuses in Dashboard",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/178357"
        ],
        customers: []
      }
    ]
  },
  {
    version: "13.1",
    released: "2024-03-19",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Partial delivery excel export refinement.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/172060"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Financial data access as a column in user list for administrators.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52882"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Adoption calculation logic adjustments.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/171538"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Remove Extranet roles.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/152856"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Include Commodity Code into Internal PO item view and into Internal Delivery schedule.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/122659"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "INC000031764262 - Getting Error when trying to delete milestone date - REQ000012598160",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/170725"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Customer assignment is removed after reactivation",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/155137"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Customer material number attribute should be removed from the Internal Delivery schedule",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/171203"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Grid issues in user view",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/168540"
        ],
        customers: []
      }
    ]
  },
  {
    version: "13.0",
    released: "2024-02-15",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Include internal purchase order number in internal shipment list.",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/151077"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Calculation logic adjustments in Lead Time/LC dashboard",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/168768"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data period for Holdings adjusted based on the OpCos input",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/160116"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Internal Shipment status to Internal Shipment list",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/158231"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Added new attributes: Actual Release Date from Customs & Actual Arrival Date to Customs to Shipment list and overview",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/158268"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added new attribute: Sales order creation date to PO list, overview and PO Items",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/167148"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data load period adjustment",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/166661"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data Import Queries adjustments for S4",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/170252"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed misalignment of columns when export to excel",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/160497"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Shipment/Internal Shipment list does not react to the date range",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/169274"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "To show date fields in week format - REQ000012598202",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/170851"
        ],
        customers: []
      }
    ]
  },
  {
    version: "12.3",
    released: "2023-12-18",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Hard-coded template: Enable possibility of automatic excel export on weekly basis",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/150945"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable possibility of automatic excel export on weekly basis",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/149984"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New aggregation logic for PO estimated/actual date",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/156557"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: 'Add new attribute "CPO received" to PO list',
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/155144"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "HOTFIX - Ag Grid grouping fails for quantity fields",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/159094"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "HOTFIX - INC000031289254 - Scheduled Exports",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/153351"
        ],
        customers: []
      }
    ]
  },
  {
    version: "12.2",
    released: "2023-11-24",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Avoid duplication of quantity properties in excel export for Internal Purchase order menu",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/128152"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "LC dashboard development",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/146486"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "To export the values as number to Excel",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/152798"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add new 'Internal Material comment' attribute in Internal Delivery Schedule bookmarked item (table), Internal Purchase order item (table) and Internal Shipment list item (table)",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/151008"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Update AGgrid license file",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/157586"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Handling unit quantity is calculated wrongly in the Shipment overview",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/155181"
        ],
        customers: []
      }
    ]
  },
  {
    version: "12.1",
    released: "2023-10-20",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "General change in mass upload functionality for Purchase order list",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/91913"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Time stamp for the data refresh shows wrong time zone",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/148039"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Cursor misfocus in table search filter",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/149982"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Notification Localization issue",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/144933"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Missing orders in ECOD - Sintel SG -Internal view",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/114502"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Keep the original value for Parent material attribute",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/143880"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Shipments are not sorted out as expected with the data value activated in the first column",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/146485"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Wrong PO status for partial deliveries",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/150925"
        ],
        customers: []
      }
    ]
  },
  {
    version: "12.0",
    released: "2023-09-25",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Enable possibility of automatic excel export on overnight batch process on targeted users",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/79536"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          'Front-end: Create aggregation logic for all columns in "Groupping" AG grid feature',
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/126548"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Excel export fail in Purchase order item view",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/144661"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Wrong status for some POs - REQ000012199352",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/147714"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "ECOD is not showing spares deliveries - REQ000012018058",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/147714"
        ],
        customers: []
      }
    ]
  },
  {
    version: "11.6",
    released: "2023-08-28",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "General adjustments Internal delivery schedule view",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/92584"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable collapsable section in Inventory E/// WH view",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/88989"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Front-end: Settings are not cashed after the refresh",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/125093"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Internal view: Calibrate partial deliveries to capture situations of unallocated quantity",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/130551"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable UI switch between week and date format on date attributes",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/119700"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Date picker in IPO item view not functioning",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/123797"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Delivery Schedule Test - Dropdown Filters Failed",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/125248"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Not Able to extract the report from Purchase Order List - REQ000012022243",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/139204"
        ],
        customers: []
      }
    ]
  },
  {
    version: "11.5",
    released: "2023-06-26",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Vendor name in purchase order list is not populating data",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/133886"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Order quantity mismatch in Excel export",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/136970"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Customization glitch: activated fields appear/disappear",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/135020"
        ],
        customers: []
      }
    ]
  },
  {
    version: "11.4",
    released: "2023-06-13",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add activation of financial data on Holding level",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85324"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Avoid duplication of quantity and amount properties in excel export for Purchase order menu",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/79013"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add 'Release tutorials' link in the Help section of ECOD",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82574"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Missing items in Excel export on partial deliveries of SO and SO item",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/131854"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Aggregation issue for Status attribute",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/136026"
        ],
        customers: []
      }
    ]
  },
  {
    version: "11.3",
    released: "2023-05-22",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "External view: calibrate partial deliveries to capture situations of unallocated quantity (impact on purchase order item & delivery schedule)",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/97035"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add column Vendor name in the Purchase order item (table)",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/100062"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable customer users to define, maintain and populate shipment milestones",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/55943"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Present unallocated quantity in delivery schedule view",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/127303"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "To extend configuration for Account Administrators",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/128760"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Email - Purchase Order Items - Cancel button not responding",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/125276"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Localization - the updated value is not visualized",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/127088"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "User can't access the Notifications link from the user panel, screen goes black",
        backlogUrls: [
          "https://dev.azure.com/ericsson/ECOD%20-%20Lighthouse%203/_workitems/edit/132959"
        ],
        customers: []
      }
    ]
  },
  {
    version: "11.2",
    released: "2023-04-17",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Internal Purchase order: Remove rows with cancelled Internal delivery numbers",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106126"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Notification email template to the user for Shipment created, started & delivered, and for Purchase order delivered",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/122173"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "To introduce Weight and Volume per handling unit (Shipment item view -> Child item level)",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/64136"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Reactivate button for External users not working in Production",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/119008"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Incorrect invoice status",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/122370"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Localization: Serial numbers internal missing",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/122884"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Internal Shipment list: Actual shipment end appears in the wrong format when the value is blank",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/122481"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "ETM TMNL Delivery schedule mismatch - REQ000011589002",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/118958"
        ],
        customers: []
      }
    ]
  },
  {
    version: "11.1",
    released: "2023-03-20",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Notifications: Capture changes on specific events between 2 consecutive/non-consecutive days",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/100544"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Upgraded framework in Ecod code base from .NET 3.1 to .NET 7.0",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/118747"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added goods marking to shipment item view",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/117817"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Allow filtering in Internal PO view: to include/exclude PSF orders",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/116013"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Category added in Localization view for several items",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/120861"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added replacement sales orders in Internal shipment list",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/105934"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "ETM TMNL Inconsistency ECOD and ECOD report on PO list delivered qty - REQ000011584057",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/118621"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Issue with ECOD Purchase Order Items EXCEL file for [OOREDOO Q P S C] - REQ000011615785",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/120736"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Missing PO items in PO list (GroupByShipmentNumber)",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/113012"
        ],
        customers: []
      }
    ]
  },
  {
    version: "11.0",
    released: "2023-02-14",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Adjust logic for breadcrumb in all menus",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/95711"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: 'Create "Shipment status" in Shipment list',
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106179"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Create Notifications user page",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/95419"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data load fix: Replace OrdervisQuery with new improved version",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/118989"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: 'New "Shipment delivered" tile in the Dashboard menu',
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84823"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Date format incorrect. Switching month and day format.IPO View. - REQ000011498420",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/114682"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Missing Export Report on Internal Shipment List - REQ000011273346",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106871"
        ],
        customers: []
      }
    ]
  },
  {
    version: "10.5",
    released: "2023-01-24",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add ship-to-party name to the Purchase order list/PO item list",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/112707"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Visualize Internal Purchase Order comment in Purchase order item view",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/97559"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable the Account Approver to Reactivate users with expired access",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106171"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add POS External to Shipment items (table)",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/109827"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Incorrect aggregation of delivery quantity in the Internal Shipment list",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/107304"
        ],
        customers: []
      }
    ]
  },
  {
    version: "10.4",
    released: "2022-12-19",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Default PO Comment toggle for all the accounts",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/108005"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "FAQ adjustment with eRR details",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106315"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Actual shipment end in the Internal serial number and Serial number",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106151"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Update GCC version to 2.3.1 (Decimals appeared in the Dashboard)",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/87083"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "MTN hard-coded file update",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/108615"
        ],
        customers: []
      }
    ]
  },
  {
    version: "10.3",
    released: "2022-12-02",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add new 'Material comment' attribute in Delivery Schedule bookmarked item (table) and Purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/79529"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "External users onboarding: Generate automatic e-mails after the Account approval",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106027"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Create notifications activation toggle and a notifications tile in Administration menu",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/95210"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Outbound information is missing for 'SP-' Parent material when the toggle is On",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/108884"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Virtual role=Customer cannot export including Shipping details",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/103098"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Ship to party ID not coming in report",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/107344"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Value under Deactivated column is not visible to any role other than Application Administrator",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/106163"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Failure on uploading comments to IPO field",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/102377"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Shipment list gives incorrect PO numbers connected to materials",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/102375"
        ],
        customers: []
      }
    ]
  },
  {
    version: "10.2",
    released: "2022-11-03",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add Project execution responsible in Internal Purchase Order and Internal purchase order item",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/64144"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add new Vendor invoice posting date property in the Internal purchase order item (table) and Internal purchase order (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99598"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Children material data is not imported for some shipments in Internal Shipment List",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72450"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Missing item lines for some partial deliveries in Internal Purchase order item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72454"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Address attributes coming from the IPO (ME23N) are for Internal purchase order items and Internal purchase orders",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/81606"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added new external links menu and eRR link new view creation",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/97681"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "To increase display in filter",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99967"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Status de-activation for SW items not working",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/100638"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Materials are duplicated in the Shipment item list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/101043"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Excel export contains the deleted lines even though the exclude deleted toggle is active",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/101141"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Duplication of the SO line items in PO item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/101472"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Deleted status in IPO view is removed without the activation",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/101473"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Shipment list/shipping items shows double qty - REQ000011178674",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/101768"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "ECOD shows incorrect order qty (doubled) for sub-item - REQ000011200418",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/101769"
        ],
        customers: []
      }
    ]
  },
  {
    version: "10.1",
    released: "2022-10-07",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Internal view: Calibration logic of partial deliveries for Internal Purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/89419"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Execution: Commodity Code/Import Code Number for Foreign Trade to be added to Purchase order item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/91497"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Hide obsolete functionalities from M3",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/92562"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Renewal SSL Certificate",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98071"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "To add Shipment destination to Shipment list/Shipment overview",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98311"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed Deleted POs  - REQ000011063822",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98325"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Wrong quantities in Delivery schedule",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98707"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Fixed Default name mixed up w/ Key in Internal Delivery schedule bookmarked items (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98721"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Fixed Excel export does not follow Shipment list customization when exporting from Purchase order list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98733"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Excel export contains different Outbound delivery quantity than in the UI for Parent-child structured items",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98734"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Fixed Wrong Outbound delivery quantity for SP- items with Parent\u002Bchild structure",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98746"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed Balance quantity is not reflected in Excel export but visible in the UI",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/98852"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed Excel Export issues for customer *HOT FIX REQUIRED*",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99063"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Delivery no. property is wrongly included in Internal Shipment item (table) for customization",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99083"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Fixed \u0027Actual shipment start\u0027 is \u0027blank\u0027 when exported in excel ",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99175"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Actual shipment start\u0027 is \u0027blank\u0027 in the Internal purchase order item (table) despite of having a date in Internal Shipment list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99176"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Internal delivery number is \u0027blank\u0027 and outbound delivery quantity 0 in excel export but has information in the UI",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99177"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Renewal of AG-grid license in Ecod front end app Due date: 6th October",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/99573"
        ],
        customers: []
      }
    ]
  },
  {
    version: "10.0",
    released: "2022-09-20",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add External Financial document",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69356"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Internal Purchase order item (table) wrongly contains attributes from Internal purchase order (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/74831"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Incorporate the \u0027Administration guide\u0027 in ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82566"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Rename Notifications to System notifications",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/88990"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Enable possibility to remove Parent level and display only child level for SP- materials",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/91221"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Remove non applicable properties from Delivery schedule bookmarked items (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/92564"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Remove non applicable properties from Internal Delivery schedule bookmarked items (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/92585"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Actual shipment start into the Internal purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/93207"
        ],
        customers: []
      }
    ]
  },
  {
    version: "9.5",
    released: "2022-08-30",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add \u0027Internal Shipment comment\u0027 to the Internal Purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/80928"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "General changes in Send Feedback and Request support forms",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84087"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Replicate attributes from Internal Purchase order (table) to Internal Purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/88780"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Duplicated serial numbers in Internal shipment when exporting excel ",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/90977"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Split attribute Current Planned goods issue in 2 localizations for purchase order and purchase order item",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/91160"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Wrong localization for general filters in Internal Purchase order (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/91314"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Wrong order quantity in purchase order item",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/91655"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Virtual role related: Purchase order number and Internal purchase order number are not displayed in the breadcrumb for none of the Virtual roles",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/92557"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed Status field data in ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/94340"
        ],
        customers: []
      }
    ]
  },
  {
    version: "9.4",
    released: "2022-07-14",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Account Assignment category to IPO view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56312"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Internal Plant Description to Internal Purchase order item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62866"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment:
          "Investigation: Keep column sorting when navigating between landing page and item views",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/74823"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "General adjustments on Delivery Schedule view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78712"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Internal SO Goods Marking in Internal purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/81663"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New Menu Internal Serial number",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/89009"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Post deployment task for 66358",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/90546"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Delivery schedule bookmarked items (table) not showing right quantities for some Materials",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/90662"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Pop-up survey received multiple times for same account user type time stamp in several accounts.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/73153"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Inconsistent Date Ranges on PO Header Level",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84203"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Missing Internal Purchase order items and therefore Internal Purchase orders",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/88522"
        ],
        customers: []
      }
    ]
  },
  {
    version: "9.3",
    released: "2022-07-05",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add a comment field in IPO item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62865"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "General adjustments Delivery Status in Internal Purchase order and Internal purchase order item",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66358"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add attribute Planning end in Internal Purchase Order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68970"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add Storage Location in the Internal Purchase Order (table) and Internal Purchase Order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78259"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Material escalated attribute in Internal Purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85430"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Tracy Import of Serial Numbers is failing",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/64558"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Delivery Shcedule shows not rows to display",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67949"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Internal Delivery Schedule: Page load error",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78865"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed No data available for one customer in TEST and PROD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85079"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed Data not exist for one customer",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/88327"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Can't see History log of Push settings",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/88451"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed No Available Export from ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/89826"
        ],
        customers: []
      }
    ]
  },
  {
    version: "9.2",
    released: "2022-06-23",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "New attribute Current Planned Goods issue to Purchase order item and Purchase order",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/80000"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add blanking logic to Current confirmed date based on Internal Purchase order creation",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/80929"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Keep Overviews section in the last position the user selected it as he/she continues navigating in ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82586"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Delivery schedule: not possible to choose the period within an available data range",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/83322"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "UX Consistency bookmark column Internal Purchase Order (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84315"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable bookmark functionality in Purchase order (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85606"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Eliminate attribute Status from Internal Purchase order (table) and Internal purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/86078"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Planned or Actual Shipment end in Internal Shipment table only takes Actual Shipment end ",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/73514"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Missing Outbound delivery quantity and Balance quantity in Internal Purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/74095"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed: Missing Goods Marking in internal shipment list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85025"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed: Wrong format of Customer Purchase order creation date with - instead of /",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85396"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed: Delivery no in Internal Shipment item (table) needs to be replaced by Internal Delivery no.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85562"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed: Shipment comment and Additional shipment comment are editable for Virtual role customer",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85566"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed: Delivery/shipment information is missing in",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/86426"
        ],
        customers: []
      }
    ]
  },
  {
    version: "9.1",
    released: "2022-06-01",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Excel download is not in synch with UX/UI.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/86331"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "New Goods Receipt posting date attribute for Internal Purchase Order item (table).",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62869"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Decouple the Invoicing status from the Delivery status.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/79806"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Shipment list cashing that was not working.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/81252"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Attribute naming adjustment under PO item level.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82524"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Modify request access FAQ.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82568"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add clear column filters button in tables.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82585"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Make column options visible.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82587"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Change wording in the Administration tiles.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82588"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Extranet Admin gets caught in a loop in Administration menu.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84218"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Update codebase according to new versions of dependencies.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84787"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Include plant description in shipment list in ECOD.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/81442"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Del. Schedule Broken Details View.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82120"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Exclude deleted items and orders in Internal Purchase Orders.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84278"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Show the link icon regardless of column width.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84793"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Application Admin: to allow turn on and turn off ESTA POs for the customer role.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84822"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Modify display logic Actual Shipment End on PO and IPO.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/84825"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Remove zero quantities switch that was not working in external delivery schedule.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/85129"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Current confirmed table filter that was not working.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/79612"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed PO that was not pulled into system.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/80466"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Storage location that was missing for plant 2005.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/80915"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Serial numbers from Tracy that were not visible when searching by the Handling unit.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/81616"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Purchase Order (table) that wrongly contained an item attribute in customization.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/70140"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Error in delivery date item item level after upload.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/80463"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Issues with ECOD Data.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/81404"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Tooltips for address columns in Internal Purchase order and Internal purchase order item that were incorrect.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/81611"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Inventory Bookmarks that was not working.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82147"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Internal purchase order item that showed No rows to show for some internal purchase orders.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/82925"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed PO item details are not available when clicking in the delivery schedule.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/83324"
        ],
        customers: []
      }
    ]
  },
  {
    version: "9.0",
    released: "2022-04-26",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "To add SO name to the Inventory view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52765"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Localize the header/title of each of the tiles in the Dashboard",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52970"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add Shipment comment and Additional Shipment comment into Purchase order item (table)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/79485"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Incorrect Ship-To-Party id and Ship-To-Party name from EAB SO",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69558"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Data displayed in Shipment List and Delivery Schedule is not in sync to the import period set for Purchase order list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72453"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Grid filters showing [object Object]",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72462"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Excel export email does not reach Inbox for none of the views in ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/73409"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Item details view doesnt load for Internal Delivery Schedule",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/80362"
        ],
        customers: []
      }
    ]
  },
  {
    version: "8.6",
    released: "2022-04-11",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "To add PO creation date to PO item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53474"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add attribute Site ID in the Shipment items",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53703"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add attribute Site Name in the Shipment items",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53704"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implement Excel Export for Internal Shipments",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61953"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "To add Additional comment field in Shipment view overview",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66454"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Deactivate access instead of delete at inactivity",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72128"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Invoice that was missing in PO item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62243"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Mismatch between ECOD and SAP dates",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/63074"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Missing sales orders for CPO",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/76837"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Different date format after import for purchase order list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78863"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Investigate If we are getting correct Customer Name when we change the customer from left panel dropdown",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/79120"
        ],
        customers: []
      }
    ]
  },
  {
    version: "8.5",
    released: "2022-03-29",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable possibility to collapse PO search",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61668"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Shipment table (shipment overview level): to add proforma invoice as one of the columns",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67029"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Enable possibility set HOLDING in adoption (investigation)",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72449"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Item details view that didn´t load for Internal Delivery Schedule",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72463"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "To introduce SO/CPO values in the PO and PO item list: 2 different fields in ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72983"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "MTN Holding: hard-coded Excel download requires update",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/74128"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Shipment weight and volume that were lower than expected",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/75842"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Estimated/Actual delivery date that wasn´t consistent for some items",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/76125"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Schenker data upload not happening for one customer",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/76610"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Investigate DAP",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/76649"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed wrong Outbound delivery quantity due to corrupted logic with delivery numbers associated to the Sales Order and Sales order line item",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/77942"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing Purchase order",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67757"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed multiple deliveries that wasn´t displayed in Purchase Order item list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72079"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Import/Upload that wasn´t working if combine key feature is not activated",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/75181"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "User Statistics - Unique logins tab: Adoption rate % calculation and list of users do not show the ones with zero visits; scoring 100% for all accounts.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/77505"
        ],
        customers: []
      }
    ]
  },
  {
    version: "8.4",
    released: "2022-03-08",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "CPO number to be added to Shipment list and Shipment overview.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/73656"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed quantity mismatch.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62767"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed PO Date Comment upload that was not working properly.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67882"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed wrong Date format.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/74882"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed status that was not showing right in PO item view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72207"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed PO item date comment format when importing.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/76331"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect logic om PO Item lines grouping.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56740"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed balance quantity showing more than order quantity.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62711"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed duplicated lines with 1 serial numbers assigned to 2 different Parent Handling Units in excel exported.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72108"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed the attribute appears in the front end (Customization view) but have no data sources connected in the background.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/74874"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed PO Item Comments Saved but not retrieved.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78460"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Delivery Schedule export that didn´t show correct month heading",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78543"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed import validation that failed in Internal Purchase Order.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78859"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed dates that were being transformed within the upload.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/79472"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed export that was not considering default date format.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/78274"
        ],
        customers: []
      }
    ]
  },
  {
    version: "8.3",
    released: "2022-02-14",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "To add End user for Trade to the PO list view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/64137"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Estimated/Actual delivery that was not calculated correctly in each delivery line for the SO item.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/64820"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Rename Order Quantity field on the child item in Shipmen.t",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67582"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable possibility to remove IPO import value SAP Company Code.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68969"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Create Estimated/Actual delivery date in Internal Purchase order item (table).",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68973"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add attribute Delivery quantity for Parent material in Shipment items (table) and Internal Shipment items (table).",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72446"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Excel export issue: the file is not received immediately.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/73234"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed wrong date format for some lines only.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67342"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed upload that was failing in PO item and IPO view in both TEST & PROD.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/73731"
        ],
        customers: []
      }
    ]
  },
  {
    version: "8.2",
    released: "2022-01-24",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add AG Grid Grouping feature in Shipment view landing tables",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68450"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Confirmed delivery date: new blanking logic",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68509"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Backend refactoring of Shipment List landing page to improve loading times",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69251"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Replace Application error message text and form",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69416"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Excel export email that did not reach Inbox for none of the views in ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/72338"
        ],
        customers: []
      }
    ]
  },
  {
    version: "8.1",
    released: "2022-01-14",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implementation of UI Improvements to table filters.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/49153"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Visualize multiple rows in Internal PO Item if one item is delivered in multiple Shipments.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/51820"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Created on date for Letter of Credit.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57158"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Add additional key for Excel download/upload functionality to manage same material on multiple SO lines.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61085"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Develop Internal Delivery Schedule view based on IPO data.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61783"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable CSV export with Japanese characters.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62383"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New blanking logic for Confirmed delivery date based on PO receipt date.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/65123"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implement Partial Quantity Confirmation based on MANA dB.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67734"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New wording for ECOD request.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68197"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Site ID and Site Name Filter in Delivery Schedule should be possible to deactivate.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68971"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed that same material is repeated in Delivery Schedule.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68972"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Site Id and Site Name that is not visible when in light mode.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69069"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Multiple rows with same material in Delivery Schedule.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69084"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed AG Grid Filters that are not working.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69095"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Delivery schedule Site ID that is not kept navigating to detail view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69158"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed wrong item details in Internal Shipment list.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69362"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed existing Shipment in IPO item view is missing in Internal Shipment List.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69363"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed filtering on SiteID or SiteName should recalculate all numbers in Delivery Schedule.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69600"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed empty detail page in Delivery Schedule.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69994"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Include Adopted Module in the Unique logins table under Adoption rate to distinguish adoption % per module.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/70044"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed the data is not loaded fully in PO item view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/70218"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Shipment list landing page wrongly indicates No rows to show.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/70422"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Statistics dashboard filtering accounts incorrectly in unique logins view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/70900"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed API calls for China Unicom customer portal are being wrongly counted as a Customer user in the unique logins.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/71403"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed Internal Shipment list Item is always showing same items for all Shipments.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68968"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed empty detail view for Delivery Schedule.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69164"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed validity period is not in sync with the data displayed.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/69266"
        ],
        customers: []
      }
    ]
  },
  {
    version: "8.0",
    released: "2021-12-01",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect qty on shipment level.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56390"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data import functionality to manage deadlock issue.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61666"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing Shipment comment field in Shipment list (table).",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66779"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed empty CPO column in Shipment view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66825"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed common filtering for Planned or Actual Shipment End",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66826"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed column filter for dates that didn´t capturing the values",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67343"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add new view for Internal Shipment",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52941"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Site ID and Site Name field from SAP in delivery schedule view as filter ",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56104"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Site Name to Delivery Schedule as a filter",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61665"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add new landing page for Internal Shipment",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66766"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed minus signs in Delivery Schedule that navigated to a broken view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66552"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "PO Header Date is editable for (virtual) customer",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67049"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed dead links in Delivery Schedule",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67428"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Disable welcome mail 2 in onboarding flow",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/68788"
        ],
        customers: []
      }
    ]
  },
  {
    version: "7.2",
    released: "2021-10-29",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "To add Comment field in date format.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/51654"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Close medium issues from the vulnerability report.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53262"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add landing page for Shipment view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53548"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing Requested Delivery Dates in IPO view for several accounts.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57849"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing Requested Delivery Date for Internal Purchase Orders.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60076"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect details for shipment.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61006"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Application Admin feature to be able to deactivate tracking link.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/63174"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Transfer from VCK dump to SAP data for PO list.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/63636"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Remove Customer from ECOD.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/64666"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Import parameters for Internal purchase order is not working as expected.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52994"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing IPOs in IPO view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/55834"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect qty on shipment level reflected.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56390"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed date range limits set in settings to apply in Shipment List.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66553"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed PO date comment where value didn´t show in table column filter.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/67048"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Export to Excel in Shipment List Landing view.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66553"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Zero Quantity in Delivery Schedule for future months.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/66551"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing items in export list function in Delivery schedule.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/65790"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Delivery schedule: quantity is not aggregated correctly.",
        backlogUrls: [
          "https://ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/64617"
        ],
        customers: []
      }
    ]
  },
  {
    version: "7.1",
    released: "2021-10-01",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed wrong data for pcs, HUs, weight, volume for shipment.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56516"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue to exclude billing items",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61814"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed data is not loaded for the Customer and Virtual customer role in delivery schedule",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/63176"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Mobile Approval list is see-through",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58099"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Re-design of DelSched",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60633"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Fixed balance quantity that was not showing right in the delivery schedule",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61609"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed date format fails in Excel downloads",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62513"
        ],
        customers: []
      }
    ]
  },
  {
    version: "7.0",
    released: "2021-10-01",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect data in shipment.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56388"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing delivery and shipment number.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57925"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed ES/FOC orders to be included for Internal role",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/59974"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed status to show correctly in overview.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60072"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed unnecessary data in exported Excel.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61000"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed status on PO item level.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61709"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Make Customer Data Health page tracey import state to stop reporting false positives.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/62616"
        ],
        customers: []
      }
    ]
  },
  {
    version: "6.9",
    released: "2021-09-17",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect qty on shipment level.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56390"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing shipment in Purchase Order view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57618"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing shipment number in Delivery Schedule Item view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57852"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: " Fixed Partially Delivered status not displaying correctly.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58262"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Removal of redundant Filter.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58449"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Removed unnecessary columns in Exported Excel.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58491"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed login status to be displayed properly.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58545"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed pop-up survey for mobile.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/59353"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add comment possibility in Shipment with active url",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/59365"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed status aggregation on CPO level.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/59902"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed count of visits in stats dashboard.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/59971"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing CPO.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60423"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect data for shipment.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56516"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing delivery and shipment number",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57925"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed aggregated status",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60072"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Removed unnecessary data in exported Excel",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61000"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect wheight for shipment",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56388"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect data for shipment",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/61006"
        ],
        customers: []
      }
    ]
  },
  {
    version: "6.8",
    released: "2021-09-01",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Update donut chart with new color palette.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/50690"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Technical tooltips implemented in overviews and in menus.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52890"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New tab in Stats dashboard.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52984"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Implemented new status Partially Delivered in Purchase Order List.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53895"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added onboarding flow for mobile.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/54090"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added user specific dates format selection.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56252"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed PO Import fails when value is *.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57996"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Created environment variables for export queues.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57997"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with export adds extra digits.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58150"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added data import period into customers admin list.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58215"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Aligned from to date ranges in endpoints.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58240"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed date format on excel export.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58832"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Added data load guidance into customer data health view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/49676"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Created a devOps only health page for services and syncs per customer.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52212"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled financial data.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52463"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Added CPO creation date in the IPO view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52767"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Added mass upload validation logic inside dialog pop-up.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52873"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Changed parameter type for So Parent with [site name].",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53000"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Plant description.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57162"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implement data delta load.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57541"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Incorrect Order and Shipment Qty.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58452"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Japanese Character is collapsed on Exported CSV.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/58490"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing data for several accounts in ECOD TST.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60008"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing shipment numbers and actual delivery dates.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60226"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed width of regular tooltips.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/60988"
        ],
        customers: []
      }
    ]
  },
  {
    version: "6.7",
    released: "2021-07-12",
    notes: [
      {
        comment: "Added Letter of Credit",
        type: "FEATURE",
        internal: "0",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52897"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Column filters are now kept during session",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/55942"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Date range was showing wrong value in pill is now fixed",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56636"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue with empty quantities in the ASP WH view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/57048"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added the possibility to change date format in entire ECOD application",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56252"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Onboarding approval flow implemented in mobile application",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/54090"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Introduced Partially Delivered as new status",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53895"
        ],
        customers: []
      }
    ]
  },
  {
    version: "6.6",
    released: "2021-06-19",
    notes: [
      {
        comment:
          "Setting for blanking out the RFS date if it is further out than x number of days, implemented",
        type: "FEATURE",
        internal: "0",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/50059"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added free text comment in IPO view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/51344"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Implemented removal of the filter toggle from all the tables",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52707"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Improved performance and stability in Delivery Schedule view.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53506"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Adjustments to BOM API",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/54068"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Grid column filter focus issue fixed",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/54929"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Holding functionality fixed",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/55637"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Excel download now contains correct delivered items",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/55809"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue where SoldtoParty was the only key for customers",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56355"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Changed text in registration form, trying to be more user friendly",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/56356"
        ],
        customers: []
      }
    ]
  },
  {
    version: "6.5",
    released: "2021-06-09",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implementation of Tracy API call",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/51487"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Hide sub-item SW (incl HWAC) in PO item packages",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52680"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Refactor Full Shipment Queries",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52791"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Include SW items and POs with SW items in ECOD",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52863"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue for missing orders",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52934"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Missing IPOs in Internal Purchase Order view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53554"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Changed input for Tracy API call",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53830"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Included all Shipments in Purchase Order Item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53894"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue for logout scenario",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53932"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Added new internal flow for SMP request handlings",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/54500"
        ],
        customers: []
      }
    ]
  },
  {
    version: "6.4",
    released: "2021-05-27",
    notes: [
      {
        type: "CHANGES",
        internal: "1",
        comment: "Adjustments to Unique logins tab in OKR dashboard",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52986"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Adoption per customer is now multiple. Aligned view for better usage.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52209"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New improved user survey",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52469"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Site Material Status Fields adjusted",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52504"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Fixed alignment of the drop down on the dashboard",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52706"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Sales Order and CPO missing for POs has been fixed",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52790"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Adjusted fields mapping in Inventory ASP WH Section",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/52870"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Region column to PO list",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53044"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Saving is now possible when adding User Filter ASP Contact Person",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53112"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Addressed missing IPOs",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53174"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with mass upload in PO item view",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53346"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with PO missing in Internal purchase orders",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53377"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue where some POs was missing.",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53378"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with Calendar date range now showing all days",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53393"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New login page in mobile app",
        backlogUrls: [
          "https:///ericsson.visualstudio.com/ECOD%20-%20Lighthouse%203/_workitems/edit/53419"
        ],
        customers: []
      }
    ]
  },
  {
    version: "6.3",
    released: "2021-05-19",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with BOM API",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4407"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue that blocked deletion of customers",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4385"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with Excel Export and blank cells",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4335"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where delivered quantity is not summing up correct",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4310"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where ES orders where showing for the wrong role",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4308"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Custom file import update can now manage duplicates",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4339"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Calibrate logic for not removing all orders if one is bad",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4333"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added new field E-Quote qty",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4313"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added CU Object Name and 4G/5G",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4312"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "RfR codes can now be multiple",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4164"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Updated the Application Admin config view ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4129"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Switch customers pointing to new endpoints to use the new integration service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3456"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Redesign of the Login page ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4149"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Replaced the User guide with latest version 3.5",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4136"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added RFS date from EAB SO (OTC flow) to IPO view ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3276"],
        customers: []
      }
    ]
  },
  {
    version: "6.2",
    released: "2021-04-29",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Button on virtual role banner color has been fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4344"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Stats Dashboard - Unique logins now has data.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4341"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Delivery precision dashboard affects PO list filtering",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4340"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "PO date comments are now imported for PO item view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4336"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Mass upload is now working in IPO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4324"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Delivery Schedule view Week Numbers are now aligned",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4316"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Delivered Quantity is now summing up correct in Delivery Schedule view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4310"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Confirmed delivery date shows the correct week now",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4302"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Select all in Delivery Schedule is now fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4286"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Possibility to remove duplicates of requests implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4240"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added FA Tracking ID to the IPO item view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4211"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Timestamp in ECOD for latest refresh of external data import",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4210"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Attributes in Shipment view now has consistent in naming",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4205"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Replaced the input for Dashboards Overall Internal Order status",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4180"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Popup when Application Admin tries to assign a user to an account where no Approver exists",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4167"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "RfR codes can now have multiple selections",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4164"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Filter by Planned/Actual in Shipment list implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4126"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Incorrect tool tip for Purchase order creation in Internal Purchase Order view fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4116"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Ordered quantitiy in ECOD is now corrected",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4109"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented paging for external api",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3999"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Internal Overall order status bar graph not displaying correctly is now fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3754"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implementation of the user filter values in the User List",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3206"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added description to NWA field",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2900"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Sales Group Code in Purchase Order List Header",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-861"],
        customers: []
      }
    ]
  },
  {
    version: "6.1",
    released: "2021-04-14",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Onboarding flow missing customer connection after approval has been fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4225"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where data was not being loaded for a customer",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4222"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where Role was restored in User Edit dialog",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4207"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Changed tool tip for Balance quantity",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4206"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Restored missing IPO data",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4132"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "All internal requests to Demo are now auto approved",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4114"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Investigation why Erisite feed failing is done",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4105"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Delivery Status is now correct in IPO view, and tooltip updated accordingly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4103"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "BOM API implementation for Integration Service is in place",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3780"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Ship-to Purchase order number on header level",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3774"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed duplicate instructions in Walkthrough",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3565"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed an issue where Customer users was visible under Internal Customer view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3564"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Moved all code from api into new integration service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3455"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Mass upload is now working with large files",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3359"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented mass upload for the Date comment fields",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3275"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Full, weekly, Shipment export implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1883"],
        customers: []
      }
    ]
  },
  {
    version: "6.0",
    released: "2021-03-26",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Adjusted onboarding flow",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4117"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Added aging fields to the E/// inventory view is now working",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4113"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Users table information is now kept when editing a user",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4040"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Created multi-selector for inclusion of Sales Document Types",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4023"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Application admin can now reject a request object",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4020"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added free text field on reject for reason of rejection",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4019"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added link to approval page in mail for approvers",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4018"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Moved to new Azure Devops Project (Not backlog)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3996"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Increased the size of the VM",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3995"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Investigated and implement Lazy Loading and Server Side render initial page",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3987"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Created a new role - Technical Support",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3900"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Walkthrough - Removed Cache filters feature",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3453"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Tool tips of child items is now showing fully",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3444"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Second internal PO for HW is now back",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3438"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "It is now possible to Save my Comment, Purchase Order",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3320"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Delivery schedule bookmarks are now being removed if item is gone in import",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3212"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Internal PO ref in PO List calibrated logic.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3176"],
        customers: []
      }
    ]
  },
  {
    version: "5.5",
    released: "2021-03-18",
    notes: [
      {
        type: "FEATURE",
        internal: "1",
        comment: "Move required keys to keyvault",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-4008"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Hookified frontend login flow and copy to mobile PWA",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3988"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed Azure function for offboarding",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3976"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Double ordered quantities in IPO item view is fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3943"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Aligned Shipment visual graph with customer logistics partner file input",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3937"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New MFA instructions",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3896"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Balance quantity field is blank issue fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3776"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Connected EDC to ECOD",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3730"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "IPO reference is now visible in PO item list",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3724"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Disregarding sub-item SW (incl HWAC) in parent item date calculations",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3542"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Enhanced IPO Import settings - Multiple Final External Customer",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3532"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Removed Internal Purchase Order attribute from Inventory E/// WH view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3492"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Changed Import logic for Project Modules in Project View settings",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3062"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled possiblity to include Child Items in excel export.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2859"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Implementation of Hiding of bookmarks in delivery schedule view details when the radio buttons are used",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1987"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Aging in National WH Inventory view ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1707"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Moved to new Azure Devops Project (Not backlog)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3996"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Align Shipment visual graph with customer logistic file input",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3937"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "OKR Dashboard - Unique login - Select All is now working as expected",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3892"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Inventory ASP WH and Site Material views, now have data from separate events",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3781"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "BOM View - Site Delivered Quantity Logic changes",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3755"],
        customers: []
      }
    ]
  },
  {
    version: "5.4",
    released: "2021-02-26",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Added UTC to filename for export file to make it more clear for the user the last part of the name string is the time.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3865"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "It is now possible to delete/update the date in Add PO item date comment field",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3864"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Internet Explorer information message is now displaying correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3863"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Comments are now uploaded on PO level",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3862"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "New release updates are now working as expected",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3861"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Included tool tips in the Customization - Export All feature",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3803"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "FEC is now an optional input parameter",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3766"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Administrators can no longer user Localization/Customization/Push Settings in Production",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3695"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue with search by serial number",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3637"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Administrators now has the same features as Application Administrators when customizing views.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3563"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Possible to have multiple FEC implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3532"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled grouping on multilevel tables",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3470"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Updated tool tips for modules 3,4 and 5",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3446"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Switched to use the new Export Service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3436"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Massupload of text comments and date comments are now working",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3197"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented PoC for handling financial data",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3173"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Changed Import logic for Project Modules in Project View settings",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3062"],
        customers: [""]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled possiblity to include Child Items in excel export.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2859"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented new onboarding flow",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2346"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "MFA activated in Production",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3777"],
        customers: []
      }
    ]
  },

  {
    version: "5.3",
    released: "2021-02-15",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Application updates is now working correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3768"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added collective number to PO list",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3642"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue where old module name still appeared in the statistics dashboard.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3615"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Changed name on tabs of Unique Logins statistics dashboard",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3606"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Market Area field is now correctly populated in the Statistics Dashboards",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3584"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New export functionality, and performance enhancements.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3436"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Massupload of text comments and date comments is now working correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3197"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Feature for enabling MFA implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2194"],
        customers: []
      }
    ]
  },
  {
    version: "5.2",
    released: "2021-02-03",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "An issue with export, of views with child views, contained an extra column (not in customization) fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3650"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Dropdown in customization view is now correctly preselected when redirecting from PO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3649"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue when Tooltips where freezing the customization view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3648"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Shipment link from item materials does now contain PO number",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3647"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Items are now correctly highlighted in Shipment view when redirecting from an order item",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3645"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue with duplicated rows on NWH Inventory bookmarks statistics",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3579"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Quantity property is properly displayed in NWH Inventory",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3575"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Found all missing Shipments",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-3567",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2960"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Customer update date is set even though the sync fails",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3543"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Added a third update interval to sync service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3494"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Removed the field Internal shipment number from ECOD",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3457"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Layout issue in MS Edge is now fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3452"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implementation of visual notification when table columns are removed by user",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3366"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New endpoint based SO Name search created",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3319"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Cypress testing foundations in place",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3306"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Massupload can now be performed with both text comments and date comments",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3197"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled grouping on grid for simple (rows without children) tables.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3177"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Adjust serial number relation to take multiple shipments into account",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3556"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added SO Name attribute into Shipment view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2894"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added collective number to IPO list",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2481"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Trimmed out leading zeros from all views",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2009"],
        customers: []
      }
    ]
  },
  {
    version: "5.1",
    released: "2020-12-18",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Blocked any editing of editable fields for Customer and ASP role",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3458"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "OKR graph switches data sets between internal and external has been fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3442"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Customization export is working correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3337"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Admin Role filter is now showing correct values",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3296"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Refactored and improved performance in Delivery Schedule",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3258"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Customers can now be deleted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3227"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Implemented new dropdown in customization view and simplyfied ways of reaching view, by cogs and table menu shortcuts",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3184"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "IPO Export is now working as expected.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3112"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Capture all Shipments (not only MAX) in calculation of quantity in Delivery Schedule",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2960"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "OKR dashboard has new Naming convention and maps the views accordingly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2912"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Impersonation feature for administrators in place.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2338"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Actual shipment end corrected",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2303"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Trimmed out leading zeros from all views",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2009"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Ship-To City from Delivery document on PO Item level",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1502"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "As an Administrator, when exporting users, the excel file is now populated correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3325"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Header level Planned/Actual calculation is now considering disregards billing item",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3310"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Walk me feature is now always available",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3309"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Delivery schedule detailing is now working correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3307"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Columns can now be restored to default layout in grid menu",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3198"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Filters logic have been improved",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3188"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Meet requested delivery date indicator based on Date Comment field and Actual Shipment End",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3171"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Meet requested delivery date indicator based on Date Comment fields",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3111"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented Goods Issue Date",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2920"],
        customers: ["XLAXIATA"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Usage statistics view has now check boxes, and sorting for checkboxes and bookmarks",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2865"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Delivery schedule view has now radio buttons, checkboxes. Sorting implemented, adjusted margins in expanded details.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2864"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Quantity in (Ordered but not received) Material status is now correct",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2652"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New common user guide",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2376"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Site delievered quantity is now correct calculated",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2364"],
        customers: []
      }
    ]
  },
  {
    version: "5.0",
    released: "2020-12-07",
    notes: [
      {
        type: "BUGFIX",
        internal: "1",
        comment: "User statistics graph is now showing correct data",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3205"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Software status logic calibrated",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3185"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Comment Upload on PO level is now working properly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3187"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added SO delivery quantity to PO Item List view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2778"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added SO confirmed loading date to PO Item List view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2779"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added SO Status update time (Delivery create) to PO Item List view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2780"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Customer group 4 (SO-\u003EAdditional data A)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2532"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "User customization of columns is now enabled",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2721"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Administration rights has been adjusted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2833"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment:
          "Setup required infrastructure on Azure for new Export Service  (1 of 3) [Export Service]",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2873"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New improved UI for single select rows in entire application",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2860",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2861",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2862",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2863"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Customization tab is now referencing Default name field",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1380"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Material name conversion implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2844"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Internal PO reference in PO List and in Mat. Info. on item",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1882"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "All user specific settings moved to database.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2078"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Information message when Push is done, now includes delay reminder.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3174"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue with deselect item for BOM status graph",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3025"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue that rendered Status as integers",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3178"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Date comment fields now has the correct format when exporting to excel",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3170"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Added disclaimer text in export dialogs",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3028"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Delivery precision dashboard is now defaulting to PO items",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2774"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Removed faulty column in Admin view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3163"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Actual shipment start week adjusted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2729"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Removed Not for UI in Customization",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2874"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Missalignment in support mapping has been fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3194"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Support ticket attachments no longer gets corrupted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3167"],
        customers: []
      }
    ]
  },
  {
    version: "4.8",
    released: "2020-11-24",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Disregarding confirmed dates is now possible",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2845"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Added a new Api user account",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2857"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Additional type ZSA3 has been added to data scope",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2867"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added date comments field to internal purchase order view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2535"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Goods marking to Internal PO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2776"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added SO to Internal PO View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2668"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Shipment on Item level",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2669"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Moved all user specific settings to database",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2078"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Customers has now an Adopted switch and a MA specification",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2943"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Created additional OKR Dashboards",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2854"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Search engine is now working properly in Material status Dashboard",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2653"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Explored grouping feature",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2875"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added SO item number to PO item view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2777"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Requested delivery date is now sourcing the correct field",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2962"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Created a new filter method in the import service that is used before all aggregations are done for Item Category filter",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3036"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue with software related status logic",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2921"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed an issue that generated an error after clicking Material Status Dashboard in National WH view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3033"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue with email selections",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3034"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where filtering a table made comments not editable",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3035"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Dates is now blank in administration for user, if value is undefined",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3048"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Adjusted OKR Api tracker",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3051"],
        customers: []
      }
    ]
  },
  {
    version: "4.7",
    released: "2020-11-06",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Adjusted PO Header and Item mass upload of input fields",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2278"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Data update time is now updated for all customers",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2075"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Table component updates",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2342",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2710"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Additional corrections in the Delivery Schedule view",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2601",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2517"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added First OA",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1606"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Overall internal order status in internal po view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1889"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Requested Delivery date in Internal PO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2552"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Calibrated SO Name API to allow 1:N relationship for SOs and CPOs",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2663"],
        customers: ["CUSD"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Aligned UI for clickable/selectable rows of a tables",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2651"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Included Delivery statuses in IPO (incl. Partial delivery)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1696"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where some SOs was missing",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2715"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added WBS description in National WH Inventory view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2467"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Created a new OKR Dashboard",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1607"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Aligned import settings between EPOD and ECOD",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2598"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where editing dates was not possible if filtering was active",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2846"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where Administrator could not see user list",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2848"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where the user list was not refreshed properly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2847"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed a rare issue where the application froze in Serial view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2772"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Status Banner to IPO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2356"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where filtered value was too bright in light theme",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2849"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Adjusted API for SO Name to allow 1:N relationship for SO and CPO",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2663"],
        customers: []
      }
    ]
  },
  {
    version: "4.6",
    released: "2020-10-29",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Filter data per ASP based on contact person",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1905"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "PO Header and Item mass upload of input fields",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2278"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New advanced table component",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2342"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "First phase of Walkthrough",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2425"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Sales order from EAB to the local company in IPO view ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1702"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added planned delivery date from the delivery note",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2429"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added POD date for the local delivery",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2431"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "A lot of adjustments to Delivery Schedule view and calculations",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2517",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2518"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Concatenation of prefix for Handling Unit",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1295"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Resolved an issue with missing SOs in data",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2715"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Resolved an issue with missing orders",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2647"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Switch to using another vault",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2329"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Developed logic to capture usage data based on API calls from customer portal.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2024"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Adjust SMP integration to target HELIX",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2503"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Complete overhaul for loading progressbars",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2519",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-2602"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Complete overhaul of Breadcrumbs and hyperlinks",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2544"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where an item is showing Released when it is Invoiced",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2520"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue where alot of material was showing the wrong type",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2662"],
        customers: []
      }
    ]
  },
  {
    version: "4.5",
    released: "2020-10-09",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Excel export now works as expected",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2418"],
        customers: ["MTN"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Unloading Point into IPO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2414"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added NWA (network and activity number) as a column in IPO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2413"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Extended Customer Material number field to allow all the characters",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2423"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "BOM view logical material location status corrected",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2203"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "BOM is now showing correct material status",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2306"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Dashboard search functionality was extend to include PO ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2252"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Quantity aggregation logic on ASPWH import fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2183"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Ship-To City from Delivery document on PO Item level",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1501"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Logic corrected for Allocated and non allocated graph",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2146"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Shipment volume and weight is now correctly calculated in ECOD",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2272"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "BOM does now aggregate material quantities correct",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2150"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Materials from CPO in E// WH can now be found on internal PO view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2180"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented select all attribute activation in customization",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2339"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Removed request ID",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2377"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an error when loading a validated BOM",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2512"],
        customers: []
      }
    ]
  },
  {
    version: "4.4",
    released: "2020-09-25",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue when loading validated BOM threw an error",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2130"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Drop down for plant and Storage Location selection is fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2337"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Create new endpoint for customer settings",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2205"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Split Market In Transit Status Change",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2192"],
        customers: ["MTN"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Calibration of Delivery Schedule current week calculation",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1844"],
        customers: ["TMO-US"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Delivery Adress to Shipping Details",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1975"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added two additional editable datefields",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1303"],
        customers: ["VEON"]
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "PO Header and Item massupload of input fields - Investigate options",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1964"],
        customers: ["VEON", "VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Delivery Schedule, Period View selection adjusted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1866"],
        customers: ["TMO-US"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Material is now visible",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2351"],
        customers: ["XLAXIATA"]
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "IPO record in EPOD does now correspond to ECOD ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2354"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Added retry functionality on Sync Service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2002"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Total Shipment Volume into Shipment View (Shipping Header Information)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1884"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Push setting is now working correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2307"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Data load is now working correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2313"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue when uploading ASP WH template ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2157"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue when BOM is not uploaded after update",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2154"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Quantity aggregation logic on ASPWH import adjusted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2183"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data is now joined when uploading for ASPWH with internal data",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2184"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data is now joined when uploading for BOM with internal data",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2185"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Demo data does include EPOD views",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1865"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Ship-To City from Delivery document on PO Item level",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1501"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Secure that all date fields in excel are formatted as dateTime.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2026"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New endpoint for getting data by SOName",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1904"],
        customers: ["CUSD"]
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Shipping Items section with HUs and Serial Numbers is now showing in TST",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2317"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Adjusted import file for four columns",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2316"],
        customers: ["MTN"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Resolved authentication issue for customers",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2274"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Turned off phonenumber detection on Edge",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2297"],
        customers: ["TIM", "VDF_UK", "XLAXIATA"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Selecting rows in email form is now working properly ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2350"],
        customers: []
      }
    ]
  },
  {
    version: "4.3",
    released: "2020-09-11",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Not possible to change order of attributes fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2164"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Error when loading validated BOM fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2130"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Missing attributes and row aggregation Ericsson WH view fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2110"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Attribute Addition to Stock in Inventory ASP WH view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2014"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Export localization is now correct",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2091"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Purchase Order List Items list are now aligned",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2092"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Tool tip text improvements",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2133"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Added more colors to graph lines",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2083"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Visible column in customization is now hidden for Administrator role",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2152"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Internal PO View verified",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1657"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Corrected shipment view to hide/show PO/internal PO column",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1515"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Linkage from quantity to detailed view is fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2089"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Verified that predefined date filter does not clash with changes in import dates",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2077"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Country dropdown fixed and working as intended",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2076"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "All date fields in excel export are now formatted as date",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1870"],
        customers: ["MTN"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Two additional Comment Fields to Item Level added with Date format validation",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1303"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Delivery Schedule, Period View selection reworked",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1866"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added possibility to filter on Requested Delivery Date in Delivery Schedule",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1974"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Additional excel placeholders in excel download",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1970"],
        customers: ["MTN"]
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Developed logic to capture usage data per module in ECOD.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1994"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Add retry functionality on Sync Service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2002"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Total Shipment Volume into Shipment View (Shipping Header Information)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1884"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Menu item translation is now correctly mapped",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2176"],
        customers: ["MTS"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Localization of menu entries implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1855"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Removed preceeding zeros",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1967"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added description and tooltip information to new localization attributes",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2070"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "ASP WH upload template is now marking mandatory fields",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2135"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "BOM template is now marking mandatory fields",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2137"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Internal PO View data download is now working correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-2155"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Redesign of progress spinners, and added more intuitive loading visibility",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1617"],
        customers: []
      }
    ]
  },
  {
    version: "4.2",
    released: "2020-08-28",
    notes: [
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implementation of Internal PO View",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1408",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1657",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1721"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Tuned authentication flow to include non-ECOD user flow",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1618"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Improve performance in Delivery Schedule view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1723"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implement temporary text in user account request dialog",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1724"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implementation of Inventory E/// WH view",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1409",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1961"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implementation of Inventory ASP WH view",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1410",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1659",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1856"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implementation of BOM view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1411"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implementation of Site Milestone status View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1412"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Corrected shipment view to hide/show PO/internal PO column",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1515"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fix where selecting bookmark ALL in Delivery Schedule view generates API failure call",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1836"],
        customers: ["TMO-US"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed issue where available roles for assignment in customization was not consequent",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1838"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed issue where caching of the Customer Purchase order date was not enabled for the option All",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1845"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Tracking link logic calibration",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1850"],
        customers: ["SLOVAKTSK"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Does not meet requested date dashboard is now matching PO list result",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1853"],
        customers: ["SWISSCOM"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Possibility to localize menu item namings",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1855"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Filter exlusion of Billing Items",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1867"],
        customers: ["SOFTBANK"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "ItemNumber2 is now populating values in Shipment View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1887"],
        customers: ["VDF_NL"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Ajdusted authentication flow",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1655"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New Overall (internal) Order Barchart Dashboard",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1620",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1875"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New Overall (internal) Order DonutChart Dashboard",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1621",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1875"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New Inventory Status Dashboard",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1622",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1648",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1875"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Erisite sync service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1524"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Child Lines on PO Item View is now showing correctly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1416"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New Meet Req field with icon and color.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1748"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New Customer settings layout",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1519"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Order visibilty sync service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1523"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Stock visibility sync service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1525"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Excel import function",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1522"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "ECOD  is now a role based access application",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1514"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Adjusted Meet Req to use icon and text",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1535"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New Site material status view",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1562",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-1518"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "ESR sync service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1520"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "ASP Sync service",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1521"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Fixed issues with PO Filters",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1778"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fix for Average Lead Time export and calculation",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1770"],
        customers: ["MTN"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue where ItemNumber was not showing data in Delivery Schedule View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1837"],
        customers: ["TMO-US"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented Role based filter for Early Start filter",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-877"],
        customers: ["MTS_RU"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Adding final descriptions and information for new localization keys",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1868"],
        customers: []
      }
    ]
  },
  {
    version: "4.1",
    released: "2020-06-18",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Excel export does now save Quantity as text",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1266"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added SO Requested Shipping Date",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1270"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Delivery schedule view enhancements",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1383"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added BD Reference Document",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1382"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Data import time can now be set (codewise)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1386"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled role handling on menu items",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1106"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Actual Shipment Start Week in PO Item list",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1392"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Include sender in email",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1415"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implemented new authentication flow in frontend",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1394"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "A bug where Meets Requested Date filter is showing in mobile even if neither column or dashboard graph is activated for account has been fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1372"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Implement logout scenario to logout user after 1 hour of inactivity",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1216"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Turned first column as default sorter in PO list",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1497"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Consistent use of sentence style formatting in ECOD",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-830"],
        customers: ["UX/UI"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Make clear that the user guide is a PDF document",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-831"],
        customers: ["UX/UI"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Adjusted padding between icons",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-845"],
        customers: ["UX/UI"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enhance Delivery Precision graph with Not on time incl. filtered result",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1294"],
        customers: ["VDF_NL"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Improved e-mail popup",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-851"],
        customers: ["UX/UI"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Re-wrote the title in the FAQ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-856"],
        customers: ["UX/UI"]
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Reduce the width box of the text",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-858"],
        customers: ["UX/UI"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Notification text adjusted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-853"],
        customers: ["UX/UI"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Added a visual state to a field",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-855"],
        customers: ["UX/UI"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Improved export popup",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-849"],
        customers: ["UX/UI"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "User export does not consider chosen filters, has been fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1526"],
        customers: []
      }
    ]
  },
  {
    version: "4.0",
    released: "2020-06-09",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Verizon Customer Custom Logic Sequence Change",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1085"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Attribute Scheduled Quantity added",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1095"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Attribute PosEx date added",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1293"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New view: Delivery Schedule",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-987"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Group material quantity per Shipment",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1291"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Planned Delivery Dashboard Improvement",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-693"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Create faked (non customer based) dataset for Demo customer",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-247"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Update ECOD Attributes (Definitions and Namings)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1302"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Adjust SQL Logic for Customer Confirmed Logic Sequence",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1278"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Predefined time period filter matching extraction limits implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1268"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Dashboards can now be toggled depending on role",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-869"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Removed Handling Unit and Shipment Number for SW",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-841"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Attributes not Changing Default names",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1384"],
        customers: []
      }
    ]
  },
  {
    version: "3.5",
    released: "2020-05-26",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implement filter caching in mobile",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1289"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Cannot push data from Test to Production for MTN Holding",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1267"],
        customers: ["MTN"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Planned Shipment End on PO Item level.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1215"],
        customers: ["KAR-TEL", "TELE2_KZ"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Adjust mobile CX/UX survey.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1195"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Blank out status and dates for Software on import",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1178"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Item shows wrong status in ECOD",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1175"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Alerts on import failures",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1170"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Customer Custom Logic Sequence Change",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1085"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Create empty customizable/localizable placeholders for attributes",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1078"],
        customers: ["MTN"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add CustomerConfirmedLogicSequence on Header",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1004"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Ship-To Party Name2",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1002"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Ship-To P.O. Item Number",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-998"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Ship-To PO Number",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-996"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New View: Serial Number View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-994"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Tooltips visible for internal Roles",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-975"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Limit data extraction to a specific starting date",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-780"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Caching of filters is now enabled",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-740"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Tooltips can be toggled in user settings",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-739"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Clear filter should also include any text entered in column search",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-714"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "When adding new users role selection should be an active choice",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-487"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Excel Export is now possible Admin views",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-454"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Create faked (non customer based) dataset for Demo customer",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-247"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Filtering by Product Groups enabled",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-180"],
        customers: []
      }
    ]
  },
  {
    version: "3.4",
    released: "2020-05-08",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Inquiry/Quote field implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1124"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Links for password reset for users is now visible in login screen",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1100"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented pop-up surveys for mobile app",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1098"],
        customers: ["ADOPTION", "BUSINESS_AND_CUSTOMER"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Planned Delivery Date - is not adapting in height is fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-1072"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Advance Invoice issued field implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-992"],
        customers: ["MTS_RU"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Holding Company feature implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-977"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add time zone in Latest Refresh",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-972"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Search field in Purchase Order List Combined view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-918"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled tracking link for Verizon US UPS Shipments",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-911"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New improved column filter",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-883"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Enabled Push setting of AdminConfig",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-876"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Changed authentication method to enable proper integration and setup Api Gateway",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-870"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Finalized secure integrations and exposing of API:s",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-868"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Sending email form refactored, new message field added.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-837"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Request Signum and wanted customer in Account request form",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-642"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Deactivate/hide tracking link for Shipments without Actual Shipment Start date/even implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-486"],
        customers: ["TELIA"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Increased size of scrollbars",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-411"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Improved design of excel export file",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-410"],
        customers: []
      }
    ]
  },
  {
    version: "3.3",
    released: "2020-04-24",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Create additional Comment Field on Header and Item",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-986"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented Comment field for orders items",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-434"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Add Sales Order Type ZOR5 to extraction",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-962"],
        customers: ["BHARTI"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable City as a separate attribute",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-828"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Implemented Shipment List View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-721"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Comments Column Not Filterable is fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-643"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable/Disable menu entries in Customer Admin",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-733"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enable excel export option to also include Shipment Data",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-826"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Final Invoice number and date for invoicing in data extract",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-993"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Setup PRODUCTION env for mobile",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-960"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Cleaned up and synced variables in Azure Devops pipelines",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-959"],
        customers: []
      }
    ]
  },
  {
    version: "3.2",
    released: "2020-04-09",
    notes: [
      {
        type: "CHANGES",
        internal: "1",
        comment: "Remove unused Attributes from ECOD",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-840"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "As an admin I can enable/disable mobile application",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-875"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Enable possibility to get filtered view of mobile PO list by using graphs (Only barchart and Meets req date)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-886"],
        customers: ["SWISSCOM"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Delivery Document No",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-878"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add Shipment related attribute",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-857"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "ECOD Admin Localization Page Enhancement and User Engagement",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-880"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Mobile PO item box does not seem to be similar to Detailed Information",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-969"],
        customers: ["SWISSCOM"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Mobile Headers in Detailed Information is in wrong colour",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-966"],
        customers: ["SWISSCOM"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Mobile color by valued logic is not applied in PO item view for Meets Requsted Date",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-967"],
        customers: ["SWISSCOM"]
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Alignement towards external integration",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-868"],
        customers: ["CHINAUNITED"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Change colour coding in Stock View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-821"],
        customers: ["BOUYGUES"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Header level Status issue fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-988"],
        customers: ["MTS"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Improved speed in PO Items list view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-862"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Fixed Meets Requested Date Logic Issue",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-696"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Enabled data population for first item in PO Item View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-915"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Enabled data extraction for Product Groups",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-180"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "AdminConfig change of Customization has no effect in Views",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-961"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Add SOName and SiteID to Purchase Order List View",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-827"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Overall Order Status Dashboard misaligned fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-874"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Bookmark filter in PO Item left column",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-745"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Hidden property was still rendered on mobile",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-968"],
        customers: ["SWISSCOM"]
      }
    ]
  },
  {
    version: "3.1",
    released: "2020-04-02",
    notes: [
      {
        type: "FEATURE",
        internal: "1",
        comment: "Deactivate menu options (interim solution)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-850"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "RFS date misalignment fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-843"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Combined view for PO Items for split market fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-786"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "User guide updated",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-746"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Shipment end added to PO item view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-744"],
        customers: ["VERIZON"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Application admins can now enable/disable dashboards per customer",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-732"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Mobile infrastructure in place",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-731"],
        customers: [""]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Balance Quantity implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-718"],
        customers: ["MTNNG"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "First date logic adjustment",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-703"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Order status hover grammar fix",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-694"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Bookmarks can now be set on purchase order items",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-745"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Combined PO Item view ",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-555"],
        customers: [""]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Possibility to deactivate Invoiced status in Overview Order status",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-528"],
        customers: ["Veon"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Mobile Orders view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-440"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Mobile Dashbboard view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-439"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Popup dialog for customer experience feedback implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-161"],
        customers: []
      }
    ]
  },
  {
    version: "3.0",
    released: "2020-03-16",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Delta changes for database implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-252"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New Customer Administration view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-255"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Tooltip updated for Planned Delivery Graph",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-549"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Updates for Stock view",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-554",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-553",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-545"
        ],
        customers: ["Bouygues"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Combined view for PO Items",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-555"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Customized customer name exported in excel",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-566"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Internal product structure can now be hidden",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-641"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue where selecting shipment in PO item view leads back to PO list view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-673"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with tooltip alignement",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-678"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Delivery precision filter is now always visible",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-679"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with wrong size on filter container",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-680"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue with column width on Bookmark table",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-681"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed height issue on Bookmark graph tile",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-682"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Excel export is now working as expected, columns alignement fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-701"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "RequestedDeliveryAt is now populated on items level",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-708"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Material Type now populated in PO Item view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-717"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment:
          "Interim solution for handling menu, dashboard activation and deactivation (hardcoded)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-720"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Customer confirmed logic sequence attribute",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-519"],
        customers: ["TMOBILE", "VERIZON"]
      }
    ]
  },
  {
    version: "2.4",
    released: "2020-03-02",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "New Dashboard, Delivery Precision against SLA",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-11"],
        customers: ["TELIA"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New field, Material Type",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-160"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New field, Contact Person",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-215"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Stock solution implemented backend",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-314"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Changed filter for Reason for Rejection",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-433"],
        customers: ["VERIZON"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Fix for the Planned Delivery Weekly Dashboard",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-470",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-469"
        ],
        customers: ["TMOBILE"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New field, IncoTerms",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-483"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Logic for Conf del date adjusted",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-483"],
        customers: ["Bouygues"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Filters is now kept during navigation",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-485"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Remove hidden fields from export",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-514"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Internal fields is now hidden in customization and localization",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-516"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New field, Total Quantity",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-526"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Better filter handling in combined view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-563"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Filters implemented in PO items list view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-552"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Linkage from Dashboards with PO Items now redirects to filtered PO Items list view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-544"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Invoice status removed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-527"],
        customers: ["CRA"]
      }
    ]
  },
  {
    version: "2.3",
    released: "2020-02-14",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Exclude Invoicing event from Application",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2045"],
        customers: ["MTS", "Veon"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Data refresh sequence changed to twice a day",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-12"],
        customers: ["TMOBILE"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "PO Item numbering is added as a new field",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-408"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Clicking on Daschboard Overall Order Status on Purchase Order Header does not lead to a filtered list is fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-475"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Column filtering on Shipments is not returning all expected entries has been fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-473"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Ericsson Sales Orders are missing from PO Item Search",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-472"],
        customers: ["TMOBILE"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Purchase Order Number filter does not capture all underlying Sales Orders",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-471"],
        customers: ["TMOBILE"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Issue with wrong Quantity fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-461"],
        customers: ["MTS"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Issue with wrong orders inclusion is fixed",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-460"],
        customers: ["MTS"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Tracking link is now correct",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-456"],
        customers: ["TELIA"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Goods marking is now correct",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-459"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Email functionality is now working as expected",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-431"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Issue with Feedback form is resloved",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-430"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue that forced the user to press login twice",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Minor column width adjustment",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-409"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Filter on Product/SKU dashboard added",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-363"],
        customers: ["TMO"]
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Create logic for hierarchic, groups and objects, as a step in the Azur C# code.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-303"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment:
          "Create logic for Supply process flow classification, as a step in the Azur C# code.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-302"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Create logic for Material Classification as a step in the Azur C# code.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-301"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Create logic for Aggregation logic as a step in the Azur C# code.",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-300"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Entire remake of Purchase Order view",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-248"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "PO items as a separate menu entry",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-248"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Data refreshed time is now visible in right pane (User Settings)",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-244"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Entire administration views has been rebuilt and is now more user friendly",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-243"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Weight information added",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-238"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "As an administration, it is possible to push settings between environments",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-162"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Data refresh interval increased",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-12"],
        customers: ["TMO"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Internal product structur object for dynamic product packages filter implemented",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-10"],
        customers: ["MTS", "Veon"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Leading zeroes are now trimmed on Sales Order and Delivery Number",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-9"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Calibration of Shipment Count Dashboard",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-3"],
        customers: ["MTNNG"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Comments can now be saved on a Purchase Order",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Breadcrumbs implemented for easier navigation",
        backlogUrls: [],
        customers: []
      }
    ]
  },
  {
    version: "2.2",
    released: "2020-02-05",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Filter on Verzion orders",
        backlogUrls: ["https://eteamproject.internal.ericsson.com/browse/ECOD-312"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Email service fixed",
        backlogUrls: [
          "https://eteamproject.internal.ericsson.com/browse/ECOD-430",
          "https://eteamproject.internal.ericsson.com/browse/ECOD-431"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Roles administration and configuration now supported",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1976",
          "https://jira.apphub.ericsson.net/browse/ECOD-1975",
          "https://jira.apphub.ericsson.net/browse/ECOD-1977",
          "https://jira.apphub.ericsson.net/browse/ECOD-1978"
        ],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Axises are now correctly scaled",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2043"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Calibration of Overall Order Status Donut Chart",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1963"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Shipment statuses in PO item view",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2094"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added Order data -> Name in PO item view",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2095"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added proforma/invoice number in PO item view",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1415"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Added more filters on dashboard tiles",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1944"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed data extraction for some customers (TELE2 KZ, KAR-TEL LLP)",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2127"],
        customers: ["MTSKZ", "VEONKZ"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed Goods marking missing",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1991"],
        customers: ["TELIA"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed missing shipments",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1990"],
        customers: ["TELIA"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed incorrect statuses",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1990"],
        customers: ["TELIA"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Calibration of Planned Delivery Date",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2046"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "CPO number is now fully visible in PO Item View",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2128"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed issue where Demo Dashboard did not work in Safari",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2093"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Removed tooltip on single line/bar charts",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1769"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Customer dropdown is now kept open on selection",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2104"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Sorting in tables is now possible",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1984"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Application Insight is now working correctly",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2129"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Two new fields, Created and Last Login, fields in users view",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1747"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "New Ericsson Contract Number, Site Name and Order Id added to Purchase Orders and Purchase Order Items",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1561"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Refinement of SQL Queries",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1837"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Transformation step-hooks aligned to new SQL Queries",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2092"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Overall order status dashboard added",
        backlogUrls: [],
        customers: ["MTNNG"]
      }
    ]
  },
  {
    version: "2.1",
    released: "2020-01-16",
    notes: [
      {
        type: "CHANGES",
        internal: "0",
        comment: "Support request form now contains a title",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Data is now loading correctly",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2127"],
        customers: ["MTS"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Minor update to SMP integration application",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Shipment number, RFS and actual delivery date is now correctly displayed",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-2106"],
        customers: ["MTSKZ", "VEONKZ"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Internal product structur object for dynamic product packages view implemented",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1899"],
        customers: [
          "VEONKZ",
          "MTNJB",
          "MTNCM",
          "MTNCG",
          "MTNCI",
          "MTNGH",
          "MTNLR",
          "MTNNG",
          "MTNRW",
          "MTNZA",
          "MTNSS",
          "MTNSD",
          "MTNSZ",
          "MTNUG",
          "MTNZM"
        ]
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fix for Shipment number",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1990"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Extract and data stabilization",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New SMP integration service",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Extract and data quality control",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Current selected customer is shown in right user panel. Customer selection dropdown is not shown when current user has only one customer connected.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1955"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The users administration view now gives a better overview of a user's connected customers",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1592"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "Fixed bug where verification of valid email when requesting access would be slow, and fixed look of new account request form",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The FAQ page has been refactored into a more compact view to give better overview.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1595"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "The feedback and support forms have been merged into one view.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1933"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "ß",
        comment: "Ericsson signum has been added as a field for when registering a new user",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1996",
          "https://jira.apphub.ericsson.net/browse/ECOD-1993",
          "https://jira.apphub.ericsson.net/browse/ECOD-1995",
          "https://jira.apphub.ericsson.net/browse/ECOD-1992"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Feedback/Support view routes request to right channel, depending on case.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1871"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Meets Requested Delivery Date and Confirmed Delivery Date KPIs in dashboard now have a descriptive tooltip, and you can now click the whole KPI to navigate to a filtered view in Orders.",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1965",
          "https://jira.apphub.ericsson.net/browse/ECOD-1964"
        ],
        customers: ["SWISSCOM"]
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "The customer details view is now accessed by clicking the edit-icon",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "It is now possible to select all rows with one click when selecting items to be e-mailed.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Quantities shown with space as thousand separator.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1834"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "New 'Ready for shipment' column added to purchase orders and purchase order items tables.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1943"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Early start indicator implemented",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1943"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Items view filtered according to orders filter",
        backlogUrls: [],
        customers: []
      }
    ]
  },
  {
    version: "2.0",
    released: "2020-01-09",
    notes: [
      {
        type: "FEATURE",
        internal: "1",
        comment: "New application architecture implemented",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New frontend application",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New API application",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New Data extractor application",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Data logic for all existing customers implemented",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Dev-Tools for customization and localisation",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "ECOD is now completely Azure hosted",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Users from Administration view can now be exported",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1601"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Overall order statuses are now greyed out depending on filter selection",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1600"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Fixed an issue in Edge when Export List file is not downloaded",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1610"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Email rows is now handle by form instead of simple email",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1651"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New filter functionality, closer connected to what is actually being filtered",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1793"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New improved export UI",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1803"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Filters now have tooltips",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1652"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New global search",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1811"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New toggable search method on column headers",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1811"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New multi-calendar filter selection",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "New MTN Dashboard",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1607"],
        customers: ["MTNNG"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "The dashboard has been extended with a new bar chart, showing planned deliveries for the previous two weeks, current week and the next coming two weeks.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1729"],
        customers: ["TMOBILE"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "The dashboard has been extended with a donut chart showing this week's order statuses, a KPI showing if there are any orders the next coming two weeks that will not meet the requested delivery date, and a KPI showing how many orders that are confirmed for delivery the next coming two weeks.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1435"],
        customers: ["SWISSCOM"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Confirmed delivery date making resulting in duplicated rows",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1733"],
        customers: ["Bouygues"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "A bug where the table would sometimes show information outside of the table has been fixed",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1749"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "For the items that have a tracking number, a link to the tracking website has been added.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1568"],
        customers: []
      }
    ]
  },
  {
    version: "1.5",
    released: "2019-11-21",
    notes: [
      {
        type: "BUGFIX",
        internal: "0",
        comment: "The phone will no longer zoom when selecting a date in the datepicker on iPhone",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Removed a bug where you could not scroll in the filter pane on mobile",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Removed bug where you could not select a custom period in datepicker.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1586"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The layout of KPI overviews has been changed and moved from the left, to the top.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1550"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The datepicker periods 'This Week', 'This Month' and 'This Year' now includes future dates as well, not only up until today's date.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "The statuses in the filter tile now have tooltips attached to them.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1585"],
        customers: []
      },
      {
        type: "BUG",
        internal: "0",
        comment:
          "A bug where the default time period would be applied to all datepicker filters has been fixed.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUG",
        internal: "0",
        comment:
          "A bug where the dashboard would not resize correctly when the filter pane or search box were visible has been fixed.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Redundant tooltips have been removed from the titles in the filter pane",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment:
          "The library 'd3', used for visualisations by EDS, has been added to the project in order to be able to add tooltips to the dashboard's bar chart.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1584"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "A descriptive title has been added in the filter pane on mobile on top of the active filters.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Removed bug where clicking a status in the filter pane would not change it's color to show it was selected",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "The filter view has been extended with more filters.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1436"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Exported files are now named with the name of the exported table.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "The field Quantity is now also variable based.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1548"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Hovering selections will now show more information about the selection.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "It is now possible to change the colors of cells in columns",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1514"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Some of the KPIs in the left menu in Purchase Order List now have tooltips with more information on hover.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1479"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "The calendar now has support for Chinese characters",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1507"],
        customers: ["CHINAUNITED"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "China UN is accessible in the application",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1091"],
        customers: ["CHINAUNITED"]
      },

      {
        type: "BUGFIX",
        internal: "0",
        comment: "Status should now be visible when a PO is selected",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1488"],
        customers: []
      },

      {
        type: "BUGFIX",
        internal: "0",
        comment: "Sorting now in the same way as the PO view",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1579"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "FAQ document shown inside application",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1483"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "A notification is shown in the application at startup for upcoming deployments",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1475"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "A disclaimer message regarding application data is shown on login page",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1522"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "An info message regarding data update frequency is visible at menu bottom end",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1486"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Spinner in reports page is now centered",
        backlogUrls: [],
        customers: []
      }
    ]
  },
  {
    version: "1.4",
    released: "2019-11-07",
    notes: [
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Aggregation logic refinement for statuses and confirmed delivery date, now confirmed delivery date and statuses should be aggregated for orders not containing any HW as well, the status of the PO should always reflect the ‘shortest’ supply event that has happened for the underlying material and invoiced POs with no customer PO tileRef. should get status ‘invoiced’ (i.e. not that many ‘No Status’ ",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1368",
          "https://jira.apphub.ericsson.net/browse/ECOD-1335",
          "https://jira.apphub.ericsson.net/browse/ECOD-1351"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Sales order number is now an available field",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1368",
          " https://jira.apphub.ericsson.net/browse/ECOD-1335",
          "https://jira.apphub.ericsson.net/browse/ECOD-1351"
        ],
        customers: ["GCUVCRU", "TMOBILE"]
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Quantity should now have the right format and also display the correct quantity",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1498",
          " https://jira.apphub.ericsson.net/browse/ECOD-1499"
        ],
        customers: ["BOUYGUES"]
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Veon should be integrated into the application with quantity and shipment number in place",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1090"],
        customers: ["GCUVCRU"]
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "All smaller ‘Overview’ tables should now be possible to classify",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1387"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "A bug on mobile where the values in the rows would not correspond the headers has been fixed",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1493"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "A bug where the infinite scroll broke after navigating from the dashboard has been fixed",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1451"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Removed Reports as menu item",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1489"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "The FAQ document is now a pdf file",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1490"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "A bug where users would get mobile view on small desktop computers has been fixed.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "When trying to export either Purchase Order och Purchase Order Item list, it is now possible to export a combined version of the two tables",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1455"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "A bug where you could scroll the menu button out-of-view has been fixed.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1371"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "A previous limitation where all rows could not be scrolled to has now been removed.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1378"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "You can now decide if you want to export the lists in CSV-format or XML-format.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1378"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "The bar chart in Dashboard has got a new design.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1377"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "The titles in the KPIs are now customizable per customer.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1230"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The search bar is now a dropdown, is automatically in focus and has a dedicated view on mobile.",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1325",
          "https://jira.apphub.ericsson.net/browse/ECOD-1327",
          "https://jira.apphub.ericsson.net/browse/ECOD-1331"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "The menu has been moved from the Angular layer to the React layer.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1304"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Aggregation logic of statuses – refinement of the implementation of the business logic",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1368"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Refinement of aggregation logic of statuses and confirmed delivery date so that it can handle orders only containing SW",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1351"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Veon added to the extract, currently with the standard view",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1090"],
        customers: ["GCUVCRU"]
      },
      {
        type: "CHANGES",
        internal: "1",
        comment:
          "It is now possible to add Ericsson Sales Order into the application via the config. file, not currently added in for any specific customer",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1379"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment:
          "Config file updates search results now matching the config name, not the base name. Shiping overview is customizable. The small tables on the side are also customizable via the config. file",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1362"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Last selected customer is preserved on application restart",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1373"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "External users can’t have admin rights",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-920"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "FAQ is now available in left menu",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1452",
          "https://jira.apphub.ericsson.net/browse/ECOD-1306"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Release notes is now available in left menu",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1446",
          "https://jira.apphub.ericsson.net/browse/ECOD-1447"
        ],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Application menu defaults to collapsed and automatically closes on selection on mobile devices",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1470"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "New table for administration views",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1298"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Feedback support process enabled",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1383"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "User Guide is now available in left menu",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1453"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Feedback is directed to Teams dedicated channel",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1443"],
        customers: []
      }
    ]
  },
  {
    version: "1.3",
    released: "2019-10-24",
    notes: [
      {
        type: "FEATURE",
        comment: "New forms for user request, feedback and support",
        internal: "0",
        backlogUrls: [
          "https://jira.apphub.ericsson.net/browse/ECOD-1299",
          "https://jira.apphub.ericsson.net/browse/ECOD-921",
          "https://jira.apphub.ericsson.net/browse/ECOD-1300",
          "https://jira.apphub.ericsson.net/browse/ECOD-1344"
        ],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "IE - Not Supported view",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1302"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "New Qlick ticket flow",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: " A user can now select up to five rows that they want to send in an email.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-594"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "The tabs in the Dashboard and Purchase Order List, as well as the level title can now be customized per customer.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-955"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Default sort of Purchase order item by confirmed delivery date.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-481"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Hide/show columns in tables (Purchase order and Purchase order item)",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-955"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Rearrange sequence of columns in tables.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-955"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Rename column header of tables.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-955"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Rename table name",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-955"],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Setting which tab to default, Purchase Order or Purchase Order Item",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-955"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Infinite scroll fixed for Edge",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1343"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Default sorting fixed",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Date format fixed",
        backlogUrls: [],
        customers: ["SLOVAKTSK"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Statuses fixed",
        backlogUrls: [],
        customers: ["TMOBILE", "MTNNG"]
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "The status 'Left local warehouse' no longer visible",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1352"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "When no status and all is invoiced, we add give the PO status",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1353"],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment: "Refreshing the page on an error refreshes the whole page, not just the iFrame",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1359"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "Moved settings of date formats to custom config file",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment:
          "Logical changes to level navigation in Purchase Order List. Instead of checking for selection of certain fields to change level, selection are now based on a unique key per row",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-955"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment: "Error messages are rewritten and now without references.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1359"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Layout changes to KPI-overview on mobile, now has three items per row in landscape mode.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1079"],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Purchase order list is now limited to loading the first 20'000 cells, to enhance performance. This means a user can only load the next set of data in the table with infinite-scroll 100 times.",
        backlogUrls: ["https://jira.apphub.ericsson.net/browse/ECOD-1354"],
        customers: []
      }
    ]
  },
  {
    version: "1.2",
    released: "2019-10-11",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Feedback functionality",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "1",
        comment: "Application Insight enabled",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Navigation from dashboard to purchase order list. You can now navigate from the dashboard to the purchase order list by clicking on a bar in the bar chart. This will lead you to the purchase order list, with the list filtered on the status you clicked on in the bar chart.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment:
          "Error warnings have been added. Any errors that cause the application to stop working (Such as session timeout) will prompt the user to refresh the page.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Changed ticket request flow",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The link to the user guide in the left-side menu has been removed. It can now be found in the top bar instead.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The link to the feedback form in the left-side menu has been remove. It can now be found in the top bar instead.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The names of the tabs in the purchase order list or now dynamically set per customer.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "1",
        comment: "What app to use is now selected using a query from the iFrame.",
        backlogUrls: [],
        customers: []
      }
    ]
  },
  {
    version: "1.1",
    released: "2019-10-04",
    notes: [
      {
        type: "BUGFIX",
        internal: "1",
        comment:
          "Changed Claims field for extracting user email, making sure external users get proper authentication.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "0",
        comment:
          "There was a bug where customers with a limited depth level in Purchase Order List would get an error when trying to navigate further than was allowed. This is now fixed.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "BUGFIX",
        internal: "1",
        comment: "Fixed a possible vulnerability relating to opening a link in new windows.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "Keep showing the overview information of PO statuses when selecting the tab 'Purchase Order Item'.",
        backlogUrls: [],
        customers: []
      },
      {
        type: "CHANGES",
        internal: "0",
        comment:
          "The page telling Internet Explorer users to use another browser now better matches the look of the rest of the application.",
        backlogUrls: [],
        customers: []
      }
    ]
  },
  {
    version: "1.0",
    released: "2019-10-01",
    notes: [
      {
        type: "FEATURE",
        internal: "0",
        comment: "Dashboard",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Purchase Order List",
        backlogUrls: [],
        customers: []
      },
      {
        type: "FEATURE",
        internal: "0",
        comment: "Initial release",
        backlogUrls: [],
        customers: []
      }
    ]
  }
];
