import { splitOnUpperCase } from ".";
import { useStore } from "../store";

export const useUserHelpers = () => {
  const [state] = useStore();

  const userIsCurrent = (userId: string | undefined) =>
    !!(userId && state.user && userId === state.user.id);

  const userRoleIsSuperUser = (userRole: number) =>
    state.userRoles.find(r => r.value === userRole && r.isApplicationAdministrator);

  const userRoleIsAdministrator = (userRole: number): boolean => {
    const i = state.userRoles.find(
      r => r.value === userRole && r.isAdministrator && !r.isApplicationAdministrator
    );
    return i ? true : false;
  };

  const userRoleIsTechSupport = (userRole: number): boolean => {
    const i = state.userRoles.find(r => r.value === userRole && r.isTechnicalSupport);
    return i ? true : false;
  };

  const userRoleIsCustomer = (userRole: number): boolean => {
    const i = state.userRoles.find(r => r.value === userRole && r.roleName === "Customer");
    return !!i;
  };

  const userRoleIsInternal = (userRole: number) =>
    state.userRoles.some(r => r.value === userRole && r.isInternal);

  const userRoleIsNonAdministrator = (userRole: number) => {
    return nonAdministratorUserRoles().some(role => role.value === userRole);
  };

  const internalDefaultUserRole = () =>
    (state.userRoles.filter(
      r =>
        userRoleIsInternal(r.value) &&
        !userRoleIsSuperUser(r.value) &&
        !userRoleIsAdministrator(r.value) &&
        !userRoleIsTechSupport(r.value)
    ) || [{ value: -1 }])[0].value;

  const externalDefaultUserRole = () =>
    (state.userRoles.filter(
      r =>
        !userRoleIsInternal(r.value) &&
        !userRoleIsSuperUser(r.value) &&
        !userRoleIsAdministrator(r.value) &&
        !userRoleIsTechSupport(r.value)
    ) || [{ value: -1 }])[0].value;

  const nonAdministratorUserRoles = () =>
    state.userRoles.filter(
      r =>
        !userRoleIsSuperUser(r.value) &&
        !userRoleIsAdministrator(r.value) &&
        !userRoleIsTechSupport(r.value)
    );

  const externalUserRoles = () => state.userRoles.filter(r => !userRoleIsInternal(r.value));

  const allUserRoles = () => state.userRoles;

  const userRoleName = (userRole: number) =>
    splitOnUpperCase(
      (state.userRoles.find(r => r.value === userRole) || { roleName: "" }).roleName
    );

  const userVirtualRoleName = (userRole: number) =>
    splitOnUpperCase(
      (state.userVirtualRoles.find(r => r.value === userRole) || { roleName: "" }).roleName
    );

  const userDatePickerFormat = (format?: string) => {
    if (!format) {
      return undefined;
    }
    const userFormat = state.userDateFormats.find(f => f.format === format);
    if (!userFormat) {
      return undefined;
    }
    switch (userFormat.description) {
      case "YYYY/MM/DD":
        return "YYYY-MM-DD";
      case "DD/MM/YYYY":
        return "DD-MM-YYYY";
      case "MM/DD/YYYY":
        return "MM-DD-YYYY";
      default:
        return undefined;
    }
  };

  return {
    userIsCurrent,
    userRoleIsAdministrator,
    userRoleIsTechSupport,
    userRoleIsCustomer,
    userRoleIsSuperUser,
    userRoleIsInternal,
    internalDefaultUserRole,
    externalDefaultUserRole,
    userRoleIsNonAdministrator,
    nonAdministratorUserRoles,
    externalUserRoles,
    allUserRoles,
    userRoleName,
    userVirtualRoleName,
    userDatePickerFormat
  };
};
