import { Button, FileUpload } from "@react-gcc-eds/core";
import React from "react";

export const FileAttachment = ({
  file,
  fileSetter
}: {
  file: File | undefined;
  fileSetter: Function;
}) => {
  return (
    <div className="input-form-file-upload">
      <label className="row">
        {`Attachment:`}
        <b>{file ? file.name : ""}</b>
      </label>
      {!file && (
        <FileUpload
          className="row"
          title="Upload screenshot"
          onChange={(e: any) => fileSetter(e.target.files[0])}
        />
      )}
      {file && (
        <div className="row">
          <Button onClick={() => fileSetter(undefined)}>
            <i className="icon icon-trashcan" />
            Remove screenshot
          </Button>
        </div>
      )}
    </div>
  );
};
