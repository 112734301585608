import React, { useEffect, useState } from "react";
import { FallbackProps } from "react-error-boundary";
import { ApplicationLayer, Button } from "@react-gcc-eds/core";
import FullScreenPanel from "../shared/full-screen-panel";
import ContactUs, { EViews } from "../mail-forms/contact-us";
import { useStore } from "../../store";
import { useApiActions } from "../../actions/api-actions";
import { useNotifications } from "@react-gcc-eds/core";
import { useSettingsActions } from "../../actions/settings-actions";

const ApiErrorManager = () => {
  const [state] = useStore();

  const { popError } = useApiActions();
  const { createNotification } = useNotifications();

  const notifyNextError = () => {
    const message = popError();

    if (message) {
      createNotification("Error", message, "warning", "triangle-warning");
    }
  };

  useEffect(() => {
    notifyNextError();
  }, [state.api.errors]);

  return null;
};

const ApplicationError = ({ error, resetErrorBoundary }: FallbackProps) => {
  const [showContact, setShowContact] = useState(false);
  const toggleContact = () => setShowContact(show => !show);
  const { currentCustomer } = useSettingsActions();
  const resetError = () => {
    resetErrorBoundary();
    window.location.replace("/");
  };

  return (
    <ApplicationLayer title="">
      <FullScreenPanel>
        <div>
          {showContact && (
            <ContactUs
              customerIdentifier={currentCustomer().identifier}
              onClose={toggleContact}
              typeOfForm={EViews.SUPPORT}
            />
          )}
          <div className="error-wrapper">
            <div className="error-header">Application Error</div>
            <div className="error-header">It's not you, it's us</div>
            {error && error.name == "ChunkLoadError" ? (
              <div className="error-header">Data loading was not completed</div>
            ) : (
              <div className="error-header">{error.message}</div>
            )}
            <div className="error-instructions">
              Please try again, and if the error persists, please&nbsp;
              <a href="#" onClick={toggleContact}>
                Contact us&nbsp;
              </a>
              to report the issue.
            </div>
            <Button onClick={resetError}>Go to Home</Button>
          </div>
        </div>
      </FullScreenPanel>
    </ApplicationLayer>
  );
};

export { ApiErrorManager, ApplicationError };
