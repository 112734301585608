import { emptyCustomer, ICustomer, useStore } from "../store";

export const useSettingsActions = () => {
  const [state, setState] = useStore();

  /*
  Defalt for walkthrough is set to eneabled, 
  leave commented code for backwards compatibility
  */

  const isWalkthroughEnabled = () => {
    return true;
    // return (
    //   currentCustomer().features.applicationSettings.enableWalkThrough &&
    // );
  };

  const currentCustomer = (): ICustomer => {
    const noCustomer = emptyCustomer();
    if (state.userPreferences.customerId && state.user) {
      return state.customer || noCustomer;
    }

    return noCustomer;
  };

  const clearCustomer = () => {
    setState(old => ({
      ...old,
      userPreferences: { ...old.userPreferences, customerId: undefined }
    }));
  };

  const toggleUserPref = (prop: string, status: boolean) => {
    setState(old => ({
      ...old,
      userPreferences: { ...old.userPreferences, [prop]: status }
    }));
  };

  return {
    isWalkthroughEnabled,
    currentCustomer,
    clearCustomer,
    toggleUserPref
  };
};
