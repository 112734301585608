import React from "react";
import cx from "classnames";

interface Props {
  label: string;
  x?: number;
  y: number;
  textAnchor: "start" | "middle" | "end";
  hidden: boolean;
}

const Label = ({ label, x, y, textAnchor, hidden }: Props) => {
  return (
    <text
      textAnchor={textAnchor}
      style={{ pointerEvents: "none" }}
      className={cx("label text", { hidden: hidden })}
      x={x !== undefined ? x : 0}
      y={y}
      opacity={1}
    >
      {label}
    </text>
  );
};

export default Label;
