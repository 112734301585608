import * as d3 from "d3";
import React, { useState, useRef, useEffect, useCallback } from "react";
import cx from "classnames";

import { Data, Serie } from "../common/interfaces";
import * as utils from "../common/helpers";

import Legend from "../common/components/Legend";
import IStackedLineBarChart from "./IStackedLineBarChart";
import StackedLineBarGroups from "../common/components/StackedLineBarGroups";
import Unit from "../common/components/Unit";
import Tooltip from "../common/components/Tooltip";

const StackedLineBarChart = ({
  data,
  width = 854,
  onToggle,
  fill = false,
  onClick,
  hideLegend = false,
  parentRef,
  invertColorScheme = false,
  unit = ""
}: IStackedLineBarChart) => {
  if (data.common.length > 1) {
    throw new Error("StackedLineBarChart can only support one common.");
  }
  const errorMsg = utils.checkData(data); // Check if data is correct
  if (errorMsg !== "") {
    throw new Error(errorMsg);
  }

  const [initialized, setInitialized] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const legendRef = useRef<HTMLDivElement>(null);
  const [Width, setWidth] = useState(width);

  useEffect(() => {
    const clientWidth = ref !== null && ref.current !== null ? ref.current.clientWidth : 0;
    setWidth(clientWidth);
  });

  const colorScale = utils.getColorScale(data.series.length, undefined, invertColorScheme);
  const [hoveredSerie, setHoveredSerie] = useState("");
  const [hoveredValue, setHoveredValue] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState(0);
  const [tooltipVisisble, setTooltipVisible] = useState(false);

  const [allHidden, setAllHidden] = useState(false);
  const [chartData, setChartData] = useState({} as unknown as Data);
  const [emptyChart, setEmptyChart] = useState(false);

  // Windowresize listener
  const [, updateState] = React.useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("toggleNavigation", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("toggleNavigation", handleResize);
    };
  }, []);

  useEffect(() => {
    const willHideAll = data.series.filter((s: Serie) => s.hidden).length === data.series.length;
    const newChartData = Object.assign({}, data);

    if (newChartData.series.length === 0 && newChartData.common.length === 0) {
      newChartData.common = [""];
      newChartData.series = [
        {
          name: "",
          values: [1]
        }
      ];
      setEmptyChart(true);
    }

    newChartData.series = newChartData.series.filter((s: Serie) => !s.hidden);

    const newTotalValues = [] as number[];

    if (willHideAll) {
      data.series.forEach((serie: Serie) => {
        serie.values.forEach((number: number) => {
          newTotalValues.push(number);
        });
      });
    } else {
      newChartData.series.forEach((serie: Serie) => {
        serie.values.forEach((number: number) => {
          newTotalValues.push(number);
        });
      });
    }
    setAllHidden(willHideAll);
    setChartData(newChartData);
    setInitialized(true);
  }, [data, data.series, allHidden]);

  const totalValue =
    chartData.series && chartData.series.length > 0
      ? chartData.series.map((s: Serie) => s.values[0]).reduce((a: number, b: number) => a + b)
      : 0;

  const totalQuantity = `Total quantity: ${d3
    .format(",")(!emptyChart ? totalValue : 0)
    .replace(/,/g, " ")}`;

  const xScale = d3
    .scaleLinear()
    .domain([0, 100])
    .nice()
    .range([0, Width - 10]);

  const colors = {};
  data.series.forEach(d => {
    colors[d.name] = colorScale(d.name);
  });

  const heightStyling = fill ? { width: "100%" } : { width };

  const sizeFromParentRef =
    parentRef && parentRef.current
      ? {
          width: `${parentRef.current.clientWidth}px`,
          marginTop: "14px"
        }
      : { width: "100%", display: "flex", marginTop: "14px" };

  return initialized ? (
    <div
      className={cx("stackedLineBarChartContainer", { disabled: emptyChart })}
      style={heightStyling}
    >
      {!hideLegend && (
        <div ref={legendRef}>
          <Legend
            series={data.series}
            colorScale={colors}
            onMouseHover={(s: string) => {
              setHoveredSerie(s);
            }}
            onClick={(i: number) => {
              if (onToggle) {
                const newData = utils.jsonClone(data); // Object.assign({}, data);
                newData.series[i].hidden = !newData.series[i].hidden;
                onToggle(newData, i);
                setHoveredSerie("");
              }
            }}
          />
        </div>
      )}
      <div ref={ref} style={!fill ? { height: "inherit", marginTop: "14px" } : sizeFromParentRef}>
        <svg
          height="30"
          className="chart stackedlinechart"
          style={{ width: "100%", display: "flex" }}
        >
          <g transform="translate(16,0)" className="canvas bar-chart">
            <StackedLineBarGroups
              data={chartData}
              x0={xScale}
              colorScale={colors}
              hoveredSerie={hoveredSerie}
              totalValue={totalValue}
              onHover={(
                _hoveredSerie: string,
                _hoveredValue: number,
                e: React.MouseEvent<SVGLineElement, MouseEvent>
              ) => {
                // console.log('e.pageX', e.pageX, e);
                if (_hoveredSerie && e) {
                  setHoveredSerie(_hoveredSerie);
                  setHoveredValue(_hoveredValue);
                  setTooltipPosition(e.pageX - 30);
                  setTooltipVisible(true);
                } else {
                  setHoveredSerie("");
                  setTooltipVisible(false);
                }
              }}
              onClickProvided={onClick !== undefined}
              onClick={(l: string, v: number[]) => onClick && onClick(l, v)}
              empty={emptyChart}
            />
            <Unit x={0} y={13} unit={`${totalQuantity} ${unit}`} textAnchor="start" />
          </g>
        </svg>
        <Tooltip
          colors={colors}
          unitTitle={hoveredSerie}
          unitValue={`
          ${d3
            .format(",")(!emptyChart ? hoveredValue : 0)
            .replace(/,/g, " ")} ${unit}`}
          description=""
          items={[]}
          x={tooltipPosition}
          y={161}
          small
          visible={tooltipVisisble}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default StackedLineBarChart;
