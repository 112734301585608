/** Source: ecod/api/src/Domain/Enums/View.cs **/
export enum EViewId {
  PO_ITEMS = 0,
  PO = 10,
  PO_ITEMS_LIST = 15,
  PO_OVERVIEW = 60,
  PO_ITEM_MATERIALS = 40,
  INTERNAL_PO = 150,
  INTERNAL_PO_OVERVIEW = 170,
  INTERNAL_PO_ITEMS = 160,
  DELIVERY_SCHEDULE_BOOKMARKED_ITEMS = 130,
  DELIVERY_SCHEDULE_ITEM_OVERVIEW = 140,
  DELIVERY_SCHEDULE_BOOKMARK_ITEM = 205,
  INTERNAL_DELIVERY_SCHEDULE_BOOKMARK = 215,
  INTERNAL_DELIVERY_SCHEDULE_ITEM_OVERVIEW = 280,
  INTERNAL_DELIVERYSCHEDULE_BOOKMARKED_ITEMS = 270,
  SHIPMENT = 220,
  SHIPMENT_OVERVIEW = 100,
  SHIPMENT_ITEMS = 110,
  SHIPMENT_ITEM_SERIALS = 50,
  SERIAL_NUMBERS = 120,
  SERIAL_NUMBER_CHILDREN = 200,
  SERIAL_NUMBERS_INTERNAL = 290,
  SERIAL_NUMBERS_CHILDREN_INTERNAL = 300,
  NWH_INVENTORY = 145,
  ASP_INVENTORY = 155,
  SITE_MATERIALS = 175,
  SITE_MILESTONES = 180,
  BOM = 185,
  BOM_ITEMS = 190,
  INTERNAL_SHIPMENT_OVERVIEW = 230,
  INTERNAL_SHIPMENT_CONTENTS = 240,
  INTERNAL_PO_IT_SERIAL = 250,
  INTERNAL_SHIPMENT = 260

  //PO_VIEW_MOBILE = 20,
  //PO_MOBILE = 30,
  //OVERVIEW_PO_IT_SHIPMENT = 70,
  //PO_IT_SHIP_DETAILS = 80
  //OVERVIEW_PO_IT_DETAIL_MOB = 90,
}
