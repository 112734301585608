import fileDownload from "js-file-download";

import { useApi } from "./api-factory";

import { useStore, apiWritingOn, apiWritingOff } from "../store";

export const useDownloadActions = () => {
  const [_, setState] = useStore();

  const { api } = useApi({ includeCustomer: false });

  const downloadExport = async (blob: string, onSuccess?: Function, onFailure?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, blob));
      const [exportBlob, fileName] = await api.postForBlob(
        `v1/DataExport/download/?fileId=${encodeURIComponent(blob)}`
      );
      setState(old => apiWritingOff(id, old));
      onSuccess && onSuccess();
      fileDownload(exportBlob, fileName || "ecod-export");
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      onFailure && onFailure();
    }
  };

  return {
    downloadExport
  };
};
