import { Button, Icon } from "@react-gcc-eds/core";
import React, { ReactElement, useState } from "react";
import { useMailActions } from "../../../actions/mail-actions";
import { useSettingsActions } from "../../../actions/settings-actions";
import { useStore } from "../../../store";
import SurveyDialog from "./survey-dialog";

export const usePopUpFeedback = () => {
  const [state] = useStore();
  type DialogToShow = "question" | "terms" | "thanks" | undefined;
  const [showDialog, setShowDialog] = useState<DialogToShow>("question");
  //type DialogToShow = "question" | "terms" | "thanks" | "specialusernotificationinfo" | undefined;
  //const [showDialog, setShowDialog] = useState<DialogToShow>("specialusernotificationinfo");
  const [answer, setAnswer] = useState<undefined | number>(undefined);

  const PopUpFeedback = (): ReactElement => {
    const { sendFeedbackForm } = useMailActions();
    const { currentCustomer } = useSettingsActions();
    const question = "How easy was it to use Ericsson Customer Order Dashboard?";
    const quotes = [
      "ECOD data is also consumed by integration with customer portals",
      "ECOD counts with a number of features to help you find and use the data in the way you need it.",
      "ECOD includes email functionality for efficient data sharing directly from the application",
      "ECOD layout can be personalized by drag/drop/hide columns functionality to fit your visualization needs."
    ];
    const handleSubmit = () => {
      if (answer) {
        sendFeedbackForm(currentCustomer().identifier, [
          {
            question: question,
            answer: answer?.toString()
          }
        ]);
        setAnswer(undefined);
        setShowDialog(undefined);
      }
    };

    const handleCancel = () => {
      setAnswer(undefined);
      setShowDialog(undefined);
      //sendFeedbackForm(currentCustomer().identifier);
    };
    const questionDialog = () => {
      return (
        <SurveyDialog
          onCancel={handleCancel}
          leftContent={
            <div
              className="content"
              style={{
                background: `url(${process.env.PUBLIC_URL + "/img/survey/feedback-stock.png"})`
              }}
            >
              <div className="big-text">Hi {state.user?.firstName}!</div>
              <div style={{ fontSize: "16px" }}>
                Your opinion is valuable, help us to make it count!
              </div>
              <Button onClick={() => setShowDialog("terms")}>Terms and consent</Button>
            </div>
          }
          rightContent={
            <div className="survey-question">
              <div className="question">{question}</div>
              <div className="answer-items">
                {[1, 2, 3, 4, 5, 6, 7].map(i => {
                  return (
                    <div
                      key={i}
                      className={`answer a${i}`}
                      onClick={() => {
                        setAnswer(i);
                        setShowDialog("thanks");
                      }}
                    >
                      {i}
                    </div>
                  );
                })}
              </div>
              <div className="answer-text-scale">
                <div>Very difficult</div>
                <div>Very easy</div>
              </div>
            </div>
          }
        />
      );
    };
    const termsAndConsentDialog = () => {
      return (
        <SurveyDialog
          onCancel={() => setShowDialog("question")}
          leftContent={
            <div
              className="content"
              style={{ background: `url(${process.env.PUBLIC_URL + "/img/survey/terms_bgr.png"})` }}
            >
              <div className="big-text">Terms and consent</div>
            </div>
          }
          rightContent={
            <div className="terms">
              We, Ericsson, would like to get your feedback in this survey in order to improve our
              customer experience and products. We will keep this data for 12 months. Your response
              is anonymized and you can at any step in this survey cancel your participation and no
              data will then be saved.
            </div>
          }
        />
      );
    };
    const thankYouDialog = () => {
      return (
        <SurveyDialog
          onCancel={handleSubmit}
          leftContent={
            <div
              className="content"
              style={{ background: `url(${process.env.PUBLIC_URL + "/img/survey/thankyou.png"})` }}
            >
              <div className="big-text">Did you know?</div>
              <span>{quotes[Math.floor(Math.random() * (quotes.length - 1))]}</span>
            </div>
          }
          rightContent={
            <div className="thank-you">
              <div style={{ fontSize: "32px", fontWeight: "bold" }}>Thank you!</div>
              <Icon className="as-circle" name="check" />
              <div style={{ fontWeight: 500 }}>Your feedback is precious to the ECOD team.</div>
            </div>
          }
        />
      );
    };
    // const ecodSpecialUserNotificationDialog = () => {
    //   return (
    //     <SurveyDialog
    //       onCancel={handleCancel}
    //       leftContent={
    //         <div
    //           style={{
    //             backgroundRepeat: "no-repeat",
    //             background: `url(${process.env.PUBLIC_URL + "/img/ecodmaintenaceinfo273x350.png"})`,
    //             width: "270px",
    //             height: "350px",
    //             margin: "-24px 0px 0px 0px",
    //             padding: "0px 0px 0px 0px"
    //           }}
    //         ></div>
    //       }
    //       rightContent={
    //         <div
    //           style={{
    //             width: "620px",
    //             height: "272px",
    //             fontSize: "32px",
    //             fontWeight: "bold",
    //             margin: "0px 0px 0px 0px",
    //             padding: "0px 0px 20px 10px"
    //           }}
    //         >
    //           <div
    //             style={{
    //               fontSize: "32px",
    //               fontWeight: "bold",
    //               margin: "-25px 0px 0px 0px",
    //               padding: "0px 0px 28px 10px"
    //             }}
    //           >
    //             Dear
    //             <br />
    //             {state.user?.firstName}
    //           </div>
    //           <div
    //             style={{
    //               fontSize: "20px",
    //               fontWeight: "bold",
    //               margin: " 0px 0px 5px 0px",
    //               padding: "0px 0px 0px 10px"
    //             }}
    //           >
    //             ECOD add som info here,
    //             <br />
    //             add more info
    //             <br />
    //             add more info.
    //             <br />
    //             <br />
    //             Add more info.
    //             <br />
    //             <br />
    //           </div>
    //           <div style={{ padding: "0px 50px 0px 0px", float: "right" }}>
    //             <Button onClick={() => handleCancel()}>Close</Button>
    //           </div>
    //         </div>
    //       }
    //     />
    //   );
    // };
    if (showDialog === "question") return questionDialog();
    if (showDialog === "terms") return termsAndConsentDialog();
    if (showDialog === "thanks") return thankYouDialog();
    //if (showDialog === "specialusernotificationinfo") return ecodSpecialUserNotificationDialog();
    return <></>;
  };

  return { PopUpFeedback };
};
