import { IPurchaseOrderStatus, useStore } from "../../../store";
import { useSettingsActions } from "../../../actions/settings-actions";

export const usePoStatus = () => {
  const [state] = useStore();
  const { currentCustomer } = useSettingsActions();

  const invoicingStatuses: IPurchaseOrderStatus[] = [
    {
      code: 0,
      value: "NoStatus"
    },
    {
      code: 10,
      value: "NotInvoiced"
    },
    {
      code: 20,
      value: "PartiallyInvoiced"
    },
    {
      code: 30,
      value: "Invoiced"
    }
  ];

  const purchaseOrderStatuses = () => {
    if (!state.customer || !state.userPreferences.customerId) {
      return [];
    }

    const customer = state.customer;

    if (!customer) {
      return [];
    }
    return customer.statusTranslations;
  };

  const internalPurchaseOrderLocationStatuses = () => {
    return currentCustomer().internalStatusTranslations;
  };

  const internalPurchaseOrderDeliveryStatuses = () => {
    return currentCustomer().internalDeliveryStatusTranslations;
  };

  const labelToStatus = (label: string): number | undefined => {
    const t = purchaseOrderStatuses();
    const s = purchaseOrderStatuses().find(s => s.value.toUpperCase() === label.toUpperCase());

    return s ? s.code : undefined;
  };

  const internalLabelToDeliveryStatus = (label: string): number | undefined => {
    const s = internalPurchaseOrderDeliveryStatuses().find(
      s => s.value.toUpperCase() === label.toUpperCase()
    );

    return s ? s.code : undefined;
  };

  const statusToLabel = (status: number): string | undefined => {
    const s = purchaseOrderStatuses().find(s => s.code === status);
    return s ? s.value : undefined;
  };

  const internalDeliveryStatusToLabel = (status: number): string | undefined => {
    const s = internalPurchaseOrderDeliveryStatuses().find(s => s.code === status);
    return s ? s.value : undefined;
  };

  const invoicingLabelToStatus = (label: string): number | undefined => {
    const t = invoicingStatuses;
    const s = invoicingStatuses.find(s => s.value.toUpperCase() === label.toUpperCase());

    return s ? s.code : undefined;
  };

  return {
    purchaseOrderStatuses,
    labelToStatus,
    statusToLabel,
    internalPurchaseOrderLocationStatuses,
    internalPurchaseOrderDeliveryStatuses,
    internalLabelToDeliveryStatus,
    internalDeliveryStatusToLabel,
    invoicingLabelToStatus
  };
};
