import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";

interface Props {
  x: number;
  y: number;
  r: number;
  colorData: string;
  opacity: number;
}

const Dot = ({ x, y, r, colorData, opacity }: Props) => {
  return (
    <AnimatePresence>
      <motion.circle
        initial={{ r: 0 }}
        animate={{ r }}
        exit={{ r: 0 }}
        className={cx(`dot ${colorData}`)}
        cx={x}
        cy={y}
        r={r}
        opacity={opacity}
      ></motion.circle>
    </AnimatePresence>
  );
};

export default Dot;
