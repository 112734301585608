import { IStep } from "../../../store";
import {
  appendClassToDiv,
  closeSettingsMenu,
  elementVisible,
  getDefinedElement,
  getTextOfElement,
  openSettingsMenu
} from "../helpers";
import { Icon } from "@react-gcc-eds/core";
import React, { RefObject } from "react";
import { ControllerExplanation } from "../components/step-components";

const useGeneralSteps = (ref: RefObject<HTMLDivElement>) => {
  const defaultStep: IStep = {
    target: "",
    content: "",
    disableScrollParentFix: true,
    disableScrolling: true,
    spotlightPadding: 0,
    placement: "bottom"
    // blockClickOutside: true
  };
  const parent = ref.current;
  const steps: IStep[] = !parent
    ? []
    : [
        {
          ...defaultStep,
          target: ".app",
          title: "General walk through",
          content:
            "This is a guided walkthrough for ECOD. It will teach you the basics of how to navigate the site.",
          placement: "center"
        },
        {
          ...defaultStep,
          target: parent,
          title: "Controlling the walkthrough",
          placement: "center",
          content: <ControllerExplanation />
        },
        {
          ...defaultStep,
          target: ".appbody .tree.navigation ul",
          title: "Navigation",
          content: "This is the navigation",
          placement: "right"
        },
        {
          ...defaultStep,
          target: getDefinedElement(parent, ".appbody .tree.navigation ul li .active"),
          title: "Navigation",
          content: `We are currently ${getTextOfElement(
            parent,
            ".appbody .tree.navigation ul li .active",
            "here",
            "in the "
          )}`,
          placement: "right-start"
        },
        {
          ...defaultStep,
          target: ".appbody .tree.navigation ul",
          title: "Navigation",
          content: "You can change view by clicking any of items in the list",
          spotlightClicks: true,
          placement: "right"
        },
        {
          ...defaultStep,
          target: ".sysbar .items-container:nth-of-type(2) div:nth-of-type(2)",
          title: "Version",
          content: "Here you can see what version of ECOD is in",
          placement: "top"
        },
        {
          ...defaultStep,
          target: ".sysbar .items-container:nth-of-type(2) div:nth-of-type(3)",
          title: "Contact",
          content: "If you want to contact us, you can do that here",
          placement: "top"
        },
        {
          ...defaultStep,
          target: ".sysbar .items-container:nth-of-type(2) div:nth-of-type(4)",
          title: "Alerts",
          content: "Alerts given by the application can be shown by click the bell",
          placement: "top"
        },
        {
          ...defaultStep,
          target: ".sysbar .items-container:nth-of-type(2) div:nth-of-type(5)",
          title: "User settings",
          content: "You can open the user settings by click your name",
          placement: "top",
          setupBeforeNext: openSettingsMenu
        },
        {
          ...defaultStep,
          target: "main .syspanel",
          title: "User settings",
          content: "Here you can see your user settings",
          placement: "left",
          blockClickOutside: true,
          setupBeforePrevious: closeSettingsMenu,
          setupBeforeClose: closeSettingsMenu
        },
        {
          ...defaultStep,
          target: "main .syspanel .profile .syspanel-subheader",
          title: "Data refresh",
          content: "Here you can see what time the data in the application was last refreshed",
          placement: "left",
          blockClickOutside: true,
          setupBeforeClose: closeSettingsMenu
        },
        {
          ...defaultStep,
          target: ".settings .container .content > div:nth-of-type(2)",
          title: "Theme",
          content: "You can switch theme by clicking here",
          placement: "left",
          blockClickOutside: true,
          setupBeforeClose: closeSettingsMenu
        },
        {
          ...defaultStep,
          target: ".settings .container .content > div:nth-of-type(3)",
          title: "Customers",
          content: "If you have several customers available, you can change that here",
          placement: "left",
          blockClickOutside: true,
          setupBeforeNext: closeSettingsMenu
        },
        // {
        //   ...defaultStep,
        //   target: ".settings .container .content > div:nth-of-type(4)",
        //   title: "Cache filters",
        //   content:
        //     "If you have this toggled to on, it will remember what you had your filters set to between sessions",
        //   placement: "left",
        //   blockClickOutside: true,
        //   setupBeforeClose: closeSettingsMenu
        // },
        // {
        //   ...defaultStep,
        //   target: ".settings .container .content > div:nth-of-type(5)",
        //   title: "Cache filters",
        //   content:
        //     "If this is toggled on, it will show technical tooltips on hover (When it's available)",
        //   placement: "left",
        //   blockClickOutside: true,
        //   setupBeforeClose: closeSettingsMenu
        // },
        {
          ...defaultStep,
          target: ".settings .container .content > div:nth-of-type(6)",
          title: "Walkthrough",
          content: "You can toggle the walkthrough off and on with this switch",
          placement: "left",
          blockClickOutside: true,
          setupBeforeClose: closeSettingsMenu,
          setupBeforeNext: closeSettingsMenu
        },
        {
          ...defaultStep,
          target: ".appbody .tree.navigation ul li:not([role='presentation'])",
          title: "Navigation",
          content: "Here is a list of links to further help",
          placement: "right",
          setupBeforeNext: () =>
            appendClassToDiv(
              ".appbody .tree.navigation ul li:not([role='presentation']) span",
              "opened"
            ),
          setupBeforePrevious: openSettingsMenu
        },
        {
          ...defaultStep,
          target: ".appbody .tree.navigation ul li:not([role='presentation']) ul li:nth-of-type(1)",
          title: "Navigation",
          content: "Here is a help guide in PDF-format that you can download by clicking it",
          placement: "right"
        },
        {
          ...defaultStep,
          target: ".appbody .tree.navigation ul li:not([role='presentation']) ul li:nth-of-type(2)",
          title: "Navigation",
          content: "You can get to the Frequently Asked Questions by clicking here",
          placement: "right"
        },
        {
          ...defaultStep,
          target: ".appbody .tree.navigation ul li:not([role='presentation']) ul li:nth-of-type(3)",
          title: "Navigation",
          content: "Here you can find the release notes",
          placement: "right"
        },
        {
          ...defaultStep,
          target: ".sysbar .items-container:nth-of-type(2) div:nth-of-type(1)",
          title: "Walkthrough",
          content: "You can restart this walk through via this icon",
          placement: "top"
        },
        {
          ...defaultStep,
          target: ".sysbar .items-container:nth-of-type(2) div:nth-of-type(1)",
          title: "Walk through icons",
          content: (
            <span>
              All places in the application where you find the icon <Icon name="help" />, you can
              get a walkthrough
            </span>
          ),
          placement: "center"
        },
        {
          ...defaultStep,
          target: ".app",
          title: "Finished",
          placement: "center",
          content: "This concludes the tour."
        }
      ];
  const removeHiddenSteps = steps.filter((step: IStep) => {
    if (typeof step.target === "string" && parent) {
      return elementVisible(parent, step.target);
    }
    return true;
  });
  return { steps: removeHiddenSteps };
};

export default useGeneralSteps;
