import { useApi } from "./api-factory";
import { useStore, apiReadingOn, apiReadingOff, IStatusMetric } from "../store";
import { ModuleNames } from "./modules-names";
import { IPeriodRange } from "../contracts";

export const useMetricsActions = () => {
  const [_, setState] = useStore();

  const { api } = useApi({ moduleId: ModuleNames.Module1 });

  const sortedByStatus = (metrics: IStatusMetric[]): IStatusMetric[] => {
    return (metrics || []).sort((a: IStatusMetric, b: IStatusMetric) => {
      return a.status - b.status;
    });
  };

  const getShipmentDeliveredMetrics = async (range: number, from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, shipmentDeliveredMetrics: true }
        }
      }));
      const itemMetric = await api.get("v1/ShipmentMetric/shipment-value", {
        range: range,
        filterFromDate: from,
        filterToDate: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          shipmentDeliveredMetrics: itemMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, shipmentDeliveredMetrics: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, shipmentDeliveredMetrics: false }
        }
      }));
    }
  };

  const getItemDeliveryPrecisionMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemDeliveryPrecisionMetric: true }
        }
      }));
      const deliveryPrecisionMetric = await api.get(
        "v1/PurchaseOrderMetric/deliveryprecision-items",
        {
          OrderCreationDateFrom: from,
          OrderCreationDateTo: to
        }
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          itemDeliveryPrecisionMetric: deliveryPrecisionMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemDeliveryPrecisionMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemDeliveryPrecisionMetric: false }
        }
      }));
    }
  };

  const getOrderDeliveryPrecisionMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderDeliveryPrecisionMetric: true }
        }
      }));
      const deliveryPrecisionMetric = await api.get("v1/PurchaseOrderMetric/deliveryprecision", {
        OrderCreationDateFrom: from,
        OrderCreationDateTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderDeliveryPrecisionMetric: deliveryPrecisionMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderDeliveryPrecisionMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderDeliveryPrecisionMetric: false }
        }
      }));
    }
  };

  const getOrderCreationDateBarsMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderCreationDateBarsMetric: true }
        }
      }));
      const orderMetric = await api.get("v1/PurchaseOrderMetric", {
        OrderCreationDateFrom: from,
        OrderCreationDateTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderCreationDateBarsMetric: sortedByStatus(orderMetric)
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderCreationDateBarsMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderCreationDateBarsMetric: false }
        }
      }));
    }
  };

  const getOrderCreationDateDonutMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderCreationDateDonutMetric: true }
        }
      }));
      const orderMetric = await api.get("v1/PurchaseOrderMetric", {
        OrderCreationDateFrom: from,
        OrderCreationDateTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderCreationDateDonutMetric: sortedByStatus(orderMetric)
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderCreationDateDonutMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderCreationDateDonutMetric: false }
        }
      }));
    }
  };

  const getItemCreationDateBarsMetric = async (from: string, to: string, itemIds: string[]) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemCreationDateBarsMetric: true }
        }
      }));
      const itemMetric = await api.post("v1/PurchaseOrderMetric/item-metrics", {
        purchaseOrderItemIds: itemIds,
        OrderCreationDateFrom: from,
        OrderCreationDateTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          itemCreationDateBarsMetric: sortedByStatus(itemMetric)
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemCreationDateBarsMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemCreationDateBarsMetric: false }
        }
      }));
    }
  };

  const getItemCreationDateDonutMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemCreationDateDonutMetric: true }
        }
      }));
      const itemMetric = await api.post("v1/PurchaseOrderMetric/item-metrics", {
        purchaseOrderItemIds: [],
        OrderCreationDateFrom: from,
        OrderCreationDateTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          itemCreationDateDonutMetric: sortedByStatus(itemMetric)
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemCreationDateDonutMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemCreationDateDonutMetric: false }
        }
      }));
    }
  };

  const getOrderConfirmedDeliveriesMetric = async () => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderConfirmedDeliveriesMetric: true }
        }
      }));
      const orderConfirmedDeliveriesMetric = await api.get(
        "v1/PurchaseOrderMetric/confirmed-deliveries"
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderConfirmedDeliveriesMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderConfirmedDeliveriesMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderConfirmedDeliveriesMetric: false }
        }
      }));
    }
  };

  const getItemConfirmedDeliveriesMetric = async () => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemConfirmedDeliveriesMetric: true }
        }
      }));
      const itemConfirmedDeliveriesMetric = await api.get(
        "v1/PurchaseOrderMetric/confirmed-deliveries-items"
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          itemConfirmedDeliveriesMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemConfirmedDeliveriesMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemConfirmedDeliveriesMetric: false }
        }
      }));
    }
  };

  const getOrderMeetRequestedDateMetric = async () => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderMeetRequestedDateMetric: true }
        }
      }));
      const orderMeetRequestedDateMetric = await api.get(
        "v1/PurchaseOrderMetric/meets-requested-date"
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderMeetRequestedDateMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderMeetRequestedDateMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderMeetRequestedDateMetric: false }
        }
      }));
    }
  };

  const getItemMeetRequestedDateMetric = async () => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemMeetRequestedDateMetric: true }
        }
      }));
      const itemMeetRequestedDateMetric = await api.get(
        "v1/PurchaseOrderMetric/meets-requested-date-items"
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          itemMeetRequestedDateMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemMeetRequestedDateMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemMeetRequestedDateMetric: false }
        }
      }));
    }
  };

  const getOrderPlannedDeliveriesMetric = async () => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderPlannedDeliveryDateMetric: true }
        }
      }));
      const orderPlannedDeliveryDateMetric = await api.get(
        "v1/PurchaseOrderMetric/planned-delivery"
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderPlannedDeliveryDateMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderPlannedDeliveryDateMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderPlannedDeliveryDateMetric: false }
        }
      }));
    }
  };

  const getItemPlannedDeliveriesMetric = async (itemIds: string[]) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemPlannedDeliveryDateMetric: true }
        }
      }));
      const itemPlannedDeliveryDateMetric = await api.post(
        "v1/PurchaseOrderMetric/planned-delivery-items",
        itemIds
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          itemPlannedDeliveryDateMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemPlannedDeliveryDateMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, itemPlannedDeliveryDateMetric: false }
        }
      }));
    }
  };

  const getOrderAverageLeadTimeMetric = async (range: IPeriodRange) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderAverageLeadTimeMetric: true }
        }
      }));
      const orderAverageLeadTimeMetric = await api.get(
        `v1/PurchaseOrderMetric/average-lead-time/${range.value}`
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderAverageLeadTimeMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderAverageLeadTimeMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderAverageLeadTimeMetric: false }
        }
      }));
    }
  };

  const getShipmentCountMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, shipmentMetric: true }
        }
      }));
      const shipmentMetric = await api.get("v1/PurchaseOrderMetric/shipment-count", {
        ActualDeliveryFrom: from,
        ActualDeliveryTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          shipmentMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, shipmentMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, shipmentMetric: false }
        }
      }));
    }
  };

  const getOrderLeadTimeMetric = async (range: IPeriodRange) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderLeadTimeMetric: true }
        }
      }));
      const orderLeadTimeMetric = await api.get(`v1/PurchaseOrderMetric/lead-time/${range.value}`);
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          orderLeadTimeMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderLeadTimeMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, orderLeadTimeMetric: false }
        }
      }));
    }
  };

  const getPlantInventoryMetric = async () => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, quantityByPlantMetric: true }
        }
      }));
      const plantInventoryMetric = await api.get("v1/InventoryMetric/quantity-by-plant");
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          quantityByPlantMetric: plantInventoryMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, quantityByPlantMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, quantityByPlantMetric: false }
        }
      }));
    }
  };

  const getInternalOrderCreationDateBarsMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, internalOrderCreationDateBarsMetric: true }
        }
      }));
      const orderMetric = await api.get("v1/InternalPurchaseOrderMetric", {
        OrderCreationDateFrom: from,
        OrderCreationDateTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          internalOrderCreationDateBarsMetric: sortedByStatus(orderMetric)
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, internalOrderCreationDateBarsMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, internalOrderCreationDateBarsMetric: false }
        }
      }));
    }
  };

  const getInternalOrderCreationDateDonutMetric = async (from: string, to: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, internalOrderCreationDateDonutMetric: true }
        }
      }));
      const orderMetric = await api.get("v1/InternalPurchaseOrderMetric", {
        OrderCreationDateFrom: from,
        OrderCreationDateTo: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          internalOrderCreationDateDonutMetric: sortedByStatus(orderMetric)
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, internalOrderCreationDateDonutMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, internalOrderCreationDateDonutMetric: false }
        }
      }));
    }
  };

  const getMaterialInventoryMetric = async (materialId: string) => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, materialInventoryMetric: true }
        }
      }));
      const materialInventoryMetric = await api.get(
        "v1/InternalPurchaseOrderMetric/inventory-material-nwh",
        { materialId }
      );
      setState(old => ({
        ...apiReadingOff(old),
        metrics: {
          ...old.metrics,
          materialInventoryMetric
        },
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, materialInventoryMetric: false }
        }
      }));
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          metrics: { ...old.fetchingData.metrics, materialInventoryMetric: false }
        }
      }));
    }
  };

  return {
    getOrderCreationDateBarsMetric,
    getOrderCreationDateDonutMetric,
    getItemCreationDateBarsMetric,
    getItemCreationDateDonutMetric,
    getOrderConfirmedDeliveriesMetric,
    getItemConfirmedDeliveriesMetric,
    getOrderMeetRequestedDateMetric,
    getItemMeetRequestedDateMetric,
    getOrderPlannedDeliveriesMetric,
    getItemPlannedDeliveriesMetric,
    getOrderAverageLeadTimeMetric,
    getShipmentCountMetric,
    getOrderLeadTimeMetric,
    getOrderDeliveryPrecisionMetric,
    getItemDeliveryPrecisionMetric,
    getPlantInventoryMetric,
    getInternalOrderCreationDateBarsMetric,
    getInternalOrderCreationDateDonutMetric,
    getMaterialInventoryMetric,
    getShipmentDeliveredMetrics
  };
};
