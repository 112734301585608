import { useContext } from "react";
import { __RouterContext } from "react-router";
import { useMsalFlow } from "../components/auth/msal-flow";
import { useStore } from "../store";
import { persistUnauthorizedUrl } from "../utils/local-storage/authorization";
import { Api } from "./api";
import { useApiActions } from "./api-actions";
import { useSettingsActions } from "./settings-actions";

export interface IApiSettings {
  includeCustomer?: boolean;
  moduleId?: string;
}
export const useApi = (apiSettings?: IApiSettings) => {
  const [state] = useStore();
  const { history } = useContext(__RouterContext);
  const { pushError } = useApiActions();
  const { currentCustomer } = useSettingsActions();
  const { settings } = state;
  const { acquireToken, signIn } = useMsalFlow();

  const errorNotification = (message: string) => {
    console.log("Error notification", message);
    pushError(message);
  };

  const noAuthRedirection = (url: string) => {
    console.log(`Unauthorized on api call: ${url} from page (${window.location.href})`);
    persistUnauthorizedUrl(window.location.pathname);
    if (state.user) {
      signIn();
    } else {
      history.push("/no-access");
    }
  };

  const apiObject = {
    api: new Api(
      settings.ApiUrl,
      !apiSettings ||
      apiSettings.includeCustomer === undefined ||
      apiSettings.includeCustomer === true
        ? currentCustomer().identifier
        : "",
      !apiSettings || !apiSettings.moduleId ? "" : apiSettings.moduleId,
      errorNotification,
      noAuthRedirection,
      acquireToken
    )
  };

  return apiObject;
};
