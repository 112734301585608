import { IUser } from "../../store";

import { localStorageKey } from ".";

const LOCAL_STORAGE_LATEST_USED_FILTERS_VERSION_KEY = "latest-filters-version";

export const getPersistedLatestUsedFiltersVersion = (user?: IUser): string => {
  const value = window.localStorage.getItem(
    localStorageKey(LOCAL_STORAGE_LATEST_USED_FILTERS_VERSION_KEY, user)
  );

  return value || "";
};

export const persistLatestUsedFiltersVersion = (version: string, user?: IUser) => {
  if (version) {
    window.localStorage.setItem(
      localStorageKey(LOCAL_STORAGE_LATEST_USED_FILTERS_VERSION_KEY, user),
      version
    );
  }
};
