import React, { useEffect, useState } from "react";
import { Icon, Kpi, KpiItem } from "@react-gcc-eds/core";
import { getWeek } from "date-fns";
import { useNumberAnimation } from "../number-animation";
import cx from "classnames";
import { IWeekMetric } from "../../../../store";
import { NoMetrics, noWeekMetric } from "../../dashboard/utils";
import { Column, Row } from "@react-gcc-eds/core";
import { DashboardGraph } from "../../dashboard";

export const KpiContainer = ({
  metricLoading,
  metrics,
  kpiBottomText,
  onKPIClick,
  amountColorCondition
}: {
  metricLoading: boolean;
  metrics: IWeekMetric[];
  kpiBottomText: string;
  onKPIClick: (
    metric: IWeekMetric,
    allMetrics: IWeekMetric[],
    graph: DashboardGraph
  ) => (() => void) | undefined;
  amountColorCondition?: (amount: number) => boolean;
}) => {
  if (metricLoading) {
    return <NoMetrics loading />;
  }
  if (noWeekMetric(metrics)) {
    return <NoMetrics />;
  }

  return (
    <Row className="enhanced-kpi-container">
      {metrics.map((m, i) => {
        const onClick = onKPIClick(m, metrics, DashboardGraph.Orders);
        return (
          <Column lg={4} sm={4} key={`kpi_${i}`} className="kpi-outer-container">
            <div className={`${onClick ? "clickable-kpi" : ""}`} onClick={onClick}>
              <AnimatedKPI
                week={m.week}
                amount={m.amount}
                bottomText={kpiBottomText}
                amountColorCondition={amountColorCondition || (() => false)}
              />
            </div>
          </Column>
        );
      })}
    </Row>
  );
};

const AnimatedKPI = ({
  week,
  amount,
  bottomText,
  amountColorCondition
}: {
  week: number;
  amount: number;
  bottomText: string;
  amountColorCondition: (amount: number) => boolean;
}) => {
  const [targetValue, setTargetValue] = useState<number>(amount);
  const [stepSize, setStepSize] = useState(1);
  const { value } = useNumberAnimation({
    targetValue: targetValue,
    stepSize
  });

  useEffect(() => {
    setStepSize(Math.ceil(Math.abs(amount - targetValue) / 250));
    setTargetValue(amount);
  }, [amount]);

  return (
    <Kpi className="kpi-centered">
      <KpiItem className="top-title">
        {week === getWeek(new Date(), { weekStartsOn: 1, firstWeekContainsDate: 4 })
          ? "This week"
          : `Week ${week}`}
        <Icon name="arrow-right" />
      </KpiItem>
      <KpiItem inline>
        <KpiItem span xl>
          <span className={cx({ "color-red": amountColorCondition(amount) })}>
            {value.toString().padStart(2, "0")}
          </span>
        </KpiItem>
      </KpiItem>
      <KpiItem>
        <KpiItem span>{bottomText}</KpiItem>
      </KpiItem>
    </Kpi>
  );
};
