import React from "react";
import { parse, min, max } from "date-fns";

import {
  IStatusMetric,
  IWeekMetric,
  ISeriesMetric,
  IPlantInventoryMetric,
  IShipmentDeliveredMetric,
  IShipmentDataPoint
} from "../../../store";
import { FullScreenLoader } from "../../shared/full-screen-loader";

export const noStatusMetric = (metric: IStatusMetric[]) => metric.every(m => m.totalCount === 0);
export const noWeekMetric = (metric: IWeekMetric[]) => metric.every(m => m.amount === 0);
export const noSeriesMetric = (metric: ISeriesMetric) =>
  metric.metrics.every(m => m.totals.every(t => t === 0));
export const noPlantMetric = (metric: IPlantInventoryMetric[]) =>
  metric.every(m => m.quantity === 0);
export const noShipmentDeliveredMetric = (metric: IShipmentDeliveredMetric) =>
  metric && metric.dataPoints.every(x => x.value === 0);
export const NoMetrics = ({ loading }: { loading?: boolean }) => {
  return (
    <div className="no-data-container">
      {loading ? <FullScreenLoader /> : "No metrics for current selection"}
    </div>
  );
};

const getTextWidth = (text: string, canvas?: HTMLCanvasElement, font?: string) => {
  const c = canvas || document.createElement("canvas");
  const context = c.getContext("2d");
  if (context) {
    context.font = font || "14px normal Ericsson Hilda";
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return 0;
};

export const splitLongBodyOnWidth = (
  s: string,
  maxWidthPixels: number,
  accumulated: string[]
): string[] => {
  let width = getTextWidth(s);
  if (width < maxWidthPixels) {
    return [...accumulated, s];
  } else {
    let newString = s;
    while (newString.length > 0 && width > maxWidthPixels) {
      const shortened = newString.slice(0, newString.length - 1);
      const lastIndex = shortened.lastIndexOf(" ");
      newString = lastIndex > 0 ? shortened.slice(0, lastIndex) : shortened;
      width = getTextWidth(newString);
    }
    const maxLength = newString.length;
    const firstPart = s.slice(0, maxLength);
    const secondPart = s.slice(maxLength, s.length);
    return splitLongBodyOnWidth(secondPart, maxWidthPixels, [...accumulated, firstPart]);
  }
};

export const splitLongBodyOnLength = (s: string, maxLengthChars: number): string[] => {
  return s.split(/\n/g).reduce((all: string[], curr: string) => {
    if (curr.length < maxLengthChars) {
      return [...all, curr];
    } else {
      const indexOfSpaceClosestToMiddle = curr.slice(0, 60).lastIndexOf(" ");
      const firstPart = curr.slice(0, indexOfSpaceClosestToMiddle);
      const secondPart = curr.slice(indexOfSpaceClosestToMiddle, curr.length);
      return [firstPart, ...splitLongBodyOnLength(secondPart, maxLengthChars)];
    }
  }, []);
};

export const minMetricCreationDate = (metrics: IWeekMetric[]) =>
  min(
    metrics
      .filter(m => m.earliestOrderCreationDate.length)
      .map(m => parse(m.earliestOrderCreationDate, "yyyy-MM-dd", new Date(1901, 1, 1)))
  );

export const maxMetricCreationDate = (metrics: IWeekMetric[]) =>
  max(
    metrics
      .filter(m => m.endDate.length)
      .map(m => parse(m.endDate, "yyyy-MM-dd", new Date(1901, 1, 1)))
  );
