import React, { useRef } from "react";
import cx from "classnames";
import { TooltipDotItem, Colors } from "../interfaces";

interface Props {
  colors: Colors;
  unitTitle: string;
  unitValue: string;
  description?: string | JSX.Element;
  items: TooltipDotItem[];
  x: number;
  y: number;
  visible: boolean;
}

const TooltipDot = ({
  colors,
  items,
  unitTitle,
  unitValue,
  description = "",
  x,
  y,
  visible
}: Props) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const tooltipItems = items.map((d: TooltipDotItem, i: number) => {
    let colorData = colors[d.key];
    colorData = colorData !== undefined ? colorData : "red";

    const subItems = Object.keys(d.subItems).map((item: string, j: number) => {
      const index = j;
      return (
        <div key={index} className="item" data-item={item}>
          <span className="key">{d.subItems[item].key}</span>
          <span className="value" style={{ float: "right", fontWeight: "bold" }}>
            {d.subItems[item].value}
          </span>
        </div>
      );
    });
    const rootindex = i;
    return (
      <div key={rootindex}>
        <div className="item" data-item="series">
          <i role="presentation" className={cx("icon", `${colorData}`, "icon-alarm-level6")} />
          <span className="key">{d.key}</span>
        </div>
        {subItems}
      </div>
    );
  });

  const mouseDistance = 12;
  const offsetWidth = tooltipRef.current !== null ? tooltipRef.current.offsetWidth : 0;
  const offsetHeight = tooltipRef.current !== null ? tooltipRef.current.offsetHeight : 0;

  const xPos =
    x + offsetWidth + mouseDistance > window.innerWidth
      ? x - offsetWidth - mouseDistance
      : x + mouseDistance;
  const yPox =
    y + offsetHeight + mouseDistance > window.innerHeight
      ? y - offsetHeight - mouseDistance / 2
      : y + mouseDistance;

  return (
    <div
      ref={tooltipRef}
      className={cx("chart-tooltip", { visible })}
      style={{ top: `${yPox}px`, left: `${xPos}px`, display: "flex" }}
    >
      <div className="content">
        <div className="title">
          <span className="unit-title">{unitTitle}</span>
          <span className="unit-value" style={{ float: "right" }}>
            {unitValue}
          </span>
        </div>
        {tooltipItems}
        {description !== "" && <div className="description">{description}</div>}
      </div>
    </div>
  );
};

export default TooltipDot;
