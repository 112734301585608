import { Button, Dialog, SignIn } from "@react-gcc-eds/core";
import { useState } from "react";
import { useStore } from "../../store";
import LoginInfoBlock from "../auth/login-info-block";
import { NewUserAccountForm } from "../mail-forms/registration";
import { useMsalFlow } from "./msal-flow";

const Login = ({ noAccess }: { noAccess?: boolean }) => {
  const [state] = useStore();
  const [showNoEcodAccess, setShowNoEcodAccess] = useState(noAccess === true);
  const [showRegistration, setShowRegistration] = useState(false);
  const { signIn, useAutoRedirectToLoggedWhenTokenValid } = useMsalFlow();

  useAutoRedirectToLoggedWhenTokenValid(!noAccess);

  const toggleRegistration = () => setShowRegistration(show => !show);

  const support = () => {
    return (
      <div className="login-support">
        <LoginInfoBlock
          paragraphText="Have you forgotten your password?"
          subtleClass="subtle-link"
          leftLink="https://myaccount.ericsson.net/xpra/"
          rightLink="https://myaccount.ericsson.net/PMUser/"
          leftText="External users"
          rightText="Ericsson users"
        />
        <Button iconName="profile" onClick={toggleRegistration}>
          Request access
        </Button>
        <LoginInfoBlock
          paragraphText="Information and help for MFA"
          subtleClass=""
          hasToolTip={true}
          leftLink={`/${state.settings.Features.MfaSetup_Filename}`}
          rightLink={`/${state.settings.Features.MfaAuthentication_Filename}`}
          leftText="Setting up MFA"
          rightText="How to Login with MFA"
        />
        <p className="support-info">
          All information available are subject to updates from time to time and are only
          indicative. Delivery plans and other contractual terms agreed between Ericsson and a
          purchasing customer are not affected.
        </p>
      </div>
    );
  };

  return (
    <>
      <SignIn
        productName="Ericsson Customer Order Dashboard"
        support={support()}
        onSignIn={signIn}
        anonymousSignIn
      />
      {showRegistration && <NewUserAccountForm onClose={toggleRegistration} />}
      <Dialog
        key="no-access-dialog"
        title="Access denied"
        visible={showNoEcodAccess}
        buttons={[
          <Button key="not_now" onClick={() => setShowNoEcodAccess(false)}>
            Not now
          </Button>,
          <Button
            key="request_access"
            iconName="profile"
            primary
            onClick={() => {
              setShowNoEcodAccess(false);
              toggleRegistration();
            }}
          >
            Request access
          </Button>
        ]}
      >
        <p>It seems like you don't have access to Ericsson Customer Order Dashboard.</p>

        <p>To apply for an account, please submit a request below.</p>
      </Dialog>
    </>
  );
};

export default Login;
