import {
  apiReadingOff,
  apiReadingOn,
  apiWritingOff,
  apiWritingOn,
  ILocalizedProperty,
  ITechnicalPropertyDetail,
  ITechnicalTooltips,
  IViewCustomization,
  IViewCustomizationOption,
  IViewProperty,
  ICustomTooltip,
  useStore
} from "../store";
import { useApi } from "./api-factory";

export const useCustomizationActions = () => {
  const [_, setState] = useStore();

  const { api } = useApi();

  const getLocalization = async (): Promise<ILocalizedProperty[]> => {
    try {
      setState(old => apiReadingOn(old, f => f.localization));
      const localization = await api.get("v1/Localize");
      setState(old => ({
        ...apiReadingOff(old, f => f.localization)
      }));
      return localization;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.localization));
      return [];
    }
  };

  const technicalFieldToText = (t: ITechnicalPropertyDetail): ICustomTooltip[] => {
    return [
      { header: "Description", body: t.description !== null ? t.description : "-" },
      { header: "Unique ID", body: t.sapID !== null ? t.sapID : "-" },
      { header: "Steps", body: t.stepwise !== null ? t.stepwise : "-" },
      { header: "Key", body: t.key !== null ? t.key : "-" }
    ];
  };

  const getTechnicalFieldDescription = async (property: string) => {
    try {
      setState(old => apiReadingOn(old));
      const tooltip = await api.get(`v1/Localize/Technical/${property}`);
      const tech: ITechnicalTooltips = {
        [property.toLowerCase()]: technicalFieldToText(tooltip)
      };
      setState(old => ({
        ...old,
        technicalTooltip: { ...old.technicalTooltip, ...tech }
      }));
      setState(old => apiReadingOff(old));
    } catch (e) {
      setState(old => apiReadingOff(old));
    }
  };

  const updateLocalizations = async (
    updatedLocalizations: ILocalizedProperty[],
    callback?: Function
  ): Promise<ILocalizedProperty[]> => {
    const { id, setApiWritingOn } = apiWritingOn();

    try {
      setState(old => setApiWritingOn(old, updatedLocalizations));
      const fetchedLocalizations = await api.post("v1/Localize", updatedLocalizations, callback);
      setState(old => apiWritingOff(id, old));

      return fetchedLocalizations;
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      return [];
    }
  };

  const getViewCustomizationOptions = async (): Promise<IViewCustomizationOption[]> => {
    try {
      setState(old => apiReadingOn(old, f => f.customizationOptions));
      const customizationViewOptions = await api.get(`v1/Customize`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customizationOptions)
      }));
      return customizationViewOptions;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customizationOptions));
      return [];
    }
  };

  const getViewCustomization = async (view: string): Promise<IViewCustomization> => {
    try {
      setState(old => apiReadingOn(old, f => f.customization));
      const customization = await api.get(`v1/Customize/${view}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customization)
      }));
      return customization;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customization));
      return { maxNumOfVisibleFields: 0, properties: [] };
    }
  };

  const updateViewCustomization = async (
    view: string,
    properties: IViewProperty[],
    callback?: Function
  ): Promise<IViewCustomization> => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, properties));
      const fetchedProperties = await api.post(`v1/Customize/${view}`, properties, callback);
      setState(old => apiWritingOff(id, old));
      return fetchedProperties;
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      return { maxNumOfVisibleFields: 0, properties: [] };
    }
  };

  const getCustomerAdminViewCustomization = async (view: string): Promise<IViewCustomization> => {
    try {
      setState(old => apiReadingOn(old, f => f.customization));
      const customization = await api.get(`v1/Customize/customer-administrator/${view}`);
      setState(old => ({
        ...apiReadingOff(old, f => f.customization)
      }));
      return customization;
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.customization));
      return { maxNumOfVisibleFields: 0, properties: [] };
    }
  };

  const updateCustomerAdminViewCustomization = async (
    view: string,
    properties: IViewProperty[],
    callback?: Function
  ): Promise<IViewCustomization> => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, properties));
      const fetchedProperties = await api.post(
        `v1/Customize/customer-administrator/${view}`,
        properties,
        callback
      );
      setState(old => apiWritingOff(id, old));
      return fetchedProperties;
    } catch (e) {
      setState(old => apiWritingOff(id, old));
      return { maxNumOfVisibleFields: 0, properties: [] };
    }
  };

  const getViewProperties = async (
    viewId: number,
    loader:
      | "ordersCustomization"
      | "itemsCustomization"
      | "internalOrdersCustomization"
      | "shipmentsSerialsCustomization"
      | "serialNumberChildrenCustomization"
  ): Promise<IViewProperty[]> => {
    try {
      setState(old => ({
        ...apiReadingOn(old),
        fetchingData: {
          ...old.fetchingData,
          viewProperties: { ...old.fetchingData.viewProperties, [loader]: true }
        }
      }));
      const properties = await api.get(`v1/Customize/properties/${viewId}`);
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          viewProperties: { ...old.fetchingData.viewProperties, [loader]: false }
        }
      }));
      return properties;
    } catch (e) {
      setState(old => ({
        ...apiReadingOff(old),
        fetchingData: {
          ...old.fetchingData,
          viewProperties: { ...old.fetchingData.viewProperties, [loader]: false }
        }
      }));
      return [];
    }
  };

  return {
    getLocalization,
    updateLocalizations,
    getViewCustomization,
    updateViewCustomization,
    getCustomerAdminViewCustomization,
    updateCustomerAdminViewCustomization,
    getViewProperties,
    getTechnicalFieldDescription,
    getViewCustomizationOptions
  };
};
