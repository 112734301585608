import React, { useEffect, useState } from "react";
import useAppMenu from "../../shared/menu";
import { Accordion, AccordionItem, ApplicationLayer, Button, Tile } from "@react-gcc-eds/core";
import { useSettingsActions } from "../../../actions/settings-actions";
import ContactUs, { EViews } from "../../mail-forms/contact-us";

type FaqSection = {
  header: string;
  faqs: {
    question: string;
    answer: string;
    link: string[];
    linkText: string[];
    opened?: boolean;
  }[];
};

const FaqView = () => {
  const [showContact, setShowContact] = useState(false);
  const { currentCustomer } = useSettingsActions();
  const [faqSections, setFaqSections] = useState<FaqSection[]>([
    {
      header: "",
      faqs: [{ question: "", answer: "", link: [""], linkText: [""], opened: false }]
    }
  ]);
  const [updateView, setUpdateView] = useState(false);

  useEffect(() => {
    fetch("/faq.json")
      .then(response => response.json())
      .then(data => setFaqSections(data));
  }, []);

  const toggleContact = () => {
    setShowContact(!showContact);
  };

  const splitOnNewLine = (line: string) => {
    return line.split(/\r?\n/);
  };

  const updateFaqsOpenOrClose = (sectionIndex: number, faqIndex: number) => {
    const stateCopy = faqSections;
    stateCopy[sectionIndex].faqs[faqIndex].opened = !stateCopy[sectionIndex].faqs[faqIndex].opened;

    setFaqSections(() => stateCopy);
    setUpdateView(() => !updateView);
  };

  return (
    <ApplicationLayer title="FAQ" menu={useAppMenu()}>
      {faqSections.map((section, sectionIndex) => (
        <Tile className="faq" key={`faq-${sectionIndex}`} title={section.header}>
          <Accordion key={`faqSection-${sectionIndex}`} lines>
            {section.faqs.map((faq, faqIndex) => {
              return (
                <AccordionItem
                  key={`faq-${sectionIndex}-${faqIndex}`}
                  title={faq.question}
                  opened={faq.opened ? true : false}
                  onOpen={() => updateFaqsOpenOrClose(sectionIndex, faqIndex)}
                  onClose={() => updateFaqsOpenOrClose(sectionIndex, faqIndex)}
                >
                  <div>
                    {splitOnNewLine(faq.answer).map((splitLine, lineIndex) => {
                      return (
                        <div>
                          <p key={`faqLine${sectionIndex}${faqIndex}${lineIndex}`}>
                            {faq.link ? (
                              <span>
                                {splitLine.split(faq.linkText[lineIndex])}
                                <a
                                  href={faq.link[lineIndex]}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {faq.linkText[lineIndex]}
                                </a>
                              </span>
                            ) : (
                              splitLine
                            )}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </AccordionItem>
              );
            })}
            {}
          </Accordion>
        </Tile>
      ))}
      <br />
      <Tile title="Can't find the right answer?">
        If you still can't find what you're looking for, you can always use our contact form and
        create a support request ticket. Write a description and attach files, e.g. screenshots
        detailing your issues and we'll try and help you as soon as possible!
        <br />
        <br />
        <Button key="showContact" primary onClick={toggleContact}>
          Contact us
        </Button>
      </Tile>
      {showContact && (
        <ContactUs
          customerIdentifier={currentCustomer().identifier}
          onClose={toggleContact}
          typeOfForm={EViews.SUPPORT}
        />
      )}
    </ApplicationLayer>
  );
};
export default FaqView;
