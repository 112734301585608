import { IDateRange } from "contracts";
import { addDays, differenceInCalendarDays, isBefore, startOfDay } from "date-fns";
import { IUserDetails, IUserFilter } from "../components/administration/users/user-details";
import { defaultRange } from "../components/filters/date-picker/predefined-ranges";
import { ICustomerIdentity } from "../types";
import {
  apiReadingOff,
  apiReadingOn,
  apiWritingOff,
  apiWritingOn,
  ENVIRONMENTS,
  ICustomer,
  IPillFilter,
  IStore,
  IUser,
  storeDefault,
  useStore
} from "../store";
import {
  getPersistedLatestUsedFiltersVersion,
  persistLatestUsedFiltersVersion
} from "../utils/local-storage/latest-used-filters";
import { useUserHelpers } from "../utils/user-helpers";
import { useApi } from "./api-factory";
import { useUserPreferencesActions } from "./user-preferences-actions";
import { sortedByName } from "./users-helpers";

export const useUserActions = () => {
  const [state, setState] = useStore();

  const { api } = useApi({ includeCustomer: false });
  const {
    getPersistedUserPreferences,
    getPersistedUserFilters,
    deleteUserFilters,
    getPersistedGridsColumnsPreferences
  } = useUserPreferencesActions();
  const { userRoleIsInternal } = useUserHelpers();

  const selectCustomer = (customers: ICustomerIdentity[], savedCustomerId?: string) => {
    if (!customers || !customers.length) {
      return undefined;
    }

    if (savedCustomerId) {
      const customer = customers.find(c => c.id.toString() === savedCustomerId.toString());

      if (customer) {
        return customer;
      }
    }

    return customers[0];
  };

  const resetUser = () => {
    setState(old => ({
      ...storeDefault,
      user: undefined,
      settings: { ...old.settings }
    }));
  };

  const mergePillFilters = (currentFilters: IPillFilter[] = [], newFilters: IPillFilter[] = []) => {
    return currentFilters.reduce((previousFilters: IPillFilter[], currentFilter: IPillFilter) => {
      const cachedFilter = newFilters.find(f => f.accessor === currentFilter.accessor) || {};
      previousFilters.push({ ...currentFilter, ...cachedFilter });
      return previousFilters;
    }, []);
  };

  const rollingDateRange = (range?: IDateRange, oldestDate?: Date): IDateRange | undefined => {
    if (!range) {
      return undefined;
    }

    if (!range.reference) {
      return range;
    }

    const now = new Date();
    const daysShift = differenceInCalendarDays(startOfDay(now), startOfDay(range.reference));
    if (daysShift > 0) {
      range = {
        from: addDays(range.from, daysShift),
        to: addDays(range.to, daysShift),
        reference: now
      };
    }
    if (oldestDate && isBefore(startOfDay(range.from), startOfDay(oldestDate))) {
      // pick closest range in case minimum import has changed
      range = defaultRange(oldestDate);
    }
    return range;
  };

  const mergeCachedFilters = (
    old: IStore,
    cachedFilters: any,
    backwardSchedule: boolean,
    defaultOrderCreationRange?: IDateRange,
    oldestDate?: Date
  ) => {
    return {
      ordersFilters:
        cachedFilters && cachedFilters.orders
          ? {
              ...old.ordersFilters,
              ...cachedFilters.orders,
              orderCreationRange: rollingDateRange(
                cachedFilters.orders.orderCreationRange,
                oldestDate
              ),
              requestedDeliveryRange: rollingDateRange(
                cachedFilters.orders.requestedDeliveryRange,
                oldestDate
              ),
              confirmedDeliveryRange: rollingDateRange(
                cachedFilters.orders.confirmedDeliveryRange,
                oldestDate
              ),
              plannedDeliveryRange: rollingDateRange(
                cachedFilters.orders.plannedDeliveryRange,
                oldestDate
              ),
              meetRequested: mergePillFilters(
                old.ordersFilters.meetRequested,
                cachedFilters.orders.meetRequested
              ),
              onTime: mergePillFilters(old.ordersFilters.onTime, cachedFilters.orders.onTime)
            }
          : {
              ...old.ordersFilters,
              orderCreationRange:
                (old.ordersFilters || {}).orderCreationRange || defaultOrderCreationRange
            },
      internalOrdersFilters:
        cachedFilters && cachedFilters.internalOrders
          ? {
              ...old.internalOrdersFilters,
              ...cachedFilters.internalOrders,
              orderCreationRange: rollingDateRange(
                cachedFilters.internalOrders.orderCreationRange,
                oldestDate
              ),
              requestedDeliveryRange: rollingDateRange(
                cachedFilters.internalOrders.requestedDeliveryRange,
                oldestDate
              ),
              currentConfirmedDateRange: rollingDateRange(
                cachedFilters.internalOrders.currentConfirmedDateRange,
                oldestDate
              ),
              delayedRequestedDate: mergePillFilters(
                old.internalOrdersFilters.delayedRequestedDate,
                cachedFilters.internalOrders.delayedRequestedDate
              )
            }
          : {
              ...old.internalOrdersFilters,
              orderCreationRange:
                (old.internalOrdersFilters || {}).orderCreationRange || defaultOrderCreationRange
            },
      poItemsFilters:
        cachedFilters && cachedFilters.poItemsFilters
          ? {
              ...old.poItemsFilters,
              ...cachedFilters.poItemsFilters,
              orderCreationRange: rollingDateRange(
                cachedFilters.poItemsFilters.orderCreationRange,
                oldestDate
              ),
              requestedDeliveryRange: rollingDateRange(
                cachedFilters.poItemsFilters.requestedDeliveryRange,
                oldestDate
              ),
              confirmedDeliveryRange: rollingDateRange(
                cachedFilters.poItemsFilters.confirmedDeliveryRange,
                oldestDate
              ),
              plannedDeliveryRange: rollingDateRange(
                cachedFilters.poItemsFilters.plannedDeliveryRange,
                oldestDate
              ),
              meetRequested: mergePillFilters(
                old.poItemsFilters.meetRequested,
                cachedFilters.poItemsFilters.meetRequested
              ),
              onTime: mergePillFilters(
                old.poItemsFilters.onTime,
                cachedFilters.poItemsFilters.onTime
              )
            }
          : {
              ...old.poItemsFilters,
              orderCreationRange:
                (old.poItemsFilters || {}).orderCreationRange || defaultOrderCreationRange
            },
      metricsFilters:
        cachedFilters && cachedFilters.metrics
          ? {
              ...old.metricsFilters,
              ...cachedFilters.metrics,
              overallOrderStatusBarsCreationRange: rollingDateRange(
                cachedFilters.metrics.overallOrderStatusBarsCreationRange,
                oldestDate
              ),
              overallOrderStatusDonutCreationRange: rollingDateRange(
                cachedFilters.metrics.overallOrderStatusDonutCreationRange,
                oldestDate
              ),
              overallInternalOrderStatusBarsCreationRange: rollingDateRange(
                cachedFilters.metrics.overallInternalOrderStatusBarsCreationRange,
                oldestDate
              ),
              overallInternalOrderStatusDonutCreationRange: rollingDateRange(
                cachedFilters.metrics.overallInternalOrderStatusDonutCreationRange,
                oldestDate
              ),
              shipmentCountCreationRange: rollingDateRange(
                cachedFilters.metrics.shipmentCountCreationRange,
                oldestDate
              ),
              deliveryPrecisionCreationRange: rollingDateRange(
                cachedFilters.metrics.deliveryPrecisionCreationRange,
                oldestDate
              ),
              shipmentDeliveredRange: rollingDateRange(
                cachedFilters.metrics.shipmentDeliveredRange,
                oldestDate
              )
            }
          : {
              ...old.metricsFilters,
              overallOrderStatusBarsCreationRange:
                (old.metricsFilters || {}).overallOrderStatusBarsCreationRange ||
                defaultOrderCreationRange,
              overallOrderStatusDonutCreationRange:
                (old.metricsFilters || {}).overallOrderStatusDonutCreationRange ||
                defaultOrderCreationRange,
              overallInternalOrderStatusBarsCreationRange:
                (old.metricsFilters || {}).overallInternalOrderStatusBarsCreationRange ||
                defaultOrderCreationRange,
              overallInternalOrderStatusDonutCreationRange:
                (old.metricsFilters || {}).overallInternalOrderStatusDonutCreationRange ||
                defaultOrderCreationRange,
              shipmentCountCreationRange:
                (old.metricsFilters || {}).shipmentCountCreationRange || defaultOrderCreationRange,
              deliveryPrecisionCreationRange:
                (old.metricsFilters || {}).deliveryPrecisionCreationRange ||
                defaultOrderCreationRange,
              shipmentDeliveredRange:
                (old.metricsFilters || {}).shipmentDeliveredRange || defaultOrderCreationRange
            },
      shipmentFilters:
        cachedFilters && cachedFilters.shipment
          ? {
              ...old.shipmentFilters,
              ...cachedFilters.shipment,
              shipmentCreationRange: rollingDateRange(
                cachedFilters.shipment.shipmentCreationRange,
                oldestDate
              )
            }
          : {
              ...old.shipmentFilters,
              shipmentCreationRange:
                (old.shipmentFilters || {}).shipmentCreationRange || defaultOrderCreationRange
            },
      internalShipmentFilters:
        cachedFilters && cachedFilters.internalShipment
          ? {
              ...old.internalShipmentFilters,
              ...cachedFilters.internalShipment,
              shipmentCreationRange: rollingDateRange(
                cachedFilters.internalShipment.shipmentCreationRange,
                oldestDate
              )
            }
          : {
              ...old.internalShipmentFilters,
              shipmentCreationRange:
                (old.internalShipmentFilters || {}).shipmentCreationRange ||
                defaultOrderCreationRange
            },
      inventoryFilters:
        cachedFilters && cachedFilters.inventory
          ? { ...old.inventoryFilters, ...cachedFilters.inventory }
          : old.inventoryFilters,
      itemsDeliveriesFilters:
        cachedFilters && cachedFilters.itemsDeliveries
          ? {
              ...old.itemsDeliveriesFilters,
              ...cachedFilters.itemsDeliveries,
              orderCreationRange: rollingDateRange(
                cachedFilters.itemsDeliveries.orderCreationRange,
                oldestDate
              )
            }
          : {
              ...old.itemsDeliveriesFilters,
              orderCreationRange:
                (old.itemsDeliveriesFilters || {}).orderCreationRange || defaultOrderCreationRange,
              periods:
                (old.itemsDeliveriesFilters || {}).periods ||
                (backwardSchedule ? ["-3", "-2", "-1"] : ["-1", "0", "1"])
            },
      internalItemsDeliveriesFilters:
        cachedFilters && cachedFilters.internalItemsDeliveries
          ? {
              ...old.internalItemsDeliveriesFilters,
              ...cachedFilters.internalItemsDeliveries,
              orderCreationRange: rollingDateRange(
                cachedFilters.internalItemsDeliveries.orderCreationRange,
                oldestDate
              )
            }
          : {
              ...old.internalItemsDeliveriesFilters,
              orderCreationRange:
                (old.internalItemsDeliveriesFilters || {}).orderCreationRange ||
                defaultOrderCreationRange,
              periods:
                (old.internalItemsDeliveriesFilters || {}).periods ||
                (backwardSchedule ? ["-3", "-2", "-1"] : ["-1", "0", "1"])
            },
      overViewsCollapsedStatuses:
        cachedFilters && cachedFilters.overViewsCollapsed
          ? {
              ...old.overViewsCollapsedStatuses,
              ...cachedFilters.overViewsCollapsed,
              poOverviewCollapsed: cachedFilters.overViewsCollapsed.poOverviewCollapsed,
              poItemOverviewCollapsed: cachedFilters.overViewsCollapsed.poItemOverviewCollapsed,
              internalPoOverviewCollapsed:
                cachedFilters.overViewsCollapsed.internalPoOverviewCollapsed,
              internalPoItemOverviewCollapsed:
                cachedFilters.overViewsCollapsed.internalPoItemOverviewCollapsed,
              shipmentOverviewCollapsed: cachedFilters.overViewsCollapsed.shipmentOverviewCollapsed,
              internalShipmentOverviewCollapsed:
                cachedFilters.overViewsCollapsed.internalShipmentOverviewCollapsed
            }
          : {
              ...old.overViewsCollapsedStatuses
            }
    };
  };

  const deleteFiltersIfVersionHasChanged = async (customerIdentifier: string) => {
    if (state.settings.Version) {
      // if filters version has changed we clear ANY cached filter
      const latestFiltersVersion = getPersistedLatestUsedFiltersVersion(state.user);
      persistLatestUsedFiltersVersion(state.settings.FiltersVersion, state.user);
      if (latestFiltersVersion !== state.settings.FiltersVersion) {
        await deleteUserFilters(customerIdentifier);
      }
    }
  };

  const getCustomerSettings = async (customerId: string) => {
    return await api.get(`User/customer/${customerId}`);
  };

  const getUser = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.user));
      const currentUser: IUser = await api.get("User/current");
      const savedPreferences = await getPersistedUserPreferences();
      const defaultCustomer = selectCustomer(currentUser.customers, savedPreferences.customerId);

      let currentCustomer: ICustomer | undefined = undefined;

      if (defaultCustomer) {
        const customerSetup = await getCustomerSettings(defaultCustomer.id);
        if (customerSetup) {
          currentCustomer = {
            ...customerSetup.customer,
            features: {
              ...customerSetup.features,
              views: [...customerSetup.features.views]
            }
          };
        }
      }

      const environments = await api.get("v1/Settings/Environment");

      const { identifier, id } = defaultCustomer || { identifier: "", id: "" };

      await deleteFiltersIfVersionHasChanged(identifier);

      const cachedFilters = await getPersistedUserFilters(id, identifier);
      const cachedGridsColumnsPreferences = await getPersistedGridsColumnsPreferences(
        id,
        identifier
      );

      const defaultOrderCreationRange = currentCustomer?.dataAvailableFromDate
        ? defaultRange(new Date(currentCustomer.dataAvailableFromDate))
        : undefined;

      setState(old => ({
        ...apiReadingOff(old, f => f.user),
        environments: [
          { env: ENVIRONMENTS.DEV, isCurrent: environments.isDevelopment },
          { env: ENVIRONMENTS.TEST, isCurrent: environments.isTest },
          { env: ENVIRONMENTS.PROD, isCurrent: environments.isProduction }
        ],
        user: {
          ...currentUser,
          role: currentUser.virtualRole !== null ? currentUser.virtualRole : currentUser.role,
          dateFormat: currentUser.dateFormat || "yyyy/MM/dd"
        },
        customer: currentCustomer,
        userPreferences: {
          ...old.userPreferences,
          ...(savedPreferences || {}),
          customerId: defaultCustomer ? defaultCustomer.id : undefined,
          ...(currentUser.virtualRole !== null && !userRoleIsInternal(currentUser.virtualRole)
            ? { technicalTooltips: false }
            : {})
        },
        ...mergeCachedFilters(
          old,
          cachedFilters,
          currentCustomer
            ? currentCustomer.features.applicationSettings.showOnlyPastScheduledDeliveries || false
            : false,
          defaultOrderCreationRange,
          currentCustomer?.dataAvailableFromDate
            ? new Date(currentCustomer?.dataAvailableFromDate)
            : undefined
        ),
        gridsColumnsPreferences: cachedGridsColumnsPreferences || old.gridsColumnsPreferences,
        gridColumnsWeekFormatPreferences:
          cachedFilters?.gridColumnsWeekFormatPreferences || old.gridColumnsWeekFormatPreferences
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.user));
    }
  };

  const getUsers = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.users));
      const availableUsers = await api.get("User");
      setState(old => ({
        ...apiReadingOff(old, f => f.users),
        users: sortedByName(availableUsers)
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.users));
    }
  };

  const createUser = async (user: IUser, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, user));
      await api.post("User", user, callback);
      const availableUsers = await api.get("User");
      setState(old => ({
        ...apiWritingOff(id, old),
        users: sortedByName(availableUsers)
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const reactivateUserAction = async (user: IUser, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, user));
      await api.put(`User/reactivate/${user.id}`, user, callback);
      const availableUsers = await api.get("User");
      setState(old => ({
        ...apiWritingOff(id, old),
        users: sortedByName(availableUsers)
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };
  const updateUserFilters = async (user: IUser) => {
    if (user.userFilters) {
      user.userFilters.forEach(async filter => {
        await api.put(`User/${user.id}/filter`, {
          localizeId: filter.localizeId,
          filterValue: filter.filterValue
        } as IUserFilter);
      });
    }
  };

  const updateUser = async (user: IUser, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, user));
      await api.put(`User/${user.id}`, user);
      await updateUserFilters(user);
      setState(old => ({
        ...apiWritingOff(id, old),
        users: old.users.map(u => (u.id === user.id ? { ...u, ...user } : u))
      }));
      if (callback) {
        callback();
      }
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const deleteUser = async (userId: string, callback?: Function) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, userId));
      await api.remove(`User/${userId}`, undefined, callback);
      const availableUsers = await api.get("User");
      setState(old => ({
        ...apiWritingOff(id, old),
        users: sortedByName(availableUsers)
      }));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getUserRoles = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.userRoles));
      const userRoles = await api.get("User/roles");
      const userVirtualRoles = await api.get("User/virtual-roles");
      setState(old => ({
        ...apiReadingOff(old, f => f.userRoles),
        userRoles,
        userVirtualRoles
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userRoles));
    }
  };

  const updateUserVirtualRole = async (userId: string, role: number | null) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, (role || "").toString()));
      await api.put(`User/virtual-role/${userId}`, { role });
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getUserDateFormats = async () => {
    try {
      setState(old => apiReadingOn(old, f => f.userDateFormats));
      const userDateFormats = await api.get("v1/Lookup/dateFormats");
      setState(old => ({
        ...apiReadingOff(old, f => f.userDateFormats),
        userDateFormats
      }));
    } catch (e) {
      setState(old => apiReadingOff(old, f => f.userDateFormats));
    }
  };

  const updateUserDateFormat = async (userId: string, format: string) => {
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, format));
      await api.put(`User/${userId}/dateformat`, { format });
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  return {
    getCustomerSettings,
    getUser,
    resetUser,
    getUsers,
    reactivateUserAction,
    createUser,
    updateUser,
    deleteUser,
    getUserRoles,
    updateUserVirtualRole,
    getUserDateFormats,
    updateUserDateFormat
  };
};
