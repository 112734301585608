import React from "react";

interface Props {
  strokeWidth: number;
  width?: number;
  height?: number;
  onMouseEnter: Function;
  onMouseLeave: Function;
  onMouseMove: Function;
  onClick: Function;
  onClickProvided: boolean;
  x1?: number;
  x2?: number;
}

const BarHover = ({
  strokeWidth,
  width,
  height,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  onClick,
  onClickProvided,
  x1,
  x2
}: Props) => {
  const x2Fix = x2 !== undefined && !isNaN(x2) ? x2 : 0;
  return (
    <line
      className={`line-hover ${onClickProvided ? "line-hoverable" : ""}`}
      stroke="blue"
      opacity={0}
      strokeWidth={strokeWidth}
      x1={width !== undefined ? width : x1}
      x2={width !== undefined ? -width : x2Fix}
      y1={height}
      y2={height !== undefined ? -height : 0}
      onMouseEnter={() => (onMouseEnter !== undefined ? onMouseEnter() : null)}
      onMouseLeave={() => (onMouseLeave !== undefined ? onMouseLeave() : null)}
      onMouseMove={e => (onMouseMove !== undefined ? onMouseMove(e) : null)}
      onClick={() => {
        onClick();
      }}
    />
  );
};

export default BarHover;
