import React from "react";
import cx from "classnames";

interface Props {
  x: number;
  y: number;
  width: number;
  height: number;
  opacity: number;
  colorData: string | unknown;
  thresholdColor: string;
}
const HistogramBar = ({ x, y, width, height, opacity, colorData, thresholdColor }: Props) => {
  return (
    <rect
      className={cx(`bar ${colorData} ${thresholdColor}`)}
      x={x}
      width={width >= 0 ? width : 0}
      y={y}
      height={height >= 0 ? height : 0}
      style={{ pointerEvents: "none" }}
      opacity={opacity}
    ></rect>
  );
};

export default HistogramBar;
