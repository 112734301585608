import {
  ISelectItem,
  SingleSelect,
  TextArea,
  TextField,
  useNotifications
} from "@react-gcc-eds/core";
import React, { useState } from "react";
import { useMailActions } from "../../../actions/mail-actions";
import { FileAttachment } from "./FileAttachment";

const feedbackTopics: ICustomSelectItem[] = [
  {
    title: "Look and feel",
    value: "Look and feel"
  },
  {
    title: "Existing features and functionality",
    value: "Existing features and functionality"
  },
  {
    title: "New features and functionality",
    value: "New features and functionality"
  }
];

interface ICustomSelectItem extends ISelectItem {
  title: string;
  value: string;
}

export const useFeedback = (customerIdentifier: string) => {
  const { sendFeedback } = useMailActions();
  const [title, setTitle] = useState("");
  const [attachment, setAttachment] = useState<File | undefined>(undefined);
  const [body, setBody] = useState("");
  const { createInstantNotification } = useNotifications();

  const notifySuccess = () => {
    setTimeout(() => {
      createInstantNotification(
        "Information sent",
        "Your feedback has been sent. Thank you very much.",
        "default",
        "send"
      );
    }, 100);
  };

  const submitIsDisabled = () => !body || !title || title.length >= 250;

  const getDisabledTooltip = () => {
    const tooltip = [];
    if (!body) {
      tooltip.push("No feedback text provided.");
    } else if (title.length >= 250) {
      tooltip.push("Feedback title too long.");
    }
    return tooltip.join("\n");
  };

  const titleValidation = (title: string) => {
    return title.length >= 250 ? "Title can not be longer than 250 characters" : undefined;
  };

  const submit = () => {
    if (title) {
      sendFeedback(customerIdentifier, title, body, attachment, notifySuccess);
    }
  };

  const components = () => [
    <p key="explanation-text">
      Please describe your suggestion and optionally attach a screenshot to help us visualize it.
    </p>,
    <div className="input-form-content" key="input-textarea-container">
      <TextField
        key="title-field"
        placeholder={"Please give a summary title..."}
        label={"Title"}
        value={title}
        className="input-form-title"
        onChange={setTitle}
        validation={titleValidation(title)}
      />
      <TextArea
        key="text-area"
        className="input-form-textarea"
        value={body}
        placeholder="Please share your thoughts..."
        onChange={setBody}
      />
    </div>,
    <FileAttachment key="file-attachment" file={attachment} fileSetter={setAttachment} />
  ];

  return {
    components,
    submit,
    getDisabledTooltip,
    submitIsDisabled
  };
};
