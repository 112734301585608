import React, { useState } from "react";
import { Thresholds, Data, Colors, StackedChartData } from "../interfaces";

import Bar from "./Bar";
import * as utils from "../helpers";
import Value from "./Value";
import Label from "./Label";
import BarHover from "./BarHover";

interface Props {
  data: Data;
  thresholds?: Thresholds[];
  label: string;
  single?: boolean;
  grouped?: boolean;
  stacked?: boolean;
  stackedChartData: StackedChartData;
  largeBars?: boolean;
  x0: d3.ScaleBand<string>;
  x1: d3.ScaleBand<string>;
  y: d3.ScaleLinear<number, number>;
  colorScale: Colors;
  value: number;
  height: number;
  onMouseHover: Function;
  opacity: number;
  hoveredSerie: string;
  onClick: Function;
  onClickProvided: boolean;
}

const VerticalBarGroup = ({
  data,
  label,
  thresholds,
  single,
  stacked,
  stackedChartData,
  largeBars,
  x0,
  x1,
  y,
  colorScale,
  value,
  height,
  onMouseHover,
  opacity,
  hoveredSerie,
  onClick,
  onClickProvided
}: Props) => {
  const valueSeparator = 2;
  const fontSize = 14;
  const [isHovered, setIsHovered] = useState(false);

  const hasNegativeValues =
    data.series.map(s => s.values.filter(v => v < 0).length).filter(a => a > 0).length > 0;

  const xPos = x0(label) !== undefined ? x0(label) : 0 + x0.bandwidth() / 2 + 1;
  const yPos = y(0);

  const dataCommon1 =
    data.common.length >= 1 && x0(data.common[1]) !== undefined ? x0(data.common[1]) : 0;
  const dataCommon2 =
    data.common.length > 0 && x0(data.common[0]) !== undefined ? x0(data.common[0]) : 0;

  const seriesDistance =
    (dataCommon1 !== undefined ? dataCommon1 : 0) - (dataCommon2 !== undefined ? dataCommon2 : 0);

  return (
    <g className="bar-group" transform={`translate(${xPos},${yPos})`} opacity={opacity}>
      {single && (
        <Value
          textAnchor="middle"
          value={value}
          y={y(value) - y(0) + (value >= 0 ? -4 : fontSize)}
          x={0}
          hidden={!utils.hasLabelSpace(data, fontSize, x0) && !isHovered}
        />
      )}
      {stackedChartData[label].map((d, i) => {
        const separator = stacked ? valueSeparator : 0;
        const thresholdColor = thresholds ? utils.getThresholdsClass(thresholds, value) : "";

        const y1 = single || stacked ? -(y(0) - y(d.previous)) : 0;
        const y2 =
          single || stacked
            ? -(y(0) - y(d.value) + y(0) - y(d.previous) - separator)
            : y(d.value) - y(0);
        const colorData = colorScale[d.key];

        const lineOpacity = hoveredSerie === d.key ? 1 : 0.2;
        const key = i;
        return (
          <Bar
            key={key}
            x1={stacked ? 0 : x1(d.key)}
            y1={y1}
            y2={y2}
            colorData={colorData}
            thresholdColor={thresholdColor}
            lineOpacity={hoveredSerie !== "" ? lineOpacity : 1}
            strokeWidth={stacked && largeBars ? 12 : undefined}
          />
        );
      })}

      <Label
        textAnchor="middle"
        label={label}
        y={hasNegativeValues ? height - yPos - 30 : fontSize + 5}
        hidden={
          single
            ? !utils.hasLabelSpace(data, fontSize, x0) && !isHovered
            : !utils.hasLabelSpace(data, fontSize, x0)
        }
      />

      <BarHover
        strokeWidth={Math.abs(seriesDistance)}
        height={height}
        onMouseEnter={() => {
          setIsHovered(true);
          onMouseHover(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          onMouseHover(false);
        }}
        onMouseMove={(e: React.MouseEvent<SVGLineElement, MouseEvent>) => {
          setIsHovered(true);
          onMouseHover(true, e);
        }}
        onClickProvided={onClickProvided}
        onClick={() => {
          const values = stackedChartData[label].map(d => {
            return { key: d.key, value: d.value };
          });
          onClick(label, values);
        }}
      />
    </g>
  );
};

export default VerticalBarGroup;
