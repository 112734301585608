import { LicenseManager } from "ag-grid-enterprise";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Route, Switch, useLocation } from "react-router-dom";
import { useStore } from "../store";
import { useAppSettings } from "../utils/app-settings";
import { useInsights } from "../utils/insights";
import { getPersistedUnauthorizedUrl } from "../utils/local-storage/authorization";
import Application from "./application";
import LoggedIn from "./auth/logged";
import Login from "./auth/login";
import { useMsalFlow } from "./auth/msal-flow";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE || "");

const Root = () => {
  const [state] = useStore();
  const { readAppSettings } = useAppSettings();
  const { setupInsights } = useInsights();
  const [settingsRead, setSettingsRead] = useState(false);
  const location = useLocation();

  const { useAddMsalEventCallback, useAutoRedirectToLoginWhenNoUser } = useMsalFlow();
  useAddMsalEventCallback();

  useEffect(() => {
    readAppSettings();
  }, []);

  useEffect(() => {
    setupInsights();
    setSettingsRead(state.settings ? true : false);
  }, [state.settings]);

  useAutoRedirectToLoginWhenNoUser();

  // We must ensure settings are read before rendering the app, the api url is read from settings
  if (!settingsRead) {
    return <div></div>;
  }
  if (
    isMobile &&
    state.settings &&
    state.settings.MobileRedirectUrl &&
    state.settings.MobileRedirectUrl.length > 0
  ) {
    const mobileUrl = state.settings.MobileRedirectUrl.replace(/^(.+?)\/*?$/, "$1");
    const url = (getPersistedUnauthorizedUrl() || location.pathname).replace(/^(.+?)\/*?$/, "$1");
    window.location.replace(`${mobileUrl}${url}`);
    return <div />;
  }

  return (
    <React.StrictMode>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/logged">
          <LoggedIn />
        </Route>
        <Route exact path="/no-access">
          <Login noAccess />
        </Route>
        <Route path="*">{state.user && <Application user={state.user} />}</Route>
      </Switch>
    </React.StrictMode>
  );
};

export default Root;
