import { IUser } from "../../store";

import { localStorageKey } from "./";

const LOCAL_STORAGE_NEXT_DEPLOYMENT_KEY = "next-deployment-shown-at";

interface INextDeployment {
  shownAt: string;
}

export const getPersistedNextDeployment = (user?: IUser): INextDeployment => {
  const value = window.localStorage.getItem(
    localStorageKey(LOCAL_STORAGE_NEXT_DEPLOYMENT_KEY, user)
  );

  if (value) {
    return JSON.parse(value);
  }

  return { shownAt: "" };
};

export const persistNextDeployment = (info: INextDeployment, user?: IUser) => {
  if (info) {
    window.localStorage.setItem(
      localStorageKey(LOCAL_STORAGE_NEXT_DEPLOYMENT_KEY, user),
      JSON.stringify(info)
    );
  }
};
