import * as d3 from "d3";
import { Data, LineData, Serie, Thresholds } from "./interfaces";

export const getMaxValueFromChartData = (data: Data, allHidden: boolean) => {
  let maxValue = 0;

  for (let i = 0; i < data.common.length; i += 1) {
    let sum = 0;

    for (let y = 0; y < data.series.length; y += 1) {
      if (allHidden) {
        sum += data.series[y].values[i];
      } else {
        sum += !data.series[y].hidden ? data.series[y].values[i] : 0;
      }
    }
    if (sum > maxValue) {
      maxValue = sum;
    }
  }
  return maxValue;
};

export const getColorScale = (length = 15, colors?: string[], invertColor?: boolean) => {
  const generateColorMatrix = (n: number) => {
    const array = [];
    for (let i = 0; i < n; i += 1) {
      array.push(`color-data-${(i % 20) + 1}`);
    }
    return invertColor ? array.reverse() : array;
  };

  return d3.scaleOrdinal().range(colors !== undefined ? colors : generateColorMatrix(length));
};
// export const getColorScale = (length: number = 15, colors?: string[], invertColor?: boolean) => {

//   const generateColorMatrix = (n:number) => {
//     const colorMatrix = !invertColor ? [
//       [],
//       [1],
//       [1, 5],
//       [1, 3, 5],
//       [1, 3, 4, 5],
//       [1, 2, 3, 4, 5]
//     ] : [
//       [],
//       [5],
//       [4, 2],
//       [4, 2, 1],
//       [5, 4, 2, 1],
//       [5, 4, 3, 2, 1]
//     ];

//     const matrixLength = colorMatrix.length - 1;
//     const last = colorMatrix[matrixLength];
//     let colorArray;

//     if (n > 5) {
//       colorArray = [...last];
//       for (let i = 0; i < n - matrixLength; i += 1) {
//         colorArray.push(last[i % matrixLength]);
//       }
//     } else {
//       colorArray = colorMatrix[n];
//     }

//     return colorArray.map(color => `color-data-${color}`);
//   }

//   return d3
//     .scaleOrdinal()
//     .range(colors !== undefined ? colors: generateColorMatrix(length))
// }

export const hasLabelSpace = (data: Data, fontSize: number, x0: d3.ScaleBand<string>) => {
  let hasSpace = true;
  let hasNegativeValues = false;

  if (data.series.length > 1 && data.common.length > 1) {
    const dataCommon1 =
      data.common.length >= 1 && x0(data.common[1]) !== undefined ? x0(data.common[1]) : 0;
    const dataCommon2 =
      data.common.length > 0 && x0(data.common[0]) !== undefined ? x0(data.common[0]) : 0;

    const seriesDistance =
      (dataCommon1 !== undefined ? dataCommon1 : 0) - (dataCommon2 !== undefined ? dataCommon2 : 0);
    data.common.forEach(d => {
      if (hasSpace) {
        hasSpace = (d.toString().length * fontSize) / 1.8 < seriesDistance;
      }
    });

    data.series.forEach(d => {
      if (!hasNegativeValues && d.values.filter(i => i < 0).length > 0) {
        hasNegativeValues = true;
      }

      d.values.forEach(value => {
        const valueLength = (value.toString().length * fontSize) / 1.8;
        if (hasSpace) {
          hasSpace = valueLength < seriesDistance;
        }
      });
    });
  }
  return hasSpace;
};

export const createLineData = (data: Data, name: string) => {
  const lineData = [] as unknown as LineData[];

  if (data.series.length === 0) {
    return [
      {
        xLabel: "2001-01-01",
        yLabel: "0"
      }
    ];
  }
  const foundSerie = data.series.find((serie: Serie) => {
    return serie.name === name;
  });

  if (foundSerie === undefined) {
    return {
      xLabel: "2001-01-01",
      yLabel: "0"
    };
  }
  data.common.forEach((common, i) => {
    lineData.push({
      xLabel: common,
      yLabel: foundSerie.values[i] !== undefined ? foundSerie.values[i].toString() : ""
    });
  });

  return lineData;
};

export const sortCommonData = (commonData: string[], isDate = true) => {
  // Note: to perform the bisection correctly, the array must be ordered
  if (isDate) {
    return commonData.sort((a: string, b: string) => new Date(a).valueOf() - new Date(b).valueOf());
  }
  return commonData.sort((a, b) => a.localeCompare(b));
};

export const round = (number: number, decimals = 2) => {
  return (Math.round(number * 100) / 100).toFixed(decimals);
};

export const d3Format = (formatSpecifier: string, value: number) => {
  const predefinedFormatSpecifiers = {
    Default: ",",
    None: "",
    Percent: ".0%",
    Signed: "+20",
    SIPrefix: ".2s",
    Hex: "#x",
    Fixed: ".1f",
    Fixed1: ".1f",
    Fixed2: ".2f",
    Grouped: ","
  };

  try {
    return d3.format(
      predefinedFormatSpecifiers[formatSpecifier] !== undefined
        ? predefinedFormatSpecifiers[formatSpecifier]
        : formatSpecifier
    )(value);
  } catch (err) {
    return d3.format(predefinedFormatSpecifiers.Default)(value);
  }
};

// Will not work for Function, Infinity or undefined.
export const jsonClone = (object: any) => {
  return JSON.parse(JSON.stringify(object));
};

export const checkData = (data: Data, checkCommon = true, checkSeries = true) => {
  let correct = true;
  let errorMsg = "";

  if (data.common === undefined && checkCommon) {
    return "Common does not exist in data.";
  }
  if (data.series === undefined && checkSeries) {
    return "Series does not exist in data.";
  }

  const seriesName = data.series
    .filter(s => s.name && s.values.length)
    .map((s: Serie) => s.name.toString());

  const hasDuplicates = (strArr: string[]) => {
    const values = Object.create(null);
    let foundDuplicates = false;
    strArr.forEach((currentValue: string) => {
      if (currentValue in values) {
        foundDuplicates = true;
      }
      values[currentValue] = true;
    });
    return foundDuplicates;
  };

  if (checkCommon && hasDuplicates(data.common)) {
    return "Duplicate strings found in data.common";
  }

  if (checkSeries && hasDuplicates(seriesName)) {
    return "Duplicate strings found in data.series name";
  }

  // Check if all data.series.values have same length
  data.series.forEach((s: Serie, i: number) => {
    if (checkCommon && s.values.length !== data.common.length) {
      correct = false;
      errorMsg = `chartdata.series[${i}] needs to have same length as data.common.`;
    }
  });

  if (!correct) {
    throw new Error(errorMsg);
  }

  return "";
};
export const getStringDomLength = (s: string, fontSize = 14) => {
  return s.length * fontSize;
};

export const getThresholdsClass = (thresholds: Thresholds[], value: number) => {
  let color = "" as string;

  if (thresholds) {
    thresholds
      .sort(
        (a, b) =>
          (a.moreThan !== undefined ? a.moreThan : 0) -
            (b.moreThan !== undefined ? b.moreThan : 0) ||
          (b.lessThan !== undefined ? b.lessThan : 0) - (a.lessThan !== undefined ? a.lessThan : 0)
      )
      .forEach(t => {
        if ((t.moreThan && value >= t.moreThan) || (t.lessThan && value <= t.lessThan)) {
          color = t.color as string;
        }
      });
  }
  return color;
};
