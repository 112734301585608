import { Icon, Tooltip } from "@react-gcc-eds/core";
import { addMonths, format, getYear, isBefore, lastDayOfMonth, startOfDay } from "date-fns";
import { isWithinInterval } from "date-fns/esm";
import React, { useEffect, useState } from "react";
import { IDateRange } from "../../../contracts";
import CalendarBody from "./calendar-body";

const monthName = (date: Date) => {
  return date.toLocaleString("en-us", { month: "long" });
};

const Calendar = ({
  dual,
  selectedDate,
  hoveredDate,
  onDateSelected,
  onDateHovered,
  selectedRange,
  oldestDate,
  rolling
}: {
  dual: boolean;
  selectedDate?: Date;
  hoveredDate?: Date;
  onDateSelected(d: Date): void;
  onDateHovered(d: Date): void;
  selectedRange?: IDateRange;
  oldestDate?: Date;
  rolling?: boolean;
}) => {
  const [shownDate, setShownDate] = useState<Date>(new Date());

  useEffect(() => {
    if (!selectedRange) {
      return;
    }

    const today = new Date();

    if (
      isWithinInterval(startOfDay(today), {
        start: startOfDay(selectedRange.from),
        end: startOfDay(selectedRange.to)
      })
    ) {
      setShownDate(today);
    } else {
      setShownDate(startOfDay(selectedRange.to));
    }
  }, [selectedRange]);

  const containsUnselectableData = (leftDate: Date) => {
    return oldestDate && isBefore(lastDayOfMonth(leftDate), oldestDate);
  };

  const preselectedRange = (): IDateRange | undefined => {
    if (!selectedDate || !hoveredDate) {
      return undefined;
    }

    return selectedDate < hoveredDate
      ? { from: selectedDate, to: hoveredDate }
      : { from: hoveredDate, to: selectedDate };
  };

  const leftDate = dual ? addMonths(shownDate, -1) : shownDate;
  const helpText = (): string => {
    if (!selectedDate) {
      return "Select start date";
    }
    return `${format(selectedDate, "dd LLL yyyy")} -  select end date`;
  };
  return (
    <div className="calendar-container">
      {rolling && (
        <div className="calendar-header">
          <span className="text">Fixed filter range</span>
          <Tooltip text="Filter is static and tight to selected range" type="pointer">
            <i style={{ marginLeft: "8px" }} className="icon icon-info" />
          </Tooltip>
        </div>
      )}
      <div className="calendar-help-text">{helpText()}</div>
      <div className="months-container">
        <div className="months-body">
          <div className="head">
            <Icon name="chevron-left" onClick={() => setShownDate(leftDate)} />
            <span className="month">{`${monthName(leftDate)} ${getYear(leftDate)}`}</span>
            {!dual && (
              <Icon name="chevron-right" onClick={() => setShownDate(addMonths(leftDate, 1))} />
            )}
          </div>
          <table className={`body ${containsUnselectableData(leftDate) ? "has-overlay" : ""}`}>
            <thead>
              <tr>
                <th>Mo</th>
                <th>Tu</th>
                <th>We</th>
                <th>Th</th>
                <th>Fr</th>
                <th>Sa</th>
                <th>Su</th>
              </tr>
            </thead>
            <tbody>
              <CalendarBody
                visibleDate={leftDate}
                onDateSelected={onDateSelected}
                onDateHovered={onDateHovered}
                preselectedRange={preselectedRange()}
                selectedRange={selectedRange}
                oldestDate={oldestDate}
              />
            </tbody>
          </table>
          {containsUnselectableData(leftDate) && (
            <div className="overlay">
              <span>No data available</span>
            </div>
          )}
        </div>
        {dual && (
          <div className="months-body">
            <div className="head">
              <span className="month">{`${monthName(shownDate)} ${getYear(shownDate)}`}</span>
              <Icon name="chevron-right" onClick={() => setShownDate(addMonths(shownDate, 1))} />
            </div>
            <table className={`body ${containsUnselectableData(shownDate) ? "has-overlay" : ""}`}>
              <thead>
                <tr>
                  <th>Mo</th>
                  <th>Tu</th>
                  <th>We</th>
                  <th>Th</th>
                  <th>Fr</th>
                  <th>Sa</th>
                  <th>Su</th>
                </tr>
              </thead>
              <tbody>
                <CalendarBody
                  visibleDate={shownDate}
                  onDateSelected={onDateSelected}
                  onDateHovered={onDateHovered}
                  preselectedRange={preselectedRange()}
                  selectedRange={selectedRange}
                  oldestDate={oldestDate}
                />
              </tbody>
            </table>
            {containsUnselectableData(shownDate) && (
              <div className="overlay">
                <span>No data available</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
