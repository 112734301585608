import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import "proxy-polyfill";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { msalConfig } from "./components/auth/msal-config";
import Root from "./components/root";
import * as serviceWorker from "./serviceWorker";
import { AppStoreProvider, storeDefault } from "./store";
import "./style/app.scss";
import { getIEVersion } from "./utils";

const notSupportedBrowser = () => {
  return (
    <div className="dark" style={{ height: "100%" }}>
      <div className="signin">
        <div className="watermark">
          <i className="icon icon-econ" />
          <div className="product">Ericsson Customer Order Dashboard</div>
        </div>
        <div className="unsupported-browser">
          Sorry, we are not supporting the current browser.
          <br />
          <br />
          Please use Microsoft Edge ©, Google Chrome © or Mozilla Firefox ©.
        </div>
        <div className="footer">
          © Ericsson AB 2013-2024 - All rights reserved. <br />
          No part of this software may be reproduced in any form without the written permission of
          the copyright holder.
        </div>
      </div>
    </div>
  );
};

// MSAL v.3.x (msal-browser v.3.0 and later) requires async instantiation.
PublicClientApplication.createPublicClientApplication(msalConfig)
  .then(msalInstance => renderWithAuth(msalInstance))
  .catch(e => console.error(e));

const AppWithMsal = (msalInstance: IPublicClientApplication) => {
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <AppStoreProvider initialValue={storeDefault}>
          <Root />
        </AppStoreProvider>
      </BrowserRouter>
    </MsalProvider>
  );
};

const renderWithAuth = (msalInstance: IPublicClientApplication) => {
  // eslint-disable-next-line
  createRoot(document.getElementById("root")!).render(
    getIEVersion() > 0 ? notSupportedBrowser() : AppWithMsal(msalInstance)
  );
  serviceWorker.unregister();
};
