import React from "react";
import * as d3 from "d3";
import { Thresholds } from "../interfaces";

interface Props {
  thresholds: Thresholds[];
  startFromZero?: boolean;
  yMin: number;
  yMax: number;
  height: number;
  defaultColor?: string;
}

interface Output {
  offset: number;
  value: number;
  color: string;
}

const LineThresholds = ({
  thresholds,
  startFromZero,
  yMin,
  yMax,
  height,
  defaultColor = "color-data-1"
}: Props) => {
  const tScale = startFromZero
    ? d3.scaleLinear().domain([0, yMax]).nice().range([0, 100])
    : d3.scaleLinear().domain([yMin, yMax]).nice().range([0, 100]);

  const output = [] as unknown as Output[];

  output.push({
    offset: tScale(yMin) as number,
    color: defaultColor,
    value: -1
  });

  thresholds.forEach((element: Thresholds) => {
    const moreThan = element.moreThan !== undefined ? element.moreThan : -1;
    const lessThan = element.lessThan !== undefined ? element.lessThan : -1;

    output.push({
      offset: tScale(moreThan !== -1 ? moreThan : lessThan),
      color: defaultColor,
      value: element.moreThan !== undefined ? element.moreThan : -1
    });
    output.push({
      offset: tScale(moreThan !== -1 ? moreThan : lessThan),
      color: defaultColor,
      value: element.moreThan !== undefined ? element.moreThan : -1
    });
  });
  output.push({
    offset: tScale(yMax),
    color: defaultColor,
    value: -1
  });

  thresholds.forEach((element: Thresholds) => {
    const moreThan = element.moreThan !== undefined ? element.moreThan : -1;
    const lessThan = element.lessThan !== undefined ? element.lessThan : -1;

    output.push({
      offset: tScale(moreThan !== -1 ? moreThan : lessThan),
      color: defaultColor,
      value: element.moreThan !== undefined ? element.moreThan : -1
    });
    output.push({
      offset: tScale(moreThan !== -1 ? moreThan : lessThan),
      color: defaultColor,
      value: element.moreThan !== undefined ? element.moreThan : -1
    });
  });

  output.push({
    offset: tScale(yMax),
    color: defaultColor,
    value: -1
  });

  output.sort((a, b) => a.offset - b.offset);

  // LessThan
  const lessThanArray = thresholds.filter((element: Thresholds) => {
    return element.lessThan !== undefined;
  });

  if (lessThanArray.length > 0) {
    lessThanArray.sort(
      (a, b) =>
        (a.lessThan !== undefined ? a.lessThan : 0) - (b.lessThan !== undefined ? b.lessThan : 0)
    );
    let j = 0;
    for (let i = 0; i < lessThanArray.length; i += 1) {
      output[j].color = lessThanArray[i].color;
      output[j + 1].color = lessThanArray[i].color;
      j += 2;
    }
  }

  // MoreThan
  const moreThanArray = thresholds.filter((element: Thresholds) => {
    return element.moreThan !== undefined;
  });

  if (moreThanArray.length > 0) {
    moreThanArray.sort(
      (a, b) =>
        (b.moreThan !== undefined ? b.moreThan : 0) - (a.moreThan !== undefined ? a.moreThan : 0)
    );

    let j = output.length - 2;
    for (let i = 0; i < moreThanArray.length; i += 1) {
      output[j].color = moreThanArray[i].color;
      output[j - 1].color = moreThanArray[i].color;
      j -= 2;
    }
  }

  const drawOffsets = () => {
    return output.map((current: Output, i: number) => {
      const key = i;
      return <stop key={key} offset={`${current.offset}%`} stopColor={current.color}></stop>;
    });
  };
  const colorData = defaultColor;
  return (
    <linearGradient
      className="linear-gradient"
      id={`gradient-threshold-line-chart-${colorData}`}
      gradientUnits="userSpaceOnUse"
      x1="0"
      y1={height}
      x2="0"
      y2="0"
    >
      {drawOffsets()}
    </linearGradient>
  );
};

export default LineThresholds;
