import { useEffect, useState } from "react";

import { Button, Dialog, Tooltip } from "@react-gcc-eds/core";

import { useFeedback } from "./feedback-actions";
import { useSupport } from "./support-actions";

export enum EViews {
  SUPPORT = 0,
  FEEDBACK = 1
}

const ContactUs = ({
  customerIdentifier,
  onClose,
  typeOfForm
}: {
  customerIdentifier: string;
  onClose: Function;
  typeOfForm: EViews;
}) => {
  const [currentView, setCurrentView] = useState<EViews>(EViews.FEEDBACK);

  const {
    submitIsDisabled: supportSubmitDisabled,
    components: supportComponents,
    getDisabledTooltip: getDisabledSupportTooltip,
    submit: submitSupport
  } = useSupport(customerIdentifier);

  const {
    submitIsDisabled: feedbackSubmitDisabled,
    components: feedbackComponents,
    getDisabledTooltip: getDisabledFeedbackTooltip,
    submit: submitFeedback
  } = useFeedback(customerIdentifier);

  const viewIsFeedback = () => typeOfForm === EViews.FEEDBACK;

  const submit = () => {
    viewIsFeedback() ? submitFeedback() : submitSupport();
    onClose();
  };

  const submitIsDisabled = () =>
    viewIsFeedback() ? feedbackSubmitDisabled() : supportSubmitDisabled();

  const disableTooltip = viewIsFeedback()
    ? getDisabledFeedbackTooltip()
    : getDisabledSupportTooltip();

  const SubmitButton = () =>
    submitIsDisabled() ? (
      <Tooltip text={disableTooltip} type="pointer" position="top-end">
        <Button primary onClick={submit} disabled={submitIsDisabled()}>
          Submit
        </Button>
      </Tooltip>
    ) : (
      <Button primary onClick={submit} disabled={submitIsDisabled()}>
        Submit
      </Button>
    );

  const buttons = () => {
    return [
      <Button key="cancel" onClick={() => onClose()}>
        Cancel
      </Button>,
      <SubmitButton key="submit" />
    ];
  };

  const dialogueTitle = () =>
    typeOfForm === EViews.FEEDBACK ? "Do you have suggestions?" : "Report an issue";

  const formIntroText = () => {
    if (typeOfForm === EViews.FEEDBACK)
      return (
        <span>
          If you have ideas either to improve existing functionalities or maybe think of totally new
          functionalities, let us know! We will add them to our backlog and contact you if we need
          more details.
        </span>
      );
    else if (typeOfForm === EViews.SUPPORT)
      return (
        <span>
          If you experience any problems using Ericsson Customer Order Dashboard, we apologize for
          any inconvenience it may cause. Please report your problem and submit this form so we can
          resolve it as quick as possible.
        </span>
      );
  };

  const formComponents = () =>
    typeOfForm === EViews.FEEDBACK ? feedbackComponents() : supportComponents();

  return (
    <Dialog title={dialogueTitle()} buttons={buttons()}>
      <div className="input-form">
        <div className="input-form-intro-text">{formIntroText()}</div>
        {formComponents()}
      </div>
    </Dialog>
  );
};

export default ContactUs;
