import { TooltipRenderProps } from "react-joyride";
import React, { useEffect, useMemo, useState } from "react";
import { Button, ProgressBar } from "@react-gcc-eds/core";
import { IStep } from "../../../store";

export interface WalkThroughDialog extends TooltipRenderProps {
  totalSteps: number;
  stepProps: IStep;
  maxStep: number;
  maxWidth?: number;
}

const WalkThroughDialog = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  maxWidth,
  isLastStep,
  totalSteps,
  stepProps,
  maxStep
}: WalkThroughDialog) => {
  const [blockNext, setBlockNext] = useState(false);

  const startIntervalCheck = (checker: () => boolean) => {
    const interval = setInterval(() => {
      const check = checker();
      setBlockNext(check);
      if (!check) {
        clearInterval(interval);
        if (stepProps.autoNext && index >= maxStep) {
          onForward(new Event("mouseevent"), (e: Event) =>
            primaryProps.onClick(e as unknown as React.MouseEvent<HTMLElement, MouseEvent>)
          );
        }
      }
    }, 100);
    return interval;
  };

  useEffect(() => {
    if (stepProps.blockNextWhile) {
      const interval = startIntervalCheck(stepProps.blockNextWhile);
      return () => clearInterval(interval);
    }
  }, [stepProps.blockNextWhile]);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "ArrowRight" || e.code === "ArrowRight") {
      e.stopPropagation();
      onForward(e, (e: Event) =>
        primaryProps.onClick(e as unknown as React.MouseEvent<HTMLElement, MouseEvent>)
      );
    } else if (e.key === "ArrowLeft" || e.code === "ArrowLeft") {
      e.stopPropagation();
      onBackwards(e, (e: Event) =>
        backProps.onClick(e as unknown as React.MouseEvent<HTMLElement, MouseEvent>)
      );
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  const onForward = async (e: Event, callback: (e: Event) => void) => {
    e.stopPropagation();
    if (stepProps.blockNextWhile ? !stepProps.blockNextWhile() : true) {
      if (stepProps.setupBeforeNext) {
        await stepProps.setupBeforeNext();
      }
      callback(e);
    }
  };
  const onBackwards = async (e: Event, callback: (e: Event) => void) => {
    e.stopPropagation();
    if (stepProps.setupBeforePrevious) {
      await stepProps.setupBeforePrevious();
    }
    callback(e);
  };

  // const onClose = async (e: Event, callback: (e: Event) => void) => {
  //   console.log("close");
  //   if (stepProps.setupBeforeClose) {
  //     await stepProps.setupBeforeClose();
  //   }
  //   setState(old => ({
  //     ...old,
  //     walkthrough: {
  //       ...old.walkthrough,
  //       continueBeingActive: false
  //     }
  //   }));
  //   callback(e);
  // };

  const BackButton = (backProps: {
    "aria-label": string;
    "data-action": string;
    onClick: (e: Event) => void;
    role: string;
    title: string;
  }) => {
    return (
      <Button {...backProps} onClick={(e: any) => onBackwards(e, backProps.onClick)}>
        <div id="back" />
        {backProps.title}
      </Button>
    );
  };

  const ForwardButton = (primaryProps: {
    "aria-label": string;
    "data-action": string;
    onClick: (e: Event) => void;
    role: string;
    title: string;
    disabled?: boolean;
  }) => {
    return (
      <Button
        {...primaryProps}
        disabled={blockNext}
        onClick={(e: any) => onForward(e, primaryProps.onClick)}
        primary
      >
        <div id="next" />
        {isLastStep ? "Done" : primaryProps.title}
      </Button>
    );
  };

  const CloseButton = () => {
    return (
      <span {...closeProps} className="close-button">
        <i className="icon icon-cross" />
      </span>
    );
  };

  const content = useMemo(() => {
    if (typeof step.content === "string") {
      return step.content.split(/\n/g).map((part, i, total) => (
        <>
          <span>{part}</span>
          {i !== total.length - 1 && <br />}
          {i !== total.length - 1 && <br />}
        </>
      ));
    }
    return step.content;
  }, [step.content]);

  return (
    <div
      {...tooltipProps}
      style={maxWidth ? { maxWidth: maxWidth } : undefined}
      className={`walk-through-dialog ${step.placement}`}
    >
      <div className="dialog-title">
        <span className="title">{step.title}</span>
        <CloseButton />
      </div>
      <div className="dialog-content">{content}</div>
      <ProgressBar value={index} max={totalSteps} thin format="value" />
      <div className="dialog-buttons">
        {index > 0 &&
          BackButton({
            ...backProps,
            onClick: backProps.onClick as unknown as (e: Event) => void
          })}
        {continuous &&
          ForwardButton({
            ...primaryProps,
            onClick: primaryProps.onClick as unknown as (e: Event) => void
          })}
      </div>
    </div>
  );
};

export default WalkThroughDialog;
