import { EViewId } from "components/shared/view-ids";
import { ReactNode } from "react";
import { Step } from "react-joyride";
import { IGridColumnsFilters, IGridColumnStatePreferences } from "../components/shared/grid";
import {
  IDateRange,
  IPeriodRange,
  lastQuarter,
  lastTwelveMonthsDatesRange,
  previousMonthRange,
  quarterRange
} from "../contracts";
import { ICustomerIdentity, IEntity, IUserAccessRequest, IUserRole } from "../types";
import { createGuid } from "../utils";
import storeFactory from "./store-factory";
import { ColumnState, ColumnVO, ServerSideGroupLevelState, SortModelItem } from "ag-grid-community";
import { IFloatingFilterText } from "components/shared/servergrid";
import { IOrderFilter } from "components/reports/purchase-lists/helpers/filters";

export const DATE_PROPERTY_CATEGORY = 10;

export enum ColumnDataType {
  None,
  Number,
  Date
}
export enum STATISTICS_DATE_GROUPING {
  DATE,
  WEEK,
  MONTH
}
interface IApi {
  pendingReads: number;
  pendingWrites: { id: string; data: any }[];
  errors: string[];
}

interface ILocalization {
  key: string;
  defaultValue: string;
  value: string;
}

export interface IPurchaseOrderStatus {
  value: string;
  code: number;
}
export interface IFinancialDataSettings {
  hasAccessToFinancialData: boolean;
}
export interface ICustomerFeatures {
  customer: string;
  views: string[];
  dashboards: string[];
  applicationSettings: IApplicationSettings;
  // Not in use yet
  financialSettings: IFinancialDataSettings;
}

export interface IGridColumnWeekFormatPreferences {
  [key: string]: {
    [key: string]: boolean;
  };
}

//TODO: Make listed and dynamic
export interface IApplicationSettings {
  showOnlyPastScheduledDeliveries?: boolean;
  enableWalkThrough?: boolean;
  isUsingCustomMaterialNaming?: boolean;
  isUsingDbSchenkerFtpIntegration?: boolean;
  enableSiteIdAndSiteName?: boolean;
  excludeDeletedInternalPurchaseOrders?: boolean;
  [key: string]: boolean | string | number | undefined;
}
export interface IHoldingCustomer {
  accountId: string;
  displayName: string;
}

export interface IStep extends Step {
  setupBeforeNext?: () => Promise<any>;
  setupBeforePrevious?: () => Promise<any>;
  setupBeforeClose?: () => Promise<any>;
  childNodeQuery?: string;
  blockNextWhile?: () => boolean;
  autoNext?: boolean;
  backSeveralSteps?: number;
  blockClickOutside?: boolean;
  skip?: boolean;
  id?: number;
}

export interface IPaginatedQuery {
  pageNumber: number;
  pageSize: number;
  filterModel: IServerGridsColumnsFilters[] | [];
  commonFilters: IServerPurchaseOrderItemsFilter;
}

export interface IPaginatedResponse<T extends {}> {
  orderBy?: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalHits: number;
  filterModel: IServerGridsColumnsFilters[] | [];
  data: T;
  commonFilters: IServerPurchaseOrderItemsFilter;
}

export interface UserInfo {
  firstName?: string;
  lastName?: string;
  header?: any;
  subHeader?: string;
}

export interface ICustomer extends ICustomerIdentity {
  accountIds?: string[];
  accounts: IHoldingCustomer[];
  aspWhClientId?: string;
  companyCode?: string;
  dataAvailableFromDate: string;
  dataUpdatedAt: string;
  dbSchenkerDataUpdateAt: string;
  esrCustomer?: string;
  features: ICustomerFeatures;
  finalCustomerNumber?: string;
  finalExternalCustomer?: string;
  finalExternalCustomerDesc?: string;
  finalExternalCustomerId?: number;
  forceRedirectToMobileVersion: boolean;
  importDataFromDate?: string;
  internalStatusTranslations: IPurchaseOrderStatus[];
  shipmentStatusTranslations: IShipmentStatuses[];
  internalShipmentStatusTranslations: IShipmentStatuses[];
  isHoldingCompany: boolean;
  isUsingFinancialData: boolean;
  localizations: ILocalization[];
  showFeedbackForm: boolean;
  soldToPartyNumber?: string;
  standaloneImport: boolean;
  statusTranslations: IPurchaseOrderStatus[];
  updateInterval: number;
  useExcelTemplate: boolean;
  internalDeliveryStatusTranslations: IPurchaseOrderStatus[];
  adoptedModule: string | null;
  marketArea?: number;
  marketAreaDesc?: string;
  marketType?: number;
  marketTypeDesc?: string;
  [key: string]:
    | string
    | string[]
    | number
    | boolean
    | IHoldingCustomer[]
    | ICustomerFeatures
    | IPurchaseOrderStatus[]
    | IShipmentStatuses[]
    | ILocalization[]
    | undefined
    | null;
}

export interface IUserFullFilter {
  userFilterId?: number;
  localizeId: number;
  localizeDefaultValue: string;
  filterValue: string;
}

export interface IUserDateFormat {
  description: string;
  format: string;
}
export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  loginEmail: string;
  ericssonSignum: string;
  role: number;
  virtualRole: number | null;
  customers: ICustomerIdentity[];
  userFilters: IUserFullFilter[];
  customerIds: string[];
  oldestDate?: Date;
  createdAt?: string;
  lastLogin?: string;
  canApprove: boolean;
  canRunGlobalStatistics: boolean;
  isDeactivated: boolean;
  customerPills?: object;
  hasAccessToFinancialData: boolean;
  hasAccessToCustomerEnabledFinancialData: boolean;
  dateFormat: string;
  reactivate: boolean;
  [key: string]:
    | string
    | string[]
    | number
    | boolean
    | Date
    | ICustomerIdentity[]
    | IUserFullFilter[]
    | object
    | null
    | undefined;
}

export interface ISearchResult {
  purchaseOrderItemIds: string[];
  matchingValue: string;
  matchingProperty: string;
  originalProperty: string;
}

export interface IMaterialInventorySearchResult {
  materialId: string;
  matchingValue: string;
  matchingProperty: string;
}

export interface IDragAndDropArea {
  droppableId: string;
  itemOrder: string[];
  title: string;
  subtitle?: string;
}

export interface IDragAndDropItem {
  id: string;
  content: ReactNode;
  movable: boolean;
}

export interface ITechnicalTooltips {
  [accessor: string]: ICustomTooltip[] | undefined;
}

export interface IStatusMetric {
  label: string;
  status: number;
  totalCount: number;
}

export interface IWeekMetric {
  week: number;
  amount: number;
  startDate: string;
  endDate: string;
  earliestOrderCreationDate: string;
}

interface IAverageMetric {
  totalLabel: string;
  totalCount: number;
  relativeLabel: string;
  relativeCount: number;
}

export interface IMaterialInventoryMetric {
  materialId?: string;
  materialName?: string;
  ordered: number;
  goodsReceivedPosted: number;
  inventoryInNwh: number;
}

export interface IShipmentDataPoint {
  label: string;
  value: number;
}
export interface IShipmentDeliveredMetric {
  currency: string;
  dataPoints: IShipmentDataPoint[];
}
interface ISeriesValues {
  label: string;
  totals: number[];
}

export interface ISeriesMetric {
  headers: string[];
  metrics: ISeriesValues[];
}

export interface IDeliveryPrecisionMetric {
  total: number;
  onTime: number;
  part: number;
}

export interface IPlantInventoryMetric {
  plant: string;
  quantity: number;
}

export interface IDataImportStatus {
  syncedOn: string;
  status: boolean;
}
export interface IDataImportStatusEntry {
  source: string;
  syncData: IDataImportStatus[];
}
export interface ICustomerDataImportHealth {
  customerId: number;
  customerDisplayName: string;
  identifier: string;
  soldtoParty: string;
  supportTicket: number;
  sourceData: IDataImportStatusEntry[];
  dataLoadingTimeValues: IDataHealthSeverityValues;
}
export interface IDataHealthSeverityValues {
  severityColor: string;
  percentage: number;
}
export interface ILocalizedProperty extends IEntity {
  key: string;
  defaultValue: string;
  value: string;
  category: number | string;
}
export interface ITechnicalPropertyDetail {
  key: string;
  defaultValue: string;
  description: string;
  sapID: string;
  stepwise: string;
  category: number;
}
export interface IViewProperty {
  propertyName: string;
  defaultName?: string;
  category: number;
  order: number;
  label: string;
  hide: boolean;
  roles: number[];
}

export interface IViewCustomization {
  maxNumOfVisibleFields: number;
  properties: IViewProperty[];
}
export interface IViewCustomizationOption {
  uiViewId: string;
  description: string;
}

export interface IPurchaseOrderBookmark {
  view: EViewId;
  key1: string;
  key2: string;
}

export interface IPurchaseOrderItem extends IEntity {
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  itemNumber: string;
  itemNumber2: string;
  lineId: string;
  description: string;
  quantity: number;
  createdAt: string;
  requestedDateItemAt: string;
  confirmedDeliveryAt: string;
  plannedDeliveryAt: string;
  plannedDeliveryDocAt: string;
  calculatedConfirmDate: string;
  orderCreationDate: string;
  deliveredAt: string;
  actualDeliveryAt: string;
  readyForShipmentAt: string;
  deliveryAddress: string;
  goodsMark: string;
  requestor: string;
  salesOrder: string;
  shipmentNumber: string;
  siteId: string;
  status: number;
  statusLabel: string;
  ericssonContractNo: string;
  siteName: string;
  soName: string;
  opCo: string;
  proformaInvoice: string;
  contactPerson: string;
  contactPersonMail: string;
  posEx: string;
  meetsRequestedDate: string;
  requestedDeliveryAt: string;
  isBundle: boolean;
  isMaterial: boolean;
  shipmentDetailId: string;
  shipToName2: string;
  paymentTerms: string;
  shipToPOItemNumber: string;
  purchaseOrderItemComment: IComment;
  additionalPurchaseOrderItemComment1: IComment;
  purchaseOrderItemDateComment: IComment;
  additionalDatePurchaseOrderItemComment1: IComment;
  materialComment: IComment;
  productGroupDesc: string;
  expectedReadyForShipmentAt: string;
  bdReferenceNumber: string;
  poFirstConfDeliveryAt: string;
  meetsShipmentEndDate: string;
  meetsCommentRequestDate: string;
  finalInvoiceDate: string;
  planningEndAt: string;
  confirmedLoadingDate: string;
  deliveryCreatedOnDate: string;
  createdonDateforLetterofCredit: string;
  itemCurrentPlannedGoodsIssueAt: string;
  soDate: string;
  city: string;
  vemng: number;
  balanceQty: number;
  [key: string]: string | number | boolean | IMaterialItem[] | IComment;
}

export interface IComment {
  comment: string;
  createdAt: string;
  updating: boolean;
  originalComment?: string;
}

export interface IInternalOrder extends IEntity {
  purchaseOrderId: string;
  internalPurchaseOrderNumber: string;
  customerPurchaseOrderNumber: string;
  currentConfirmedDate: string;
  createdAt: string;
  requestedDeliveryDate: string;
  initialConfirmedDeliveryDate: string;
  confirmedDeliveryDateUpdatedAt: string;
  actualDeliveryAt: string;
  delayedVsInitialRequestedDate: string;
  delayedVsInitialConfirmedDate: string;
  shipmentNumber: string;
  salesOrderNumber: string;
  wbsid: string;
  wbsName: string;
  vendorName: string;
  locationUpdatedAt: string;
  siteId: string;
  deliveryAddress: string;
  deliveryAddressID: string;
  deliveryAddressStreet: string;
  deliveryAddressCity: string;
  deliveryAddressPostalCode: string;
  deliveryAddressCountry: string;
  deliveryAddressRegion: string;
  poAdressName1Itm: string;
  poAdressName2Itm: string;
  shipToPartyID: string;
  shipToPartyName: string;
  locationStatus: number;
  locationStatusLabel: string;
  isBookmarked: number;
  uniquePOItems: number;
  deliveryStatus: number;
  deliveryStatusLabel: string;
  internalPurchaseOrderComment: IComment;
  internalPurchaseOrderDateComment: IComment;
  internalPurchaseOrderItemDateComment: IComment;
  internalShipmentComment: IComment;
  internalAdditionalShipmentComment: IComment;
  goodsIssueDate: string;
  readyForShipmentAt: string;
  internalCreatedAt: string;
  cpoCreationDate: string;
  vendorInvoicePostingDate: string;
  [key: string]: string | number | boolean | IComment;
}

export interface IInternalItem extends IEntity {
  accountId: number;
  internalPurchaseOrderNumber: string;
  purchaseOrderItem: string;
  materialID: string;
  materialName: string;
  quantity: number;
  currentConfirmedDeliveryAt: string;
  initialConfirmedDeliveryAt: string;
  delayedVsInitialRequestedDate: string;
  delayedVsInitialConfirmedDate: string;
  salesOrderNumber: string;
  wbsid: string;
  wbsName: string;
  confirmedDeliveryDateUpdatedAt: string;
  actualDeliveryAt: string;
  shipmentNumber: string;
  vendorName: string;
  requestedDeliveryAt: string;
  locationUpdatedAt: string;
  configurationId: string;
  configurationName: string;
  siteId: string;
  siteName: string;
  deliveryAddress: string;
  deliveryAddressID: string;
  deliveryAddressStreet: string;
  deliveryAddressCity: string;
  deliveryAddressPostalCode: string;
  deliveryAddressCountry: string;
  deliveryAddressRegion: string;
  poAdressName1Itm: string;
  poAdressName2Itm: string;
  shipToPartyID: string;
  shipToPartyName: string;
  locationStatus: number;
  locationStatusLabel: string;
  deliveryStatus: number;
  deliveryStatusLabel: string;
  [key: string]: string | number | boolean;
}

export interface IPurchaseOrder extends IEntity {
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  createdAt: string;
  requestedDeliveryAt: string;
  confirmedDeliveryAt: string;
  plannedDeliveryAt: string;
  finalInvoiceDate: string;
  contactPerson: string;
  contactPersonMail: string;
  actualDeliveryAt: string;
  deliveredAt: string;
  readyForShipmentAt: string;
  deliveryAddress: string;
  goodsMark: string;
  requestor: string;
  salesOrder: string;
  siteId: string;
  status: number;
  statusLabel: string;
  uniquePOItems: number;
  meetsRequestedDate: string;
  ericssonContractNo: string;
  siteName: string;
  orderId: string;
  comment?: IComment;
  additionalPurchaseOrderComment1?: IComment;
  purchaseOrderDateComment?: IComment;
  SOName: string;
  opCo: string;
  latestValueContractInvoice: string;
  latestInquiryNumber: string;
  poCalculatedConfirmDate: string;
  shipToName2: string;
  poPlanningEndDate: string;
  expectedReadyForShipmentAt: string;
  currentPlannedGoodsIssueAt: string;
  plannedDeliveryDocAt: string;
  SReceivedAt: string;
  SODate: string;
  onTime: string;
  isBookmarked: number;
  isUpdating: boolean;
  [key: string]: string | number | boolean | IPurchaseOrderItem[] | IComment | undefined;
}

export interface IInternalPurchaseOrder extends IEntity {
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  itemNumber: string;
  itemNumber2: string;
  lineId: string;
  description: string;
  quantity: number;
  requestedDateItemAt: string;
  confirmedDeliveryAt: string;
  plannedDeliveryAt: string;
  calculatedConfirmDate: string;
  orderCreationDate: string;
  actualDeliveryAt: string;
  readyForShipmentAt: string;
  deliveryAddress: string;
  goodsMark: string;
  requestor: string;
  salesOrder: string;
  shipmentNumber: string;
  siteId: string;
  status: number;
  statusLabel: string;
  ericssonContractNo: string;
  siteName: string;
  soName: string;
  proformaInvoice: string;
  contactPerson: string;
  contactPersonMail: string;
  posEx: string;
  meetsRequestedDate: string;
  requestedDeliveryAt: string;
  isBundle: boolean;
  isMaterial: boolean;
  shipmentDetailId: string;
  shipToName2: string;
  paymentTerms: string;
  shipToPOItemNumber: string;
  internalPurchaseOrderItemComment: IComment;
  additionalPurchaseOrderItemComment1: IComment;
  internalPurchaseOrderItemDateComment: IComment;
  additionalDatePurchaseOrderItemComment1: IComment;
  internalShipmentComment: IComment;
  internalAdditionalShipmentComment: IComment;
  productGroupDesc: string;
  expectedReadyForShipmentAt: string;
  bdReferenceNumber: string;
  Week: string;
  poFirstConfDeliveryAt: string;
  meetsShipmentEndDate: string;
  meetsCommentRequestDate: string;
  city: string;
  vemng: number;
  balanceQty: number;
  [key: string]: string | number | boolean | IMaterialItem[] | IComment;
}

export interface IInternalOrdersView {
  payload: IInternalOrder[];
  properties: IViewProperty[];
}

export interface IInternalOrderView {
  payload: IInternalOrder;
  properties: IViewProperty[];
}

export interface IInternalOrderItemsView {
  payload: IInternalItem[];
  properties: IViewProperty[];
}

export interface IPurchaseOrdersView {
  payload: IPurchaseOrder[];
  properties: IViewProperty[];
}

export interface IPurchaseOrderView {
  payload: IPurchaseOrder;
  properties: IViewProperty[];
}

export interface IPurchaseOrderItemsView {
  payload: IPurchaseOrderItem[];
  properties: IViewProperty[];
}

export interface IInternalPurchaseOrderItemsView {
  payload: IInternalPurchaseOrderItem[];
  properties: IViewProperty[];
}

export interface IInternalPurchaseOrderItem extends IEntity {
  purchaseOrderId: string;
  purchaseOrderNumber: string;
  internalPurchaseOrderNumber: string;
  purchaseOrderItem: string;
  itemNumber: string;
  itemNumber2: string;
  lineId: string;
  description: string;
  quantity: number;
  requestedDateItemAt: string;
  confirmedDeliveryAt: string;
  plannedDeliveryAt: string;
  calculatedConfirmDate: string;
  currentConfirmedDate: string;
  initialConfirmedDeliveryAt: string;
  confirmedDeliveryDateUpdatedAt: string;
  goodsReceiptCreationDate: string;
  goodsReceiptPostingDate: string;
  currentConfirmedDeliveryAt: string;
  internalCreatedAt: string;
  cpoCreationDate: string;
  planningEndAt: string;
  shipmentStartAt: string;
  locationUpdatedAt: string;
  goodsIssueDate: string;
  orderCreationDate: string;
  actualDeliveryAt: string;
  readyForShipmentAt: string;
  deliveryAddress: string;
  goodsMark: string;
  requestor: string;
  salesOrder: string;
  shipmentNumber: string;
  siteId: string;
  status: number;
  statusLabel: string;
  ericssonContractNo: string;
  siteName: string;
  soName: string;
  proformaInvoice: string;
  contactPerson: string;
  contactPersonMail: string;
  posEx: string;
  meetsRequestedDate: string;
  requestedDeliveryAt: string;
  isBundle: boolean;
  isMaterial: boolean;
  shipmentDetailId: string;
  shipToName2: string;
  paymentTerms: string;
  shipToPOItemNumber: string;
  purchaseOrderItemComment: IComment;
  additionalPurchaseOrderItemComment1: IComment;
  purchaseOrderItemDateComment: IComment;
  additionalDatePurchaseOrderItemComment1: IComment;
  productGroupDesc: string;
  internalPurchaseOrderItemComment: IComment;
  internalPurchaseOrderItemDateComment: IComment;
  internalShipmentComment: IComment;
  internalAdditionalShipmentComment: IComment;
  internalMaterialComment: IComment;
  expectedReadyForShipmentAt: string;
  bdReferenceNumber: string;
  poFirstConfDeliveryAt: string;
  meetsShipmentEndDate: string;
  meetsCommentRequestDate: string;
  vendorInvoicePostingDate: string;
  city: string;
  vemng: number;
  balanceQty: number;
  [key: string]: string | number | boolean | IMaterialItem[] | IComment;
}

export interface IPurchaseOrderItemDeliveryDetails {
  calculatedConfirmDate: string;
  status: number;
  statusLabel: string;
  itemIds: string[];
}

export interface IInternalPurchaseOrderItemDelivery {
  siteId?: string;
  lineId: string;
  siteName: string;
  itemNumber: string;
  itemNumber2: string;
  description: string;
  customMaterialName: string;
  isBookmarked: boolean;
  deliveries: IInternalPurchaseOrderItemDeliveryDetails[];
  updating: boolean;
  periods: IPurchaseOrderItemMetricPeriod[];
  purchaseOrderItemComment: IComment;
  additionalPurchaseOrderItemComment1: IComment;
  internalMaterialComment: IComment;
}

export interface IInternalPurchaseOrderItemDeliveryDetails {
  calculatedConfirmDate: string;
  status: number;
  statusLabel: string;
  itemIds: string[];
}

export interface IDeliveryMaterials {
  material: string;
  isBookmarked: boolean;
  isUpdating: boolean;
}

export interface IInternalPurchaseOrderItemMetricPeriod {
  quantity: string;
  period: string;
}

export interface IInternalPurchaseOrderItemMetric extends IEntity {
  itemNumber: string;
  itemNumber2: string;
  description: string;
  purchaseOrderItemComment: IComment;
  additionalPurchaseOrderItemComment1: IComment;
  periods: IPurchaseOrderItemMetricPeriod[];
  lineId: string;
  isBookmarked: boolean;
  vemng: string;
  quantity: string;
  grQuantity: string;
  balanceQty: string;
  siteId: string;
  siteName: string;
  startDate: string;
  endDate: string;
}

// export interface IInternalDeliveryRecurrence {
//   description: string;
//   value: DeliveryRecurrence;
//   apiParam: number;
// }

export interface IDeliveryMaterials {
  material: string;
  isBookmarked: boolean;
  isUpdating: boolean;
}

export interface IInternalDeliveryMaterials {
  material: string;
  isBookmarked: boolean;
  isUpdating: boolean;
}

export interface IPurchaseOrderItemDelivery {
  lineId: string;
  itemNumber: string;
  itemNumber2: string;
  description: string;
  customMaterialName: string;
  isBookmarked: boolean;
  deliveries: IPurchaseOrderItemDeliveryDetails[];
  updating: boolean;
  periods: IPurchaseOrderItemMetricPeriod[];
  purchaseOrderItemComment: IComment;
  additionalPurchaseOrderItemComment1: IComment;
  materialComment: IComment;
  siteId?: string;
  siteName?: string;
}

export interface IPurchaseOrderItemMetricPeriod {
  lineId: string;
  period: string;
  quantity: string;
  startDate: string;
  endDate: string;
  earliestOrderCreationDate: string;
  itemIds: string[];
  shipmentNumber: string;
  status: number;
}

export interface IPurchaseOrderItemMetric extends IEntity {
  itemNumber: string;
  itemNumber2: string;
  description: string;
  purchaseOrderItemComment: IComment;
  additionalPurchaseOrderItemComment1: IComment;
  periods: IPurchaseOrderItemMetricPeriod[];
  lineId: string;
  isBookmarked: boolean;
  deliveredQuantity: string;
  quantity: string;
  balanceQty: string;
  siteId: string;
  siteName: string;
  startDate: string;
  endDate: string;
}

export interface IPurchaseOrderItemsMetricsView {
  payload: IPurchaseOrderItemMetric[];
  properties: IViewProperty[];
}

export interface IInternalPurchaseOrderItemsMetricsView {
  payload: IInternalPurchaseOrderItemMetric[];
  properties: IViewProperty[];
}

export interface IInternalPurchaseOrderItemMetricPeriod {
  lineId: string;
  period: string;
  quantity: string;
  startDate: string;
  earliestOrderCreationDate: string;
  endDate: string;
  itemIds: string[];
  shipmentNumber: string;
  status: number;
}
interface IPurchaseOrderItemOverview {
  [key: string]: string | number;
}

export interface IInternalPurchaseOrderItemOverviewView {
  payload: IInternalPurchaseOrderItemOverview;
  properties: IViewProperty[];
}

interface IInternalPurchaseOrderItemOverview {
  [key: string]: string | number;
}

export interface IPurchaseOrderItemOverviewView {
  payload: IPurchaseOrderItemOverview;
  properties: IViewProperty[];
}

export interface IShipmentStatuses {
  value: string;
  code: number;
}

export interface IShipmentStatusBoard {
  status: string;
  count: number;
  tooltip: string;
}
export interface IShipmentOverviewItem extends IEntity {
  shipmentNumber: string;
  shipmentNo: string;
  extId: string;
  custPOorder: string;
  carrier: string;
  shipmentCreatedAt: string;
  shipmentStartAt: string;
  planningStartAt: string;
  plannedOrActualEnd: string;
  etaCustomsPointPlannedAt: string;
  etaCustomsPointActualAt: string;
  etaDoorPlannedAt: string;
  actualArrivalDatetoCustoms: string;
  actualReleaseDatefromCustoms: string;
  planningEndAt: string;
  actualDeliveryAt: string;
  trackingLink: string;
  shipmentComment: IComment;
  additionalShipmentComment: IComment;
  shipmentStatus: number;
  shipmentStatusLabel: string;
  opCo: string;
  [key: string]: string | number | boolean | IComment | undefined;
}

export interface IShipmentMilestonesHeaderExport {
  worksheet: string;
  headers: string[];
  tableRows: any[];
}
export interface IShipmentMilestoneColumnItem {
  id: string;
  customerId: number;
  columnName: string;
  columnDescription: string;
  [key: string]: string | number | boolean | IComment | undefined;
}

export interface IShipmentMilestoneItem {
  id: string;
  customerShipmentMilestoneConfigurationId: string;
  shipmentNumber: string;
  milestoneDate: IComment;
  createdBy: string;
  [key: string]: string | number | boolean | IComment | undefined;
}

export interface IShipmentMilestoneDynamicRow extends IEntity {
  shipmentNumber: string;

  [key: string]: string | IComment;
}

export interface IShipment extends IEntity {
  extId: string;
  shipmentNo: string;
  shipmentComment: IComment;
  additionalShipmentComment: IComment;
  shipmentStatus: number;
  custPOorder: string;
  planningEndAt: string;
  actualDeliveryAt: string;
  trackingLink: string;
  carrier: string;
  shipmentNumber: string;
  shipmentCreatedAt: string;
  planningStartAt: string;
  plannedOrActualEnd: string;
  shipmentStatusLabel: string;
  [key: string]: string | number | boolean | IComment | undefined;
}

export interface IShipmentView {
  payload: IShipment[];
  properties: IViewProperty[];
}

export interface IShipmentOverview {
  payload: IShipmentOverviewItem[];
  properties: IViewProperty[];
}

export interface IInternalShipmentOverview {
  payload: IInternalShipmentOverviewItem[];
  properties: IViewProperty[];
}

export interface IInternalShipmentOverviewItem extends IEntity {
  shipmentNumber: string;
  shipmentNo: string;
  extId: string;
  custPOorder: string;
  carrier: string;
  shipmentCreatedAt: string;
  shipmentStartAt: string;
  planningStartAt: string;
  plannedOrActualEnd: string;
  planningEndAt: string;
  actualDeliveryAt: string;
  trackingLink: string;
  internalShipmentComment: IComment;
  internalAdditionalShipmentComment: IComment;
  shipmentComment: IComment;
  [key: string]: string | number | boolean | IComment | undefined;
  //More to come
}

export interface IShipmentDetailsView {
  payload: {
    carrier: string;
    shipmentNumber: string;
    shipmentComment: IComment;
    additionalShipmentComment: IComment;
    [key: string]: string | number | IComment;
  };
  properties: IViewProperty[];
}

export interface IInternalShipmentDetailsView {
  payload: {
    carrier: string;
    shipmentNumber: string;
    shipmentComment: IComment;
    internalShipmentComment: IComment;
    internalAdditionalShipmentComment: IComment;
    [key: string]: string | number | IComment;
  };
  properties: IViewProperty[];
}

export interface IShipmentStatus {
  trackingLink: string;
  planningStartAt?: string;
  shipmentStartAt?: string;
  etaCustomsPointPlanned?: string;
  etaCustomsPointActual?: string;
  planningEndAt?: string;
  actualDeliveryAt?: string;
}
export interface IShipmentStatuses {
  value: string;
  code: number;
}

export interface IShipmentContent extends IEntity {
  id: string;
  material: string;
  purchaseOrderId: string;
  internalPurchaseOrderId: string;
  itemNumber: string;
  itemNumber2: string;
  bdReferenceNumber: string;
  isHighlighted: boolean;
  finalInvoiceDate: string;
  [key: string]: string | number | boolean;
}

export interface IShipmentContentView {
  payload: IShipmentContent[];
  properties: IViewProperty[];
}

export interface IShipmentFilters {
  shipmentCreationRange?: IDateRange;
  statuses?: number[];
  plannedStartRange?: IDateRange;
  plannedActualEndRange?: IDateRange;
  opCo?: string[];
  // [key: string]: IDateRange | number[] | undefined;
  // length: number;
}

interface IShipmentOverviewFilters {
  creationRange?: IDateRange;
}
export interface IMaterialItem extends IEntity {
  id: string;
  material: string;
  description: string;
  quantity: number;
  handlingUnitNumber?: string;
  shipmentNumber?: string;
  shipmentDetailId: string;
  isHardware: boolean;
  [key: string]: string | number | boolean | undefined;
}

export interface IMaterialsView {
  payload: IMaterialItem[];
  properties: IViewProperty[];
}

export interface IMaterialDetails extends IEntity {
  material: string;
  description: string;
  serialNumber: string;
  handlingUnitNumber: string;
  [key: string]: string;
}

export interface IMaterialDetailsView {
  payload: IMaterialDetails[];
  properties: IViewProperty[];
}

export interface IOverViewsCollapsedStatuses {
  poOverviewCollapsed?: boolean;
  poItemOverviewCollapsed?: boolean;
  internalPoOverviewCollapsed?: boolean;
  internalPoItemOverviewCollapsed?: boolean;
  shipmentOverviewCollapsed?: boolean;
  internalShipmentOverviewCollapsed?: boolean;
  nwhInventoryBookmarkOverviewCollapsed?: boolean;
}

export interface IPillFilter {
  accessor: string;
  label: string;
  selected: boolean;
  rule: (r: any) => boolean;
  icon?: string;
  severity?: "critical" | "major" | "minor" | "warning" | "indeterminate" | "cleared";
}

export interface IPropertyFilter {
  name: string;
  value: string;
}

interface IInternalOrdersFilters {
  orderCreationRange?: IDateRange;
  requestedDeliveryRange?: IDateRange;
  currentConfirmedDateRange?: IDateRange;
  locationStatuses?: number[];
  deliveryStatuses?: number[];
  delayedRequestedDate: IPillFilter[];
  [key: string]: IDateRange | number[] | IPillFilter[] | undefined;
}

interface IPurchaseOrdersFilters {
  orderCreationRange?: IDateRange;
  statuses?: number[];
  requestedDeliveryRange?: IDateRange;
  confirmedDeliveryRange?: IDateRange;
  plannedDeliveryRange?: IDateRange;
  meetRequested: IPillFilter[];
  onTime: IPillFilter[];
  opCo?: string[];
}

export interface IServerPurchaseOrderItemsFilter {
  CreatedAtFrom?: string;
  CreatedAtTo?: string;
  StatusLabel?: number[];
  RequestedDeliveryAtFrom?: string;
  RequestedDeliveryAtTo?: string;
  ConfirmedDeliveryAtFrom?: string;
  ConfirmedDeliveryAtTo?: string;
  PlannedDeliveryAtFrom?: string;
  PlannedDeliveryAtTo?: string;
  MeetsRequestedDate?: string[];
  OpCo?: number[];
  [key: string]: any;
}

export enum DeliveryRecurrence {
  Weekly,
  Monthly,
  Quarterly
}

export interface IDeliveryRecurrence {
  description: string;
  value: DeliveryRecurrence;
  apiParam: number;
}

export interface IInternalDeliveryRecurrence {
  description: string;
  value: DeliveryRecurrence;
  apiParam: number;
}

interface IPurchaseOrderItemsDeliveriesFilters {
  orderCreationRange?: IDateRange;
  requestedDeliveryRange?: IDateRange;
  removeZeroQuantities: boolean;
  recurrence: IDeliveryRecurrence;
  periods?: string[];
  regions: string[];
  siteId: string;
  siteName: string;
}

interface IInternalPurchaseOrderItemsDeliveriesFilters {
  orderCreationRange?: IDateRange;
  requestedDeliveryRange?: IDateRange;
  removeZeroQuantities: boolean;
  recurrence: IInternalDeliveryRecurrence;
  periods?: string[];
  regions: string[];
  siteId: string;
  siteName: string;
}

export interface IUserPreferences {
  // selected customer
  theme?: "light" | "dark";
  customerId?: string;
  useDualCalendar: boolean;
  technicalTooltips: boolean;
  notifications: boolean;
  scheduledExports: boolean;
}

export interface IShowNotifications {
  showNotifications: boolean;
}

export interface IInventoryNWH extends IEntity {
  // configurationId: string;
  // quantity: number;

  customerPurchaseOrderNumber: string;
  deliveryNote: string;
  fasid: string;
  internalPurchaseOrderNumber: string;
  material: string;
  materialName: string;
  materialQuantity: number;
  plant: string;
  plantID: string;
  salesOrder: string;
  siteAllocated: string;
  siteId: string;
  storageLocationID: string;
  wbsDescription: string;
  wbsid: string;
  isBookmarked: number;
  updating: boolean;
}

interface IInventoryNWHView {
  payload: IInventoryNWH[];
  properties: IViewProperty[];
}

interface IBookmarkedMaterial {
  material: string;
  quantity: number;
}

export interface IBookmarkedMaterials {
  allocated: IBookmarkedMaterial[];
  nonAllocated: IBookmarkedMaterial[];
}

export interface IInventoryASP extends IEntity {
  aspMaterialQuantity: number;
  aspName: string;
  aspid: string;
  customerPurchaseOrderNumber: string;
  deliveryNote: string;
  deliveryNumber: string;
  handlingUnitNumber: string;
  material: string;
  materialID: string;
  postalCode: string;
  region: string;
  salesOrderNumber: string;
  siteId: string;
  timeStamp: string;
  warehouseAddress: string;
  wbsid: string;
  whCompanyName: string;
}

interface IInventoryASPView {
  payload: IInventoryASP[];
  properties: IViewProperty[];
}

export interface IInventoryFilters {
  material?: string;
}

export interface ISiteDeliveredQuantity {
  deliveredQuantity: number;
  totalQuantity: number;
}

export interface ISite extends IEntity {
  siteId: string;
  bomId: string;
  siteDeliveredQuantity: ISiteDeliveredQuantity;
  isBookmarked: number;
  isSelected: boolean;
  updating: boolean;
}

export interface ISiteView {
  payload: ISite[];
  properties: IViewProperty[];
}

export interface ISiteMilestone {
  name: string;
  completedDate: string;
}

export interface ISiteMaterial extends IEntity {
  aspId: string;
  aspName: string;
  siteId: string;
  materialID: string;
  material: string;
}

export interface ISiteMaterialView {
  payload: ISiteMaterial[];
  properties: IViewProperty[];
}

export interface IBomMaterialStatus {
  statusLabel: string;
  quantity: number;
}

export interface IBom extends IEntity {
  bomId: string;
  siteId: string;
  bomStatusLabel: string;
  siteDeliveredQuantity: ISiteDeliveredQuantity;
  isBookmarked: number;
  updating: boolean;
  [key: string]: string | number | boolean | ISiteDeliveredQuantity;
}

export interface IBomView {
  payload: IBom[];
  properties: IViewProperty[];
}

interface IBomFilters {
  statuses: string[];
}

export interface IBomContent extends IEntity {
  materialID: string;
  isSelected: boolean;
  isBookmarked: number;
  updating: boolean;
}

export interface IBomContentView {
  payload: IBomContent[];
  properties: IViewProperty[];
}

export interface IDashboardItem {
  id: number;
  dashboardKey: string;
  visible: boolean;
  isModifiable: boolean;
  relatedFields: string[];
  roles: number[];
}

export interface INotificationItem {
  id: number;
  notificationId: number;
  notificationKey: string;
  visible: boolean;
  isModifiable: boolean;
  relatedFields: string[];
  roles: number[];
}

export interface IUserCustomerNotificationItem {
  customerId: number;
  userId: number;
  notificationId: number;
  notificationScope: number;
  frequency: number;
  recurringDay: number;
  enabled: boolean;
}
export interface INotificationAdminItem {
  id: number;
  notificationAdminKey: string;
  visible: boolean;
  roles: number[];
}

export interface IScheduledExportAdminItem {
  id: number;
  customerId: string;
  visible: boolean;
  roles: number[];
  frequency: number;
}
export interface IScheduledExportItemView {
  id: number;
  key: string;
  dependencyId?: number;
}

export interface IScheduledExportItem {
  id: number;
  scheduledExportKey: string;
  views: IScheduledExportItemView[];
  visible: boolean;
  roles: number[];
}

export interface IScheduledExport {
  recurringDay: number;
  scheduledExportUserCustomerSettings: IUserCustomerScheduledExportItem[] | undefined;
}

export interface IUserCustomerScheduledExportItem {
  customerId: number;
  userId: number;
  id: number;
  scheduledExportId: number;
  enabled: boolean;
  selectedViewIds: number[];
  // scheduledExportKey: string;
}

export interface IMenuItem {
  id: number;
  menuKey: string;
  visible: boolean;
  roles: number[];
}

export interface IMigrationHistory {
  performedBy: string;
  sourceEnvironment: string;
  targetEnvironment: string;
  migrationOf: string;
  timestamp: string;
}

export enum PUSH_TYPE {
  LOCALIZATION = "Localization",
  CUSTOMIZATION = "Customization",
  DASHBOARDS = "Dashboards",
  MENUS = "Menus",
  PARAMETERS = "Parameters"
}

export enum ENVIRONMENTS {
  DEV = "Development",
  TEST = "Test",
  PROD = "Production"
}

export interface IEnvironment {
  env: ENVIRONMENTS;
  isCurrent: boolean;
}
interface IPushSettings {
  migrationHistories: IMigrationHistory[];
}
interface IMetricLoading {
  orderDeliveryPrecisionMetric?: boolean;
  itemDeliveryPrecisionMetric?: boolean;
  orderCreationDateBarsMetric?: boolean;
  orderCreationDateDonutMetric?: boolean;
  itemCreationDateBarsMetric?: boolean;
  itemCreationDateDonutMetric?: boolean;
  orderConfirmedDeliveriesMetric?: boolean;
  itemConfirmedDeliveriesMetric?: boolean;
  orderMeetRequestedDateMetric?: boolean;
  itemMeetRequestedDateMetric?: boolean;
  orderPlannedDeliveryDateMetric?: boolean;
  itemPlannedDeliveryDateMetric?: boolean;
  orderAverageLeadTimeMetric?: boolean;
  shipmentMetric?: boolean;
  orderLeadTimeMetric?: boolean;
  quantityByPlantMetric?: boolean;
  internalOrderCreationDateBarsMetric?: boolean;
  internalOrderCreationDateDonutMetric?: boolean;
  materialInventoryMetric?: boolean;
  shipmentDeliveredMetrics?: boolean;
  [key: string]: boolean | undefined;
}
interface ICountLoading {
  [key: string]: number | boolean | undefined | number[];
}

interface IMetricFilters {
  overallOrderStatusBarsCreationRange?: IDateRange;
  overallOrderStatusBarsItemsSearch?: ISearchResult;
  overallOrderStatusDonutCreationRange?: IDateRange;
  plannedDeliveryItemsSearch?: ISearchResult;
  shipmentCountCreationRange?: IDateRange;
  orderLeadTimeCreationRange: IPeriodRange;
  orderAverageLeadTimeCreationRange: IPeriodRange;
  deliveryPrecisionCreationRange?: IDateRange;
  overallInternalOrderStatusBarsCreationRange?: IDateRange;
  overallInternalOrderStatusDonutCreationRange?: IDateRange;
  materialInventorySearch?: IMaterialInventorySearchResult;
  shipmentDeliveredRange?: IDateRange;
}

export interface IConnectedCustomer {
  id: number;
  displayName: string;
  identifier: string;
  isBookmarked: number;
  bookmarkUpdating: boolean;
  adoptedModule: string | null;
  marketArea: number;
  marketAreaDesc: string;
  marketType: number;
  marketTypeDesc: string;
  [key: string]: number | string | boolean | null;
}

export interface IUniqueLoginData {
  customerId: number;
  customerDisplayName: string;
  user: string;
  role: number;
  roleDesc: string;
  visits: number;
  timestamp: string;
  isExternal: boolean;
  module: string;
  [key: string]: number | string | boolean;
}

export interface IUsageStatistics {
  module: string;
  count: number;
  isExternal: boolean;
  timestamp: string;
  customerId: number;
  customerDisplayName: string;
  [key: string]: string | number | boolean;
}
export interface IUserSessionModules {
  module: string;
  isExternal: boolean;
  userSessions: number;
  timestamp: string;
}
export interface IUsagePerAccountStatistics {
  costumerId: string;
  customerName: string;
  inAdoption: boolean;
  marketArea: string;
  userSessionModules: IUserSessionModules[];
  [key: string]: string | number | boolean | IUserSessionModules[];
}
export interface IFilters {
  metrics: IMetricFilters;
  orders: IPurchaseOrdersFilters;
  shipment: IShipmentFilters;
  internalShipment: IShipmentFilters;
  inventory: IInventoryFilters;
  itemsDeliveries: IPurchaseOrderItemsDeliveriesFilters;
  internalItemsDeliveries: IInternalPurchaseOrderItemsDeliveriesFilters;
  internalOrders: IInternalOrdersFilters;
  overViewsCollapsed: IOverViewsCollapsedStatuses;
  gridColumnsWeekFormatPreferences: IGridColumnWeekFormatPreferences;
  poItemsFilters: IPurchaseOrdersFilters;
}

// Current Serial number search.
interface ISerialNumberSearch {
  serialNumber: string;
  handlingUnitNumber: string;
  lastSearch?: SerialSearchType;
  results?: ISerialNumberSearchResult;
}

// Current Internal Serial number search.
interface IInternalSerialNumberSearch {
  serialNumber: string;
  handlingUnitNumber: string;
  lastSearch?: SerialSearchType;
  results?: IInternalSerialNumberSearchResult;
}

// Serial number search result as sent by the API.
export interface ISerialNumberSearchResult {
  purchaseOrderId: string;
  purchaseOrderNumber?: string;
  goodsMark: string;
  shipmentNumber: string;
  totalWeight: number;
  searchResult: IItemSerialContentView;
  actualShipmentEnd: Date;
}

// Internal Serial number search result as sent by the API.
export interface IInternalSerialNumberSearchResult {
  purchaseOrderNumber?: string;
  goodsMark: string;
  shipmentNumber: string;
  totalWeight: number;
  searchResult: IInternalItemSerialContentView;
  actualShipmentEnd: Date;
}

//Top-level parent of Serial numbers data.
export interface IItemSerial extends IEntity {
  serialNumber: string;
  itemNumber: string;
  itemNumber2: string;
  posEx: string;
  description: string;
  hasChildren: boolean;
  manufacturingDate: Date;
  countryOfOrigin: string;
  tracyStatus: string;
  productRevision: string;
  children: IItemSerialChildContentView;
}

//Top-level parent of Serial numbers data.
export interface IInternalItemSerial extends IEntity {
  serialNumber: string;
  itemNumber: string;
  description: string;
  hasChildren: boolean;
  manufacturingDate: Date;
  countryOfOrigin: string;
  tracyStatus: string;
  productRevision: string;
  children: IItemSerialChildContentView;
}

// Any child level of Serial numbers data.
export interface IItemSerialChild extends IEntity {
  id: string;
  childSerialNumber: string;
  productName: string;
  functionalDescription: string;
  productRevision: string;
  hasChildren: boolean;
  children: IItemSerialChildContentView;
}

export interface IItemSerialContentView {
  payload: IItemSerial[];
  properties: IViewProperty[];
}

export interface IInternalItemSerialContentView {
  payload: IInternalItemSerial[];
  properties: IViewProperty[];
}

export interface IItemSerialChildContentView {
  payload: IItemSerialChild[];
  properties: IViewProperty[];
}

export enum SerialSearchType {
  Serial,
  HandlingUnit
}

export interface ICustomTooltip {
  header?: string;
  body: string;
}

export interface IFetchingData {
  bookmarkedMaterials?: boolean;
  bomContent?: boolean;
  boms?: boolean;
  customizationOptions?: boolean;
  customization?: boolean;
  customerDashboards?: boolean;
  customerFeatures?: boolean;
  customer?: boolean;
  customerParams?: boolean;
  customerMenus?: boolean;
  customerFinancial?: boolean;
  customerProjectViewSettings?: boolean;
  customerNotifications?: boolean;
  userCustomerNotifications?: boolean;
  userCustomerNotificationsEnabled?: boolean;
  customerScheduledExports?: boolean;
  userCustomerScheduledExports?: boolean;
  userCustomerScheduledExportsEnabled?: boolean;
  adminCustomerScheduleExports?: boolean;
  scheduledExports?: boolean;
  customers?: boolean;
  externalOrders?: boolean;
  externalOrderItems?: boolean;
  inventoryNWH?: boolean;
  internalOrders?: boolean;
  selectedInternalOrderItems?: boolean;
  inventoryASP?: boolean;
  purchaseOrderItemsDeliveries?: boolean;
  purchaseOrderItemsMetrics?: boolean;
  itemDeliveriesContent?: boolean;
  internalItemDeliveriesContent?: boolean;
  internalPurchaseOrderItemsDeliveries?: boolean;
  internalPurchaseOrderItemsMetrics?: boolean;
  isBookmarkingPurchaseOrderItem?: boolean;
  isBookmarkingInternalPurchaseOrderItem?: boolean;
  internalItemOverview?: boolean;
  itemOverview?: boolean;
  localization?: boolean;
  notifications?: boolean;
  regions?: boolean;
  selectedBomOverallMaterialStatus?: boolean;
  selectedBomSingleMaterialStatus?: boolean;
  selectedShipment?: boolean;
  selectedInternalShipment?: boolean;
  selectedSiteMilestones?: boolean;
  selectedInternalOrder?: boolean;
  serialNumberSearch?: boolean;
  internalSerialNumberSearch?: boolean;
  sites?: boolean;
  shipments?: boolean;
  shipmentOverview?: boolean;
  shipmentMilestoneColumns?: boolean;
  shipmentMilestoneByShipmentNumbers?: boolean;
  shipmentMilestoneHistory?: boolean;
  internalShipmentOverview?: boolean;
  shipmentContents?: boolean;
  shipmentMaterialDetails?: boolean;
  shipmentStatus?: boolean;
  siteMaterials?: boolean;
  user?: boolean;
  userRoles?: boolean;
  users?: boolean;
  userAccessRequests?: boolean;
  marketAreas?: boolean;
  marketTypes?: boolean;
  siteIDs?: boolean;
  siteNames?: boolean;
  usageStatistics: {
    connectedCustomers?: boolean;
    apiCalls: number;
    authentications: number;
    uniqueUsage: number[];
    accountUsage: boolean;
    [key: string]: boolean | number | undefined | number[];
  };
  viewProperties: {
    ordersCustomization?: boolean;
    itemsCustomization?: boolean;
    internalOrdersCustomization?: boolean;
    shipmentSerialsCustomization?: boolean;
    serialNumberChildrenCustomization?: boolean;
  };
  metrics: IMetricLoading;
  customerDataImportHealth?: boolean;
  selectedPurchaseOrderItemsPage?: boolean;

  [key: string]: boolean | undefined | IMetricLoading | ICountLoading;
}

export interface IMarketArea {
  marketArea: number;
  marketAreaDesc: string;
}

export interface IMarketType {
  marketType: number;
  marketTypeDesc: string;
}

export interface IGridsColumnsPreferences {
  [key: string]: IGridColumnStatePreferences | undefined;
}

export interface IGridsColumnsFilters {
  [key: string]: IGridColumnsFilters | undefined;
}

export interface IServerGridsColumnsFilters {
  columnKey: string;
  filterValues: string[];
  filterText: string;
  sortIndex: number;
  sortDirection: string;
  groupIndex: number;
}

export interface IServerColumnFilterSettings {
  columnSettings: IServerGridsColumnsFilters[];
  commonFilters: IServerPurchaseOrderItemsFilter;
}
export interface IGroupModel extends ColumnVO {
  groupIndex: number | null | undefined;
}
export interface IStore {
  // current user
  user?: IUser;
  // current customer
  customer?: ICustomer;
  // all available users
  users: IUser[];
  userAccessRequests: IUserAccessRequest[];
  // all available user roles
  userRoles: IUserRole[];
  userVirtualRoles: IUserRole[];
  userDateFormats: IUserDateFormat[];
  // all available customers
  customers: ICustomer[];
  // all available countries
  countries: string[];
  // all available regions
  regions: string[];
  // all available market areas
  marketAreas: IMarketArea[];
  // all available market types
  marketTypes: IMarketType[];
  // all available siteIds
  siteIDs: string[];
  //all available site names
  siteNames: string[];
  //Filtered site ids and names
  filteredSiteIDs: string[];
  filteredSiteNames: string[];
  // active dashboards
  dashboards: IDashboardItem[];
  // active menus
  menus: IMenuItem[];
  // Metrics
  metrics: {
    // PO metric
    orderCreationDateBarsMetric: IStatusMetric[];
    orderCreationDateDonutMetric: IStatusMetric[];
    // PO items metric
    itemCreationDateBarsMetric: IStatusMetric[];
    itemCreationDateDonutMetric: IStatusMetric[];
    // Confirmed deliveries metric
    orderConfirmedDeliveriesMetric: IWeekMetric[];
    itemConfirmedDeliveriesMetric: IWeekMetric[];
    // Meet requested date metric
    orderMeetRequestedDateMetric: IWeekMetric[];
    itemMeetRequestedDateMetric: IWeekMetric[];
    // Planned deliveries metric
    orderPlannedDeliveryDateMetric: IWeekMetric[];
    itemPlannedDeliveryDateMetric: IWeekMetric[];
    // Average lead time metric
    orderAverageLeadTimeMetric: IAverageMetric[];
    // Shipment count metric
    shipmentMetric: IStatusMetric[];
    // Lead time metric
    orderLeadTimeMetric: ISeriesMetric;
    //Delivery Precision metric
    orderDeliveryPrecisionMetric: IDeliveryPrecisionMetric;
    itemDeliveryPrecisionMetric: IDeliveryPrecisionMetric;
    // Inventory metric
    quantityByPlantMetric: IPlantInventoryMetric[];
    // Internal PO metric
    internalOrderCreationDateBarsMetric: IStatusMetric[];
    internalOrderCreationDateDonutMetric: IStatusMetric[];
    // Material inventory
    materialInventoryMetric: IMaterialInventoryMetric;
    //Shipment delivered
    shipmentDeliveredMetrics: IShipmentDeliveredMetric;
  };
  metricsSearches: {
    overallOrderStatusSearchResult: ISearchResult[];
    plannedDeliverySearchResult: ISearchResult[];
    materialInventorySearchResult: IMaterialInventorySearchResult[];
  };
  dataHealth: { customerDataImport: ICustomerDataImportHealth[] };
  fetchingData: IFetchingData;
  metricsFilters: IMetricFilters;
  // orders filters
  ordersFilters: IPurchaseOrdersFilters;
  // internal orders filters
  internalOrdersFilters: IInternalOrdersFilters;
  // Server side PO items filters
  poItemsFilters: IPurchaseOrdersFilters;
  poItemsServerFilter: IServerPurchaseOrderItemsFilter;
  // All POs
  purchaseOrders: IPurchaseOrdersView;
  // All Internal POs
  internalPurchaseOrders: IInternalOrdersView;
  // Notifications
  customerNotifications: INotificationAdminItem[];
  notifications: INotificationItem[];
  userCustomerNotifications: IUserCustomerNotificationItem[];
  userCustomerNotificationsEnabled: boolean;

  // Scheduled Exports
  customerScheduledExports: IScheduledExportAdminItem[];
  adminCustomerScheduleExports: IScheduledExportItem[];
  scheduledExports: IScheduledExport;
  userCustomerScheduledExports: IScheduledExport;
  userCustomerScheduledExportsEnabled: boolean;

  // PO items deliveries
  purchaseOrderItemsDeliveries: IPurchaseOrderItemDelivery[];
  purchaseOrderItemOverview: IPurchaseOrderItemOverviewView;
  purchaseOrderItemDeliveriesContent: IPurchaseOrderItemsView;
  purchaseOrderItemsMetrics: IPurchaseOrderItemsMetricsView;
  // Internal PO items deliveries
  internalPurchaseOrderItemsDeliveries: IInternalPurchaseOrderItemDelivery[];
  internalPurchaseOrderItemOverview: IInternalPurchaseOrderItemOverviewView;
  internalPurchaseOrderItemsMetrics: IInternalPurchaseOrderItemsMetricsView;
  internalPurchaseOrderItemDeliveriesContent: IInternalPurchaseOrderItemsView;
  // PO items deliveries filters
  itemsDeliveriesFilters: IPurchaseOrderItemsDeliveriesFilters;
  internalItemsDeliveriesFilters: IInternalPurchaseOrderItemsDeliveriesFilters;
  // Statuses if overviews are collapsed or not
  overViewsCollapsedStatuses: IOverViewsCollapsedStatuses;
  // selected PO and related items
  selectedOrder?: IPurchaseOrderView;
  selectedOrderItems?: IPurchaseOrderItemsView;
  // selected Internal PO and related items
  selectedInternalOrder?: IInternalOrderView;
  selectedInternalOrderItems?: IInternalPurchaseOrderItemsView;
  // Shipments
  shipmentOverview: IShipmentOverview;
  shipmentMilestoneColumns: IShipmentMilestoneColumnItem[];
  shipmentMilestoneByShipmentNumbers: IShipmentMilestoneItem[];
  shipmentMilestoneHistory: IShipmentMilestoneItem[];
  shipmentMilestonesExportData: IShipmentMilestonesHeaderExport;
  internalShipmentOverview: IInternalShipmentOverview;
  shipments: IShipmentView;
  selectedShipment?: IShipmentDetailsView;
  selectedInternalShipment?: IInternalShipmentDetailsView;
  selectedShipmentItem?: IShipmentOverview;
  selectedShipmentStatus?: IShipmentStatus;
  selectedShipmentContents?: IShipmentContentView;
  selectedShipmentMaterialDetails?: IMaterialDetailsView;
  shipmentFilters: IShipmentFilters;
  internalShipmentFilters: IShipmentFilters;
  shipmentOverviewFilters: IShipmentOverviewFilters;
  // selected PO item material
  selectedOrderItemMaterialsDetail?: IMaterialDetailsView;
  technicalTooltip: ITechnicalTooltips;
  usageStatisticsFilters: {
    apiCallsDateRange: IDateRange;
    apiCallsGroupBy: STATISTICS_DATE_GROUPING;
    authenticationDateRange: IDateRange;
    authenticationGroupBy: STATISTICS_DATE_GROUPING;
    uniqueLoginsDateRange: IDateRange;
    usagePerAccountDateRange: IDateRange;
    usagePerAccountMarketArea: string;
    usagePerAccountUserType: string;
    adoptionRateFilter: IPeriodRange;
  };

  inventoryNWH: IInventoryNWHView;
  bookmarkedMaterials: IBookmarkedMaterials;
  inventoryFilters: IInventoryFilters;

  inventoryASP: IInventoryASPView;

  serialNumberSearch: ISerialNumberSearch;
  internalSerialNumberSearch: IInternalSerialNumberSearch;

  sites: ISiteView;
  selectedSiteMilestones?: ISiteMilestone[];
  siteMaterial: ISiteMaterialView;

  usageStatistics: {
    connectedCustomers: IConnectedCustomer[];
    apiCalls: IUsageStatistics[];
    authentications: IUsageStatistics[];
    uniqueLogins: IUniqueLoginData[];
    usagePerAccount: IUsagePerAccountStatistics[];
  };

  // BOMs
  boms: IBomView;
  bomsFilters: IBomFilters;
  bomContent: IBomContentView;
  selectedBomOverallMaterialStatus?: IBomMaterialStatus[];
  selectedBomSingleMaterialStatus?: IBomMaterialStatus[];

  // api status
  api: IApi;
  // Application settings are read from appSettings.json in the Root component.
  settings: any;
  // user preferences
  userPreferences: IUserPreferences;
  //Current environment
  environments: IEnvironment[];
  //Settings for migration between environments
  pushSettings: IPushSettings;
  walkthrough: {
    blockClickOutside?: boolean;
    continueBeingActive?: boolean;
  };
  // Grids preferences
  gridsColumnsPreferences: IGridsColumnsPreferences;
  gridsColumnsFilters: IGridColumnsFilters;
  updateGridsColumnsPreferences: boolean;
  gridColumnsWeekFormatPreferences: IGridColumnWeekFormatPreferences;

  [key: string]: any;

  selectedPurchaseOrderItemsPage: IPaginatedResponse<IPurchaseOrderItemsView>;
  serverSideFilterSettings?: IServerGridsColumnsFilters[];
  serverSideGroupModel?: IGroupModel[];
  serverSideSortModel?: SortModelItem[];
  serverSideFloatingTexts?: IFloatingFilterText[];
  serverSideFilterModel?: {
    [key: string]: any;
  };
  serverGroupState?: ColumnState[];
  serverGroupLevelState?: ServerSideGroupLevelState[];

  expandOrCollapseAll: boolean;
}
const defaultShipmentMilestonesExportData = {
  worksheet: "",
  headers: [],
  tableRows: []
};
const defaultMetricsFilters = {
  overallOrderStatusBarsCreationRange: undefined,
  overallOrderStatusDonutCreationRange: undefined,
  shipmentCountCreationRange: undefined,
  orderLeadTimeCreationRange: quarterRange,
  orderAverageLeadTimeCreationRange: quarterRange,
  deliveryPrecisionCreationRange: undefined,
  overallInternalOrderStatusBarsCreationRange: undefined,
  overallInternalOrderStatusDonutCreationRange: undefined,
  materialInventorySearch: undefined,
  shipmentDeliveredRange: undefined
};

const defaultOrdersFilters: IPurchaseOrdersFilters = {
  orderCreationRange: undefined,
  requestedDeliveryRange: undefined,
  confirmedDeliveryRange: undefined,
  plannedDeliveryRange: undefined,
  statuses: undefined,
  meetRequested: [
    {
      accessor: "yes",
      label: "Yes",
      severity: "cleared",
      selected: false,
      rule: (o: IPurchaseOrder | IPurchaseOrderItem) =>
        o.meetsRequestedDate.toString().toLowerCase() === "yes"
    },
    {
      accessor: "no",
      label: "No",
      severity: "critical",
      selected: false,
      rule: (o: IPurchaseOrder | IPurchaseOrderItem) =>
        o.meetsRequestedDate.toString().toLowerCase() === "no"
    },
    {
      accessor: "dash",
      label: "-",
      icon: "help",
      selected: false,
      rule: (o: IPurchaseOrder | IPurchaseOrderItem) =>
        o.meetsRequestedDate.toString().toLowerCase() !== "no" &&
        o.meetsRequestedDate.toString().toLowerCase() !== "yes"
    }
  ],
  onTime: [
    {
      accessor: "yes",
      label: "Yes",
      severity: "cleared",
      selected: false,
      rule: (o: IPurchaseOrder | IPurchaseOrderItem) => o.onTime.toString().toLowerCase() === "yes"
    },
    {
      accessor: "no",
      label: "No",
      severity: "critical",
      selected: false,
      rule: (o: IPurchaseOrder | IPurchaseOrderItem) => o.onTime.toString().toLowerCase() === "no"
    }
  ],
  opCo: undefined
};

const defaultServerPurchaseOrderItemsFilter: IServerPurchaseOrderItemsFilter = {
  createdAtFrom: "2023-05-14",
  createdAtTo: "2024-05-14",
  statusLabel: [],
  requestedDeliveryAtFrom: "",
  requestedDeliveryAtTo: "",
  confirmedDeliveryAtFrom: "",
  confirmedDeliveryAtTo: "",
  plannedDeliveryAtFrom: "",
  plannedDeliveryAtTo: "",
  meetsRequestedDate: [],
  opCo: []
};

const defaultInternalOrdersFilters: IInternalOrdersFilters = {
  orderCreationRange: undefined,
  statuses: [],
  requestedDeliveryRange: undefined,
  currentConfirmedDateRange: undefined,
  delayedRequestedDate: [
    {
      accessor: "NotOnTime",
      label: "Not on time",
      severity: "critical",
      selected: false,
      rule: (o: IInternalOrder | IInternalItem) => parseInt(o.delayedVsInitialRequestedDate) > 0
    },
    {
      accessor: "OnTime",
      label: "On time",
      severity: "cleared",
      selected: false,
      rule: (o: IInternalOrder | IInternalItem) =>
        o.delayedVsInitialRequestedDate.length > 0 &&
        isNaN(parseInt(o.delayedVsInitialRequestedDate))
    },
    {
      accessor: "dash",
      label: "-",
      icon: "help",
      selected: false,
      rule: (o: IInternalOrder | IInternalItem) => !o.delayedVsInitialRequestedDate
    }
  ]
};

const deliveryRecurrences: IDeliveryRecurrence[] = [
  { description: "Weekly", value: DeliveryRecurrence.Weekly, apiParam: 0 },
  { description: "Monthly", value: DeliveryRecurrence.Monthly, apiParam: 1 },
  { description: "Per quarter", value: DeliveryRecurrence.Quarterly, apiParam: 2 }
];

const defaultOverviewCollapsedStatuses: IOverViewsCollapsedStatuses = {
  poOverviewCollapsed: false,
  poItemOverviewCollapsed: false,
  internalPoOverviewCollapsed: false,
  internalPoItemOverviewCollapsed: false,
  shipmentOverviewCollapsed: false,
  internalShipmentOverviewCollapsed: false,
  nwhInventoryBookmarkOverviewCollapsed: false
};

const defaultItemsDeliveriesFilters: IPurchaseOrderItemsDeliveriesFilters = {
  orderCreationRange: undefined,
  requestedDeliveryRange: undefined,
  removeZeroQuantities: false,
  recurrence: deliveryRecurrences[0],
  periods: undefined,
  regions: [],
  siteId: "",
  siteName: ""
};

const internalDeliveryRecurrences: IInternalDeliveryRecurrence[] = [
  { description: "Weekly", value: DeliveryRecurrence.Weekly, apiParam: 0 },
  { description: "Monthly", value: DeliveryRecurrence.Monthly, apiParam: 1 },
  { description: "Per quarter", value: DeliveryRecurrence.Quarterly, apiParam: 2 }
];

const defaultInternalItemsDeliveriesFilters: IInternalPurchaseOrderItemsDeliveriesFilters = {
  orderCreationRange: undefined,
  requestedDeliveryRange: undefined,
  removeZeroQuantities: false,
  recurrence: internalDeliveryRecurrences[0],
  periods: undefined,
  regions: [],
  siteId: "",
  siteName: ""
};

const defaultShipmentFilters: IShipmentFilters = {
  shipmentCreationRange: undefined,
  plannedStartRange: undefined,
  plannedActualEndRange: undefined,
  statuses: [],
  opCo: undefined
  // length: 0
};

const defaultShipmentOverviewFilters = {
  creationRange: undefined
};

const defaultBomsFilters = {
  statuses: []
};

const storeDefault: IStore = {
  user: undefined,
  users: [],
  userAccessRequests: [],
  userRoles: [],
  userVirtualRoles: [],
  userDateFormats: [],
  customers: [],
  countries: [],
  regions: [],
  marketAreas: [],
  marketTypes: [],
  siteIDs: [],
  siteNames: [],
  filteredSiteIDs: [],
  filteredSiteNames: [],
  dashboards: [],
  menus: [],
  metrics: {
    orderCreationDateBarsMetric: [],
    orderCreationDateDonutMetric: [],
    itemCreationDateBarsMetric: [],
    itemCreationDateDonutMetric: [],
    orderConfirmedDeliveriesMetric: [],
    itemConfirmedDeliveriesMetric: [],
    orderMeetRequestedDateMetric: [],
    itemMeetRequestedDateMetric: [],
    orderPlannedDeliveryDateMetric: [],
    itemPlannedDeliveryDateMetric: [],
    orderAverageLeadTimeMetric: [],
    shipmentMetric: [],
    orderDeliveryPrecisionMetric: { onTime: 0, part: 0, total: 0 },
    itemDeliveryPrecisionMetric: { onTime: 0, part: 0, total: 0 },
    orderLeadTimeMetric: { headers: [], metrics: [] },
    quantityByPlantMetric: [],
    internalOrderCreationDateBarsMetric: [],
    internalOrderCreationDateDonutMetric: [],
    materialInventoryMetric: {
      ordered: 0,
      goodsReceivedPosted: 0,
      inventoryInNwh: 0
    },
    shipmentDeliveredMetrics: { currency: "", dataPoints: [] }
  },
  dataHealth: {
    customerDataImport: []
  },
  fetchingData: {
    viewProperties: {},
    usageStatistics: {
      apiCalls: 0,
      authentications: 0,
      uniqueUsage: [],
      accountUsage: false
    },
    IDatahealthSeverityValues: {
      // severity: "green",
      // numberofRows: number
    },
    metrics: {
      orderDeliveryPrecisionMetric: undefined,
      itemDeliveryPrecisionMetric: undefined,
      orderCreationDateBarsMetric: undefined,
      orderCreationDateDonutMetric: undefined,
      itemCreationDateBarsMetric: undefined,
      itemCreationDateDonutMetric: undefined,
      orderConfirmedDeliveriesMetric: undefined,
      itemConfirmedDeliveriesMetric: undefined,
      orderMeetRequestedDateMetric: undefined,
      itemMeetRequestedDateMetric: undefined,
      orderPlannedDeliveryDateMetric: undefined,
      itemPlannedDeliveryDateMetric: undefined,
      orderAverageLeadTimeMetric: undefined,
      shipmentMetric: undefined,
      orderLeadTimeMetric: undefined,
      quantityByPlantMetric: undefined,
      internalOrderCreationDateBarsMetric: undefined,
      internalOrderCreationDateDonutMetric: undefined,
      materialInventoryMetric: undefined,
      shipmentDeliveredMetrics: undefined
    },
    customerDataImportHealth: undefined,
    inventoryASP: undefined,
    inventoryNWH: undefined,
    bookmarkedMaterials: undefined,
    boms: undefined,
    bomContent: undefined,
    selectedBomOverallMaterialStatus: undefined,
    selectedBomSingleMaterialStatus: undefined,
    customer: undefined,
    customers: undefined,
    customerNotifications: undefined,
    userCustomerNotifications: undefined,
    userCustomerNotificationsEnabled: undefined,
    notifications: undefined,
    customerScheduledExports: undefined,
    userCustomerScheduledExports: undefined,
    userCustomerScheduledExportsEnabled: undefined,
    adminCustomerScheduleExports: undefined,
    scheduledExports: undefined,
    customerParams: undefined,
    users: undefined,
    user: undefined,
    userRoles: undefined,
    userDateFormats: undefined,
    userAccessRequests: undefined,
    localization: undefined,
    customization: undefined,
    customizationOptions: undefined,
    customerDashboards: undefined,
    regions: undefined,
    marketAreas: undefined,
    marketTypes: undefined,
    siteIDs: undefined,
    siteNames: undefined,
    filteredSiteIDs: undefined,
    filteredSiteNames: undefined,
    itemOverview: undefined,
    purchaseOrderItemsDeliveries: undefined,
    internalPurchaseOrderItemsDeliveries: undefined,
    purchaseOrderItemsMetrics: undefined,
    internalPurchaseOrderItemsMetrics: undefined,
    itemDeliveriesContent: undefined,
    internalItemDeliveriesContent: undefined,
    isBookmarkingPurchaseOrderItem: undefined,
    isBookmarkingInternalPurchaseOrderItem: undefined,
    externalOrders: undefined,
    externalOrderItems: undefined,
    internalOrders: undefined,
    selectedInternalOrder: undefined,
    selectedInternalOrderItems: undefined,
    serialNumberSearch: undefined,
    internalSerialNumberSearch: undefined,
    shipmentOverview: undefined,
    shipmentMilestoneColumns: undefined,
    shipmentMilestoneByShipmentNumbers: undefined,
    shipmentMilestoneHistory: undefined,
    shipmentMilestonesExportData: undefined,
    internalShipmentOverview: undefined,
    shipments: undefined,
    selectedShipment: undefined,
    selectedInternalShipment: undefined,
    shipmentStatus: undefined,
    shipmentContents: undefined,
    shipmentMaterialDetails: undefined,
    sites: undefined,
    selectedSiteMilestones: undefined,
    siteMaterials: undefined
  },
  metricsSearches: {
    overallOrderStatusSearchResult: [],
    plannedDeliverySearchResult: [],
    materialInventorySearchResult: []
  },
  metricsFilters: defaultMetricsFilters,
  ordersFilters: defaultOrdersFilters,
  poItemsFilters: defaultOrdersFilters,
  poItemsServerFilter: {},
  internalOrdersFilters: defaultInternalOrdersFilters,
  itemsDeliveriesFilters: defaultItemsDeliveriesFilters,
  internalItemsDeliveriesFilters: defaultInternalItemsDeliveriesFilters,
  overViewsCollapsedStatuses: defaultOverviewCollapsedStatuses,
  purchaseOrders: { payload: [], properties: [] },
  purchaseOrderGroupedItems: { payload: [], properties: [] },
  internalPurchaseOrders: { payload: [], properties: [] },
  purchaseOrderItemsDeliveries: [],
  purchaseOrderItemOverview: { payload: {}, properties: [] },
  purchaseOrderItemDeliveriesContent: { payload: [], properties: [] },
  purchaseOrderItemsMetrics: { payload: [], properties: [] },
  internalPurchaseOrderItemDeliveriesContent: { payload: [], properties: [] },
  shipmentMilestoneColumns: [],
  shipmentMilestoneByShipmentNumbers: [],
  shipmentMilestoneHistory: [],
  shipmentMilestonesExportData: defaultShipmentMilestonesExportData,
  internalPurchaseOrderItemsDeliveries: [],
  customerNotifications: [],
  userCustomerNotifications: [],
  userCustomerNotificationsEnabled: false,
  notifications: [],
  customerScheduledExports: [],
  userCustomerScheduledExports: { recurringDay: 0, scheduledExportUserCustomerSettings: [] },
  userCustomerScheduledExportsEnabled: false,
  adminCustomerScheduleExports: [],
  scheduledExports: { recurringDay: 0, scheduledExportUserCustomerSettings: [] },
  internalPurchaseOrderItemOverview: { payload: {}, properties: [] },
  internalPurchaseOrderItemsMetrics: { payload: [], properties: [] },
  selectedOrder: undefined,
  selectedGroupedItem: undefined,
  shipmentOverview: { payload: [], properties: [] },
  internalShipmentOverview: { payload: [], properties: [] },
  shipments: { payload: [], properties: [] },
  shipmentFilters: defaultShipmentFilters,
  internalShipmentFilters: defaultShipmentFilters,
  shipmentOverviewFilters: defaultShipmentOverviewFilters,
  usageStatisticsFilters: {
    apiCallsDateRange: lastTwelveMonthsDatesRange,
    apiCallsGroupBy: STATISTICS_DATE_GROUPING.MONTH,
    authenticationDateRange: lastTwelveMonthsDatesRange,
    authenticationGroupBy: STATISTICS_DATE_GROUPING.MONTH,
    uniqueLoginsDateRange: lastTwelveMonthsDatesRange,
    usagePerAccountMarketArea: "All",
    usagePerAccountUserType: "All",
    usagePerAccountDateRange: previousMonthRange,
    adoptionRateFilter: lastQuarter
  },

  inventoryNWH: { payload: [], properties: [] },
  bookmarkedMaterials: { allocated: [], nonAllocated: [] },
  inventoryFilters: {},

  inventoryASP: { payload: [], properties: [] },

  technicalTooltip: {},
  bookmarkUpdate: { uniqueIds: [] },

  serialNumberSearch: {
    serialNumber: "",
    handlingUnitNumber: ""
  },

  internalSerialNumberSearch: {
    serialNumber: "",
    handlingUnitNumber: ""
  },

  sites: {
    payload: [],
    properties: []
  },
  siteMaterial: {
    payload: [],
    properties: []
  },

  usageStatistics: {
    connectedCustomers: [],
    authentications: [],
    apiCalls: [],
    uniqueLogins: [],
    usagePerAccount: []
  },

  boms: {
    payload: [],
    properties: []
  },
  bomsFilters: defaultBomsFilters,
  bomContent: {
    payload: [],
    properties: []
  },

  api: {
    pendingReads: 0,
    pendingWrites: [],
    errors: []
  },
  settings: undefined,
  userPreferences: {
    customerId: undefined,
    useDualCalendar: true,
    technicalTooltips: false,
    notifications: false,
    scheduledExports: false
  },
  environments: [],
  pushSettings: {
    migrationHistories: []
  },
  walkthrough: {},

  gridsColumnsPreferences: {},
  gridsColumnsFilters: {},
  updateGridsColumnsPreferences: false,
  gridColumnsWeekFormatPreferences: {},
  selectedPurchaseOrderItemsPage: {
    pageNumber: 0,
    pageSize: 20,
    totalPages: 0,
    totalHits: 0,
    filterModel: [],
    data: {
      payload: [],
      properties: []
    },
    commonFilters: {}
  },
  expandOrCollapseAll: false
};

const uniqueId = () => {
  return `id-${Math.random().toString(36).substr(2, 16)}`;
};

const objectPropertyName = (obj: object, selector: (o: any) => any) => {
  const res: { [prop: string]: string } = {};
  Object.keys(obj).map(k => {
    res[k] = k;
  });
  return selector(res);
};

const apiReadingOn = (state: IStore, fetchingSelector?: (f: IFetchingData) => any) => {
  return {
    ...state,
    api: { ...state.api, pendingReads: state.api.pendingReads + 1 },
    fetchingData: fetchingSelector
      ? {
          ...state.fetchingData,
          [objectPropertyName(state.fetchingData, fetchingSelector)]: true
        }
      : state.fetchingData
  };
};

const isFetching = (state: IStore) => {
  return Object.values(state.fetchingData).reduce((res: boolean, curr) => {
    if (typeof curr === "boolean") {
      return curr || res;
    } else if (curr === undefined) {
      return res;
    } else {
      return res || Object.values(curr).some(f => f === true);
    }
  }, false);
};

const apiReadingOff = (state: IStore, fetchingSelector?: (f: IFetchingData) => any) => ({
  ...state,
  api: { ...state.api, pendingReads: state.api.pendingReads - 1 },
  fetchingData: fetchingSelector
    ? {
        ...state.fetchingData,
        [objectPropertyName(state.fetchingData, fetchingSelector)]: false
      }
    : state.fetchingData
});

const apiWritingOn = () => {
  const id = uniqueId();
  return {
    id,
    setApiWritingOn: (state: IStore, data: object | string) => ({
      ...state,
      api: { ...state.api, pendingWrites: [...state.api.pendingWrites, { id, data }] }
    })
  };
};

const apiWritingOff = (id: string, state: IStore) => ({
  ...state,
  api: { ...state.api, pendingWrites: state.api.pendingWrites.filter(w => w.id !== id) }
});

const isUniqueIdReading = (state: IStore, uniqueDataString: string) =>
  state.api.pendingWrites.some(w => w.data === uniqueDataString);

const isApiReading = (state: IStore) => state.api.pendingReads > 0;
const isApiWriting = (state: IStore) => state.api.pendingWrites.length > 0;
const isApiWritePending = <T extends {}>(state: IStore, data: T, selector: (item: T) => string) =>
  state.api.pendingWrites.find(w => selector(data) === selector(w.data as T));

const addUniqueKeyToPayload = <T extends IEntity>(view: {
  payload: T[];
  properties: IViewProperty[];
}) => ({
  ...view,
  payload: view.payload.map((p: T) => ({ ...p, uniqueKey: createGuid() }))
});

const emptyCustomer = (): ICustomer => ({
  id: "",
  dataAvailableFromDate: "",
  identifier: "",
  displayName: "",
  isHoldingCompany: false,
  useExcelTemplate: false,
  accounts: [],
  soldToPartyNumber: "",
  finalCustomerNumber: "",
  updateInterval: 0,
  localizations: [],
  statusTranslations: [],
  internalStatusTranslations: [],
  shipmentStatusTranslations: [],
  internalShipmentStatusTranslations: [],
  isUsingFinancialData: false,
  dataUpdatedAt: "",
  dbSchenkerDataUpdateAt: "",
  showFeedbackForm: false,
  forceRedirectToMobileVersion: false,
  features: {
    customer: "",
    dashboards: [],
    views: [],
    applicationSettings: {},
    //Not used yet
    financialSettings: {
      hasAccessToFinancialData: false
    }
  },
  standaloneImport: false,
  internalDeliveryStatusTranslations: [],
  adoptedModule: null
});

const { Provider: AppStoreProvider, useStore } = storeFactory<IStore>();

export {
  AppStoreProvider,
  useStore,
  storeDefault,
  isFetching,
  apiReadingOn,
  apiReadingOff,
  apiWritingOn,
  apiWritingOff,
  isApiReading,
  isApiWriting,
  isApiWritePending,
  isUniqueIdReading,
  addUniqueKeyToPayload,
  emptyCustomer,
  defaultOrdersFilters,
  defaultServerPurchaseOrderItemsFilter,
  defaultInternalOrdersFilters,
  deliveryRecurrences,
  internalDeliveryRecurrences,
  defaultShipmentFilters
};
