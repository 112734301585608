import React from "react";
import { ApplicationLayer } from "@react-gcc-eds/core";
import useAppMenu from "./menu";
import { FullScreenLoader } from "./full-screen-loader";

const AppViewLoader = () => {
  return (
    <ApplicationLayer title="" menu={useAppMenu()}>
      <FullScreenLoader />
    </ApplicationLayer>
  );
};

export default AppViewLoader;
