import React from "react";

interface Props {
  hoverWidth: number;
  hoverHeight: number;
  onMouseEnter: Function;
  onMouseLeave: Function;
  onMouseMove: Function;
  onClick: Function;
}

const HistogramBarHover = ({
  hoverWidth,
  hoverHeight,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  onClick
}: Props) => {
  return (
    <rect
      className="line-hover"
      width={hoverWidth}
      height={hoverHeight}
      strokeWidth={0}
      stroke="black"
      fill="blue"
      opacity={0}
      y={0}
      onMouseEnter={e => (onMouseEnter !== undefined ? onMouseEnter(e) : null)}
      onMouseLeave={e => (onMouseLeave !== undefined ? onMouseLeave(e) : null)}
      onMouseMove={e => (onMouseMove !== undefined ? onMouseMove(e) : null)}
      onClick={() => {
        onClick();
      }}
    ></rect>
  );
};

export default HistogramBarHover;
