import React from "react";
import { Button, Icon } from "@react-gcc-eds/core";
import { IconNames } from "@react-gcc-eds/core/lib/types/components/icon/IIcon";

const KeyBoardButton = ({ iconName, children }: { iconName?: string; children?: string }) => {
  return (
    <div className="keyboard-button">
      <div className="content">
        {iconName && <Icon name={iconName as IconNames} />}
        {children && children}
      </div>
    </div>
  );
};

const ControllerExplanation = () => {
  const arrowRightButton = <KeyBoardButton iconName="arrow-right" />;
  const arrowLeftButton = <KeyBoardButton iconName="arrow-left" />;
  const escButton = <KeyBoardButton>Esc</KeyBoardButton>;
  const nextButton = (
    <Button onClick={undefined} primary>
      Next
    </Button>
  );
  const backButton = (
    <Button onClick={undefined} primary>
      Back
    </Button>
  );
  const cross = (
    <span style={{ margin: "0 4px" }}>
      <Icon name="cross" />
    </span>
  );
  const disabledButton = (
    <Button disabled primary onClick={undefined}>
      Next
    </Button>
  );
  return (
    <div className="controller-explanation">
      <span className="explanation-row">
        You can control the walkthrough either via the buttons or your keyboard.
      </span>
      <span className="explanation-header">Go to next</span>
      <span className="explanation-row">
        Either by clicking {nextButton} or {arrowRightButton} button on your keyboard
      </span>
      <span className="explanation-header">Go to Previous</span>
      <span className="explanation-row">
        Either by clicking {backButton} or {arrowLeftButton} button on your keyboard
      </span>
      <span className="explanation-header">Close walkthrough</span>
      <span className="explanation-row">
        Exit the walkthrough at any time either via {cross} or the {escButton} button on your
        keyboard
      </span>
      <span className="explanation-header">Action needed</span>
      <span className="explanation-row">
        When {disabledButton} looks like this, it means an action is needed from you in order to
        conitnue
      </span>
    </div>
  );
};

export { ControllerExplanation };
