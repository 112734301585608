import React from "react";

export const activeFiltersIcon = (activeFilters: number, onClick?: () => void) => {
  return (
    <div
      key="active-filters"
      className={`action custom-filter ${activeFilters > 0 ? "active" : ""}`}
      onClick={() => onClick && onClick()}
    >
      <i className="icon icon-filter" />
    </div>
  );
};
