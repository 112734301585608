import React, { ReactElement, useEffect, useState } from "react";
import { NoMetrics } from "../utils";
import { Gauge } from "@react-gcc-eds/charts";
import { IDeliveryPrecisionMetric } from "../../../../store";
import { useNumberAnimation } from "../../helpers/number-animation";
import { useLocalization } from "../../../../utils/localization";

interface IGaugeLimit {
  /** The minimum value of the corresponding limit */
  from: number;
  /** The maximum value of the corresponding limit. */
  to: number;
  /** Color to denote state o severity. */
  color: "red" | "orange" | "yellow" | "green" | "gray" | "transparent" | "unknown";
  /** Label shown on the tooltip of the corresponding limit. Notice that HTML can be written within the label string parameter (taking into consideration the correct use of double quotes). */
  label: string | ReactElement;
}

export interface IGaugeSettings {
  value: number;
  decimals?: number;
  min?: number;
  max?: number;
  units?: string;
  size?: "small" | "medium" | "large";
  limits?: IGaugeLimit[];
}

export const DeliveryPrecisionGauge = ({
  settings,
  metrics,
  loading,
  onOnTimeSelected,
  onNotOnTimeSelected
}: {
  loading: boolean;
  metrics: IDeliveryPrecisionMetric;
  settings: IGaugeSettings;
  onOnTimeSelected?: (() => void) | undefined;
  onNotOnTimeSelected?: (() => void) | undefined;
}) => {
  const { localeString } = useLocalization();
  const [targetValue, setTargetValue] = useState<number>(0);
  const { value } = useNumberAnimation({
    targetValue: targetValue,
    stepSize: 0.05
  });

  useEffect(() => {
    setTargetValue(metrics.part);
  }, [metrics.part]);

  if (loading) {
    return <NoMetrics loading />;
  }
  if (metrics.part === 0) {
    return <NoMetrics />;
  }

  return (
    <div className="delivery-precision">
      <Gauge value={value} dataSettings={settings} />
      <div className="precision-data-text">
        <div className="text-container">
          <span className="data-header">Total: </span>
          <span className="data">{metrics.total}</span>
        </div>
        <div
          className={`text-container${onOnTimeSelected ? " link" : ""}`}
          onClick={onOnTimeSelected}
        >
          <span className="data-header">{localeString("OnTime")}: </span>
          <span className="data">{metrics.onTime}</span>
        </div>
        <div
          className={`text-container${onNotOnTimeSelected ? " link" : ""}`}
          onClick={onNotOnTimeSelected}
        >
          <span className="data-header">
            Not {(localeString("OnTime") || "On Time").toLocaleLowerCase()}:
          </span>
          <span className="data">{metrics.total - metrics.onTime}</span>
        </div>
      </div>
    </div>
  );
};
