import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Pill, Tile, useSettings } from "@react-gcc-eds/core";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import DatePicker from "../../../filters/date-picker";
import { defaultOrdersFilters, isFetching, useStore } from "../../../../store";
import CustomTabs, { ITab } from "../../../shared/custom-tabs";
import { DeliveryPrecisionGauge, IGaugeSettings } from "./delivery-precision-gauge";
import {
  DashboardGraph,
  DashboardTileAlignment,
  DashboardTileAlignment as TileAlignment,
  IDashboardItemSelected
} from "../index";
import { usePredefinedRanges } from "../../../filters/date-picker/predefined-ranges";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardDeliveryPrecisionSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { dateAsYMDString } from "../../../../utils";
import { activeFiltersIcon } from "..//../../filters/filters-icon";
import cx from "classnames";
import { useLocalization } from "../../../../utils/localization";

const DeliveryTimePrecision = ({
  alignment,
  className,
  ordersLabel,
  itemsLabel,
  onItemSelected
}: {
  alignment: TileAlignment;
  className?: string;
  ordersLabel: string;
  itemsLabel: string;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const [state] = useStore();
  const { theme } = useSettings();
  const tileRef = useRef<HTMLDivElement>(null);
  const { currentCustomer } = useSettingsActions();
  const { rangeAsString, largestRange, isLargestRange } = usePredefinedRanges(
    new Date(currentCustomer().dataAvailableFromDate)
  );
  const { getOrderDeliveryPrecisionMetric, getItemDeliveryPrecisionMetric } = useMetricsActions();
  const { setMetricsDeliveryPrecisionCreationRange } = useMetricsFiltersActions();
  const { localeString } = useLocalization();
  const { steps } = useDashboardDeliveryPrecisionSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const { isWalkthroughEnabled } = useSettingsActions();
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);
  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { orderDeliveryPrecisionMetric: orderMetric, itemDeliveryPrecisionMetric: itemMetric } =
    state.metrics;
  const { orderDeliveryPrecisionMetric: ordersLoading, itemDeliveryPrecisionMetric: itemsLoading } =
    state.fetchingData.metrics;
  const getTabs = useCallback(() => {
    const tabs = [];
    if (ordersLabel !== undefined) {
      tabs.push(orderTab);
    }
    if (itemsLabel !== undefined) {
      tabs.push(itemsTab);
    }
    return tabs;
  }, [ordersLoading, itemsLoading]);

  const defaultSettings = {
    decimals: 1,
    min: 0,
    max: 100,
    units: "%",
    size: "large"
  } as IGaugeSettings;

  const handleSelected = (graph: DashboardGraph, onTime: boolean): (() => void) | undefined => {
    if (!onItemSelected) {
      return undefined;
    }
    return () =>
      onItemSelected({
        graph,
        filters: {
          orderCreationRange: state.metricsFilters.deliveryPrecisionCreationRange,
          onTime: defaultOrdersFilters.onTime.map(f =>
            f.accessor === "yes" ? { ...f, selected: onTime } : { ...f, selected: !onTime }
          )
        }
      });
  };

  const orderTab = {
    view: (
      <DeliveryPrecisionGauge
        loading={ordersLoading === true}
        metrics={orderMetric}
        settings={defaultSettings}
        onOnTimeSelected={handleSelected(DashboardGraph.Orders, true)}
        onNotOnTimeSelected={handleSelected(DashboardGraph.Orders, false)}
      />
    ),
    title: ordersLabel,
    index: 0
  } as ITab;

  const itemsTab = {
    view: (
      <DeliveryPrecisionGauge
        loading={itemsLoading === true}
        metrics={itemMetric}
        settings={defaultSettings}
      />
    ),
    title: itemsLabel,
    index: 1
  } as ITab;

  const [_, setSelectedTab] = useState<ITab>(orderTab);

  const resetFilter = () => {
    setMetricsDeliveryPrecisionCreationRange(largestRange.selector());
  };

  const resetFilterPill = () => {
    return (
      <Pill className={cx("filter-pill", theme)} onToggle={resetFilter}>
        Reset filters
      </Pill>
    );
  };

  const filtersSummary = (
    <div className="dashboard-active-filter">
      <Pill>{rangeAsString(state.metricsFilters.deliveryPrecisionCreationRange)}</Pill>
      {!isLargestRange(state.metricsFilters.deliveryPrecisionCreationRange)
        ? resetFilterPill()
        : null}
    </div>
  );

  const filtersContent = () => {
    return [
      <DatePicker
        dual={state.userPreferences.useDualCalendar}
        rolling
        selectedRange={state.metricsFilters.deliveryPrecisionCreationRange}
        oldestDate={new Date(currentCustomer().dataAvailableFromDate)}
        onRangeSelected={setMetricsDeliveryPrecisionCreationRange}
        right={alignment !== TileAlignment.LEFT}
        key="date-picker"
        className="filter"
      />
    ];
  };

  const tileHeader = (
    <div className="dashboard-tile">
      {!isLargestRange(state.metricsFilters.deliveryPrecisionCreationRange)
        ? activeFiltersIcon(1)
        : null}
      <WalkthroughHeader
        walkthroughActive={isWalkthroughEnabled()}
        header={localeString("Dashboard_DeliveryPrecision") || "Delivery precision"}
        tooltipInfo="Percentage of order in chosen time period that has been delivered within contractual lead time"
        alignment={alignment}
        disabled={isFetching(state)}
        onClick={startWalkThrough}
      />
    </div>
  );

  useEffect(() => {
    if (state.metricsFilters.deliveryPrecisionCreationRange) {
      const { from, to } = state.metricsFilters.deliveryPrecisionCreationRange;

      const rangeFrom = dateAsYMDString(from);
      const rangeTo = dateAsYMDString(to);

      getOrderDeliveryPrecisionMetric(rangeFrom, rangeTo);
      getItemDeliveryPrecisionMetric(rangeFrom, rangeTo);
    }
  }, [state.metricsFilters.deliveryPrecisionCreationRange]);

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={filtersSummary}
        actions={filtersContent()}
      >
        <CustomTabs
          onTabChange={(tab: ITab) => setSelectedTab(tab)}
          tabs={getTabs()}
          defaultTabIndex={1}
        />
      </Tile>
      <WalkThrough />
    </>
  );
};

export default DeliveryTimePrecision;
