import React from "react";
import { PieData } from "./interfaces";

interface Props {
  data: PieData;
  arc: any;
  color: string;
  opacity: number;
}

const Arc = ({ data, arc, color, opacity }: Props) => {
  return <path className={`arc ${color}`} d={arc(data)} fill={color} opacity={opacity} />;
};

export default Arc;
