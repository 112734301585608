import { useEffect } from "react";

import { useStore } from "../../store";
import { useNotifications } from "@react-gcc-eds/core";
import {
  getPersistedNextDeployment,
  persistNextDeployment
} from "../../utils/local-storage/next-deployment";

const A_SECOND = 1000;
const A_MINUTE = 60;

const NextDeployment = () => {
  const [state] = useStore();
  const { createNotification } = useNotifications();

  const notifyNextDeployment = () => {
    try {
      const { settings } = state;

      if (settings.Deployment && settings.Deployment.Date) {
        const latest = getPersistedNextDeployment(state.user);

        if (latest && latest.shownAt) {
          const now = new Date();
          const latestShownAt = Date.parse(latest.shownAt);

          if (Math.abs(now.getTime() - latestShownAt) < 15 * A_SECOND * A_MINUTE) {
            // latest shown less than 15 minutes ago ... skip
            return;
          }
        }

        persistNextDeployment({ shownAt: new Date().toString() }, state.user);

        createNotification(
          `A new version will be deployed on ${settings.Deployment.Date}`,
          settings.Deployment.Content,
          "default",
          "info"
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (state.user) {
      notifyNextDeployment();
    }
  }, [state.user]);

  return null;
};

export default NextDeployment;
