import { IUser } from "../store";

export const sortedByName = (users: IUser[]) => {
  const sortBy = [
    {
      prop: "firstName",
      direction: 1
    },
    {
      prop: "lastName",
      direction: 1
    }
  ];

  return users.sort((a: IUser, b: IUser) => {
    let i = 0,
      result = 0;
    while (i < sortBy.length && result === 0) {
      result =
        sortBy[i].direction *
        ((a[sortBy[i].prop] || "").toString().toUpperCase() <
        (b[sortBy[i].prop] || "").toString().toUpperCase()
          ? -1
          : (a[sortBy[i].prop] || "").toString().toUpperCase() >
              (b[sortBy[i].prop] || "").toString().toUpperCase()
            ? 1
            : 0);
      i++;
    }
    return result;
  });
};
