import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import * as utils from "../chart/common/helpers";
import Legend from "../chart/common/components/Legend";
import Arc from "./Arc";
import HoverArc from "./HoverArc";
import Label from "./Label";
import { PieData, Data, Serie, IDonut } from "./interfaces";

const Donut = ({
  data,
  unit,
  showAbsoluteValue = false,
  showValue = false,
  onToggle,
  onClick,
  invertColorScheme = false
}: IDonut) => {
  const checkData = () => {
    if (data.series === undefined) {
      throw new Error("Series in data is missing");
    }
    data.series.forEach((s: Serie, i: number) => {
      if (s.values === undefined || s.values.length === 0) {
        throw new Error(`values in data.series[${i}] can not be empty.`);
      }
      if (s.name === undefined) {
        throw new Error(`name in data.series[${i}] can not be empty.`);
      }
      if (typeof s.values[0] !== "number") {
        throw new Error(`values in data.series[${i}] must have number.`);
      }
    });
  };
  checkData();

  const Unit = unit;
  const width = 150;
  const height = 150;

  const colorScale = utils.getColorScale(data.series.length, undefined, invertColorScheme);
  const colors = {};

  const [initialized, setInitialized] = useState(false);
  const [donutData, setDonutData] = useState({} as unknown as Data);

  useEffect(() => {
    const newDonutData = Object.assign({}, data);
    newDonutData.series = newDonutData.series.filter((s: Serie) => !s.hidden);
    setDonutData(newDonutData);
    setInitialized(true);
  }, [data, data.series]);

  data.series.forEach(d => {
    colors[d.name] = colorScale(d.name);
  });

  const allHidden = data.series.filter(d => d.hidden).length === data.series.length;

  const [highLightedArc, setHighlightedArc] = useState("");
  const [labels, setLabels] = useState({
    value: 0,
    unit: Unit,
    label: ""
  });

  const determineArcSize = (arc: number, type: "inner" | "outer") => {
    const value =
      donutData.series !== undefined &&
      donutData.series.some((v: Serie) => v.hidden !== undefined && v.hidden)
        ? 3
        : 10;
    if (type === "inner") {
      return arc + value;
    }
    if (type === "outer") {
      return arc - value;
    }
    return arc;
  };

  const size = 180;
  const radius = size / 2;

  const getTotalValue = () => {
    let total = 0;
    donutData.series.forEach(item => {
      total += item.values[0];
    });
    return total;
  };

  const getPercentage = (value: number) => {
    const total = getTotalValue();
    return ((value / total) * 100).toFixed(1) as unknown as number;
  };

  if (!initialized) return <div></div>;

  const createPie = d3
    .pie()
    .sort(null)
    // @ts-ignore
    .value((s: Serie) => s.values[0]);
  const defaultArc = d3
    .arc()
    .innerRadius(determineArcSize(radius, "inner"))
    .outerRadius(determineArcSize(radius, "outer"));
  const thinArcs = d3
    .arc()
    .outerRadius(() => radius + 5)
    .innerRadius(() => radius - 5);
  const thickArcs = d3
    .arc()
    .outerRadius(() => radius + 10)
    .innerRadius(() => radius - 10);
  const hoverArcs = d3
    .arc()
    .outerRadius(() => radius + 10)
    .innerRadius(() => 0);

  // @ts-ignore
  const pieData = createPie(donutData.series);

  const drawChart = () => {
    return (
      <svg width={width * 2} height={height * 2} className="chart">
        <g transform={`translate(${width} ${height})`} className="donut">
          <g className="labels">
            {(highLightedArc !== "" || showValue || allHidden) && (
              <Label
                value={highLightedArc !== "" || allHidden ? labels.value : getTotalValue()}
                unit={highLightedArc !== "" || allHidden ? labels.unit : Unit}
                label={highLightedArc !== "" ? labels.label : ""}
                height={height}
              />
            )}
          </g>
          <g className="active-arc">
            {
              // @ts-ignore
              pieData.map((d: PieData, i: number) => {
                const arc =
                  highLightedArc !== "" && d.data.name !== highLightedArc ? thinArcs : thickArcs;
                const opacity = highLightedArc !== "" && d.data.name !== highLightedArc ? 0.2 : 1;
                const key = i;
                return (
                  <Arc
                    key={key}
                    data={d}
                    arc={highLightedArc !== "" ? arc : defaultArc}
                    color={colors[d.data.name]}
                    opacity={opacity}
                  />
                );
              })
            }
          </g>
          <g className="hover-arc">
            {
              // @ts-ignore
              pieData.map((d: PieData, i: number) => {
                const keyindex = i;
                return (
                  <HoverArc
                    key={keyindex}
                    data={d}
                    name={d.data.name}
                    arc={hoverArcs}
                    onHover={(s: string) => {
                      setHighlightedArc(s);
                      const percentage = getPercentage(d.data.values[0]);

                      setLabels({
                        value: showAbsoluteValue ? d.value : percentage,
                        unit: showAbsoluteValue ? Unit : "%",
                        label: d.data.name
                      });
                    }}
                    onClick={(key: string, value: string) => {
                      const percentage = getPercentage(d.data.values[0]);
                      if (onClick) {
                        onClick(key, showAbsoluteValue ? value : percentage);
                      }
                    }}
                  />
                );
              })
            }
          </g>
        </g>
      </svg>
    );
  };

  return (
    <div className="eds-donut">
      <Legend
        series={data.series}
        colorScale={colors}
        onMouseHover={(s: string) => {
          setHighlightedArc(s);
          if (s !== "") {
            // @ts-ignore
            const hoveredData = pieData.find((d: PieData) => d.data.name === s);
            if (hoveredData === undefined) return;

            // @ts-ignore
            const percentage = getPercentage(hoveredData.data.values[0]);
            setLabels({
              value: showAbsoluteValue ? hoveredData.value : percentage,
              unit: showAbsoluteValue ? Unit : "%",

              // @ts-ignore
              label: hoveredData.data.name
            });
          }
        }}
        onClick={(i: number) => {
          if (onToggle) {
            const newData = utils.jsonClone(data) as Data; // Object.assign({}, data);
            newData.series[i].hidden = !newData.series[i].hidden;
            onToggle(newData, i);
            setHighlightedArc("");
          }
        }}
      />
      {drawChart()}
    </div>
  );
};

export default Donut;
