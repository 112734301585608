import React, { Component } from "react";
import { select, selectAll } from "d3-selection";
import { axisBottom, format } from "d3";
import * as utils from "../helpers";

interface AxisProps {
  xScale: any;
  tickSize: number;
  tickPadding: number;
  ticks: number;
  domain?: boolean;
  formatType?: "default" | "round" | "date";
  line?: boolean;
  width?: number;
  height?: number;
  removeLastTick?: boolean;
  tickFormat?: string;
  hideValues?: boolean;
  noLine?: boolean;
}

export default class AxisBottom extends Component<AxisProps> {
  private axisElement: SVGGElement | null;

  public componentDidMount() {
    this.renderAxis();
  }

  public componentDidUpdate() {
    this.renderAxis();
  }

  private renderAxis() {
    const {
      xScale,
      tickSize,
      tickPadding,
      ticks,
      domain,
      formatType,
      removeLastTick,
      tickFormat,
      hideValues
    } = this.props;
    const hasDomain = domain !== undefined ? domain : false;

    if (xScale !== undefined) {
      const axisConfig = axisBottom(xScale)
        .tickSize(tickSize)
        .tickPadding(tickPadding)
        .ticks(ticks);

      if (formatType !== undefined && formatType === "default") {
        axisConfig.tickFormat((d: number) => format(".2s")(d as number).replace(".0", ""));
      }

      if (tickFormat !== undefined) {
        axisConfig.tickFormat((d: number) => utils.d3Format(tickFormat, d));
      }

      select(this.axisElement).call(axisConfig);

      if (removeLastTick) {
        selectAll(".tick")
          // @ts-ignore
          .filter((a, i, list) => {
            return i === list.length - 1;
          })
          .attr("display", "none");
      }

      select(this.axisElement).selectAll(".domain").remove();
      if (hideValues) {
        select(this.axisElement).selectAll("text").remove();
      }

      if (hasDomain) {
        select(this.axisElement)
          .selectAll(".tick")
          .selectAll("line")
          .classed("solid", hasDomain)
          .attr("y1", tickSize - 10)
          .classed("zero", (d: number) => d === 0);
      } else {
        select(this.axisElement)
          .selectAll(".tick")
          .selectAll("line")
          .classed("zero", (d: number) => d === 0);
      }
    }
  }

  public render() {
    const { line, height, width, noLine } = this.props;

    return (
      <g
        transform={line ? "translate(0,0)" : ""}
        className="axis bottom"
        fontFamily="sans-serif"
        fontSize={10}
        textAnchor="middle"
        ref={(el: any) => {
          this.axisElement = el;
        }}
      >
        {height !== undefined && width !== undefined && !noLine && (
          <>
            <path
              className="customdomain"
              stroke="currentColor"
              transform="translate(0,0)"
              d={`M${width},${height}.5H0`}
            ></path>
            <path
              className="customdomain"
              stroke="currentColor"
              d={`M-8,${height}.5H0.5V0.5H-8`}
            ></path>
          </>
        )}
        {height !== undefined && width !== undefined && noLine && (
          <>
            <path
              className="customdomain"
              stroke="currentColor"
              transform="translate(0,0)"
              d={`M${width},${height}.5H0`}
            ></path>
          </>
        )}
      </g>
    );
  }
}
