import { useApi } from "./api-factory";
import { useStore, apiReadingOn, apiReadingOff } from "../store";

export const useSearchActions = () => {
  const [_, setState] = useStore();

  const { api } = useApi();

  const searchOverallOrderItems = async (query: string, from: string, to: string) => {
    try {
      setState(old => apiReadingOn(old));
      const searchResult = await api.get("v1/Search/items", {
        SearchQuery: query,
        OrderCreationStartDate: from,
        OrderCreationEndDate: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metricsSearches: {
          ...old.metricsSearches,
          overallOrderStatusSearchResult: searchResult
        }
      }));
    } catch (e) {
      setState(old => apiReadingOff(old));
    }
  };

  const searchPlannedItems = async (query: string, from: string, to: string) => {
    try {
      setState(old => apiReadingOn(old));
      const searchResult = await api.get("v1/Search/planned-item-delivery", {
        SearchQuery: query,
        PlannedDeliveryStartDate: from,
        PlannedDeliveryEndDate: to
      });
      setState(old => ({
        ...apiReadingOff(old),
        metricsSearches: {
          ...old.metricsSearches,
          plannedDeliverySearchResult: searchResult
        }
      }));
    } catch (e) {
      setState(old => apiReadingOff(old));
    }
  };

  const searchMaterial = async (query: string) => {
    try {
      setState(old => apiReadingOn(old));
      const searchResult = await api.get("v1/Search/internal-item-material", {
        SearchQuery: query
      });
      setState(old => ({
        ...apiReadingOff(old),
        metricsSearches: {
          ...old.metricsSearches,
          materialInventorySearchResult: searchResult
        }
      }));
    } catch (e) {
      setState(old => apiReadingOff(old));
    }
  };

  return { searchPlannedItems, searchOverallOrderItems, searchMaterial };
};
