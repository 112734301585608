import React, { Component } from "react";
import { select, selectAll } from "d3-selection";
import { AxisScale, AxisDomain, axisTop, format, axisRight } from "d3";
import * as utils from "../helpers";

interface AxisProps {
  xScale?: AxisScale<AxisDomain>;
  yScale?: AxisScale<AxisDomain>;
  tickSize: number;
  tickPadding: number;
  thresholds?: Thresholds[];
  fontSize: number;
  bottom?: boolean;
  left?: boolean;
  lineLength?: number;
  tickFormat?: string;
  innerTicks?: boolean;
}

interface Thresholds {
  moreThan?: number;
  lessThan?: number;
  color: string;
}

export default class AxisTresholds extends Component<AxisProps> {
  private axisElement: SVGGElement | null;

  public componentDidMount() {
    this.renderAxis();
  }

  public componentDidUpdate() {
    this.renderAxis();
  }

  private renderAxis() {
    const {
      xScale,
      yScale,
      bottom,
      left,
      thresholds,
      tickPadding,
      tickSize,
      fontSize,
      tickFormat,
      innerTicks = false
    } = this.props;

    if (thresholds !== undefined) {
      const equalsThreshold = (d: number | undefined, extraClassName: string) => {
        let threshold = "";
        thresholds.forEach(t => {
          if (d === t.moreThan || d === t.lessThan) {
            threshold = t.color;
          }
        });
        return threshold !== "" ? `${threshold} ${extraClassName}` : "";
      };

      selectAll(".tick").classed("marked-for-removal", (d: number) => !!equalsThreshold(d, ""));
      selectAll(".tick.marked-for-removal").remove();

      const thresholdTicks = thresholds.map((threshold: Thresholds) => {
        if (threshold.moreThan === undefined && threshold.lessThan === undefined) {
          return 0;
        }
        if (threshold.moreThan !== undefined) {
          return threshold.moreThan;
        }

        if (threshold.lessThan !== undefined) {
          return threshold.lessThan;
        }
        return 0;
      });

      if ((bottom && xScale !== undefined) || (!bottom && !left && xScale !== undefined)) {
        const thresholdAxisConfig = axisTop(xScale)
          .tickPadding(tickPadding)
          .tickSize(tickSize)
          .tickValues(thresholdTicks);

        if (tickFormat !== undefined) {
          thresholdAxisConfig.tickFormat((d: number) => utils.d3Format(tickFormat, d));
        } else {
          thresholdAxisConfig.tickFormat((d: number) =>
            format(".2s")(d as number).replace(".0", "")
          );
        }

        select(this.axisElement)
          .classed("axis", true)
          .classed("threshold", true)
          .call(thresholdAxisConfig);
      } else if (yScale !== undefined) {
        const thresholdAxisConfig = axisRight(yScale)
          .tickPadding(tickPadding)
          .tickSize(
            tickSize -
              (innerTicks ? utils.getStringDomLength(Math.max(...thresholdTicks).toString()) : 0)
          )
          .tickValues(thresholdTicks);

        if (tickFormat !== undefined) {
          thresholdAxisConfig.tickFormat((d: number) => utils.d3Format(tickFormat, d));
        } else {
          thresholdAxisConfig.tickFormat((d: number) =>
            format(".2s")(d as number).replace(".0", "")
          );
        }

        select(this.axisElement)
          .classed("axis", true)
          .classed("threshold", true)
          .classed("left", true)
          .call(thresholdAxisConfig);
      }

      selectAll(".domain").remove();

      selectAll(".tick")
        .selectAll("line")
        .attr("class", (d: number) => equalsThreshold(d, "thresholdElement line"));

      selectAll(".tick")
        .selectAll("text")
        .attr("font-size", fontSize)
        .attr("class", (d: number) => equalsThreshold(d, "thresholdElement text"));

      selectAll(".tick")
        .selectAll("line")
        .classed("zero", (d: number) => d === 0);
    }
  }

  public render() {
    return (
      <g
        className="axis threshold"
        fontFamily="sans-serif"
        fontSize={10}
        textAnchor="start"
        ref={el => {
          this.axisElement = el;
        }}
      />
    );
  }
}
