import * as d3 from "d3";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { PlotSerie as Serie, BubbleChartData as Data, TooltipDotItem } from "../common/interfaces";
import * as utils from "../common/helpers";
import Legend from "../common/components/Legend";
import TooltipDot from "../common/components/TooltipDot";
import Unit from "../common/components/Unit";
import IScatterPlot from "./IScatterPlot";
import DotGroups from "../common/components/DotGroups";
import MouseArea from "../common/components/MouseArea";
import Crosshair from "../common/components/Crosshair";
import AxisGroup from "../common/components/AxisGroup";
import TrendlineGroups from "../common/components/TrendlineGroups";

const ScatterPlot = ({
  data,
  onToggle,
  width = 1000,
  height = 400,
  startFromZero,
  x = {
    isLogarithmic: false
  },
  y = {
    isLogarithmic: false
  },
  r,
  diameter = 14,
  fill = false,
  hideLegend = false,
  trendline = false,
  onClick,
  parentRef,
  invertColorScheme = false
}: IScatterPlot) => {
  /** States */
  const [initialized, setInitialized] = useState(false);
  const [allHidden, setAllHidden] = useState(false);
  const [totalValuesX, setTotalValuesX] = useState([] as number[]);
  const [totalValuesY, setTotalValuesY] = useState([] as number[]);
  const [totalValuesR, setTotalValuesR] = useState([] as number[]);
  const [hoveredSerie, setHoveredSerie] = useState("");

  const [emptyChart, setEmptyChart] = useState(false);
  const [chartData, setChartData] = useState({} as unknown as Data);
  const [crosshairVisible, setCrosshairVisible] = useState(false);
  const [crosshairPosition, setCrosshairPosition] = useState({ x: 0, y: 0 });
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipDotVisible, setTooltipDotVisible] = useState(false);
  const [tooltipDotData, setTooltipDotData] = useState({
    unitTitle: "",
    unitValue: "",
    items: [] as TooltipDotItem[],
    description: "" as string | JSX.Element
  });
  const [tooltipTrendlineVisible, setTooltipTrendlineVisible] = useState(false);
  const [tooltipTrendlineData, setTooltipTrendlineData] = useState({
    unitTitle: "",
    unitValue: "",
    items: [] as TooltipDotItem[],
    description: "" as string | JSX.Element
  });
  const ref = useRef<HTMLDivElement>(null);

  const tickPadding = 10;
  const axisPadding = 12;

  /** Chart-legend */

  const heightOffset = 20;

  const legendRef = useRef<HTMLDivElement>(null);
  const defaultLegendHeight = hideLegend ? 0 : 55;
  const chartLegendHeight =
    legendRef !== null && legendRef.current !== null
      ? legendRef.current.clientHeight + heightOffset
      : defaultLegendHeight;

  /** Styling */
  const heightStyling = fill
    ? { height: "100%", width: "100%" }
    : { height: height + chartLegendHeight, width };

  const sizeFromParentRef =
    parentRef && parentRef.current
      ? {
          height: `${parentRef.current.clientHeight}px`,
          width: `${parentRef.current.clientWidth}px`
        }
      : { height: "100%", width: "100%", display: "flex" };

  /** Default dot diameter 14x14, same as icon in legend pill */

  const radius = diameter ? diameter / 2 : 0;

  /** Colors */
  const colorScale = utils.getColorScale(data.series.length, undefined, invertColorScheme);
  const colors = {};
  data.series.forEach(d => {
    colors[d.name] = colorScale(d.name);
  });

  /** Chart size */
  const [Height, setHeight] = useState(height);
  const [Width, setWidth] = useState(width);

  useEffect(() => {
    const clientWidth = ref !== null && ref.current !== null ? ref.current.clientWidth : 0;
    const clientHeight = ref !== null && ref.current !== null ? ref.current.clientHeight : 0;
    setWidth(clientWidth);
    setHeight(clientHeight - chartLegendHeight);
  });

  /** Windowresize listener */
  const [, updateState] = React.useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("toggleNavigation", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("toggleNavigation", handleResize);
    };
  }, []);

  /** Init */
  useEffect(() => {
    const willHideAll = data.series.filter((s: Serie) => s.hidden).length === data.series.length;
    const newChartData = Object.assign({}, data);

    if (newChartData.series[0].values.length === 0) {
      newChartData.series = [
        {
          name: "",
          values: [
            { name: "", x: 41.5, y: 76, r: 14.7 },
            { name: "", x: 38.1, y: 60, r: 18.8 },
            { name: "", x: 32.1, y: 70, r: 15.5 }
          ]
        }
      ];
      setEmptyChart(true);
    } else {
      setEmptyChart(false);
    }

    newChartData.series = newChartData.series.filter((s: Serie) => !s.hidden);

    const newTotalValuesX = [] as number[];
    const newTotalValuesY = [] as number[];
    const newTotalValuesR = [] as number[];

    if (willHideAll) {
      data.series.forEach((serie: Serie) => {
        serie.values.forEach((number: any) => {
          newTotalValuesX.push(number.x);
          newTotalValuesY.push(number.y);
          newTotalValuesR.push(number.r);
        });
      });
    } else {
      newChartData.series.forEach((serie: Serie) => {
        if (!serie.hidden) {
          serie.values.forEach((number: any) => {
            newTotalValuesX.push(number.x);
            newTotalValuesY.push(number.y);
            newTotalValuesR.push(number.r);
          });
        }
      });
    }

    setAllHidden(willHideAll);
    setTotalValuesX(newTotalValuesX);
    setTotalValuesY(newTotalValuesY);
    setTotalValuesR(newTotalValuesR);
    setChartData(newChartData);
    setInitialized(true);
  }, [data, data.series, allHidden]);

  /** X-axis */

  let xMin = Math.min(...totalValuesX) > 0 && startFromZero ? 0 : Math.min(...totalValuesX);
  let xMax = Math.max(...totalValuesX);

  const xMinRange = 0;
  const xMaxRange = Width - radius * 2;

  // Create temporary scale with extended range
  const xScaleTemp = x.isLogarithmic ? d3.scaleLog() : d3.scaleLinear();

  xScaleTemp.domain([xMin, xMax]).range([xMinRange, xMaxRange]);

  // Get new domain values
  xMin = xScaleTemp.invert(xMinRange);
  xMax = xScaleTemp.invert(xMaxRange);

  // Logarithmic scale can't start with 0
  if (x.startFromZero && !x.isLogarithmic) {
    xMin = xMin < 0 ? xMin : 0;
  }

  const xScale = x.isLogarithmic ? d3.scaleLog() : d3.scaleLinear();
  xScale
    .domain([xMin, xMax])
    .nice()
    .range([0, Width - 50]);

  /** Y-axis */
  let yMin = Math.min(...totalValuesY);
  let yMax = Math.max(...totalValuesY);

  const yMinRange = Height;
  const yMaxRange = 0;

  // Create temporary scale with extended range
  const yScaleTemp = y?.isLogarithmic ? d3.scaleLog() : d3.scaleLinear();
  yScaleTemp.domain([yMin, yMax]).range([yMinRange - radius, yMaxRange + radius]);

  // Get new domain values
  yMin = yScaleTemp.invert(yMinRange);
  yMax = yScaleTemp.invert(yMaxRange);

  // Logarithmic scale can't start with 0
  if (y.startFromZero && !y.isLogarithmic) {
    yMin = yMin < 0 ? yMin : 0;
  }

  const yScale = y.isLogarithmic ? d3.scaleLog() : d3.scaleLinear();
  yScale
    .domain([yMin, yMax])
    .range([yMinRange - 100, yMaxRange])
    .nice();

  /** Dots (R-axis) */
  const rMin = Math.min(...totalValuesR);
  const rMax = Math.max(...totalValuesR);

  const rScale = d3.scaleSqrt().domain([rMin, rMax]).range([radius, radius]);

  const clipPathId = Date.now().toString();
  const clipRectangle = (
    <defs>
      <clipPath id={clipPathId}>
        <rect className="clip-rectangle" y="0" width={Width} height={Height - 84}></rect>
      </clipPath>
    </defs>
  );

  return initialized ? (
    <div className="chart-container" style={heightStyling}>
      {!hideLegend && (
        <div ref={legendRef}>
          <Legend
            series={data.series}
            colorScale={colors}
            onMouseHover={(s: string) => {
              setHoveredSerie(s);
            }}
            onClick={(i: number) => {
              if (onToggle) {
                const newData = utils.jsonClone(data);
                newData.series[i].hidden = !newData.series[i].hidden;
                onToggle(newData, i);
                setHoveredSerie("");
              }
            }}
          />
        </div>
      )}
      <div ref={ref} style={!fill ? { height: "inherit" } : sizeFromParentRef}>
        <svg className="chart" style={{ height: "100%", width: "100%", display: "flex" }}>
          <g transform="translate(45,38)" className="canvas bubble-chart">
            <AxisGroup
              xScale={xScale}
              yScale={yScale}
              x={x}
              y={y}
              tickPadding={tickPadding}
              width={Width}
              height={Height - 75}
              axisPadding={axisPadding}
              hideValues={emptyChart}
            />
            {x !== undefined && x.unit !== undefined && (
              <Unit x={Width + 40 - (x.unit.length * 8) / 1.5} y={Height - 43} unit={x.unit} />
            )}
            <Unit x={5} y={-20} unit={y !== undefined ? y.unit : ""} textAnchor="middle" />
            {!emptyChart && (
              <Crosshair
                x={crosshairPosition.x}
                y={crosshairPosition.y}
                xScale={xScale}
                yScale={yScale}
                width={Width}
                height={Height - 85}
                visible={crosshairVisible}
                dotHover={tooltipDotVisible}
              />
            )}
            <MouseArea
              height={Height - 85}
              width={Width}
              onMouseMove={(event: React.MouseEvent) => {
                setCrosshairVisible(true);
                const currentTargetRect = event.currentTarget.getBoundingClientRect();
                setCrosshairPosition({
                  x: event.clientX - currentTargetRect.left,
                  y: event.clientY - currentTargetRect.top
                });
              }}
              onMouseLeave={() => {
                setCrosshairVisible(false);
              }}
              onClick={() => {}}
            />
            {trendline && (
              <>
                {clipRectangle}
                <TrendlineGroups
                  clipPath={clipPathId}
                  data={chartData}
                  xMin={xMin}
                  xMax={xMax}
                  yScale={yScale}
                  xScale={xScale}
                  colorScale={colors}
                  hoveredSerie={hoveredSerie}
                  onHover={(
                    e: React.MouseEvent<SVGLineElement, MouseEvent>,
                    label: string,
                    tooltipItems: TooltipDotItem[],
                    index: number,
                    onHover: boolean,
                    hoveredTrendline: string
                  ) => {
                    if (e !== undefined && onHover) {
                      setTooltipTrendlineVisible(true);
                      setTooltipDotVisible(false);
                      setTooltipTrendlineData({
                        unitTitle: label,
                        unitValue: "",
                        items: tooltipItems,
                        description:
                          data.descriptions !== undefined && index < data.descriptions.length
                            ? data.descriptions[index]
                            : ""
                      });
                      setCrosshairVisible(false);
                      setTooltipPosition({ x: e.clientX, y: e.clientY });
                      setHoveredSerie(hoveredTrendline);
                    } else {
                      setTooltipTrendlineVisible(false);
                      setHoveredSerie("");
                    }
                  }}
                  onHoverLeave={() => {
                    setTooltipTrendlineVisible(false);
                  }}
                />
              </>
            )}
            {!emptyChart && (
              <DotGroups
                data={chartData}
                yScale={yScale}
                xScale={xScale}
                rScale={rScale}
                yUnit={y !== undefined ? y.unit : ""}
                xUnit={x !== undefined ? x.unit : ""}
                rUnit={r !== undefined ? r.unit : ""}
                colorScale={colors}
                hoveredSerie={hoveredSerie}
                onHover={(
                  e: React.MouseEvent<SVGLineElement, MouseEvent>,
                  label: string,
                  tooltipItems: TooltipDotItem[],
                  index: number,
                  onHover: boolean,
                  hoveredDot: string
                ) => {
                  const hoverCoords = Object.keys(tooltipItems[0].subItems).map(
                    item => tooltipItems[0].subItems[item].value
                  );
                  if (e !== undefined && onHover) {
                    setTooltipDotVisible(true);
                    setTooltipDotData({
                      unitTitle: label,
                      unitValue: "",
                      items: tooltipItems,
                      description:
                        data.descriptions !== undefined && index < data.descriptions.length
                          ? data.descriptions[index]
                          : ""
                    });
                    setCrosshairVisible(true);

                    setCrosshairPosition({
                      x: xScale(hoverCoords[0]),

                      y: yScale(hoverCoords[1])
                    });
                    setTooltipPosition({ x: e.clientX, y: e.clientY });
                    setHoveredSerie(hoveredDot);
                  } else {
                    setTooltipDotVisible(false);
                    setHoveredSerie("");
                  }
                }}
                onHoverLeave={() => {
                  setTooltipDotVisible(false);
                }}
                onClick={(l: string, v: number[]) => onClick && onClick(l, v)}
              />
            )}
          </g>
        </svg>
        <TooltipDot
          colors={colors}
          unitTitle={tooltipDotData.unitTitle}
          unitValue={tooltipDotData.unitValue}
          description={tooltipDotData.description}
          items={tooltipDotData.items}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          visible={tooltipDotVisible}
        />
        <TooltipDot
          colors={colors}
          unitTitle={tooltipTrendlineData.unitTitle}
          unitValue={tooltipTrendlineData.unitValue}
          description={tooltipTrendlineData.description}
          items={tooltipTrendlineData.items}
          x={tooltipPosition.x}
          y={tooltipPosition.y}
          visible={tooltipTrendlineVisible}
        />
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default ScatterPlot;
