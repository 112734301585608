import React, { useState } from "react";
import { Data, Serie, Colors, Thresholds } from "../interfaces";
import VerticalBarGroup from "./VerticalBarGroup";
import * as utils from "../helpers";

interface Props {
  data: Data;
  thresholds?: Thresholds[];
  x0: d3.ScaleBand<string>;
  x1: d3.ScaleBand<string>;
  y: d3.ScaleLinear<number, number>;
  colorScale: Colors;
  single?: boolean;
  grouped?: boolean;
  stacked?: boolean;
  largeBars?: boolean;
  height: number;
  hoveredSerie: string;
  onHover: Function;
  onClick: Function;
  onClickProvided: boolean;
  tooltipFormat?: string;
}

const VerticalBarGroups = ({
  data,
  thresholds,
  x0,
  x1,
  y,
  colorScale,
  single,
  grouped,
  stacked,
  largeBars,
  height,
  hoveredSerie,
  onHover,
  onClick,
  onClickProvided,
  tooltipFormat
}: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const defaultOpacity = hoveredIndex === -1 ? 1 : 0.2;

  const stackedChartData = {};
  data.common.forEach((label, i) => {
    const prevValues = [] as number[];
    const dataForLabel = data.series.map((s, k) => {
      const getPrevious = () => {
        if (k === 0) {
          return 0;
        }
        prevValues.push(data.series[k - 1].values[i]);
        return prevValues.reduce((a, b) => a + b);
      };
      return {
        key: s.name,
        value: s.values[i],
        previous: getPrevious()
      };
    });
    stackedChartData[label] = dataForLabel;
  });

  return (
    <>
      {data.common.map((label: string, index: number) => {
        const valuesForLabel = data.series.map((serie: Serie) => {
          return serie.values.length >= index ? serie.values[index] : 0;
        });
        const key = index;
        return (
          valuesForLabel.length > 0 && (
            <VerticalBarGroup
              key={key}
              data={data}
              thresholds={thresholds}
              label={label}
              stackedChartData={stackedChartData}
              x0={x0}
              x1={x1}
              y={y}
              single={single}
              grouped={grouped}
              stacked={stacked}
              largeBars={largeBars}
              colorScale={colorScale}
              value={data.series[0].values[index]}
              height={height}
              onMouseHover={(
                isHovered: boolean,
                e: React.MouseEvent<SVGLineElement, MouseEvent>
              ) => {
                if (isHovered) {
                  setHoveredIndex(index);
                } else {
                  setHoveredIndex(-1);
                }

                const items = data.series.map(d => {
                  return {
                    key: d.name,
                    value: utils.d3Format(
                      tooltipFormat !== undefined ? tooltipFormat : "Default",
                      d.values[index]
                    )
                  };
                });
                onHover(e, label, items, index);
              }}
              opacity={hoveredIndex === index ? 1 : defaultOpacity}
              hoveredSerie={hoveredSerie}
              onClick={(l: string, v: number[]) => {
                onClick(l, v);
              }}
              onClickProvided={onClickProvided}
            />
          )
        );
      })}
    </>
  );
};
export default VerticalBarGroups;
