import React from "react";
import { parseISO, format, isEqual, addMinutes, parse, getWeek, getYear } from "date-fns";

export const copyToClipboard = (
  e: React.MouseEvent | React.KeyboardEvent,
  copyValue: string,
  onSuccess?: Function
) => {
  e.preventDefault();
  e.stopPropagation();

  const textArea = document.createElement("textarea");
  textArea.value = copyValue;
  textArea.style.position = "fixed"; //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
    onSuccess && onSuccess();
  } catch (err) {
    console.error("Copy to clipboard failed", err);
  }

  document.body.removeChild(textArea);
};

export const isEmail = (email: string) => {
  if (!email) {
    return true;
  }
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const isExternalEmail = (email: string) => {
  if (!email || !isEmail(email)) {
    return false;
  }
  return email.split("@")[1].toLowerCase() !== "ericsson.com";
};

export const isSignum = (signum: string) => {
  return /[A-Za-z]\w{4,}/.test(signum);
};

export const isUrl = (s: string) => {
  const match =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/gm;
  return match.test(s);
};

export const getIEVersion = () => {
  const sAgent = window.navigator.userAgent;
  const Idx = sAgent.indexOf("MSIE");

  // If IE, return version number.
  if (Idx > 0) return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
  // If IE 11 then look for Updated user agent string.
  else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
  else return 0; //It is not IE
};

export const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    window.navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

export const splitOnUpperCase = (s: string): string => {
  if (!s) {
    return "";
  }
  return s.split(/(?=[A-Z])/).join(" ");
};

export const upperCaseInitial = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const lowerCaseInitial = (s: string) => {
  if (!s) {
    return "";
  }
  // Any capital before first non-capital (except last) is set to lower case
  // Rest of the string is kept unchanged
  // Initial char is then set lower case
  // ShipToPartyID => shipToPartyID
  // WBSID => wbsid
  // WBSName = wbsName
  // UniquePOItems => uniquePOItems
  let lowerCaseFound = false;
  let result = "";
  for (let i = 0; i < s.length; i++) {
    if (s[i] === s[i].toLowerCase()) {
      lowerCaseFound = true;
    }
    if (
      !lowerCaseFound &&
      s[i] === s[i].toUpperCase() &&
      (i === s.length - 1 || s[i + 1] === s[i + 1].toUpperCase())
    ) {
      result = result + s[i].toLowerCase();
    } else {
      result = result + s[i];
    }
  }
  return result.charAt(0).toLowerCase() + result.slice(1);
};

export const numericalSorter =
  <T extends { [key: string]: any }>(selector: (item: T) => any) =>
  (a: T, b: T) =>
    selector(a) - selector(b);

export const stringSorter =
  <T extends { [key: string]: any } | number | string>(selector: (item: T) => any) =>
  (a: T, b: T) => {
    if (selector(a) > selector(b)) return 1;
    if (selector(b) > selector(a)) return -1;
    return 0;
  };

export const leadingZero = (week: string | number) => {
  return `${(week as number) < 10 ? "0" : ""}${week}`;
};

export const numberAsString = (number: string, thousandSeparator?: string) =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSeparator || ","}`);

export const dateAsYMDString = (d: Date) => format(d, "yyyy-MM-dd");

export const dateTimeFormatter = (date: string, dateFormat?: string) => {
  const dateTime = parseISO(date || "");

  if (!dateTime || isEqual(dateTime, parseISO("0001-01-01T00:00:00")) || !dateFormatter) {
    return "";
  }

  try {
    const dateValue = format(dateTime, `${dateFormat} HH:mm`);
    return dateValue;
  } catch {
    return "";
  }
};

export const dateFormatter = (value: string, dateFormat?: string, weekFormat?: boolean) => {
  if (!value || !dateFormat) return value;

  try {
    let dateValue = parse(value, dateFormat, new Date());
    if (dateValue.toString() === "Invalid Date") {
      dateValue = parse(value, "yyyy/MM/dd", new Date());
    }
    if (dateValue.toString() === "Invalid Date") {
      dateValue = parse(value, "yyyy-MM-dd", new Date());
    }

    if (weekFormat) {
      let weekValue;

      if (dateFormat === "yyyy/MM/dd") {
        weekValue = format(dateValue, "yyyy/'W'II", { weekStartsOn: 1 });
      } else {
        weekValue = format(dateValue, "'W'II/yyyy", { weekStartsOn: 1 });
      }
      return weekValue;
    }

    const timeZoneDate = format(dateValue, `${dateFormat}`);
    return timeZoneDate;
  } catch {
    return value;
  }
};

export const createGuid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const formatFinancialValuesPerThousands = (value: any) => {
  if (!value) return "" as string;

  const [integerPart, fractionalPart] = value.toString().split(".");

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const formattedNumber = fractionalPart
    ? `${formattedIntegerPart}.${fractionalPart}`
    : formattedIntegerPart;

  return formattedNumber as string;
};
