import { useEffect } from "react";

import { useStore } from "../../store";
import { useNotifications } from "@react-gcc-eds/core";

const StartupMessage = () => {
  const [state] = useStore();
  const { createNotification } = useNotifications();

  const notifyStartupMessage = () => {
    try {
      const { settings } = state;

      if (
        settings.StartupMessage &&
        (settings.StartupMessage.Title || settings.StartupMessage.Message)
      ) {
        createNotification(
          settings.StartupMessage.Title || "",
          settings.StartupMessage.Message || "",
          "default",
          "info"
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (state.user) {
      notifyStartupMessage();
    }
  }, [state.user]);

  return null;
};

export default StartupMessage;
