import { useEffect, useState } from "react";

import { Button, Dialog, ITabItem, Loader, Tab, Tabs, Tooltip } from "@react-gcc-eds/core";
import NotificationsTab from "./tabs/notifications-tab";
import BpoTab from "./tabs/bpo-tab";
import BipoTab from "./tabs/bipo-tab";
import { useNotificationActions } from "../../../actions/notification-actions";
import { INotificationItem, IUserCustomerNotificationItem, useStore } from "../../../store";
import { ConfirmDialog } from "../../../components/shared/confirm-dialog";
// import { useFeedback } from "./feedback-actions";
// import { useSupport } from "./support-actions";

export enum EViews {
  SUPPORT = 0,
  FEEDBACK = 1
}

enum NOTIFICATION_TAB {
  NOTIFICATIONS,
  BPO,
  BIPO
}
enum Operation {
  Cancel,
  Save,
  Delete
}
const areEqual = (a?: INotificationItem[], b?: INotificationItem[]) => {
  if (!a || !b) return false;
  return JSON.stringify(a) === JSON.stringify(b);
};

const NotificationsDialog = ({
  customerIdentifier,
  onClose,
  typeOfForm,
  onFrequencyChanged,
  onNotificationScopeChanged,
  onDayChanged,
  notificationsList,
  adminCustomerNotificationsList,
  onNotificationsEnabled,
  viewChanged,
  onSave
}: {
  customerIdentifier: string;
  onClose: Function;
  typeOfForm: EViews;
  onFrequencyChanged: (i: number) => void;
  onNotificationScopeChanged: (value: number, item?: IUserCustomerNotificationItem) => void;
  onDayChanged: (i: number) => void;
  notificationsList: {
    current: IUserCustomerNotificationItem[];
    original: IUserCustomerNotificationItem[];
    equals: boolean;
  };
  adminCustomerNotificationsList: INotificationItem[];
  onNotificationsEnabled: (key: number, value: boolean) => void;
  viewChanged: boolean;
  onSave: () => void;
}) => {
  const { getUserCustomerNotificationsList } = useNotificationActions();
  const [currentView, setCurrentView] = useState<EViews>(EViews.FEEDBACK);
  const [selectedTab, setSelectedTab] = useState<NOTIFICATION_TAB>(NOTIFICATION_TAB.NOTIFICATIONS);
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [selectedFrequency, setFrequency] = useState<string>("");
  const [state] = useStore();
  const [notifications, setNotificationsList] = useState<{
    current: INotificationItem[];
    original: INotificationItem[];
    equals: boolean;
  }>({ current: [], original: [], equals: true });
  const [confirm, setConfirm] = useState({ active: false, operation: Operation.Cancel });
  const viewUntouched = viewChanged;
  const buttons = () => {
    return [
      <>
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          primary
          key="submit"
          onClick={() => setConfirm({ active: true, operation: Operation.Save })}
          disabled={viewUntouched}
        >
          Save
        </Button>
      </>
    ];
  };

  const renderTab = () => {
    switch (selectedTab) {
      case NOTIFICATION_TAB.BPO:
        return <BpoTab />;
      case NOTIFICATION_TAB.BIPO:
        return <BipoTab />;
      default:
        return (
          notificationsList && (
            <NotificationsTab
              onDayChanged={onDayChanged}
              onFrequencyChanged={onFrequencyChanged}
              onNotificationScopeChanged={onNotificationScopeChanged}
              notifications={notificationsList}
              adminNotifications={adminCustomerNotificationsList}
              onNotificationEnabled={onNotificationsEnabled}
            />
          )
        );
    }
  };
  const handleSave = async () => {
    onSave();
  };

  useEffect(() => {
    if (confirm.operation === 1) onClose();
  }, [state.fetchingData.userCustomerNotifications]);

  const handleCancel = async () => {
    setNotificationsList({
      current: notifications.original,
      original: notifications.original,
      equals: true
    });
  };
  const confirmParams = [
    {
      title: "Discard updates?",
      body: "This will discard any updated settings and revert back to previous saved settings.",
      buttonContent: "Discard updates",
      buttonType: "warning",
      handle: handleCancel
    },
    {
      title: "Save changes?",
      body: "This will save and update the current settings. Any previous saved settings will be overwritten.",
      buttonContent: "Save",
      buttonType: "primary",
      handle: handleSave
    }
  ];
  const tabs: ITabItem[] = [
    {
      title: "Notifications",
      onSelected: () => setSelectedTab(NOTIFICATION_TAB.NOTIFICATIONS)
    },
    {
      title: "Bookmarked items purchase order",
      onSelected: () => setSelectedTab(NOTIFICATION_TAB.BPO),
      disabled: true
    },
    {
      title: "Bookmars items internal purchase order",
      onSelected: () => setSelectedTab(NOTIFICATION_TAB.BIPO),
      disabled: true
    }
  ];

  return (
    <Dialog title="E-mail notifications" buttons={buttons()} className={"notifications-dialog"}>
      <span className="sub-title">
        I want to recieve e-mail notifications for the following events
      </span>
      <Tabs tabs={tabs} />
      {renderTab()}
      <ConfirmDialog
        confirmCondition={confirm.active}
        messageTitle={confirmParams[confirm.operation].title}
        messageBody={confirmParams[confirm.operation].body}
        onProceedButton={
          <Button
            key="proceed"
            primary={confirmParams[confirm.operation].buttonType === "primary"}
            warning={confirmParams[confirm.operation].buttonType === "warning"}
            onClick={() => {
              setConfirm({ active: true, operation: Operation.Save });
              confirmParams[confirm.operation].handle();
            }}
          >
            {confirmParams[confirm.operation].buttonContent}
          </Button>
        }
        onCancel={() => {
          setConfirm({ active: false, operation: Operation.Cancel });
        }}
      />
    </Dialog>
  );
};

export default NotificationsDialog;
