import React, { useState } from "react";

import { IDateRange } from "../../../contracts";

import Header from "./header";
import PredefinedRangeSelector from "./predefined-range-selector";
import Calendar from "./calendar";

import { useClickOutside } from "./hooks";

const DatePicker = ({
  dual,
  right,
  rolling,
  selectedRange,
  oldestDate,
  onRangeSelected,
  label,
  className
}: {
  dual: boolean;
  right?: boolean;
  rolling?: boolean;
  selectedRange?: IDateRange;
  oldestDate?: Date;
  onRangeSelected(r: IDateRange): void;
  label?: string;
  className?: string;
}) => {
  const [opened, setOpened] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [hoveredDate, setHoveredDate] = useState<Date>();

  const calendarContainerRef = useClickOutside(() => setOpened(false));

  const resetSelections = () => {
    setSelectedDate(undefined);
    setHoveredDate(undefined);
    setOpened(false);
  };

  const handleDateSelected = (d: Date) => {
    if (!selectedDate) {
      setSelectedDate(d);
    } else {
      onRangeSelected(
        selectedDate < d
          ? { from: selectedDate, to: d, reference: rolling ? undefined : new Date() }
          : { from: d, to: selectedDate, reference: rolling ? undefined : new Date() }
      );

      resetSelections();
    }
  };

  const handleDateHovered = (d: Date) => {
    setHoveredDate(d);
  };

  const handleRangeSelected = (range: IDateRange) => {
    onRangeSelected(range);

    resetSelections();
  };

  return (
    <div className={className ? className : ""}>
      {label ? <label>{label}</label> : undefined}
      <div ref={calendarContainerRef} className="date-picker">
        <Header
          tooltip={opened ? "Close calendar" : "Open calendar"}
          onClick={() => setOpened(!opened)}
          onRangeSelected={handleRangeSelected}
          selectedRange={selectedRange}
          oldestDate={oldestDate}
        />
        {opened && (
          <div className={`calendar opened ${dual ? "dual" : ""} ${right ? "right" : ""}`}>
            <PredefinedRangeSelector
              selectedRange={selectedRange}
              onRangeSelected={handleRangeSelected}
              oldestDate={oldestDate}
              rolling={rolling}
            />
            <Calendar
              dual={dual}
              selectedDate={selectedDate}
              hoveredDate={hoveredDate}
              onDateSelected={handleDateSelected}
              onDateHovered={handleDateHovered}
              selectedRange={selectedRange}
              oldestDate={oldestDate}
              rolling={rolling}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
