import { defaultOrdersFilters, IPillFilter, IPropertyFilter, useStore } from "../store";
import { IDateRange } from "../contracts";

export interface IExternalOrdersRedirectionFilters {
  orderCreationRange?: IDateRange;
  requestedDeliveryRange?: IDateRange;
  confirmedDeliveryRange?: IDateRange;
  calculatedConfirmRange?: IDateRange;
  status?: number;
  meetRequested?: IPillFilter[];
  opCo?: string[];
  onTime?: IPillFilter[];
  property?: IPropertyFilter;
}

export const useOrdersFiltersActions = () => {
  const [_, setState] = useStore();

  const setOrdersCreationRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      ordersFilters: { ...old.ordersFilters, orderCreationRange: range }
    }));
  };

  const setOrdersRequestedDeliveryRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      ordersFilters: { ...old.ordersFilters, requestedDeliveryRange: range }
    }));
  };

  const setOrdersConfirmedDeliveryRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      ordersFilters: { ...old.ordersFilters, confirmedDeliveryRange: range }
    }));
  };

  const setOrdersPlannedDeliveryRange = (range: IDateRange) => {
    setState(old => ({
      ...old,
      ordersFilters: { ...old.ordersFilters, plannedDeliveryRange: range }
    }));
  };

  const setOrdersStatus = (status: number) => {
    const newStatus = (old: number[], status: number) => {
      if (old.includes(status)) {
        return old.filter(s => s !== status);
      } else {
        return [...old, status];
      }
    };

    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        statuses: newStatus(old.ordersFilters.statuses || [], status)
      }
    }));
  };

  const setOrdersMeetRequested = (filter: IPillFilter[]) => {
    setState(old => ({
      ...old,
      ordersFilters: { ...old.ordersFilters, meetRequested: filter }
    }));
  };

  const setOrdersOnTime = (filter: IPillFilter[]) => {
    setState(old => ({
      ...old,
      ordersFilters: { ...old.ordersFilters, onTime: filter }
    }));
  };

  const setOrdersOpCo = (opCo: string[]) => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        opCo: opCo.length ? opCo : undefined
      }
    }));
  };

  const setOrdersRedirectionFilters = (filters: IExternalOrdersRedirectionFilters) => {
    const {
      orderCreationRange,
      requestedDeliveryRange,
      confirmedDeliveryRange,
      status,
      meetRequested,
      opCo,
      onTime
    } = filters;
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        orderCreationRange: orderCreationRange,
        requestedDeliveryRange,
        confirmedDeliveryRange,
        statuses: status === undefined ? undefined : [status],
        meetRequested: meetRequested || defaultOrdersFilters.meetRequested,
        onTime: onTime || defaultOrdersFilters.onTime,
        opCo
      }
    }));
  };

  const clearOrdersRequestedDeliveryRange = () => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        requestedDeliveryRange: undefined
      }
    }));
  };

  const clearOrdersConfirmedDeliveryRange = () => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        confirmedDeliveryRange: undefined
      }
    }));
  };

  const clearOrdersPlannedDeliveryRange = () => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        plannedDeliveryRange: undefined
      }
    }));
  };

  const clearOrdersStatus = () => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        statuses: undefined
      }
    }));
  };

  const clearOrdersMeetRequested = () => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        meetRequested: defaultOrdersFilters.meetRequested
      }
    }));
  };

  const clearOrdersOnTime = () => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        onTime: defaultOrdersFilters.onTime
      }
    }));
  };

  const clearOrdersOpCo = () => {
    setState(old => ({
      ...old,
      ordersFilters: {
        ...old.ordersFilters,
        opCo: undefined
      }
    }));
  };

  return {
    setOrdersCreationRange,
    setOrdersRequestedDeliveryRange,
    setOrdersConfirmedDeliveryRange,
    setOrdersPlannedDeliveryRange,
    setOrdersStatus,
    setOrdersMeetRequested,
    setOrdersOnTime,
    setOrdersOpCo,
    setOrdersRedirectionFilters,
    clearOrdersRequestedDeliveryRange,
    clearOrdersConfirmedDeliveryRange,
    clearOrdersPlannedDeliveryRange,
    clearOrdersStatus,
    clearOrdersMeetRequested,
    clearOrdersOnTime,
    clearOrdersOpCo
  };
};
