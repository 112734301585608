import {
  apiReadingOff,
  apiReadingOn,
  apiWritingOff,
  apiWritingOn,
  isApiWritePending,
  IFilters,
  IUserPreferences,
  useStore,
  IGridsColumnsPreferences
} from "../store";
import { useApi } from "./api-factory";

const PREFERENCES_KEY = "Preferences";
const FILTERS_KEY = "Filters";
const GRIDS_KEY = "Grids";

export const useUserPreferencesActions = () => {
  const [state, setState] = useStore();

  const { api } = useApi({ includeCustomer: false });

  const postDelay = () =>
    isApiWritePending<string>(state, PREFERENCES_KEY, (data: string) => data) ||
    isApiWritePending<string>(state, FILTERS_KEY, (data: string) => data)
      ? 500
      : 0;

  const delayedPost = (func: Function) => {
    setTimeout(async () => {
      func();
    }, postDelay());
  };

  const getPersistedUserPreferences = async (): Promise<IUserPreferences> => {
    const defaultPreferences: IUserPreferences = {
      theme: "light",
      useDualCalendar: true,
      technicalTooltips: false,
      notifications: false,
      scheduledExports: false
    };
    try {
      setState(old => apiReadingOn(old));
      const preferences = await api.get(`v1/Settings/usersettings/${PREFERENCES_KEY}`);
      setState(old => apiReadingOff(old));
      return preferences
        ? { ...JSON.parse(preferences), useDualCalendar: true }
        : defaultPreferences;
    } catch (e) {
      setState(old => apiReadingOff(old));
      return defaultPreferences;
    }
  };

  const persistUserPreferences = async (preferences: IUserPreferences) => {
    if (!preferences) {
      return;
    }
    delayedPost(async () => {
      const { id, setApiWritingOn } = apiWritingOn();
      try {
        setState(old => setApiWritingOn(old, PREFERENCES_KEY));
        await api.post("v1/Settings/usersettings", {
          key: PREFERENCES_KEY,
          value: JSON.stringify(preferences)
        });
        setState(old => ({ ...apiWritingOff(id, old), userPreferences: preferences }));
      } catch (e) {
        setState(old => apiWritingOff(id, old));
      }
    });
  };

  const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

  const parser = (key: string, value: any) => {
    if (typeof value === "string" && dateFormat.test(value)) {
      return new Date(value);
    }

    return value;
  };

  const getPersistedUserFilters = async (
    customerId: string,
    customerIdentifier: string
  ): Promise<IFilters | undefined> => {
    try {
      api.setCustomer(customerIdentifier);
      setState(old => apiReadingOn(old));
      const filters = await api.get(`v1/Settings/usersettings/${FILTERS_KEY}/${customerId}`);
      setState(old => apiReadingOff(old));
      if (filters) {
        return JSON.parse(filters, parser);
      }

      return undefined;
    } catch (e) {
      setState(old => apiReadingOff(old));
      return undefined;
    }
  };

  const persistUserFilters = async (
    customerId: string,
    customerIdentifier: string,
    filters: IFilters
  ) => {
    if (!filters) {
      return;
    }

    delayedPost(async () => {
      api.setCustomer(customerIdentifier);
      const { id, setApiWritingOn } = apiWritingOn();
      try {
        setState(old => setApiWritingOn(old, FILTERS_KEY));
        await api.post(`v1/Settings/usersettings/${customerId}`, {
          key: FILTERS_KEY,
          value: JSON.stringify(filters)
        });
        setState(old => apiWritingOff(id, old));
      } catch (e) {
        setState(old => apiWritingOff(id, old));
      }
    });
  };

  const deleteUserFilters = async (customerIdentifier: string) => {
    api.setCustomer(customerIdentifier);
    const { id, setApiWritingOn } = apiWritingOn();
    try {
      setState(old => setApiWritingOn(old, FILTERS_KEY));
      await api.remove(`v1/Settings/usersettings/all/${FILTERS_KEY}`);
      setState(old => apiWritingOff(id, old));
    } catch (e) {
      setState(old => apiWritingOff(id, old));
    }
  };

  const getPersistedGridsColumnsPreferences = async (
    customerId: string,
    customerIdentifier: string
  ): Promise<IGridsColumnsPreferences | undefined> => {
    try {
      api.setCustomer(customerIdentifier);
      setState(old => apiReadingOn(old));
      const preferences = await api.get(`v1/Settings/usersettings/${GRIDS_KEY}/${customerId}`);
      setState(old => apiReadingOff(old));
      return preferences ? JSON.parse(preferences) : undefined;
    } catch (e) {
      setState(old => apiReadingOff(old));
      return undefined;
    }
  };

  const persistGridsColumnsPreferences = async (
    customerId: string,
    customerIdentifier: string,
    preferences: IGridsColumnsPreferences
  ) => {
    if (!preferences) {
      return;
    }

    delayedPost(async () => {
      api.setCustomer(customerIdentifier);
      const { id, setApiWritingOn } = apiWritingOn();
      try {
        setState(old => setApiWritingOn(old, GRIDS_KEY));
        await api.post(`v1/Settings/usersettings/${customerId}`, {
          key: GRIDS_KEY,
          value: JSON.stringify(preferences)
        });
        setState(old => apiWritingOff(id, old));
      } catch (e) {
        setState(old => apiWritingOff(id, old));
      }
    });
  };

  return {
    getPersistedUserPreferences,
    persistUserPreferences,
    getPersistedUserFilters,
    persistUserFilters,
    deleteUserFilters,
    getPersistedGridsColumnsPreferences,
    persistGridsColumnsPreferences
  };
};
