import React, { useEffect, useMemo, useRef, useState } from "react";
import { Pill, Tile, useSettings } from "@react-gcc-eds/core";
import { isFetching, useStore } from "../../../../store";
import { useMetricsActions } from "../../../../actions/metrics-actions";
import { useMetricsFiltersActions } from "../../../../actions/metrics-filters-actions";
import { Donut } from "@react-gcc-eds/charts";
import { Serie } from "@react-gcc-eds/charts/dist/components/donut/interfaces";
import { NoMetrics, noStatusMetric } from "../utils";
import { usePoStatus } from "../../helpers/po-status";
import DatePicker from "../../../filters/date-picker";
import CustomTabs, { ITab } from "../../../shared/custom-tabs";
import { DashboardGraph, DashboardTileAlignment, IDashboardItemSelected } from "..";
import { DashboardTileAlignment as TileAlignment } from "../index";
import { usePredefinedRanges } from "../../../filters/date-picker/predefined-ranges";
import { useSettingsActions } from "../../../../actions/settings-actions";
import { useWalkThrough } from "../../../walkthrough";
import { WalkthroughHeader } from "../../../walkthrough/components/walkthrough-icon";
import { useDashboardOverallOrderDonutChartSteps } from "../../../walkthrough/step-hooks/dashboard-steps";
import { dateAsYMDString } from "../../../../utils";
import { activeFiltersIcon } from "../../../../components/filters/filters-icon";
import cx from "classnames";
import { useLocalization } from "../../../../utils/localization";

const OverallOrderStatusDonut = ({
  ordersLabel,
  itemsLabel,
  customerId,
  className,
  alignment,
  onItemSelected
}: {
  ordersLabel: string;
  itemsLabel: string;
  customerId: string;
  className: string;
  alignment: TileAlignment;
  onItemSelected?(data: IDashboardItemSelected): void;
}) => {
  const [state] = useStore();
  const fetching =
    state.fetchingData.metrics.orderCreationDateDonutMetric &&
    state.fetchingData.metrics.itemCreationDateDonutMetric;
  const tileRef = useRef<HTMLDivElement>(null);
  const { currentCustomer } = useSettingsActions();
  const [hiddenOrdersStatuses, setHiddenOrdersStatuses] = useState([]);
  const [hiddenItemsStatuses, setHiddenItemsStatuses] = useState([]);
  const [tabs, setTabs] = useState<ITab[]>([]);
  const { steps } = useDashboardOverallOrderDonutChartSteps(
    tileRef,
    alignment === DashboardTileAlignment.LEFT ? "right" : "left"
  );
  const { localeString } = useLocalization();
  const maxWidth = useMemo(() => {
    if (tileRef.current) {
      return tileRef.current.clientWidth;
    }
  }, [tileRef.current, window.innerWidth]);

  const { WalkThrough, startWalkThrough } = useWalkThrough({
    steps,
    maxWidth
  });
  const { isWalkthroughEnabled } = useSettingsActions();
  const { getOrderCreationDateDonutMetric, getItemCreationDateDonutMetric } = useMetricsActions();
  const { setMetricsOverallOrderStatusDonutCreationRange } = useMetricsFiltersActions();

  const { labelToStatus } = usePoStatus();
  const { rangeAsString, largestRange, isLargestRange } = usePredefinedRanges(
    new Date(currentCustomer().dataAvailableFromDate)
  );
  const { theme } = useSettings();

  useEffect(() => {
    if (state.metricsFilters.overallOrderStatusDonutCreationRange) {
      const { from, to } = state.metricsFilters.overallOrderStatusDonutCreationRange;

      const rangeFrom = dateAsYMDString(from);
      const rangeTo = dateAsYMDString(to);

      getOrderCreationDateDonutMetric(rangeFrom, rangeTo);
      getItemCreationDateDonutMetric(rangeFrom, rangeTo);
    }
  }, [state.metricsFilters.overallOrderStatusDonutCreationRange, customerId]);

  const purchaseOrders = () => {
    const { orderCreationDateDonutMetric } = state.metrics;
    const { orderCreationDateDonutMetric: loadingOrder } = state.fetchingData.metrics;

    if (loadingOrder) {
      return <NoMetrics loading />;
    }
    if (noStatusMetric(orderCreationDateDonutMetric)) {
      return <NoMetrics />;
    }
    const d: Serie[] = [];
    orderCreationDateDonutMetric.forEach(m => {
      d.push({
        colorID: m.status,
        name: m.label,
        values: [m.totalCount],
        hidden: hiddenOrdersStatuses.find(i => i === m.status) !== undefined
      });
    });

    return (
      <Donut
        onClick={(label: string) => {
          onItemSelected &&
            onItemSelected({
              filters: {
                orderCreationRange: state.metricsFilters.overallOrderStatusDonutCreationRange,
                status: labelToStatus(label)
              },
              graph: DashboardGraph.Orders
            });
        }}
        data={{ series: d }}
        unit="orders"
        showValue
        showAbsoluteValue
        onToggle={(data: any) =>
          setHiddenOrdersStatuses(
            data.series.filter((d: Serie) => d.hidden).map((d: Serie) => d.colorID)
          )
        }
      />
    );
  };

  const purchaseOrdersItems = () => {
    const { itemCreationDateDonutMetric } = state.metrics;
    const { itemCreationDateDonutMetric: loading } = state.fetchingData.metrics;

    if (loading) {
      return <NoMetrics loading />;
    }
    if (noStatusMetric(itemCreationDateDonutMetric)) {
      return <NoMetrics />;
    }

    const d: Serie[] = [];
    itemCreationDateDonutMetric.forEach(m => {
      d.push({
        colorID: m.status,
        name: m.label,
        values: [m.totalCount],
        hidden: hiddenItemsStatuses.find(i => i === m.status) !== undefined
      });
    });

    return (
      <Donut
        onClick={(label: string) => {
          onItemSelected &&
            onItemSelected({
              filters: {
                orderCreationRange: state.metricsFilters.overallOrderStatusDonutCreationRange,
                status: labelToStatus(label)
              },
              graph: DashboardGraph.Items
            });
        }}
        data={{ series: d }}
        unit="order items"
        showAbsoluteValue
        showValue
        onToggle={(data: any) =>
          setHiddenItemsStatuses(
            data.series.filter((d: Serie) => d.hidden).map((d: Serie) => d.colorID)
          )
        }
      />
    );
  };

  const resetFilter = () => {
    setMetricsOverallOrderStatusDonutCreationRange(largestRange.selector());
  };

  const resetFilterPill = () => {
    return (
      <Pill className={cx("filter-pill", theme)} onToggle={resetFilter}>
        Reset filters
      </Pill>
    );
  };

  const filtersSummary = (
    <div className="dashboard-active-filter">
      <Pill>{rangeAsString(state.metricsFilters.overallOrderStatusDonutCreationRange)}</Pill>
      {!isLargestRange(state.metricsFilters.overallOrderStatusDonutCreationRange)
        ? resetFilterPill()
        : null}
    </div>
  );

  const dateFilter = (
    <DatePicker
      key="datepicker"
      dual={state.userPreferences.useDualCalendar}
      rolling
      right={alignment !== TileAlignment.LEFT}
      selectedRange={state.metricsFilters.overallOrderStatusDonutCreationRange}
      oldestDate={new Date(currentCustomer().dataAvailableFromDate)}
      onRangeSelected={setMetricsOverallOrderStatusDonutCreationRange}
    />
  );

  const tileHeader = (
    <div className="dashboard-tile">
      {!isLargestRange(state.metricsFilters.overallOrderStatusDonutCreationRange)
        ? activeFiltersIcon(1)
        : null}
      <WalkthroughHeader
        walkthroughActive={isWalkthroughEnabled()}
        tooltipInfo="Graph is showing the status for the selected time period"
        alignment={alignment}
        header={localeString("Dashboard_OverallOrderStatusPieChart") || "Overall order status"}
        onClick={startWalkThrough}
        disabled={isFetching(state)}
      />
    </div>
  );

  const purchaseOrderTab = {
    view: purchaseOrders(),
    title: ordersLabel,
    index: 0
  } as ITab;

  const purchaseOrderItemsTab = {
    view: purchaseOrdersItems(),
    title: itemsLabel,
    index: 1
  } as ITab;

  useEffect(() => {
    const tabs = [];
    if (ordersLabel !== undefined) {
      tabs.push(purchaseOrderTab);
    }
    if (itemsLabel !== undefined) {
      tabs.push(purchaseOrderItemsTab);
    }
    setTabs(tabs);
  }, [
    state.fetchingData.metrics.orderCreationDateDonutMetric,
    state.fetchingData.metrics.itemCreationDateDonutMetric,
    hiddenItemsStatuses,
    hiddenOrdersStatuses
  ]);

  return (
    <>
      <Tile
        parentRef={tileRef}
        className={className}
        lg={alignment === TileAlignment.FULL ? 12 : 6}
        sm={12}
        title={tileHeader}
        subtitle={filtersSummary}
        actions={[dateFilter]}
      >
        <CustomTabs tabs={tabs} />
      </Tile>
      {!fetching && <WalkThrough />}
    </>
  );
};

export default OverallOrderStatusDonut;
